'use strict'

import React, { useState } from 'react'
import { NewGrid, Text, Button } from '@citifyd/style'
import { useFormikContext } from 'formik'
import { get } from 'lodash'

import FormikField from '../../../../shared/react/components/FormikField'
import PhoneField from '../../../../shared/react/components/PhoneField'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import StickyFooter from '../../../../shared/react/components/StickyFooter'
import LoadingManager from '../../../../shared/react/components/LoadingManager'
import styles from './EnterRecipient.module.scss'

const EnterRecipient = ({ countries, setStep }) => {
  const t = useTranslator()
  const { values, errors } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const Helper = useAngularService('Helper')
  const Settings = useAngularService('Settings')
  const SchemaValidator = useAngularService('SchemaValidator')

  const handleContinue = async e => {
    e?.preventDefault()
    const phoneNumber = values.phoneNumber?.replace(/\s/g, '')
    const email = values.email.trim()

    if (errors.email) {
      Helper.showAlert(t('ticketTransfer.invalidEmail'))
      return
    }

    if (phoneNumber === '' && email === '') {
      Helper.showAlert(t('ticketTransfer.phoneOrEmailRequired'))
      return
    }

    let promise

    if (phoneNumber !== '') {
      promise = Settings.getDetailedCountryData(values.countryCode)
    } else {
      promise = Helper.promise()
    }

    setIsLoading(true)

    try {
      const country = await promise
      const schema = get(country, 'schemas.phoneNumber')

      if (schema) {
        const errors = SchemaValidator.getErrorMessages(schema, {
          phoneNumber
        })

        if (errors.length) {
          Helper.showAlert(errors[0])
          setIsLoading(false)
        } else {
          setStep(2)
        }
      } else {
        setStep(2)
      }
    } catch (err) {
      Helper.showErrorAlert(err.data)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <LoadingManager isLoading={isLoading} />
  }

  return (
    <form onSubmit={handleContinue}>
      <NewGrid.Row>
        <NewGrid.Col xs={12} md={6}>
          <PhoneField
            placeholder={t('permits.form.phoneNumber')}
            countries={countries}
            countryCodeDefault={values.countryCode || 'us'}
            label={t('ticketTransfer.recipientsPhone')}
          />
        </NewGrid.Col>
        <NewGrid.Col xs={12} md={6}>
          <NewGrid.Row>
            <NewGrid.Col xs={12} md={1} className={styles.or}>
              <Text>{t('ticketTransfer.or')}</Text>
            </NewGrid.Col>
            <NewGrid.Col xs={12} md={11}>
              <FormikField
                placeholder={t('ticketTransfer.email')}
                label={t('ticketTransfer.recipientsEmail')}
                name='email'
              />
            </NewGrid.Col>
          </NewGrid.Row>
        </NewGrid.Col>
      </NewGrid.Row>

      <StickyFooter justifyContent='flex-end'>
        <Button uppercase onMouseDown={handleContinue} extraPadding>
          {t('commonButtons.continue')}
        </Button>
      </StickyFooter>
    </form>
  )
}

export default EnterRecipient
