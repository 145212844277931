'use strict'

import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { NewGrid } from '@citifyd/style'

import ApolloClient from '../../shared/services/apolloClient'
import LoadingManager from '../../shared/react/components/LoadingManager'
import PageHeader from '../../shared/react/components/PageHeader'
import { useAngularService, useTranslator } from '../../shared/react/hooks'
import AddPermitForm from './components/AddPermitForm'
import ADMIN_GET_PERMIT_PASS_COMMON_FIELDS from '../../graphql/permitPass/queries/AdminGetPermitPassCommonFields'

const apolloClient = ApolloClient()

const AddPermit = () => {
  const t = useTranslator()
  const $stateParams = useAngularService('$stateParams')
  const $state = useAngularService('$state')
  const Permissions = useAngularService('Permissions')
  const Settings = useAngularService('Settings')
  const Properties = useAngularService('Properties')
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [countries, setCountries] = useState([])
  const [properties, setProperties] = useState([])
  const { permitId } = $stateParams
  const loadingMessage = t('permits.form.loading')

  const [getPermit, { data, loading }] = useLazyQuery(
    ADMIN_GET_PERMIT_PASS_COMMON_FIELDS,
    {
      variables: {
        id: parseInt(permitId)
      },
      client: apolloClient,
      fetchPolicy: 'network-only'
    }
  )

  const loadProperties = async () => {
    try {
      let params = {}

      if (Permissions.userMaySeeAllProperties()) {
        params = {
          all: true,
          includeOwner: true,
          order: 'id',
          reverse: true
        }
      }

      const response = await Properties.findAll(params)
      setProperties(response.data.lots)
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const loadCountries = async () => {
    setIsLoading(true)

    try {
      const countries = await Settings.getCountries()

      setCountries(countries)
    } catch (err) {
      setHasError(true)
      console.log('err', err)
    } finally {
      loadProperties()
    }
  }

  const loadPermit = async () => {
    try {
      getPermit()
    } catch (err) {
      console.log('err', err)
      setHasError(true)
    } finally {
      loadCountries()
    }
  }

  useEffect(() => {
    if (permitId) {
      loadPermit()
    } else {
      loadCountries()
    }
  }, [])

  if (isLoading || hasError || loading) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    )
  }

  const { admin_getPermitPass: permit } = data || {}

  if (permit?.status === 'PAST' || permit?.status === 'REMOVED') {
    return $state.go('permits')
  }

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          defaultState={permitId ? 'permit' : 'permits'}
          defaultParams={permitId ? { permitId } : null}
          weight='300'
          title={
            permitId ? t('permits.permitInformation') : t('permits.permits')
          }
        />
      </PageHeader>

      <AddPermitForm
        permit={permit}
        countries={countries}
        properties={properties}
      />
    </NewGrid>
  )
}

export default AddPermit
