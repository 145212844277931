import { useState } from 'react'
import { useAngularService } from '../../../../shared/react/hooks'

export const useTickets = ({ nonCustomer, setUser }) => {
  const [ticketsLoaded, setTicketsLoaded] = useState(false)
  const [ticketsError, setTicketsError] = useState()
  const Users = useAngularService('Users')
  const $stateParams = useAngularService('$stateParams')
  const { id: userId } = $stateParams

  const fetchTickets = async () => {
    if (nonCustomer) {
      setUser(old => ({
        ...old,
        tickets: []
      }))
      return
    }

    let tickets = []

    try {
      const response = await Users.fetchTickets(userId)

      tickets = response.data.tickets

      tickets.forEach(ticket => {
        ticket.statusTag = ticket.statusTags[0]

        if (ticket.bundlePurchaseId) {
          ticket.price = 0
        } else {
          ticket.price = ticket.rate.fee + ticket.rate.value
        }
      })

      setUser(old => ({
        ...old,
        tickets
      }))
    } catch (err) {
      setTicketsError(true)
    } finally {
      setTicketsLoaded(true)
    }
  }

  return {
    fetchTickets,
    ticketsLoaded,
    ticketsError
  }
}

export default useTickets
