'use strict'

import React from 'react'
import classNames from 'classnames'
import { Icon } from '@citifyd/style'

import './style.scss'

export default function RoundButton ({
  disabled = false,
  onClick,
  className,
  title,
  type
}) {
  const isDeleteButton = type === 'delete'

  const classes = classNames('component-round-button', className, {
    disabled: disabled,
    delete: isDeleteButton
  })

  return (
    <a
      href='#'
      onClick={onClick}
      className={classes}
      disabled={disabled}
      title={title}
    >
      <Icon
        pull='none'
        appearance='white'
        icon={isDeleteButton ? 'times' : 'plus'}
      />
    </a>
  )
}
