'use strict'

import React, { useEffect, useState } from 'react'
import { NewGrid } from '@citifyd/style'

import PageHeader from '../../shared/react/components/PageHeader'
import LoadingManager from '../../shared/react/components/LoadingManager'
import { useTranslator, useAngularService } from '../../shared/react/hooks'
import TicketTransactionData from './components/TicketTransactionData'

const Ticket = () => {
  const t = useTranslator()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const loadingMessage = t('ticket.loadingPass')
  const $stateParams = useAngularService('$stateParams')
  const Tickets = useAngularService('Tickets')
  const Helper = useAngularService('Helper')
  const [ticket, setTicket] = useState()
  const ticketId = $stateParams.id

  const loadTicket = async () => {
    setHasError(false)

    try {
      const response = await Tickets.load(ticketId)

      const ticket = response.data.ticket

      Helper.setPageTitle(
        t('ticket.pageTitleWithData', {
          id: ticket.id,
          eventName: ticket.event.name
        })
      )
      setTicket(ticket)
    } catch (err) {
      console.log('err', err)
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadTicket()
  }, [])

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    )
  }

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          defaultState={ticket?.user ? 'user' : 'users'}
          defaultParams={{ id: ticket?.user?.id }}
        />
      </PageHeader>

      <TicketTransactionData ticket={ticket} actionsEnabled />
    </NewGrid>
  )
}

export default Ticket
