'use strict'

import React from 'react'
import { FormikProvider as FormikProviderWrapper } from 'formik'

import FormikError from './FormikError'

const FormikProvider = ({ value, children, disableScroll = false }) => {
  return (
    <FormikProviderWrapper value={value}>
      {children}
      {!disableScroll && <FormikError />}
    </FormikProviderWrapper>
  )
}

export default FormikProvider
