'use strict'

import React, { useState } from 'react'
import { Badge } from '@citifyd/style'
import AnimateHeight from 'react-animate-height'

import { useTranslator } from '../../../../shared/react/hooks.js'
import TicketVoucherBox from '../TicketVoucherBox'
import styles from './TicketDeletedVoucherGroup.module.scss'

const TicketDeletedVoucherGroup = ({ ticket, vouchers }) => {
  const t = useTranslator()
  const [height, setHeight] = useState(0)

  return (
    <div>
      {!height && (
        <Badge
          className={styles.badge}
          onClick={() => setHeight('auto')}
          weight='700'
          appearance='gray'
          variant='subtitle'
        >
          {t('transaction.cancelledTransfersButton', {
            count: vouchers.length
          })}
        </Badge>
      )}

      <AnimateHeight duration={400} height={height} className={styles.animate}>
        {vouchers.map(voucher => (
          <TicketVoucherBox
            key={voucher.id}
            ticket={ticket}
            voucher={voucher}
          />
        ))}
      </AnimateHeight>
    </div>
  )
}

export default TicketDeletedVoucherGroup
