'use strict'

import angular from 'angular'
import _ from 'lodash'

import { formatPhoneNumber } from '../utils/phone-number-formatting'

const app = angular.module('citifydSellerApp')

app.filter('phoneNumber', function () {
  return function (input) {
    let phoneNumber = _.isString(input) ? input : _.get(input, 'phoneNumber')
    let countryCode =
      _.get(input, 'phoneCountryCode') || _.get(input, 'countryCode')
    return formatPhoneNumber(phoneNumber, countryCode)
  }
})
