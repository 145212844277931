'use strict'

import angular from 'angular'

import Footer from '../../react/components/Footer'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component('ctFooter', react2angular(Footer))
