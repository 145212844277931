import gql from 'graphql-tag'
import PermitCommonFields from '../fragments/PermitCommonFields'
import Address from '../../address/fragments/Address'

export default gql`
  ${Address}
  ${PermitCommonFields}
  query AdminGetPermitPass($id: Int) {
    admin_getPermitPass(id: $id) {
      ...PermitCommonFields
      lot {
        countryCode
        address {
          ...Address
        }
      }
      vehicle {
        license
      }
      status
      activatedAt
      user {
        name
      }
      requester {
        name
      }
    }
  }
`
