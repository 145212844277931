'use strict'

import React from 'react'
import { useTranslator } from '../../hooks'
import { camelCase, kebabCase } from 'lodash'
import { Badge } from '@citifyd/style'

export default function ReservedParkingStatusBadge ({ status }) {
  const t = useTranslator()

  const label = t('reservedParking.statuses.' + camelCase(status))

  const getBadgeAppearance = () => {
    const kebabCaseStatus = kebabCase(status)
    let appearance

    switch (kebabCaseStatus) {
      case 'ongoing':
      case 'purchased':
        appearance = { appearance: 'white' }
        break

      case 'complete':
        appearance = { appearance: 'completed', textAppearance: 'white' }
        break

      case 'cancelled':
        appearance = { appearance: 'tertiary', textAppearance: 'white' }
        break

      default:
        appearance = { appearance: 'gray' }
        break
    }

    return appearance
  }

  return <Badge {...getBadgeAppearance()}>{label}</Badge>
}
