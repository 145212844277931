/* global FormData */

'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Properties', (API, Authentication, $http) => {
  function findAllProperties (options) {
    return $http({
      method: 'GET',
      params: options,
      url: API.base + '/admin/lots',
      headers: Authentication.generateHeaders()
    })
  }

  function createProperty (data) {
    return $http({
      method: 'POST',
      url: API.base + '/me/lots',
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  function updateProperty (property) {
    return $http({
      method: 'PUT',
      url: API.base + '/me/lots/' + property.id,
      headers: Authentication.generateHeaders(),
      data: property
    })
  }

  function updatePropertyVisibility (propertyId, isVisible) {
    return $http({
      method: 'PUT',
      url: API.base + '/me/lots/' + propertyId,
      headers: Authentication.generateHeaders(),
      data: {
        id: propertyId,
        isVisible: isVisible
      }
    })
  }

  function uploadPhotoToProperty (lotId, file) {
    var formData = new FormData()
    formData.append('image', file)

    var url = API.base + '/me/lots/' + lotId + '/photos'
    var headers = Authentication.generateHeaders()

    return $http.post(url, formData, {
      transformRequest: angular.identity,
      headers: { ...headers, 'Content-Type': undefined }
    })
  }

  function destroyProperty (id) {
    return $http({
      method: 'DELETE',
      url: API.base + '/me/lots/' + id,
      headers: Authentication.generateHeaders()
    })
  }

  function findProperty (id) {
    return $http({
      method: 'GET',
      url: API.base + '/me/lots/' + id,
      headers: Authentication.generateHeaders()
    })
  }

  function getSubscriptions (id) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/lots/' + id + '/subscriptions',
      headers: Authentication.generateHeaders()
    })
  }

  function fetchSchedule (id, options) {
    return $http({
      method: 'GET',
      params: options,
      url: API.base + '/admin/lots/' + id + '/schedule',
      headers: Authentication.generateHeaders()
    })
  }

  function fetchWeeklySchedule (id) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/lots/' + id + '/schedule/weekly',
      headers: Authentication.generateHeaders()
    })
  }

  function updateSchedule (id, schedule) {
    return $http({
      method: 'PUT',
      url: API.base + '/admin/lots/' + id + '/schedule',
      headers: Authentication.generateHeaders(),
      data: { availabilities: schedule }
    })
  }

  return {
    findAll: findAllProperties,
    find: findProperty,
    create: createProperty,
    update: updateProperty,
    fetchSchedule: fetchSchedule,
    fetchWeeklySchedule: fetchWeeklySchedule,
    getSubscriptions: getSubscriptions,
    updateVisibility: updatePropertyVisibility,
    uploadPhoto: uploadPhotoToProperty,
    updateSchedule: updateSchedule,
    destroy: destroyProperty
  }
})
