import { find } from 'lodash'
import * as yup from 'yup'

export const parseEvents = events => {
  return events.map(event => {
    event.lots.forEach(lot => {
      var regular = find(lot.spaces, { name: 'regular' })
      var max = regular?.max || 0
      var occupied = regular?.occupied || 0

      lot.soldOut = max - occupied === 0
    })

    return event
  })
}

export const makeValidationSchema = () => {
  return yup.object().shape({
    eventId: yup.string(),
    lotId: yup.string(),
    cardId: yup.string(),
    allowDifferentRate: yup.boolean(),
    reason: yup
      .string()
      .trim()
      .required(),
    requestedByEmail: yup.boolean(),
    requestedByPhone: yup.boolean()
  })
}

export const getData = values => {
  const {
    selectedEvent,
    selectedLot,
    selectedCardId,
    reason,
    requestedByEmail,
    requestedByPhone
  } = values

  let data = {
    eventId: selectedEvent.id,
    lotId: selectedLot.id,
    extra: {
      reason,
      requestedByEmail,
      requestedByPhone
    },
    allowDifferentRate: true
  }

  if (selectedCardId) {
    data.cardId = selectedCardId
  }

  return data
}
