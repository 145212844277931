'use strict'

import angular from 'angular'
import _ from 'lodash'
import { react2angular } from 'react2angular'

import PhoneVerification from '../../react/components/PhoneVerification'

const app = angular.module('citifydSellerApp')

app.component(
  'ctPhoneVerification',
  react2angular(PhoneVerification, [
    'title',
    'user',
    'onVerify',
    'onCancel',
    'onLogout'
  ])
)
