'use strict'

import React, { useState, useEffect, useRef } from 'react'
import { NewGrid, Select, Text } from '@citifyd/style'

import Table from '../../../../shared/react/components/Table'
import { useTranslator } from '../../../../shared/react/hooks'
import {
  generateOrderForClickEvent,
  sort
} from '../../../../shared/react/sorting'
import { getTranslator } from '../../../../shared/react/utils'
import SearchInput from '../../../../shared/react/components/SearchInput'
import { getFilterOptions, getData, statusCondition } from './utils'
import styles from './UserTicketsTable.module.scss'

const UserTicketsTable = ({ user }) => {
  const { tickets } = user
  const t = useTranslator()
  const [sortedTickets, setSortedTickets] = useState(tickets)
  const [orders, setOrders] = useState([['start', 'desc']])
  const [search, setSearch] = useState('')
  const [currentFilter, setCurrentFilter] = useState({
    status: '',
    searchQuery: ''
  })
  const timer = useRef(null)

  useEffect(() => {
    setSortedTickets(
      sort({ items: sortedTickets, order: orders, sortFunctions })
    )
  }, [tickets, orders, currentFilter])

  if (!tickets.length) {
    return null
  }

  const handleSort = (event, property) => {
    setOrders(
      generateOrderForClickEvent({
        event,
        currentOrder: orders,
        propertyName: property
      })
    )
  }

  const { columns, data } = getData({
    tickets: sortedTickets,
    handleSort,
    orders,
    search
  })

  const updateFilters = filters => {
    const { status, searchQuery } = filters
    const search = searchQuery.trim()

    const items = tickets.filter(ticket => {
      const { statusTag, event } = ticket

      const searchCondition = search
        ? event.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
        : true

      if (status) {
        return statusCondition(status, statusTag) && searchCondition
      }

      return searchCondition
    })

    setSortedTickets(items)
    setCurrentFilter(filters)
  }

  const handleStatus = e => {
    const value = e.target.value

    const newCurrentFilter = {
      ...currentFilter,
      status: value
    }

    updateFilters(newCurrentFilter)
  }

  const handleSearch = e => {
    setSearch(e.target.value)

    if (timer) {
      clearTimeout(timer.current)
    }

    const newCurrentFilter = {
      ...currentFilter,
      searchQuery: e.target.value
    }

    timer.current = setTimeout(() => {
      updateFilters(newCurrentFilter)
    }, 500)
  }

  return (
    <>
      <NewGrid.Row marginBottom justify='between'>
        <NewGrid.Col xs={12} sm={6} lg={3}>
          <Select
            className={styles.filter}
            fullWidth
            name='status'
            options={getFilterOptions(tickets)}
            value={currentFilter.status}
            onChange={e => handleStatus(e)}
          />
        </NewGrid.Col>
        <NewGrid.Col xs={12} sm={6} lg={3}>
          <SearchInput value={search} onChange={handleSearch} fullWidth />
        </NewGrid.Col>
      </NewGrid.Row>

      {!sortedTickets.length ? (
        <Text>{t('user.noResults')}</Text>
      ) : (
        <Table
          columns={columns}
          data={data}
          sortable
          clickable
          responsive
          striped
        />
      )}
    </>
  )
}

const sortFunctions = {
  status: status => {
    const t = getTranslator()
    return t(`reservations.statuses.${status}`).toLowerCase()
  },
  price: amountCharged => {
    let priceAsNumber = Number(amountCharged)
    if (isNaN(priceAsNumber)) priceAsNumber = 0
    return priceAsNumber
  }
}

export default UserTicketsTable
