import React, { useMemo } from 'react'
import { Radio, Text } from '@citifyd/style'
import CurrencyFormatter from '@citifyd/currency-formatter'

import {
  getCurrentLanguage,
  getTranslator
} from '../../../../shared/react/utils'
import styles from '../../TicketExchange.module.scss'

const getColumns = () => {
  const t = getTranslator()

  return [
    {
      key: 'radio',
      width: '1%',
      value: ''
    },
    {
      key: 'lot',
      width: '80%',
      value: t('ticketExchange.lot')
    },
    {
      key: 'difference',
      width: '20%',
      value: t('ticketExchange.difference')
    }
  ]
}

const formatLots = (lots, ticket, selectedEvent) => {
  const currentRate = ticket.rate.value + ticket.rate.fee

  const formattedLots = lots.map(lot => {
    const rate = lot.rate.value + lot.rate.fee
    let diff = rate - currentRate

    if (
      Math.abs(diff) < 50 ||
      (selectedEvent.id === ticket.event.id && lot.id === ticket.lot.id)
    ) {
      diff = 0
    }

    lot.rateDifference = diff

    if (ticket.lot.id === lot.id) {
      return {
        ...lot,
        isCurrentLot: true
      }
    }

    return lot
  })

  return formattedLots
}

const getDifference = lot => {
  const t = getTranslator()
  const language = getCurrentLanguage()

  if (lot.rateDifference === 0) {
    return t('ticketExchange.noCharge')
  }

  return lot.rateDifference > 0
    ? CurrencyFormatter.format(lot.rateDifference, {
        currency: lot.country.currency,
        language
      })
    : t('ticketExchange.refundAmount', {
        amount: Math.abs(lot.rateDifference),
        currency: lot.country.currency
      })
}

export const getData = (
  selectedEvent,
  handleSelectEvent,
  selectedLot,
  ticket
) => {
  const t = getTranslator()
  const { lots } = selectedEvent

  const formattedLots = useMemo(
    () => formatLots(lots, ticket, selectedEvent),
    []
  )

  const data = formattedLots.map(lot => ({
    ...(!lot.soldOut && { onClick: () => handleSelectEvent(lot) }),
    hover: false,
    radio: {
      value: !lot.soldOut ? (
        <Radio disabled={lot.soldOut} checked={lot.id === selectedLot} />
      ) : (
        <div></div>
      ),
      noVerticalPadding: true,
      verticalAlign: 'middle'
    },
    lot: {
      value: (
        <Text className={styles.currentLot} bold={lot.isCurrentLot}>
          {lot.name}{' '}
          {lot.soldOut && !lot.isCurrentLot && (
            <i>{t('ticketExchange.soldOut')}</i>
          )}
          {lot.isCurrentLot && t('ticketExchange.currentLot')}
        </Text>
      ),
      verticalAlign: 'middle'
    },
    difference: {
      value: useMemo(() => getDifference(lot), []),
      textColor: lot.rateDifference < 0 ? 'tertiary' : 'default',
      verticalAlign: 'middle'
    }
  }))

  return {
    columns: getColumns(),
    data,
    lots: formattedLots
  }
}
