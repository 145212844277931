import gql from 'graphql-tag'

export default gql`
  query AdminGenerateReservedParkingDefaultRefundOptions($passId: Int!) {
    admin_generateReservedParkingDefaultRefundOptions(
      reservedParkingPassId: $passId
    ) {
      refunder
      feeRetentionMethod
      stripeFeePayer
    }
  }
`
