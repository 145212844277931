'use strict'

import angular from 'angular'

import './generate-report.html'
import './generate-report.scss'

const app = angular.module('citifydSellerApp')

app.controller('GenerateReportModalCtrl', function (
  $scope,
  $sce,
  $window,
  $timeout,
  $element,
  close,
  params
) {
  $scope.step = 'confirm'
  $scope.filterDescription = params.disableFilterDescriptionFirstCharacterLowercase
    ? params.filterDescription
    : params.filterDescription[0].toLowerCase() +
      params.filterDescription.substr(1)
  $scope.fileFormat = params.fileFormat.toLowerCase()
  $scope.isGenerating = false

  // Generate the report
  $scope.generate = function () {
    if ($scope.fileFormat === 'csv') {
      $scope.step = 'generated'
      $scope.downloadUrl = $sce.trustAsResourceUrl(params.reportUrl)
    } else {
      $scope.isGenerating = true // Not implemented for other formats
    }
  }

  // Cancel the modal
  $scope.cancel = function () {
    $element.remodal().close({
      action: 'cancel'
    })
  }

  // Close modal after successful action
  $scope.successClose = function () {
    $element.remodal().close({
      action: 'confirmed'
    })
  }

  // When remodal is closed, we send a close message to the controller that opened this modal.
  $element.on('closed', function (e) {
    close(e.reason)
  })
})
