'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.filter('nl2br', function () {
  return function (input, options) {
    if (!input) {
      return ''
    }

    options = {
      safeHtml: false,
      ...options
    }

    if (!options.safeHtml) {
      input = input.replace(/</g, '&lt;').replace(/>/g, '&gt;')
    }

    return input.replace(/\n/g, '<br>\n')
  }
})
