'use strict'

import angular from 'angular'

import templateUrl from './event.html'
import './event.scss'

import gatesTemplateUrl from './views/gates.html'
import ticketsTemplateUrl from './views/tickets.html'
import waitlistTemplateUrl from './views/waitlist.html'

const app = angular.module('citifydSellerApp')

app.controller('EventCtrl', function (
  Authentication,
  Events,
  Helper,
  Permissions,
  $scope,
  $state,
  $stateParams,
  $location
) {
  var eventId = $stateParams.id

  $scope.userMayEditEvent = function () {
    return Permissions.userMayEditEvent()
  }

  $scope.activateTab = function (tabName, changeState = true) {
    $scope.tab = tabName
    $scope.$broadcast('activate-tab:' + tabName)

    if (changeState) {
      $state.transitionTo(
        'event',
        { id: eventId, tab: tabName },
        { location: 'replace', notify: false, inherit: false }
      )
    }
  }

  $scope.isTabActive = function (tabName) {
    return $scope.tab === tabName
  }

  function loadEvent () {
    $scope.loadingEvent = true

    return Events.find(eventId).then(
      function (response) {
        $scope.loadingEvent = false
        $scope.event = response.data.event

        Helper.setPageTitle($scope.event.name)
      },

      function (response) {
        $scope.event = null
        $scope.loadingEvent = false

        if (response.status === 404) {
          $location.url('/')
        } else {
          $scope.loadingError = true
          Helper.showErrorAlert(response)
        }
      }
    )
  }

  function init () {
    $scope.tab = null

    $scope.gatesTemplateUrl = gatesTemplateUrl
    $scope.ticketsTemplateUrl = ticketsTemplateUrl
    $scope.waitlistTemplateUrl = waitlistTemplateUrl

    $scope.canSeeUserInformation = Permissions.userMaySeeUserInformation()

    return loadEvent().then(function () {
      if ($scope.event) {
        $scope.activateTab($stateParams.tab || 'gates', false)
      }
    })
  }

  init()
})

export const controller = 'EventCtrl'
export const auth = true
export const title = 'i18n:event.pageTitle'
export { templateUrl }
