'use strict'

import React, { useState, useEffect } from 'react'
import { Select, NewGrid, DatePicker } from '@citifyd/style'
import { find } from 'lodash'
import moment from 'moment-timezone'

import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import { getFilterSelectionFromState } from '../../utils'
import styles from './RevenueFilter.module.scss'
import { selectionToParams, getLotsOptions } from './utils'
import CSVDownload from '../../../../shared/react/components/CSVDownload'
import {
  getAngularService,
  getCurrentLanguage
} from '../../../../shared/react/utils'

const MAX_RANGE_ALLOWED_IN_MONTHS = 6

const RevenueFilter = ({
  enableLotSelection,
  enableDateRangeSelection = true,
  lots = [],
  currencies = [],
  onClickGenerateCsv
}) => {
  const t = useTranslator()
  const Authentication = useAngularService('Authentication')
  const $state = useAngularService('$state')
  const isAdmin = Authentication.getLoggedUser().isAdmin
  const [selection, setSelection] = useState({})
  const [errors, setErrors] = useState({})
  const language = getCurrentLanguage()

  useEffect(() => {
    const $state = getAngularService('$state')
    const params = getFilterSelectionFromState($state, currencies)

    setSelection(params)
    validate(params)
  }, [])

  const updateFilters = newSelection => {
    if (!validate(newSelection)) {
      return false
    }

    $state.transitionTo(
      $state.current.name,
      selectionToParams(newSelection, enableDateRangeSelection),
      {
        location: 'replace',
        notify: false,
        inherit: true
      }
    )
  }

  const fieldChanged = e => {
    const value = e.target.value
    const name = e.target.name

    let newSelection = {
      ...selection,
      [name]: value
    }

    if (name === 'lotId') {
      const lot = find(lots, lot => lot.id.toString() === value)

      if (lot) {
        newSelection = {
          ...newSelection,
          currency: lot.country.currency
        }
      }
    }

    setSelection(newSelection)
    updateFilters(newSelection)
  }

  const validate = newSelection => {
    if (enableDateRangeSelection && newSelection.dateRange) {
      const { dateRange } = newSelection
      const startDate = moment(dateRange.start)
      const endDate = moment(dateRange.end)
      let valid = true

      if (startDate.isAfter(endDate)) {
        setErrors(old => ({
          ...old,
          ['dateRange.end']: t(
            'revenue.revenueFilter.errors.endDate.mustBeAfterStartDate'
          )
        }))
        valid = false
      }

      if (endDate.diff(startDate, 'months') > MAX_RANGE_ALLOWED_IN_MONTHS) {
        setErrors(old => ({
          ...old,
          ['dateRange.end']: t(
            'revenue.revenueFilter.errors.endDate.rangeLimitReached',
            {
              maxMonths: MAX_RANGE_ALLOWED_IN_MONTHS
            }
          )
        }))
        valid = false
      }

      if (valid) {
        setErrors({})
      }

      return valid
    }
  }

  const setDate = (field, date) => {
    const newSelection = {
      ...selection,
      dateRange: {
        ...selection.dateRange,
        [field]: date
      }
    }

    setSelection(newSelection)
    updateFilters(newSelection)
  }

  const lotsOptions = getLotsOptions(lots, t)

  const currenciesOptions = currencies.map(currency => ({
    value: currency.code,
    label: currency.name
  }))

  const generateCsvClicked = () => {
    if (!!validate(selection) && onClickGenerateCsv) {
      onClickGenerateCsv()
    }
  }

  return (
    <NewGrid.Row marginBottom>
      <NewGrid.Col md={11} className={styles.boxFilters}>
        <NewGrid.Row>
          {enableLotSelection && (
            <NewGrid.Col className={styles.column} sm={12} md={3}>
              <Select
                fullWidth
                name='lotId'
                labelSize='small'
                label={t('revenue.revenueFilter.fields.property')}
                options={lotsOptions}
                defaultValue=''
                value={selection.lotId || ''}
                onChange={e => fieldChanged(e)}
              />
            </NewGrid.Col>
          )}
          {isAdmin && (
            <NewGrid.Col className={styles.column} sm={12} md={3}>
              <Select
                fullWidth
                name='currency'
                labelSize='small'
                label={t('revenue.revenueFilter.fields.currency')}
                options={currenciesOptions}
                value={selection.currency || ''}
                onChange={e => fieldChanged(e)}
              />
            </NewGrid.Col>
          )}
          {enableDateRangeSelection && (
            <>
              <NewGrid.Col className={styles.column} sm={12} md={3}>
                <DatePicker
                  locale={language}
                  onChange={date => setDate('start', date)}
                  selectedDate={selection.dateRange?.start}
                  label={t('revenue.revenueFilter.fields.startDate')}
                />
              </NewGrid.Col>
              <NewGrid.Col className={styles.column} sm={12} md={3}>
                <DatePicker
                  locale={language}
                  onChange={date => setDate('end', date)}
                  selectedDate={selection.dateRange?.end}
                  label={t('revenue.revenueFilter.fields.endDate')}
                  error={errors['dateRange.end']}
                  errorMessage={t(errors['dateRange.end'])}
                />
              </NewGrid.Col>
            </>
          )}
        </NewGrid.Row>
      </NewGrid.Col>
      <NewGrid.Col sm={12} md={1} className={styles.boxCSV}>
        <CSVDownload
          disabled={errors['dateRange.end']}
          onClick={generateCsvClicked}
        />
      </NewGrid.Col>
    </NewGrid.Row>
  )
}

export default RevenueFilter
