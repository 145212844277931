'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.controller('LoginTwoFactorStepCtrl', function (
  $scope,
  $window,
  $i18next,
  Authentication,
  Helper
) {
  $scope.cancel = function () {
    if (!$window.confirm('Are you sure you would like to cancel?')) {
      return
    }

    $window.location = '/'
  }

  $scope.verify = function () {
    $scope.isLoading = true

    const data = {
      ...$scope.credentials,
      twoFactorAuthenticationCode: $scope.code,
      twoFactorAuthenticationTrustPermanently: $scope.remember
    }

    Authentication.tryAuthenticate(data).then(
      function success (response) {
        if (response.user) {
          $scope.handleSuccessfulLogin() // defined in login.js
        }
      },

      function error (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)
      }
    )
  }

  $scope.resend = function (method) {
    $scope.isLoading = true

    const data = {
      ...$scope.credentials,
      twoFactorAuthenticationMethod: method
    }

    Authentication.tryAuthenticate(data).then(
      function success (response) {
        $scope.isLoading = false

        if (response.twoFactorAuthentication) {
          showMessageSentAlert(method)
        } else if (response.user) {
          $scope.handleSuccessfulLogin() // defined in login.js
        }
      },

      function error (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)
      }
    )
  }

  function showMessageSentAlert (method) {
    let message

    switch (method) {
      case 'sms':
        message = $i18next.t('login.twoFactor.resent.sms')
        break

      case 'call':
        message = $i18next.t('login.twoFactor.resent.call')
        break
    }

    if (message) {
      Helper.showAlert(message)
    }
  }

  function init () {
    $scope.isLoading = false
    $scope.credentials = null
    $scope.code = ''
    $scope.remember = false

    $scope.$on('step-changed', (e, data) => {
      $scope.credentials = data.credentials
    })
  }

  init()
})
