import { getAngularService } from '../../../react/utils'

export const loadCountries = async () => {
  const Helper = getAngularService('Helper')
  const Settings = getAngularService('Settings')

  const response = await Settings.getCountries()
  return Helper.planifyCountries(response).map(country => ({
    label: country.name,
    value: country.isoCode
  }))
}

export const loadUserData = async userId => {
  const Users = getAngularService('Users')
  const response = await Users.fetchData(userId)
  return response.data.user
}

export const loadSelectedCountryData = async (
  countryCode,
  trackLoading,
  untrackLoading,
  formikbag
) => {
  const Settings = getAngularService('Settings')

  trackLoading('loadSelectedCountryData')
  try {
    const data = await Settings.getDetailedCountryData(countryCode)
    formikbag.setFieldValue('selectedCountryData', data)
  } catch (err) {
    console.log('err', err)
  } finally {
    untrackLoading('loadSelectedCountryData')
  }
}

export const countryPostalCodeProperty = (countryData, propertyName) => {
  return (
    countryData?.schemas?.address?.properties?.postalCode[propertyName] ?? null
  )
}

export const COMPLETE_CARD_CLASS_NAME = 'citifyd-stripe-card-complete'

// Checks if the card field from Stripe is complete imperatively, when the user
// hasn't yet typed anything on the field.
// Based on the solution from hofman-stripe at:
// https://github.com/stripe-archive/react-stripe-elements/issues/283#issuecomment-772659591
export const isStripeCardComplete = cardRef => {
  return (
    cardRef.current.getElementsByClassName(COMPLETE_CARD_CLASS_NAME).length > 0
  )
}
