'use strict'

import produce from 'immer'
import _ from 'lodash'

import { buildRule, revalidateRuleField, validateRule } from '../utils/rules'
import { sortFunctions } from '../utils/sorting'
import { generateOrderForClickEvent, sort } from '../../../shared/react/sorting'

import {
  ADD_RULE,
  DUPLICATE_RULE,
  SORT_RULES,
  DELETE_RULE,
  UPDATE_RULE_FIELD,
  VALIDATE_RULES,
  START_LOADING,
  STOP_LOADING,
  PATCH_STATE,
  MARK_EDITABLE_RULES_AS_PERSISTED,
  PATCH_PREVIEW_STATE
} from './actions'

const INITIAL_STATE = {
  // Loading
  isLoading: true,
  loadingMessage: null,

  // Mode (can be 'view', 'edit' or 'preview')
  mode: 'view',

  // Lot and rate definition, they're set when they page is loaded
  lot: null,
  rateDefinition: null,

  // States for editor
  editableRules: [], // Rules extracted from 'rateDefinition' and transformed to front-end friendly format
  hasUnsavedRules: false,
  editEnabled: false,

  // States for preview screen
  preview: {
    timeRanges: {},
    selectedDay: null,
    error: null
  },

  // Auxiliary rule fields
  lastRuleId: 0, // used for generating new rule IDs
  rulesOrder: [['id', 'asc']] // rules can be sorted by one or more columns
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_RULE:
      return produce(state, state => {
        const ruleId = ++state.lastRuleId
        const newRule = buildRule({ id: ruleId })
        state.editableRules.push(newRule)
        state.hasUnsavedRules = true
      })

    case DUPLICATE_RULE:
      return produce(state, state => {
        const ruleIndex = _.findIndex(
          state.editableRules,
          rule => rule.id === action.payload.id
        )
        const ruleClone = _.cloneDeep(state.editableRules[ruleIndex])
        ruleClone.id = ++state.lastRuleId
        state.editableRules.splice(ruleIndex + 1, 0, ruleClone)
        state.hasUnsavedRules = true
      })

    case DELETE_RULE:
      return produce(state, state => {
        _.remove(state.editableRules, rule => rule.id === action.payload.id)
        state.hasUnsavedRules = true
      })

    case UPDATE_RULE_FIELD:
      return produce(state, state => {
        const { id, fieldPath, fieldValue } = action.payload
        const rule = _.find(state.editableRules, rule => rule.id === id)
        if (rule) {
          _.set(rule, fieldPath, fieldValue)
          revalidateRuleField(rule, fieldPath)
          state.hasUnsavedRules = true
        }
      })

    case VALIDATE_RULES:
      return produce(state, state => {
        for (const rule of state.editableRules) {
          const { errors } = validateRule(rule)
          rule.errors = errors
        }
      })

    case START_LOADING:
      return produce(state, state => {
        state.isLoading = true
        state.loadingMessage = _.get(action.payload, 'message', null)
      })

    case STOP_LOADING:
      return produce(state, state => {
        state.isLoading = false
        state.loadingMessage = null
      })

    case SORT_RULES:
      return produce(state, state => {
        state.rulesOrder = generateOrderForClickEvent({
          currentOrder: state.rulesOrder,
          propertyName: action.payload.propertyName,
          event: action.payload.event
        })

        state.editableRules = sort({
          items: state.editableRules,
          order: state.rulesOrder,
          sortFunctions
        })
      })

    case MARK_EDITABLE_RULES_AS_PERSISTED:
      return produce(state, state => {
        state.hasUnsavedRules = false
      })

    case PATCH_PREVIEW_STATE:
      return produce(state, state => {
        state.preview = { ...state.preview, ...action.payload.patch }
      })

    case PATCH_STATE:
      return { ...state, ...action.payload.patch }

    default:
      return state
  }
}
