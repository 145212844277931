'use strict'

import angular from 'angular'
import _ from 'lodash'

import '../views/step-3.html'

const app = angular.module('citifydSellerApp')

app.controller('AddPropertyStep3Ctrl', function (
  Helper,
  Properties,
  $location,
  $scope,
  $window,
  $i18next
) {
  $scope.forms = {}

  $scope.addGate = function () {
    var last = _.last($scope.gates)
    var shouldAdd = !last || _.get(last, 'name')

    if (shouldAdd) {
      $scope.gates.push({
        name: '',
        scannerCode: null,
        beaconUids: '',
        isSystemGate: false,
        type: 'ingress',
        armedGate: false,
        reportsStatus: false,
        hasBarrier: false,
        automaticActivation: false,
        hasAttendant: false
      })
    }
  }

  $scope.destroyGate = function (gate) {
    if (
      gate.id &&
      !$window.confirm($i18next.t('addProperty.gates.confirmRemoval'))
    ) {
      return false
    }

    if (gate.id) {
      gate.destroy = true
    } else {
      _.remove($scope.gates, gate)
    }
  }

  function adjustGates (gates) {
    gates = angular.copy(gates)

    _.forEach(gates, function (gate) {
      gate.beaconUids = (gate.beaconUids || [])
        .map(function (gate) {
          return gate.trim()
        })
        .filter(function (gate) {
          return gate !== ''
        })

      if (_.isEmpty(gate.scannerCode)) {
        gate.scannerCode = null
      }
    })

    return gates
  }

  function save () {
    $scope.$broadcast('show-errors-check-validity')

    if ($scope.forms.gatesForm.$invalid) {
      Helper.scrollToError(true)
      return false
    }

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'addProperty.loadingMessages.savingGateInfo'
    )

    var data = {
      id: $scope.lot.id,
      gates: adjustGates($scope.gates)
    }

    Properties.update(data).then(
      function () {
        $location.path('/properties').search('')
      },

      function (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)
      }
    )
  }

  function buildSelectizeOptions (gates) {
    gates.forEach(function (gate) {
      gate.selectizeOptions = _.map(gate.beaconUids, function (uid) {
        return { text: uid, value: uid }
      })
    })
  }

  function init () {
    $scope.gates = []

    $scope.$watch('lot', function () {
      if ($scope.lot) {
        $scope.gates = angular.copy($scope.lot.gates)
        buildSelectizeOptions($scope.gates)
      }
    })

    $scope.$on('add-property-step-3-submitted', save)

    $scope.selectizeConfig = { delimiter: ';', create: true }
  }

  init()
})
