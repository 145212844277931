'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('InviteParkersConfirmationCtrl', function (
  Helper,
  Users,
  $i18next,
  $window,
  $scope
) {
  $scope.submitForm = function () {
    if (!validate()) {
      return
    }

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'inviteParkers.confirmation.creatingAccounts'
    )

    const users = $scope.accounts.map(account => {
      account = _.pick(account, ['name', 'email', 'phoneNumber'])

      if (account.phoneNumber) {
        account.phoneCountryCode = 'us' // only US for now
      }

      return account
    })

    const bundles = _($scope.bundles)
      .filter('selected')
      .map(bundle => _.pick(bundle, 'id'))
      .value()

    const events = _($scope.events)
      .filter('selected')
      .map(event => ({
        eventId: event.id,
        lotId: event.selectedLot.id,
        availabilityId: event.selectedLot.availability.id
      }))
      .value()

    const data = {
      users: users,
      bundles: bundles,
      events: events,
      reason: $scope.reason,
      extra: $scope.extraInfo
    }

    Users.createParkerSignupInvitation(data).then(
      function success (response) {
        $scope.changeStep(5, {
          accounts: $scope.accounts,
          bundles: $scope.bundles,
          events: $scope.events,
          result: response.data
        })
      },

      function error (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)
      }
    )
  }

  function validate () {
    if ($scope.reason.trim() === '') {
      $window.alert(
        $i18next.t('inviteParkers.confirmation.reason.missingError')
      )
      return false
    }

    return true
  }

  function init () {
    $scope.isLoading = false
    $scope.hasPurchases = false
    $scope.reason = ''
    $scope.extraInfo = { requestedByPhone: false, requestedByEmail: false }

    $scope.$on('init-step-4', function (e, data) {
      $scope.accounts = data.accounts
      $scope.bundles = data.selectedBundles
      $scope.events = data.selectedEvents
      $scope.hasPurchases =
        data.selectedBundles.length > 0 || data.selectedEvents.length > 0
    })
  }

  init()
})
