'use strict'

import angular from 'angular'

import './closeout-cashier.html'

const app = angular.module('citifydSellerApp')

app.controller('CloseoutCashierModalCtrl', function (
  $scope,
  $element,
  GateEntries,
  Helper,
  params,
  close
) {
  var eventId = params.eventId
  var eventGateId = params.eventGateId

  $scope.success = false

  $scope.gateName = params.eventGateName

  $scope.closeout = {
    eventGateId: eventGateId,
    name: '',
    counter: ''
  }

  $scope.close = function (data) {
    $element.remodal().close(data)
  }

  $scope.closeAfterSave = function () {
    $scope.close({ action: 'saved' })
  }

  $scope.save = function () {
    $scope.$broadcast('show-errors-check-validity')

    if ($scope.closeoutCashierForm.$invalid) {
      return false
    }

    $scope.isLoading = true

    GateEntries.closeoutCashier(eventId, $scope.closeout).then(
      function () {
        $scope.isLoading = false
        $scope.success = true
      },

      function (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)
      }
    )
  }

  $element.on('closed', function (e) {
    close(e.reason)
  })
})
