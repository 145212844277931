import { getAngularService, getTranslator } from '../../../react/utils'

export const downloadTicket = async ({ ticketId, language }) => {
  const FileSaver = getAngularService('FileSaver')
  const Tickets = getAngularService('Tickets')
  const t = getTranslator()

  try {
    const response = await Tickets.exportPurchaseReceipt(ticketId, language)
    const data = new Blob([response.data], { type: 'application/pdf' })
    FileSaver.saveAs(data, `parking-receipt-${ticketId}.pdf`)

    return {
      status: 'success'
    }
  } catch (err) {
    return {
      status: 'error',
      reason: t('transaction.pdfGenerationError')
    }
  }
}

export const downloadBundlePurchase = async ({
  bundlePurchaseId,
  language
}) => {
  const t = getTranslator()
  const BundlePurchases = getAngularService('BundlePurchases')
  const FileSaver = getAngularService('FileSaver')

  try {
    const response = await BundlePurchases.exportPurchaseReceipt(
      bundlePurchaseId,
      language
    )
    const data = new Blob([response.data], { type: 'application/pdf' })
    FileSaver.saveAs(data, `bundle-purchase-${bundlePurchaseId}.pdf`)

    return {
      status: 'success'
    }
  } catch (err) {
    console.log('Error', err)

    return {
      status: 'error',
      reason: t('transaction.pdfGenerationError')
    }
  }
}
