import gql from 'graphql-tag'

export default gql`
  fragment UsAddress on UsAddress {
    line1
    line2
    city
    state
    postalCode
  }
`
