import React from 'react'
import { Button, Text } from '@citifyd/style'

import NavBar from '../NavBar'
import { useTranslator } from '../../hooks'
import styles from '../NavBar/NavBar.module.scss'

const PasswordChangeNotificationBar = ({
  passwordChangeSuggestionDisplayed,
  openChangePasswordModal,
  dismissPasswordChangeSuggestion
}) => {
  const t = useTranslator()

  if (!passwordChangeSuggestionDisplayed) {
    return null
  }

  return (
    <NavBar>
      <div className={styles.textWithButtons}>
        <div className={styles.text}>
          <Text>{t('index.lastPassword', { n: 90 })}</Text>
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={openChangePasswordModal}
            appearance='white'
            uppercase
            size='small'
          >
            {t('index.changePassword')}
          </Button>
          <Button
            onClick={dismissPasswordChangeSuggestion}
            appearance='white'
            uppercase
            size='small'
          >
            {t('index.dismiss')}
          </Button>
        </div>
      </div>
    </NavBar>
  )
}

export default PasswordChangeNotificationBar
