'use strict';

/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens and enables tab
 * support for dropdown menus.
 */
(function () {
  var container, button, menu, links, subMenus

  container = document.getElementById('site-navigation')
  if (!container) {
    return
  }

  button = container.getElementsByTagName('button')[0]
  if (typeof button === 'undefined') {
    return
  }

  menu = container.getElementsByTagName('ul')[0]

  // Hide menu toggle button if menu is empty and return early.
  if (typeof menu === 'undefined') {
    button.style.display = 'none'
    return
  }

  menu.setAttribute('aria-expanded', 'false')
  if (menu.className.indexOf('nav-menu') === -1) {
    menu.className += ' nav-menu'
  }

  button.onclick = function () {
    if (container.className.indexOf('toggled') !== -1) {
      container.className = container.className.replace(' toggled', '')
      button.setAttribute('aria-expanded', 'false')
      menu.setAttribute('aria-expanded', 'false')
    } else {
      container.className += ' toggled'
      button.setAttribute('aria-expanded', 'true')
      menu.setAttribute('aria-expanded', 'true')
    }
  }

  // Get all the link elements within the menu.
  links = menu.getElementsByTagName('a')
  subMenus = menu.getElementsByTagName('ul')

  // Set menu items with submenus to aria-haspopup="true".
  for (var i = 0, len = subMenus.length; i < len; i++) {
    subMenus[i].parentNode.setAttribute('aria-haspopup', 'true')
  }

  // Each time a menu link is focused or blurred, toggle focus.
  for (i = 0, len = links.length; i < len; i++) {
    links[i].addEventListener('focus', toggleFocus, true)
    links[i].addEventListener('blur', toggleFocus, true)
  }

  /**
   * Sets or removes .focus class on an element.
   */
  function toggleFocus () {
    var self = this

    // Move up through the ancestors of the current link until we hit .nav-menu.
    while (self.className.indexOf('nav-menu') === -1) {
      // On li elements toggle the class .focus.
      if (self.tagName.toLowerCase() === 'li') {
        if (self.className.indexOf('focus') !== -1) {
          self.className = self.className.replace(' focus', '')
        } else {
          self.className += ' focus'
        }
      }

      self = self.parentElement
    }
  }
})()
