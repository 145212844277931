import CurrencyFormatter from '@citifyd/currency-formatter'

import { getAngularService, getCurrentLanguage } from '../../shared/react/utils'

export const fetchLots = () => {
  const Authentication = getAngularService('Authentication')
  const Properties = getAngularService('Properties')

  const user = Authentication.getLoggedUser()

  return Properties.findAll(user.isAdmin ? { all: true } : {}).then(
    response => response.data.lots
  )
}

export const formatCurrency = value => {
  const language = getCurrentLanguage()
  const { currency } = getAngularService('$stateParams')

  return CurrencyFormatter.format(value, {
    currency: currency,
    language
  })
}
