/* global CITIFYD_APP_VERSION */
'use strict'

import angular from 'angular'
import _ from 'lodash'
import './styles/app.scss'
import '@citifyd/style/dist/main.css'
import * as Sentry from '@sentry/browser'
import { Angular as AngularIntegration } from '@sentry/integrations'

import HistoryTracker from './shared/services/history-tracker'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new AngularIntegration()],
  environment: process.env.SENTRY_ENV
})

require('./vendor/scripts/i18next')
require('./vendor/scripts/navigation')

const app = angular.module('citifydSellerApp', [
  require('./vendor/angular/angular-moment'),
  require('./vendor/angular/angular-animate'),
  require('./vendor/angular/angular-cookies'),
  require('./vendor/angular/angular-resource'),
  require('./vendor/angular/angular-route'),
  require('./vendor/angular/angular-sanitize'),
  require('./vendor/angular/angular-touch'),
  require('./vendor/angular/angular-ui-mask'),
  require('./vendor/angular/ngmap'),
  require('./vendor/angular/ng-infinite-scroll'),
  require('./vendor/angular/angular-modal-service'),
  require('./vendor/angular/ng-currency'),
  require('./vendor/angular/selectize'),
  require('./vendor/angular/slick-carousel'),
  require('./vendor/angular/ui-router'),
  require('./vendor/angular/angular-file-saver'),
  require('./vendor/angular/ng-i18next'),
  require('./vendor/angular/angular-tooltips'),
  require('./vendor/angular/ng-numbers-only'),
  require('./vendor/angular/angular-dynamic-locale'),
  require('./vendor/angular/ng-sentry')
])

require('./pages')
require('./shared/animations')
require('./shared/controllers')
require('./shared/directives')
require('./shared/filters')
require('./shared/modals')
require('./shared/services')
require('./routes')

app.config([
  'tmhDynamicLocaleProvider',
  tmhDynamicLocaleProvider => {
    tmhDynamicLocaleProvider.localeLocationPattern(
      '/node_modules/angular-i18n/angular-locale_{{locale | lowercase}}.js'
    )
  }
])

app.config($locationProvider => $locationProvider.html5Mode(true))

app.constant('ENV', {
  apiUrl: process.env.API_URL,
  name: process.env.ENV,
  stripeKey: process.env.STRIPE_KEY,
  intercomAppKey: process.env.INTERCOM_APP_KEY,
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  liveAppUrl: process.env.LIVE_APP_URL,
  cloudinaryUploadPresetPrefix: process.env.CLOUDINARY_UPLOAD_PRESET_PREFIX,
  posDashboardUrl: process.env.POS_DASHBOARD_URL,
  limeDashboardUrl: process.env.LIME_DASHBOARD_URL,
  cloudinaryUploadPresetPrefix: process.env.CLOUDINARY_UPLOAD_PRESET_PREFIX
})

app.config([
  'uiMask.ConfigProvider',
  uiMask => {
    uiMask.maskDefinitions({
      A: /[a-z]/,
      a: /[a-z*]/,
      N: /[0-9]/,
      n: /[0-9*]/,
      '*': /[a-zA-Z0-9]/
    })
  }
])

app.run((ENV, Languages, $http) => {
  // CITIFYD_APP_VERSION is defined on webpack.config.js based on the package.json version
  $http.defaults.headers.common[
    'Citifyd-app-version'
  ] = `Dashboard ${CITIFYD_APP_VERSION}`
  $http.defaults.headers.common[
    'Citifyd-accept-language'
  ] = Languages.getCurrent()
})

app.run(Intercom => Intercom.load())

app.run(
  (ENV, Authentication, Helper, Intercom, $rootScope, $state, $i18next) => {
    $rootScope.currentYear = new Date().getFullYear()

    $rootScope.openLiveApp = function () {
      var url = ENV.liveAppUrl.replace(/\/$/, '')
      window.open(
        url + '/#!/user/login?token=' + Authentication.getAccessToken()
      )
    }

    $rootScope.$on('$stateChangeStart', function (
      e,
      toState,
      params,
      fromState,
      fromParams,
      options
    ) {
      Helper.confirmPageUnload(
        function () {
          var requiresAuth = toState && toState.auth

          if (options.enableBackLink) {
            const stateId = HistoryTracker.addState({
              name: fromState.name,
              params: fromParams
            })
            params['_origin'] = stateId
          }

          if (requiresAuth && !Authentication.isLoggedIn()) {
            var loginParams = {}
            var url = $state.href(toState.name, params, { relative: true })

            if (toState.auth && toState.url !== '/') {
              loginParams.source = encodeURIComponent(url)
            }

            e.preventDefault()
            $state.go('login', loginParams, { location: 'replace' })
            return
          } else {
            $rootScope.waitingResolvers = true
          }

          $rootScope.showMenu = requiresAuth
        },

        function () {
          e.preventDefault()
        }
      )
    })

    $rootScope.$on('$stateChangeSuccess', function (e, toState) {
      $rootScope.waitingResolvers = false

      if (toState) {
        let title = toState.title
        if (_.startsWith(title, 'i18n:')) {
          title = $i18next.t(title.replace('i18n:', ''))
        }
        Helper.setPageTitle(title)
      }

      Intercom.update()
    })

    $rootScope.navigate = function ($event, to, params, options = {}) {
      // If the command key is down or it's a middle click,
      // open the URL in a new tab
      if ($event && ($event.metaKey || $event.which === 2)) {
        var url = $state.href(to, params, { absolute: true })
        window.open(url, '_blank')
      } else {
        $state.go(to, params, options)
      }
    }

    $rootScope.back = function (defaultState) {
      var defaultParams = {}

      if (_.isObject(defaultState)) {
        defaultParams = defaultState.params
        defaultState = defaultState.state
      }

      if (window.history.length > 2) {
        window.history.back()
      } else {
        $state.transitionTo(defaultState || 'dashboard', defaultParams)
      }
    }
  }
)
