import moment from 'moment-timezone'

const MAX_RANGE_ALLOWED_IN_MONTHS = 12

export const validate = (newSelection, setErrors, t) => {
  const { startDate, endDate } = newSelection
  const start = moment(startDate)
  const end = moment(endDate)
  let valid = true

  if (start.isAfter(end)) {
    setErrors(old => ({
      ...old,
      ['dateRange.end']: t(
        'permits.permitsFilters.endDateErrors.mustBeAfterStartDate'
      )
    }))
    valid = false
  }

  if (end.diff(start, 'months') > MAX_RANGE_ALLOWED_IN_MONTHS) {
    setErrors(old => ({
      ...old,
      ['dateRange.end']: t(
        'permits.permitsFilters.endDateErrors.rangeLimitReached',
        {
          maxMonths: MAX_RANGE_ALLOWED_IN_MONTHS
        }
      )
    }))
    valid = false
  }

  if (valid) {
    setErrors({})
  }

  return valid
}

export const selectionToParams = selection => {
  const { status, search } = selection

  let formattedDateRange
  const startDate = moment(selection.startDate).format('YYYY-MM-DD')
  const endDate = moment(selection.endDate).format('YYYY-MM-DD')
  formattedDateRange = `${startDate}:${endDate}`

  return {
    status: status?.toLowerCase(),
    search,
    dateRange: formattedDateRange
  }
}
