import gql from 'graphql-tag'
import ReservedParkingPass from '../fragments/ReservedParkingPassesFragment'
import PageInfo from '../fragments/PageInfoFragment'

export default gql`
  query AdminListReservedParkingPasses(
    $lotId: Int
    $status: ReservedParkingPassStatus
    $startDate: Date!
    $endDate: Date!
    $searchTerm: String
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    admin_listReservedParkingPasses(
      lotId: $lotId
      status: $status
      startDate: $startDate
      endDate: $endDate
      searchTerm: $searchTerm
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      edges {
        node {
          ...ReservedParkingPass
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ReservedParkingPass}
  ${PageInfo}
`
