'use strict'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { chunk, findIndex } from 'lodash'
import produce from 'immer'
import { Icon, Text, NewGrid } from '@citifyd/style'
import styles from './AccountLots.module.scss'

import { useTranslator } from '../../../../shared/react/hooks'
import { getAngularService } from '../../../../shared/react/utils'

import LoadingManager from '../../../../shared/react/components/LoadingManager'
import RouterLink from '../../../../shared/react/components/RouterLink'

import Lot from './Lot.jsx'

function AccountLots () {
  const t = useTranslator()

  const [lots, setLots] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const Properties = getAngularService('Properties')

    async function fetchData () {
      setIsLoading(true)
      try {
        const response = await Properties.findAll()
        setLots(response.data.lots)
      } catch (err) {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  const canCreateLots = useMemo(() => {
    const Permissions = getAngularService('Permissions')
    return Permissions.userMayCreateLot()
  }, [])

  const canEditLots = useMemo(() => {
    const Permissions = getAngularService('Permissions')
    return Permissions.userMayEditLot()
  }, [])

  const updateLotOnList = useCallback(
    (lotId, newLotInformation) => {
      setLots(
        produce(lots, lots => {
          const lotIndex = findIndex(lots, lot => lot.id === lotId)
          lots[lotIndex] = newLotInformation
        })
      )
    },
    [lots]
  )

  return (
    <>
      <Text
        className={styles.title}
        component='h2'
        variant='h1'
        bold
        gutterBottom
      >
        {t('accountInfo.properties')}

        {!isLoading && canCreateLots && (
          <RouterLink state='add-property' className={styles.addNew}>
            <Text weight='300' variant='subtitle'>
              {t('accountInfo.add')}
            </Text>{' '}
            <Icon icon='plus-circle' pull='right' />
          </RouterLink>
        )}
      </Text>

      <LotList
        lots={lots}
        canEditLots={canEditLots}
        updateLotOnList={updateLotOnList}
        isLoading={isLoading}
        hasError={hasError}
      />
    </>
  )
}

function LotList ({ lots, canEditLots, updateLotOnList, isLoading, hasError }) {
  const chunks = useMemo(() => chunk(lots, 2), [lots])

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />
  }

  if (!lots.length) {
    return <NoLots />
  }

  const renderChunks = () =>
    chunks.map(lots =>
      lots.map(lot => (
        <NewGrid.Col md={6} key={lot.id}>
          <Lot
            lot={lot}
            canEditLot={canEditLots}
            updateLotOnList={updateLotOnList}
          />
        </NewGrid.Col>
      ))
    )

  return <NewGrid.Row className={styles.lotList}>{renderChunks()}</NewGrid.Row>
}

function NoLots () {
  const t = useTranslator()
  return (
    <div className={styles.noLots}>
      <Text gutterBottom variant='h5'>
        {t('accountInfo.setup')}
      </Text>
      <Text>{t('accountInfo.start')}</Text>
    </div>
  )
}

export default AccountLots
