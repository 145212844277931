'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory(
  'GateEntries',
  (API, Authentication, Helper, moment, $i18next, $http) => {
    function getSummaryForEvent (eventId) {
      return $http({
        method: 'GET',
        url: API.base + '/admin/events/' + eventId + '/gates-summary',
        headers: Authentication.generateHeaders()
      }).then(function (response) {
        return response.data.lots
      })
    }

    function closeoutCashier (eventId, data) {
      return $http({
        method: 'POST',
        url: API.base + '/admin/events/' + eventId + '/closeout-cashier',
        headers: Authentication.generateHeaders(),
        data: data
      })
    }

    function getEntriesForEventGate (eventId, eventGateId, options) {
      return $http({
        method: 'GET',
        url:
          API.base +
          '/admin/events/' +
          eventId +
          '/gates/' +
          eventGateId +
          '/entries',
        headers: Authentication.generateHeaders(),
        params: options
      })
    }

    function getTicketsWithNoEntries (eventId, lotId) {
      return $http({
        method: 'GET',
        url:
          API.base +
          '/admin/events/' +
          eventId +
          '/lots/' +
          lotId +
          '/tickets-with-no-entries',
        headers: Authentication.generateHeaders()
      })
    }

    function updateParkerGateEntry (eventId, userId, eventGateId) {
      return $http({
        method: 'PUT',
        url:
          API.base +
          '/admin/events/' +
          eventId +
          '/parkers/' +
          userId +
          '/gate-entry',
        headers: Authentication.generateHeaders(),
        data: { eventGateId: parseInt(eventGateId, 10) }
      })
    }

    function localizeSystemGateName (gate) {
      if (!gate.isSystemGate) {
        return gate.name
      }

      const cleanName = gate.name.toLowerCase().replace(/ /g, '_')
      return $i18next.t(`systemGates.${cleanName}`)
    }

    return {
      getEntriesForEventGate: getEntriesForEventGate,
      getTicketsWithNoEntries: getTicketsWithNoEntries,
      getSummaryForEvent: getSummaryForEvent,
      updateParkerGateEntry: updateParkerGateEntry,
      closeoutCashier: closeoutCashier,
      localizeSystemGateName
    }
  }
)
