'use strict'

import React, { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button, NewGrid, Icon, Text } from '@citifyd/style'
import { cloneDeep, pick } from 'lodash'

import LoadingManager from '../../shared/react/components/LoadingManager'
import RouterLink from '../../shared/react/components/RouterLink'
import StickyFooter from '../../shared/react/components/StickyFooter'
import { useTranslator, useAngularService } from '../../shared/react/hooks'
import PageHeader from '../../shared/react/components/PageHeader'
import FormikProvider from '../../shared/react/components/FormikProvider'

import MyAccountForm from './components/MyAccountForm'
import MyAccountDevices from './components/MyAccountDevices'
import VerifyPhoneNumberModal from '../../shared/modals/VerifyPhoneNumberModal'
import ChangePasswordModal from '../../shared/modals/ChangePasswordModal/ChangePasswordModal.jsx'

import {
  useModalOpener,
  withModalManager
} from '../../shared/react/modalManager'

import styles from './MyAccount.module.scss'

const EDITABLE_FIELDS = ['name', 'email', 'twoFactorAuthenticationEnabled']

const MyAccount = () => {
  const t = useTranslator()
  const openModal = useModalOpener()
  const Authentication = useAngularService('Authentication')
  const Sessions = useAngularService('Sessions')
  const $state = useAngularService('$state')
  const Users = useAngularService('Users')
  const Helper = useAngularService('Helper')
  const devicesToRemove = useRef([])
  const [twoFactorDevices, setTwoFactorDevices] = useState(null)
  const [editUser, setEditUser] = useState(null)
  const [hasError, setHasError] = useState(false)
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(null)

  const [loadingMessage, setLoadingMessage] = useState(
    t('myAccount.loadingInfo')
  )
  const [isLoading, setIsLoading] = useState(true)

  const openChangePasswordModal = () => openModal(ChangePasswordModal)

  const openPhoneNumberModal = () => {
    const user = Authentication.getLoggedUser()

    openModal(VerifyPhoneNumberModal, {
      user,
      title: t('myAccount.updatePhoneNumber')
    })
  }

  const filterTwoFactorSessions = sessions => {
    return sessions.filter(s => s.permanentTwoFactorAuthentication)
  }

  const loadDevices = async () => {
    try {
      const sessions = await Sessions.getMySessions()

      setTwoFactorDevices(filterTwoFactorSessions(sessions))
    } catch (err) {
      Helper.showErrorAlert(err.data)
    } finally {
      setIsLoading(false)
    }
  }

  const loadUser = async () => {
    setIsLoading(true)

    try {
      const response = await Authentication.reloadUser()

      setEditUser(cloneDeep(response))
    } catch (err) {
      console.log(err)
    } finally {
      setLoadingMessage(t('myAccount.loadingDevices'))
      loadDevices()
    }
  }

  useEffect(() => {
    loadUser()
  }, [])

  const removeDeletedDevices = async () => {
    for (const deviceId of devicesToRemove.current) {
      await Sessions.updateMySession(deviceId, {
        permanentTwoFactorAuthentication: false
      })
    }

    Authentication.reloadUser().then(() => $state.go('properties'))
  }

  const onSubmit = async values => {
    const user = Authentication.getLoggedUser()
    const data = pick(values, EDITABLE_FIELDS)

    setLoadingMessage(t('myAccount.savingInformation'))
    setIsLoading(true)

    if (data.twoFactorAuthenticationEnabled === false) {
      devicesToRemove.current = twoFactorDevices.map(device => device.id)
    }

    try {
      await Users.updateUser(user.id, data)

      removeDeletedDevices()
    } catch (err) {
      setIsLoading(false)
      Helper.showErrorAlert(err.data)
    }
  }

  const formikbag = useFormik({
    initialValues: {
      name: editUser?.name || '',
      email: editUser?.email || '',
      twoFactorAuthenticationEnabled:
        editUser?.twoFactorAuthenticationEnabled || false
    },
    onSubmit,
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      email: yup
        .string()
        .email()
        .required()
    }),
    enableReinitialize: true
  })

  const { handleSubmit } = formikbag

  return (
    <NewGrid className={styles.myAccount}>
      <PageHeader>
        <PageHeader.Title
          defaultState='properties'
          title={t('myAccount.accountInformation')}
        />
      </PageHeader>
      {isLoading ? (
        <LoadingManager
          isLoading={isLoading}
          loadingMessage={loadingMessage}
          hasError={hasError}
        />
      ) : (
        <div className={styles.content}>
          <FormikProvider value={formikbag}>
            <MyAccountForm />
            <NewGrid.Row>
              <NewGrid.Col>
                <Button
                  fullWidth
                  shadow
                  uppercase
                  letterSpacing
                  justifyContent='center'
                  onClick={openPhoneNumberModal}
                  size='small'
                >
                  {t('myAccount.updatePhoneNumber')}
                </Button>
              </NewGrid.Col>
              <NewGrid.Col>
                <Button
                  fullWidth
                  shadow
                  uppercase
                  letterSpacing
                  justifyContent='center'
                  onClick={openChangePasswordModal}
                  size='small'
                >
                  {t('myAccount.changePassword')}
                </Button>
              </NewGrid.Col>
            </NewGrid.Row>
            <MyAccountDevices
              editUser={editUser}
              twoFactorDevices={twoFactorDevices}
              setTwoFactorDevices={setTwoFactorDevices}
              devicesToRemove={devicesToRemove.current}
            />
            <StickyFooter justifyContent='space-between'>
              <RouterLink state='properties' className={styles.backLink}>
                <Icon size='subtitle' icon='chevron-left' appearance='white' />
                <Text variant='subtitle' appearance='white'>
                  {t('commonButtons.goBackWithoutSaving')}
                </Text>
              </RouterLink>
              <Button uppercase onMouseDown={handleSubmit} extraPadding>
                {t('commonButtons.save')}
              </Button>
            </StickyFooter>
          </FormikProvider>
        </div>
      )}
    </NewGrid>
  )
}

export default withModalManager(MyAccount)
