'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.filter('formatBeaconUids', () => {
  return function (beaconEntries) {
    return _(beaconEntries)
      .pluck('beaconUid')
      .filter(function (x) {
        return !_.isUndefined(x)
      })
      .uniq()
      .join(', ')
  }
})
