'use strict'

import React, { useEffect, useState, useRef } from 'react'
import { Select, NewGrid, DatePicker } from '@citifyd/style'
import moment from 'moment-timezone'

import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import styles from './PermitsFilters.module.scss'
import { getCurrentLanguage } from '../../../../shared/react/utils'
import { validate, selectionToParams } from './utils'
import SearchInput from '../../../../shared/react/components/SearchInput'

const PermitsFilters = ({ currentFilter }) => {
  const t = useTranslator()
  const [errors, setErrors] = useState({})
  const $state = useAngularService('$state')
  const [selection, setSelection] = useState({})
  const timer = useRef(null)
  const language = getCurrentLanguage()
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSelection(currentFilter)
    setSearch(currentFilter.search || '')
  }, [currentFilter])

  const statusOptions = [
    {
      label: t('permits.status.all'),
      value: ''
    },
    {
      value: 'FUTURE',
      label: t('permits.status.pending')
    },
    {
      value: 'ONGOING',
      label: t('permits.status.activated')
    },
    {
      value: 'PAST',
      label: t('permits.status.expired')
    },
    {
      value: 'REMOVED',
      label: t('permits.status.cancelled')
    }
  ]

  const updateFilters = newSelection => {
    if (!validate(newSelection, setErrors, t)) {
      return false
    }

    $state.transitionTo($state.current.name, selectionToParams(newSelection), {
      location: 'replace',
      notify: false,
      inherit: true
    })
  }

  const fieldChanged = e => {
    const value = e.target.value
    const name = e.target.name

    const newSelection = {
      ...selection,
      [name]: value
    }

    setSelection(newSelection)
    updateFilters(newSelection)
  }

  const setDate = (field, date) => {
    const newSelection = {
      ...selection,
      [field]: date
    }

    setSelection(newSelection)
    updateFilters(newSelection)
  }

  const handleSearch = e => {
    setSearch(e.target.value)

    if (timer) {
      clearTimeout(timer.current)
    }

    const newSelection = {
      ...selection,
      search: e.target.value
    }

    timer.current = setTimeout(() => {
      updateFilters(newSelection)
    }, 500)
  }

  return (
    <NewGrid.Row className={styles.filters}>
      <NewGrid.Col className={styles.filter} xs={12} md={3}>
        <Select
          fullWidth
          name='status'
          labelSize='small'
          label={t('permits.permitsFilters.status')}
          options={statusOptions}
          value={selection.status || ''}
          onChange={fieldChanged}
        />
      </NewGrid.Col>
      <NewGrid.Col className={styles.filter} xs={12} md={3}>
        <DatePicker
          locale={language}
          onChange={date => setDate('startDate', date)}
          selectedDate={moment(selection.startDate).toDate()}
          label={t('permits.permitsFilters.startDate')}
        />
      </NewGrid.Col>
      <NewGrid.Col className={styles.filter} xs={12} md={3}>
        <DatePicker
          locale={language}
          onChange={date => setDate('endDate', date)}
          selectedDate={moment(selection.endDate).toDate()}
          label={t('permits.permitsFilters.endDate')}
          error={errors['dateRange.end']}
          errorMessage={t(errors['dateRange.end'])}
        />
      </NewGrid.Col>
      <NewGrid.Col className={styles.filter} xs={12} md={3}>
        <SearchInput
          value={search}
          id='search'
          label={t('permits.permitsFilters.search')}
          labelSize='small'
          onChange={handleSearch}
          fullWidth
        />
      </NewGrid.Col>
    </NewGrid.Row>
  )
}

export default PermitsFilters
