'use strict'

import React from 'react'
import { Badge } from '@citifyd/style'

import { useTranslator } from '../../hooks'
import styles from './TicketStatusBadge.module.scss'

const TicketStatusBadge = React.memo(({ statusTag }) => {
  const t = useTranslator()
  const label = t(`ticketStatusTags.${statusTag}`)

  const getBadgeAppearance = () => {
    let appearance

    switch (statusTag) {
      case 'purchased':
      case 'additional':
        appearance = { appearance: 'white', textAppearance: 'gray_darker' }
        break

      case 'cancelled':
        appearance = { appearance: 'tertiary', textAppearance: 'white' }
        break

      case 'redeemed':
        appearance = { className: styles.redeemed, textAppearance: 'white' }
        break

      case 'transferred':
        appearance = { className: styles.transferred }
        break

      case 'parked':
        appearance = { appearance: 'completed', textAppearance: 'white' }
        break

      case 'expired':
        appearance = { appearance: 'gray_darker', textAppearance: 'white' }
        break

      case 'deleted':
        appearance = { appearance: 'error', textAppearance: 'white' }
        break

      default:
        appearance = { appearance: 'gray' }
        break
    }

    return appearance
  }

  return <Badge {...getBadgeAppearance()}>{label}</Badge>
})

export default TicketStatusBadge
