import React from 'react'
import jstz from 'jstz'

import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'
import PageHeader from '../../../../shared/react/components/PageHeader'
import RouterLinkButton from '../../../../shared/react/components/RouterLinkButton'
import SearchInput from '../../../../shared/react/components/SearchInput'
import CSVDownload from '../../../../shared/react/components/CSVDownload'
import styles from './UsersListHeader.module.scss'

const UserListHeader = ({
  searchQuery,
  setSearchQuery,
  sortBy,
  sortReverse
}) => {
  const t = useTranslator()
  const API = useAngularService('API')
  const CitifydModal = useAngularService('CitifydModal')
  const Authentication = useAngularService('Authentication')
  const loggedUser = Authentication.getLoggedUser()
  const handleSearchQuery = e => setSearchQuery(e.target.value)

  const filledSearchQuery = searchQuery.trim() !== ''

  const getFilterDescription = () => {
    let localeKey = 'users.filterDescription'
    let localeOptions = {}

    if (filledSearchQuery) {
      localeKey += 'WithSearchQuery'
      localeOptions.searchQuery = searchQuery.trim()
    }

    return t(localeKey, localeOptions)
  }

  const generateCsvClicked = () => {
    const url = `${
      API.base
    }/admin/users/csv?timezoneName=${jstz
      .determine()
      .name()}&order=${sortBy}&reverse=${sortReverse}${
      filledSearchQuery ? `&search=${searchQuery.trim()}` : ''
    }`

    CitifydModal.open('generate-report', {
      fileFormat: 'csv',
      filterDescription: getFilterDescription(),
      reportUrl: Authentication.mountAuthenticatedUrl(url)
    })
  }

  return (
    <>
      <PageHeader.Action className={styles.searchMobile}>
        <SearchInput
          value={searchQuery}
          id='search'
          onChange={handleSearchQuery}
          fullWidth
        />
      </PageHeader.Action>
      <PageHeader className={styles.pageHeader}>
        <PageHeader.Title
          variant='h3'
          component='h2'
          title={t('users.allUsers')}
        />
        <PageHeader.Actions>
          <PageHeader.Action>
            <RouterLinkButton
              uppercase
              size='small'
              extraPadding
              shadow
              letterSpacing
              state='invite-parkers'
              nowrap
            >
              {t('users.inviteNewUsers')}
            </RouterLinkButton>
          </PageHeader.Action>
          <PageHeader.Action className={styles.searchDesktop}>
            <SearchInput
              value={searchQuery}
              id='search'
              onChange={handleSearchQuery}
              fullWidth
            />
          </PageHeader.Action>
          {loggedUser.isAdmin && (
            <PageHeader.Action>
              <CSVDownload onClick={() => generateCsvClicked()} />
            </PageHeader.Action>
          )}
        </PageHeader.Actions>
      </PageHeader>
    </>
  )
}

export default UserListHeader
