'use strict'
import React from 'react'
import { Button, Text, Modal } from '@citifyd/style'
import { useTranslator } from '../../../../react/hooks'

const Deleted = ({ onClose, userOrInvitation }) => {
  const t = useTranslator()

  return (
    <>
      <Modal.Title>
        {t('modalManageOrganizationUser.removed', {
          name: userOrInvitation.name
        })}
      </Modal.Title>
      <Modal.Content>
        <Text appearance='default' textAlign='center' variant='h3' weight='300'>
          {t('modalManageOrganizationUser.noAccess')}
        </Text>
      </Modal.Content>
      <Modal.Actions display='block'>
        <Button
          onMouseDown={() => onClose({ refreshUsers: true })}
          type='button'
          justifyContent='center'
          extraPadding
          uppercase
        >
          {t('commonButtons.done')}
        </Button>
      </Modal.Actions>
    </>
  )
}
export default Deleted
