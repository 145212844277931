import { getAngularService, getTranslator } from '../../../utils'

const verifyPhoneNumber = async ({ values, user }) => {
  const PhoneNumbers = getAngularService('PhoneNumbers')
  const Users = getAngularService('Users')
  const t = getTranslator()

  if (_.isEmpty(values.code)) {
    return {
      status: 'error',
      reason: {
        data: {
          error: {
            message: t('phoneVerification.codeIsRequired')
          }
        }
      }
    }
  }

  try {
    const phoneNumbersResponse = await PhoneNumbers.verify({
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      code: values.code
    })

    const response = await Users.updateUser(user.id, {
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      phoneVerificationToken: phoneNumbersResponse.token
    })

    return {
      status: 'verified',
      user: response.data.user
    }
  } catch (err) {
    return { status: 'error', reason: err }
  }
}
export default verifyPhoneNumber
