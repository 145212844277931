'use strict'

import React from 'react'
import { Text, Checkbox } from '@citifyd/style'
import CurrencyFormatter from '@citifyd/currency-formatter'
import moment from 'moment-timezone'

import styles from './TicketRefundSummary.module.scss'
import Table from '../../../../shared/react/components/Table'
import { useTranslator } from '../../../../shared/react/hooks'
import { getCurrentLanguage } from '../../../../shared/react/utils'

const TicketRefundSummary = React.memo(
  ({
    ticket,
    calculateRefundState,
    canToggleStripeProcessingFeeRetention,
    refundOptions,
    setRefundOptions
  }) => {
    const t = useTranslator()
    const language = getCurrentLanguage()
    const {
      originalAmount,
      transfersReversedAmount,
      stripeProcessingFee,
      amountToRefund
    } = calculateRefundState || {}

    const columns = [
      {
        value: t('ticketRefund.eventDate'),
        key: 'eventDate',
        width: '15%'
      },
      {
        value: t('ticketRefund.event'),
        key: 'event',
        width: '40%'
      },
      {
        value: t('refundPages.lot'),
        key: 'lot',
        width: '30%'
      },
      {
        value: t('refundPages.amount'),
        key: 'amount',
        width: '15%'
      }
    ]

    const toggleStripeProcessingFeeRetention = () => {
      setRefundOptions(old => ({
        ...old,
        feeRetentionMethod:
          old.feeRetentionMethod === 'stripe_processing_fee'
            ? 'no_retention'
            : 'stripe_processing_fee'
      }))
    }

    const getAmount = amount =>
      CurrencyFormatter.format(amount, {
        currency: ticket.currency,
        language
      })

    const data = [
      {
        eventDate: {
          value: t('defaultFormats.date', {
            date: moment.tz(ticket.event.start, ticket.event.timezoneName)
          }),
          nowrap: 'nowrap'
        },
        event: {
          value: ticket.event.name
        },
        lot: {
          value: ticket.lot.name,
          nowrap: 'nowrap'
        },
        amount: {
          value: getAmount(originalAmount)
        }
      },
      transfersReversedAmount > 0 && {
        className: styles.adjustment,
        lot: {
          value: t('refundPages.previousTransactions'),
          textColor: 'tertiary'
        },
        amount: {
          value: getAmount(transfersReversedAmount * -1),
          textColor: 'tertiary'
        }
      },
      canToggleStripeProcessingFeeRetention && {
        className: styles.checkbox,
        lot: {
          value: (
            <Checkbox
              label={<Text>{t('refundPages.reimburseStripeFee')}</Text>}
              onChange={toggleStripeProcessingFeeRetention}
              checked={refundOptions.feeRetentionMethod === 'no_retention'}
            />
          ),
          noVerticalPadding: true,
          verticalAlign: 'middle'
        },
        amount: {
          value:
            refundOptions.feeRetentionMethod === 'no_retention'
              ? getAmount(stripeProcessingFee)
              : getAmount(stripeProcessingFee * -1),
          textColor:
            refundOptions.feeRetentionMethod === 'no_retention'
              ? 'default'
              : 'tertiary',
          verticalAlign: 'middle'
        }
      },
      {
        lot: {
          value: <b>{t('refundPages.refund')}</b>
        },
        amount: {
          value: <b>{getAmount(amountToRefund)}</b>
        }
      }
    ]

    return (
      <div>
        <Text component='h5' variant='subtitle' gutterBottom>
          {t('refundPages.refundSummary')}
        </Text>

        <Table
          columns={columns}
          responsive
          data={data}
          className={styles.table}
        />
      </div>
    )
  }
)

export default TicketRefundSummary
