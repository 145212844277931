'use strict'

import React from 'react'
import classNames from 'classnames'
import { Box } from '@citifyd/style'

const BoxContent = ({ children, variation, className, ...props }) => {
  return (
    <Box className={className} {...props}>
      {children}
    </Box>
  )
}

BoxContent.Header = ({ title, children, className, appearance, ...rest }) => {
  return (
    <Box.Header
      className={className}
      title={title}
      appearance={appearance}
      {...rest}
    >
      {children}
    </Box.Header>
  )
}

BoxContent.HeaderActions = ({ className, children }) => {
  return <Box.HeaderActions className={className}>{children}</Box.HeaderActions>
}

BoxContent.Content = ({ children, noHorizontalPadding, ...rest }) => {
  return (
    <Box.Content noHorizontalPadding={noHorizontalPadding} {...rest}>
      {children}
    </Box.Content>
  )
}

BoxContent.Footer = ({ children, appearance, className, ...rest }) => {
  return (
    <Box.Footer appearance={appearance} className={className} {...rest}>
      {children}
    </Box.Footer>
  )
}

BoxContent.Table = ({ children, className, ...props }) => {
  return (
    <div className='content-box__body'>
      <table className={classNames('light-table', className)} {...props}>
        {children}
      </table>
    </div>
  )
}

export default BoxContent
