'use strict'

import React, { useState, useEffect } from 'react'
import { NewGrid } from '@citifyd/style'

import LoadingManager from '../../../shared/react/components/LoadingManager'
import PageHeader from '../../../shared/react/components/PageHeader'

import SubscriptionOverviewBox from './SubscriptionOverviewBox.jsx'
import SubscriptionPaymentHistoryBox from './SubscriptionPaymentHistoryBox.jsx'

import { getAngularService } from '../../../shared/react/utils'
import { useTranslator } from '../../../shared/react/hooks'

import styles from '../Subscription.module.scss'

function Page () {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [subscription, setSubscription] = useState(null)

  useEffect(() => {
    const Subscriptions = getAngularService('Subscriptions')
    const { id } = getAngularService('$stateParams')

    async function fetchData () {
      setIsLoading(true)
      try {
        const subscription = await Subscriptions.find(id)
        setSubscription(subscription)
      } catch (err) {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />
  }

  return (
    <NewGrid className={styles.pageSubscription}>
      <div className='heading clearfix'>
        <SubscriptionPageTitle subscription={subscription} />
      </div>
      <SubscriptionOverviewBox subscription={subscription} />
      <SubscriptionPaymentHistoryBox subscription={subscription} />
    </NewGrid>
  )
}

function SubscriptionPageTitle ({ subscription }) {
  const t = useTranslator()

  if (!subscription || !subscription.user) {
    return (
      <PageHeader>
        <PageHeader.Title title={t('subscriptionPage.pageTitle')} />
      </PageHeader>
    )
  }

  return (
    <PageHeader>
      <PageHeader.Title
        defaultState='user'
        defaultParams={{ id: subscription.user.id }}
      />
    </PageHeader>
  )
}

export default Page
