'use strict'

import React, { useEffect, useState, Fragment } from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import moment from 'moment-timezone'
import { Text } from '@citifyd/style'

import BoxContent from '../BoxContent'
import Table from '../Table'
import LoadingManager from '../LoadingManager'
import { formatAvailabilityAllocation } from '../../../utils/availability-allocation-utils'

import { useTranslator } from '../../hooks'
import { getAngularService, getCurrentLanguage } from '../../utils'
import styles from './ReservationFullInformation.module.scss'

function FeeBreakdownBox ({ reservation }) {
  const t = useTranslator()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [feeBreakdown, setFeeBreakdown] = useState(null)

  useEffect(() => {
    const Reservations = getAngularService('Reservations')
    const { id } = getAngularService('$stateParams')

    async function fetchData () {
      setIsLoading(true)
      try {
        const feeBreakdown = await Reservations.generateFeeBreakdown(id)
        setFeeBreakdown(feeBreakdown)
      } catch (err) {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [reservation])

  return (
    <BoxContent>
      <BoxContent.Header
        title={t('reservationPage.feeBreakdownBox.feeBreakdown')}
      />
      <BoxContent.Content noHorizontalPadding>
        <FeeBreakdownContent
          feeBreakdown={feeBreakdown}
          isLoading={isLoading}
          hasError={hasError}
        />
      </BoxContent.Content>
    </BoxContent>
  )
}

function FeeBreakdownContent ({ feeBreakdown, isLoading, hasError }) {
  const t = useTranslator()

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />
  }

  const content = [
    availabilityAllocations({ feeBreakdown }),
    citifydServiceFee({ t, feeBreakdown }),
    totalAmountBeforeDiscounts({ t, feeBreakdown }),
    userCredits({ t, feeBreakdown }),
    merchantValidations({ t, feeBreakdown }),
    minimumChargeAdjustment({ t, feeBreakdown }),
    totalDiscounts({ t, feeBreakdown }),
    totalAmountAfterDiscounts({ t, feeBreakdown })
  ]

  const data = content.filter(el => el !== null && el !== undefined)

  return (
    <Fragment>
      {!feeBreakdown.finalResult.isFinished && (
        <Text className={styles.paymentNotFinished}>
          {t('reservationPage.feeBreakdownBox.notFinishedDisclaimer')}
        </Text>
      )}

      <Table
        className={styles.breakDownTable}
        spacing
        showHeader={false}
        columns={[{ key: 'title' }, { key: 'amount' }]}
        data={data}
      />
    </Fragment>
  )
}

const availabilityAllocations = ({ feeBreakdown }) => {
  const { availabilityAllocations } = feeBreakdown.firstStep
  const { timezoneName, currency } = feeBreakdown.utils

  return availabilityAllocations.map(allocation =>
    availabilityAllocationRow({
      availabilityAllocation: allocation,
      timezoneName: timezoneName,
      currency: currency
    })
  )[0]
}

const availabilityAllocationRow = ({
  availabilityAllocation,
  timezoneName,
  currency
}) => {
  const formatted = formatAvailabilityAllocation({
    availabilityAllocation,
    timezoneName,
    currency
  })

  return {
    title: (
      <div>
        <Text gutterBottom appearance='gray_dark' variant='small'>
          {formatted.timeRange}
        </Text>
        {formatted.rate}
      </div>
    ),
    amount: (
      <div>
        <Price amount={availabilityAllocation.total} currency={currency} />
      </div>
    )
  }
}

const citifydServiceFee = ({ t, feeBreakdown }) => {
  return {
    title: t('reservationPage.feeBreakdownBox.citifydServiceFee'),
    amount: (
      <Price
        amount={feeBreakdown.firstStep.citifydFee}
        currency={feeBreakdown.utils.currency}
      />
    )
  }
}

const totalAmountBeforeDiscounts = ({ t, feeBreakdown }) => {
  return {
    title: <b>{t('reservationPage.feeBreakdownBox.totalFees')}</b>,
    amount: (
      <b>
        <Price
          amount={feeBreakdown.firstStep.totalAmount}
          currency={feeBreakdown.utils.currency}
        />
      </b>
    )
  }
}

const userCredits = ({ t, feeBreakdown }) => {
  const { usedCreditsAmount } = feeBreakdown.secondStep

  if (usedCreditsAmount === 0) {
    return null
  }

  return {
    title: t('reservationPage.feeBreakdownBox.userCredits'),
    amount: {
      value: (
        <Price
          amount={-usedCreditsAmount}
          currency={feeBreakdown.utils.currency}
        />
      ),
      textColor: 'tertiary'
    }
  }
}

function merchantValidations ({ t, feeBreakdown }) {
  const { merchantValidations } = feeBreakdown.secondStep
  const { timezoneName, currency } = feeBreakdown.utils

  return merchantValidations.map((validation, index) =>
    merchantValidationRow({
      t,
      key: index,
      key: index,
      merchantValidation: validation,
      timezoneName: timezoneName,
      currency: currency
    })
  )[0]
}

const merchantValidationRow = ({
  t,
  merchantValidation,
  timezoneName,
  currency
}) => {
  return {
    title: (
      <div>
        <Text gutterBottom appearance='gray_dark' variant='small'>
          {t('reservationPage.feeBreakdownBox.businessRewardsValidation', {
            date: moment.tz(merchantValidation.date, timezoneName)
          })}
        </Text>
        {merchantValidation.businessName}
      </div>
    ),
    amount: {
      value: (
        <Price
          amount={-merchantValidation.amountDiscounted}
          currency={currency}
        />
      ),
      textColor: 'tertiary'
    }
  }
}

const minimumChargeAdjustment = ({ t, feeBreakdown }) => {
  const { minimumChargeAdjustment } = feeBreakdown.secondStep

  if (minimumChargeAdjustment === 0) {
    return null
  }

  return {
    title: t('reservationPage.feeBreakdownBox.minimumChargeAdjustment'),
    amount: (
      <Price
        amount={minimumChargeAdjustment}
        currency={feeBreakdown.utils.currency}
      />
    )
  }
}

const totalDiscounts = ({ t, feeBreakdown }) => {
  const {
    totalValidationsAndCreditsUsedMinusMinimumChargeAdjustment
  } = feeBreakdown.secondStep

  if (totalValidationsAndCreditsUsedMinusMinimumChargeAdjustment === 0) {
    return null
  }

  return {
    title: <b>{t('reservationPage.feeBreakdownBox.totalDiscounts')}</b>,
    amount: {
      value: (
        <b>
          <Price
            amount={-totalValidationsAndCreditsUsedMinusMinimumChargeAdjustment}
            currency={feeBreakdown.utils.currency}
          />
        </b>
      ),
      textColor: 'tertiary'
    }
  }
}

const totalAmountAfterDiscounts = ({ t, feeBreakdown }) => {
  if (
    feeBreakdown.finalResult.amountCharged ===
    feeBreakdown.firstStep.totalAmount
  ) {
    return null
  }

  return {
    title: <b>{t('reservationPage.feeBreakdownBox.amountCharged')}</b>,
    amount: (
      <b>
        <Price
          amount={feeBreakdown.finalResult.amountCharged}
          currency={feeBreakdown.utils.currency}
        />
      </b>
    )
  }
}

function Price ({ amount, currency }) {
  const language = getCurrentLanguage()
  return CurrencyFormatter.format(amount, { currency, language })
}

export default FeeBreakdownBox
