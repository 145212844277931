import { getTranslator, getAngularService } from '../../../utils'

const checkNumberAndSendVerification = async values => {
  const PhoneNumbers = getAngularService('PhoneNumbers')
  const t = getTranslator()

  try {
    const response = await PhoneNumbers.check({
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      checkOtherUsersOnly: true
    })

    if (response.userExists) {
      const message = t('phoneVerification.phoneNumberAlreadyTaken')
      const error = new Error(message)
      error.data = { error: { message: message } }
      throw error
    }

    await PhoneNumbers.sendVerification({
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      method: 'textMessage'
    })

    return {
      status: 'sent_code'
    }
  } catch (err) {
    return {
      status: 'error',
      reason: err
    }
  }
}

export default checkNumberAndSendVerification
