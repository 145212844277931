'use strict'

import React, { useState, useMemo } from 'react'
import { NewGrid, Text } from '@citifyd/style'
import { useTranslator } from '../../hooks'
import { getAngularService } from '../../utils'
import RouterLink from '../RouterLink'

import styles from './NavMenu.module.scss'
import classNames from 'classnames'

export default function NavMenu ({ openMenu, loggedUser }) {
  const t = useTranslator()
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname)
  const Permissions = getAngularService('Permissions')
  const $rootScope = getAngularService('$rootScope')
  const $location = getAngularService('$location')

  $rootScope.$on('$stateChangeSuccess', function () {
    setCurrentRoute($location.path())
  })

  const Link = ({ children, state }) => (
    <RouterLink state={state}>
      <Text>{children}</Text>
    </RouterLink>
  )

  const MenuItem = useMemo(
    () => ({ children, item }) => {
      const regex = new RegExp('^/' + (item || '') + '$')

      return (
        <li
          className={classNames({ [styles.active]: regex.test(currentRoute) })}
        >
          {children}
        </li>
      )
    },
    [currentRoute]
  )

  if (loggedUser?.role === 'localManager') {
    return (
      <div
        className={classNames(styles.navWrap, { [styles.openMenu]: openMenu })}
      >
        <NewGrid>
          <NewGrid.Row nogutter>
            <NewGrid.Col xs={12}>
              <div className={styles.nav}>
                <ul className={styles.links}>
                  <MenuItem item=''>
                    <Link state='dashboard'>
                      {t('header.siteNav.snapshot')}
                    </Link>
                  </MenuItem>
                  <MenuItem item='(all-propertie(s.*|properties.*)'>
                    <Link state='all-properties'>
                      {t('header.siteNav.properties')}
                    </Link>
                  </MenuItem>
                </ul>
              </div>
            </NewGrid.Col>
          </NewGrid.Row>
        </NewGrid>
      </div>
    )
  }

  if (loggedUser?.role !== 'localManager') {
    return (
      <div
        className={classNames(styles.navWrap, { [styles.openMenu]: openMenu })}
      >
        <NewGrid>
          <NewGrid.Row nogutter>
            <NewGrid.Col>
              <div className={styles.nav}>
                <ul className={styles.links}>
                  {Permissions.userMayAccessSnapshots() && (
                    <MenuItem item=''>
                      <Link state='dashboard'>
                        {t('header.siteNav.snapshot')}
                      </Link>
                    </MenuItem>
                  )}

                  {Permissions.userMayAccessSchedule() && (
                    <MenuItem item='(schedule.*|events|events\/[0-9]+.*)'>
                      <Link state='schedule'>
                        {t('header.siteNav.schedule')}
                      </Link>
                    </MenuItem>
                  )}

                  {Permissions.userMayAppendLotToEvent() && (
                    <MenuItem item='append-to-event'>
                      <Link state='appendable-events'>
                        {t('header.siteNav.addEvent')}
                      </Link>
                    </MenuItem>
                  )}

                  {Permissions.userMayCreateEvent() && (
                    <MenuItem item='events\/new'>
                      <Link state='add-event'>
                        {t('header.siteNav.addEvent')}
                      </Link>
                    </MenuItem>
                  )}

                  {Permissions.userMayAccessRevenue() && (
                    <MenuItem item='revenue.*'>
                      <Link state='revenue'>{t('header.siteNav.revenue')}</Link>
                    </MenuItem>
                  )}

                  {Permissions.userMaySeeUserInformation() && (
                    <MenuItem item='users.*'>
                      <Link state='users'>{t('header.siteNav.parkers')}</Link>
                    </MenuItem>
                  )}

                  {Permissions.userMaySeePermits() && (
                    <MenuItem item='(permits.*|permit.*)'>
                      <Link state='permits'>{t('header.siteNav.permits')}</Link>
                    </MenuItem>
                  )}

                  {Permissions.userMaySeeAllProperties() && (
                    <MenuItem item='all-properties.*'>
                      <Link state='all-properties'>
                        {t('header.siteNav.propertiesAdmin')}
                      </Link>
                    </MenuItem>
                  )}

                  {loggedUser?.isAdmin && (
                    <MenuItem item='referral-codes'>
                      <Link state='referral-codes'>
                        {t('header.siteNav.referralCodesAdmin')}
                      </Link>
                    </MenuItem>
                  )}
                </ul>

                <ul className={styles.account}>
                  <MenuItem item='properties.*'>
                    <Link state='properties'>
                      {t('header.siteNav.properties')}
                    </Link>
                  </MenuItem>
                </ul>
              </div>
            </NewGrid.Col>
          </NewGrid.Row>
        </NewGrid>
      </div>
    )
  }
}
