'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Revenue', function RevenueFactory (
  API,
  Authentication,
  Helper,
  $http,
  $httpParamSerializer
) {
  function fetchCurrencies (options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/revenue/currencies',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function fetchDates (options) {
    return Helper.abortableRequest({
      method: 'GET',
      url: API.base + '/admin/revenue',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function fetchDatesCsvUrl (options) {
    return Authentication.mountAuthenticatedUrl(
      API.base + '/admin/revenue.csv?' + $httpParamSerializer(options)
    )
  }

  function fetchEvents (options) {
    return Helper.abortableRequest({
      method: 'GET',
      url: API.base + '/admin/revenue/events',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function fetchEventsCsvUrl (options) {
    return Authentication.mountAuthenticatedUrl(
      API.base + '/admin/revenue/events.csv?' + $httpParamSerializer(options)
    )
  }

  function fetchForDate (date, options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/revenue/' + date,
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function fetchForDateCsvUrl (date, options) {
    return Authentication.mountAuthenticatedUrl(
      API.base +
        '/admin/revenue/' +
        date +
        '.csv?' +
        $httpParamSerializer(options)
    )
  }

  function fetchForEvent (eventId, options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/revenue/events/' + eventId,
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function fetchForEventCsvUrl (eventId, options) {
    return Authentication.mountAuthenticatedUrl(
      API.base +
        '/admin/revenue/events/' +
        eventId +
        '.csv?' +
        $httpParamSerializer(options)
    )
  }

  function fetchForEventPdfUrl (eventId, options) {
    options['use_user_language'] = true
    return (
      API.base +
      '/events/' +
      eventId +
      '/report/html?' +
      $httpParamSerializer(options)
    )
  }

  function fetchPayouts (options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/revenue/payouts',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function fetchPayoutsCsvUrl (options) {
    return Authentication.mountAuthenticatedUrl(
      API.base + '/admin/revenue/payouts.csv?' + $httpParamSerializer(options)
    )
  }

  function fetchPayout (id) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/revenue/payouts/' + id,
      headers: Authentication.generateHeaders()
    })
  }

  function fetchPayoutItems (id, options) {
    return Helper.abortableRequest({
      method: 'GET',
      url: API.base + '/admin/revenue/payouts/' + id + '/items',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function fetchPayoutItemsCsvUrl (id, options) {
    return Authentication.mountAuthenticatedUrl(
      API.base +
        '/admin/revenue/payouts/' +
        id +
        '/items.csv?' +
        $httpParamSerializer(options)
    )
  }

  return {
    fetchCurrencies,
    fetchDates,
    fetchDatesCsvUrl,
    fetchEvents,
    fetchEventsCsvUrl,
    fetchForDate,
    fetchForDateCsvUrl,
    fetchForEvent,
    fetchForEventCsvUrl,
    fetchForEventPdfUrl,
    fetchPayouts,
    fetchPayoutsCsvUrl,
    fetchPayout,
    fetchPayoutItems,
    fetchPayoutItemsCsvUrl
  }
})
