'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('InviteParkersLotSelectionCtrl', function (
  $stateParams,
  $scope,
  Helper,
  Authentication,
  $i18next,
  $state,
  $window
) {
  $scope.selectBundle = function (eventGroup, bundleId) {
    eventGroup.selectedBundleId = bundleId
  }

  $scope.selectLot = function (eventGroup, lotId) {
    var lot = _.find(eventGroup.events[0].lots, { id: lotId })

    if (lot.soldOut) {
      return
    }

    eventGroup.selectedLotId = lotId
  }

  $scope.submitForm = function () {
    if (!confirmLotSelections()) {
      return false
    }

    nextStep()
  }

  function nextStep () {
    var data = {
      accounts: $scope.accounts,
      selectedBundles: getSelectedBundles(),
      selectedEvents: getSelectedEvents()
    }

    if (hasMultipleCurrencies(data)) {
      Helper.showAlert(
        $i18next.t('inviteParkers.lotSelection.errors.multipleCurrencies')
      )
      return
    }

    $scope.changeStep(4, data)
  }

  function hasMultipleCurrencies (purchaseData) {
    let bundleCurrencies = purchaseData.selectedBundles.map(
      bundle => bundle.lot.country.currency
    )
    let eventCurrencies = purchaseData.selectedEvents.map(
      event => event.selectedLot.country.currency
    )
    let currencies = _.uniq([...bundleCurrencies, ...eventCurrencies])

    return currencies.length > 1
  }

  function getSelectedBundles () {
    return $scope.bundleGroups
      .filter(function (bundleGroup) {
        return bundleGroup.selectedBundleId
      })
      .map(function (bundleGroup) {
        return _.find(bundleGroup.bundles, { id: bundleGroup.selectedBundleId })
      })
  }

  function getSelectedEvents () {
    var eventGroups = _.cloneDeep($scope.eventGroups)

    return _(eventGroups)
      .filter(function (eventGroup) {
        return eventGroup.selectedLotId
      })
      .map(function (eventGroup) {
        return eventGroup.events.map(function (event) {
          var lot = _.find(event.lots, { id: eventGroup.selectedLotId })
          event.selectedLot = lot
          return event
        })
      })
      .flatten()
      .value()
  }

  function confirmLotSelections () {
    var bundlesSelected = _.filter($scope.bundleGroups, 'selectedBundleId')
      .length
    var eventLotsSelected = _.filter($scope.eventGroups, 'selectedLotId').length

    if (
      $scope.bundleGroups.length !== bundlesSelected ||
      $scope.eventGroups.length !== eventLotsSelected
    ) {
      $window.alert($i18next.t('inviteParkers.lotSelection.errors.lotsMissing'))
      return false
    }

    return true
  }

  function addSelectedBundleId (bundleGroups) {
    return bundleGroups.map(function (bundleGroup) {
      bundleGroup.selectedBundleId = null
      return bundleGroup
    })
  }

  function groupEventsByRate (events) {
    var groupedEvents = _.groupBy(events, function (event) {
      var rates = _(event.lots)
        .sortBy('id')
        .map(function (lot) {
          var lotRates = JSON.stringify(
            _(lot.rate)
              .pairs()
              .sortBy('0')
              .value()
          )
          return lot.id + ':' + lotRates + ':' + lot.soldOut
        })
        .value()
        .join('|')

      return rates
    })

    return _.values(groupedEvents).map(function (eventGroup) {
      return {
        events: eventGroup,
        lots: _.cloneDeep(eventGroup[0].lots),
        selectedLotId: null
      }
    })
  }

  $scope.separateGroup = function (group) {
    const index = _.findIndex($scope.eventGroups, g => g === group)

    const newGroups = group.events.map(event => ({
      events: [event],
      lots: group.lots,
      selectedLotId: group.selectedLotId
    }))

    $scope.eventGroups.splice(index, 1, ...newGroups)
  }

  function shouldSkipStep () {
    return $scope.bundleGroups.length === 0 && $scope.eventGroups.length === 0
  }

  function initialize () {
    $scope.$on('init-step-3', function (e, data) {
      $scope.accounts = data.accounts
      $scope.bundleGroups = addSelectedBundleId(data.selectedBundleGroups)
      $scope.eventGroups = groupEventsByRate(data.selectedEvents)

      if (shouldSkipStep()) {
        nextStep()
      }
    })

    $scope.$on('return-to-step-3', () => {
      if (shouldSkipStep()) {
        $scope.returnStep()
      }
    })
  }

  initialize()
})
