'use strict'

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { calculateRefundPreview } from '../utils'
import { useTranslator } from '../../../shared/react/hooks'
import { getAngularService } from '../../../shared/react/utils'

export const ReservationRefundContext = createContext()

export const useReservationRefundContext = () =>
  useContext(ReservationRefundContext)

export default function ReservationRefundContextProvider ({
  reservationId,
  children
}) {
  const t = useTranslator()

  const [isLoading, setIsLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState(null)
  const [hasError, setHasError] = useState(false)
  const [reservation, setReservation] = useState(null)
  const [refundOptions, setRefundOptions] = useState(null)
  const [userInterfaceSettings, setUserInterfaceSettings] = useState(null)
  const [refundPreview, setRefundPreview] = useState(null)

  // Load reservation
  useEffect(() => {
    async function fetchData () {
      const Reservations = getAngularService('Reservations')
      setIsLoading(true)

      try {
        const reservation = await Reservations.find(reservationId)
        if (reservation.cancelledAt) {
          redirectToReservation(reservationId)
        }

        const defaultRefundOptions = await Reservations.getDefaultCancellationOptions(
          reservationId
        )

        setReservation(reservation)

        const refundOptions = {
          ...defaultRefundOptions.options,
          reason: '',
          requestedByPhone: false,
          requestedByEmail: false
        }

        setRefundOptions(refundOptions)
        setUserInterfaceSettings(defaultRefundOptions.userInterfaceSettings)

        const refundPreview = await calculateRefundPreview({
          reservationId,
          feeRetentionMethod: refundOptions.feeRetentionMethod
        })

        setRefundPreview(refundPreview)
      } catch (err) {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  const requestRefund = useCallback(async () => {
    const Reservations = getAngularService('Reservations')

    setIsLoading(true)
    setLoadingMessage(t('refundPages.requestingRefund'))

    try {
      await Reservations.cancel(reservationId, refundOptions)
      redirectToReservation(reservationId)
    } catch (err) {
      const { showErrorAlert } = getAngularService('Helper')
      setIsLoading(false)
      showErrorAlert(err.data)
    }
  }, [reservationId, refundOptions])

  const redirectToReservation = useCallback(() => {
    const $state = getAngularService('$state')
    $state.go('reservation', { id: reservationId }, { location: 'replace' })
  }, [reservationId])

  return (
    <ReservationRefundContext.Provider
      value={{
        reservationId,
        reservation,
        isLoading,
        loadingMessage,
        hasError,
        refundOptions,
        setRefundOptions,
        refundPreview,
        userInterfaceSettings,

        redirectToReservation,
        requestRefund
      }}
    >
      {children}
    </ReservationRefundContext.Provider>
  )
}
