'use strict'

import { getAngularService } from '../../shared/react/utils'
import _ from 'lodash'

export async function calculateRefundPreview ({
  reservationId,
  feeRetentionMethod
}) {
  const Reservations = getAngularService('Reservations')

  const { operations } = await Reservations.calculateRefundPreview(
    reservationId,
    {
      feeRetentionMethod,
      // we must inform 'seller' independent of the real checkbox value in order to always show complimentary reversals
      refunder: 'seller',
      refundAmount: 'REVERSE_ALL'
    }
  )

  const refundDestinations = _(operations)
    .filter(operation =>
      ['purchase', 'payment', 'authorization'].includes(
        operation.transaction.type
      )
    )
    .groupBy(operation => _.get(operation, 'transaction.card.id'))
    .map(operationsPerCard => ({
      card: operationsPerCard[0].transaction.card,
      amount: _.sum(operationsPerCard, 'amountToReverse')
    }))
    .value()

  return {
    refundDestinations,
    operations
  }
}
