'use strict'

import _ from 'lodash'
import ApolloClient from '../../shared/services/apolloClient'
import ADMIN_GET_RESERVED_PARKING_PASS from '../../graphql/reservedParkingPasses/queries/AdminGetReservedParkingPass'
import ADMIN_GENERATE_RESERVED_PARKING_DEFAULT_REFUND_OPTIONS from '../../graphql/reservedParkingPasses/queries/AdminGenerateReservedParkingDefaultRefundOptions'
import ADMIN_GENERATE_RESERVED_PARKING_REFUND_PREVIEW from '../../graphql/reservedParkingPasses/queries/AdminGenerateReservedParkingRefundPreview'

export const calculateRefundPreview = ({ refundPreview }) => {
  const refundDestinations = _(refundPreview)
    .filter(operation =>
      ['purchase', 'payment', 'authorization'].includes(
        operation.transaction.type?.toLowerCase()
      )
    )
    .groupBy(operation => _.get(operation, 'transaction.card.id'))
    .map(operationsPerCard => ({
      card: operationsPerCard[0].transaction.card,
      amount: _.sum(operationsPerCard, 'amountToReverse')
    }))
    .value()

  return {
    refundDestinations,
    refundPreview
  }
}

export const getReservedParkingPass = async reservedParkingPassId => {
  const response = await ApolloClient().query({
    query: ADMIN_GET_RESERVED_PARKING_PASS,
    variables: {
      passId: parseInt(reservedParkingPassId)
    }
  })
  const pass = response?.data?.admin_getReservedParkingPass
  if (!pass) {
    throw new Error('Could not load pass')
  }
  return pass
}

export const getReservedParkingPassDefaultRefundOptions = async reservedParkingPassId => {
  const response = await ApolloClient().query({
    query: ADMIN_GENERATE_RESERVED_PARKING_DEFAULT_REFUND_OPTIONS,
    variables: {
      passId: parseInt(reservedParkingPassId)
    }
  })

  const refundOptions =
    response?.data?.admin_generateReservedParkingDefaultRefundOptions

  if (!refundOptions) {
    throw new Error('Could not load default refund options')
  }

  return refundOptions
}

export const getReservedParkingPassRefundPreview = async ({
  reservedParkingPass,
  refundOptions
}) => {
  const response = await ApolloClient().query({
    query: ADMIN_GENERATE_RESERVED_PARKING_REFUND_PREVIEW,
    variables: {
      passId: parseInt(reservedParkingPass?.id),
      refundAmount: reservedParkingPass?.calculatedPrice?.total,
      feeRetentionMethod: refundOptions?.feeRetentionMethod?.toUpperCase(),
      refunder: refundOptions?.refunder?.toUpperCase()
    }
  })

  const refundPreview = response.data.admin_generateReservedParkingRefundPreview

  if (!refundPreview) {
    throw new Error('Could not load refund preview')
  }

  return calculateRefundPreview({
    refundPreview: response.data.admin_generateReservedParkingRefundPreview
  })
}
