'use strict'

import React, { useState, useEffect, useRef } from 'react'
import { Text } from '@citifyd/style'

import { getTranslator } from '../../../../shared/react/utils'
import { useTranslator } from '../../../../shared/react/hooks'
import {
  generateOrderForClickEvent,
  sort
} from '../../../../shared/react/sorting'
import LoadingManager from '../../../../shared/react/components/LoadingManager'
import Table from '../../../../shared/react/components/Table'
import { getData } from './utils'

function MonthlySubscriptions ({ isLoading, hasError, subscriptions }) {
  const t = useTranslator()

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        loadingMessage={t('user.subscriptions.loading')}
        hasError={hasError}
        errorMessage={t('user.errorLoadingPasses')}
      />
    )
  }

  if (subscriptions.length === 0) {
    return <Text>{t('user.subscriptions.empty')}</Text>
  }

  return <SubscriptionsTable subscriptions={subscriptions} />
}

const initialOrders = [['start', 'desc']]
const getSort = (items, order) => ({ items, order, sortFunctions })

function SubscriptionsTable ({ subscriptions }) {
  const [sortedSubscriptions, setSortedSubscriptions] = useState(
    sort(getSort(subscriptions, initialOrders))
  )
  const [orders, setOrders] = useState(initialOrders)
  const firstSort = useRef(true)

  const handleSort = (event, property) => {
    setOrders(
      generateOrderForClickEvent({
        event,
        currentOrder: orders,
        propertyName: property
      })
    )
  }

  useEffect(() => {
    if (!firstSort.current) {
      setSortedSubscriptions(sort(getSort(subscriptions, orders)))
    } else {
      firstSort.current = false
    }
  }, [subscriptions, orders])

  const { columns, data } = getData({
    subscriptions: sortedSubscriptions,
    handleSort,
    orders
  })

  return (
    <Table
      spacing
      responsive
      columns={columns}
      sortable
      data={data}
      clickable
    />
  )
}

const sortFunctions = {
  statuses: statuses => {
    const t = getTranslator()
    return statuses
      .map(status => t(`subscriptions.statuses.${status}`).toLowerCase())
      .join(',')
  }
}

export default MonthlySubscriptions
