import gql from 'graphql-tag'

export default gql`
  mutation AdminCancelAndRefundReservedParkingPass(
    $passId: Int!
    $feeRetentionMethod: FeeRetentionMethod!
    $refunder: Refunder!
    $stripeFeePayer: StripeFeePayer!
    $reason: String!
    $requestedByEmail: Boolean!
    $requestedByPhone: Boolean!
  ) {
    admin_cancelAndRefundReservedParkingPass(
      passId: $passId
      feeRetentionMethod: $feeRetentionMethod
      refunder: $refunder
      stripeFeePayer: $stripeFeePayer
      reason: $reason
      requestedByEmail: $requestedByEmail
      requestedByPhone: $requestedByPhone
    )
  }
`
