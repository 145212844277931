'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('BundlePurchases', (API, Authentication, $http) => {
  function loadBundlePurchase (bundlePurchaseId) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/bundle-purchases/' + bundlePurchaseId,
      headers: Authentication.generateHeaders()
    })
  }

  function exportPurchaseReceipt (bundlePurchaseId, language) {
    return $http({
      method: 'GET',
      url: `${API.base}/admin/bundle-purchases/${bundlePurchaseId}/purchase-receipt/export?request_language=${language}`,
      headers: {
        ...Authentication.generateHeaders(),
        'Citifyd-accept-language': undefined
      },
      responseType: 'arraybuffer'
    })
  }

  return {
    load: loadBundlePurchase,
    exportPurchaseReceipt: exportPurchaseReceipt
  }
})
