'use strict'

import angular from 'angular'
import jQuery from 'jquery'
import jstz from 'jstz'
import moment from 'moment-timezone'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.factory(
  'Helper',
  (ENV, $q, $http, $state, $timeout, $i18next, $window, $document) => {
    const $ = jQuery
    const Helper = {}

    // e.g.
    // getTranslatedMonthName('Feb') returns 'Fev'
    // getTranslatedMonthName('February') returns 'Fevereiro'
    // when the selected i18next language is pt-BR
    Helper.getTranslatedMonthName = function (month) {
      const format = month.length === 3 ? 'MMM' : 'MMMM'
      return moment()
        .locale('en')
        .month(month)
        .locale($i18next.i18n.language)
        .format(format)
    }

    // e.g.
    // getTranslatedDayName('Monday') returns 'Segunda-feira'
    // getTranslatedDayName('Mon') returns 'Seg'
    // getTranslatedDayName('Mon', 'dddd') returns 'Segunda-feira'
    // when the selected i18next language is pt-BR
    Helper.getTranslatedDayName = function (day, format) {
      if (!format) {
        format = day.length === 3 ? 'ddd' : 'dddd'
      }
      return moment()
        .locale('en')
        .day(day)
        .locale($i18next.i18n.language)
        .format(format)
    }

    Helper.confirmPageUnload = function (okCallback, cancelCallback) {
      var confirmationFunction = $window.onbeforeunload || function () {}
      var confirmationMessage = confirmationFunction()

      if (confirmationMessage) {
        if ($window.confirm(confirmationMessage)) {
          if (_.isFunction(okCallback)) {
            okCallback(true)
          }
        } else if (_.isFunction(cancelCallback)) {
          cancelCallback()
        }
      } else if (_.isFunction(okCallback)) {
        okCallback(false)
      }
    }

    Helper.setPageUnloadConfirmation = function ($scope, func) {
      var previousUnloadHandler = $window.onbeforeunload
      $window.onbeforeunload = func

      $scope.$on('$destroy', function () {
        console.log('scope destroyed', previousUnloadHandler)
        $window.onbeforeunload = previousUnloadHandler
      })
    }

    Helper.calculateReservationDuration = function (startTime, endTime) {
      var end = endTime ? moment(endTime) : moment()
      var duration = moment.duration(end.diff(startTime))

      return this.formatDuration(duration)
    }

    Helper.formatDuration = function (duration) {
      duration = moment.duration(duration, 'minutes')

      const days = duration.get('days')
      const hours = duration.get('hours') + duration.get('days') * 24
      const minutes = duration.get('minutes')

      var str
      if (days === 1) {
        str = $i18next.t('timeDuration.day', {
          days: days
        })
      } else if (days > 1) {
        str = $i18next.t('timeDuration.day_plural', {
          days: days
        })
      } else if (hours >= 1) {
        str = $i18next.t('timeDuration.hoursAndMinutes', {
          hours: hours,
          minutes: minutes
        })
      } else {
        str = $i18next.t('timeDuration.minutes', {
          minutes: minutes
        })
      }

      return str
    }

    Helper.sendHiddenForm = function (url, data, target) {
      var $form = $('<form style="display:none"></form>')
      $form.attr('target', target)
      $form.attr('action', url)
      $form.attr('method', 'post')

      _.forEach(data, function (value, key) {
        $('<input type="hidden" />')
          .attr('name', key)
          .val(value)
          .appendTo($form)
      })

      $form.appendTo('body').submit()
      $timeout(function () {
        $form.remove()
      }, 100)
    }

    Helper.removeUndefinedAndNullValues = function (obj) {
      return _(obj)
        .omit(_.isUndefined)
        .omit(_.isNull)
        .value()
    }

    Helper.isNil = function (value) {
      return value === null || value === undefined
    }

    Helper.scrollTo = function (selector, focus, margin) {
      var $el = $(selector)
      var offset = $el.offset()

      if (margin === undefined) {
        margin = -16
      }

      if (!offset) {
        return false
      }

      var top = offset.top + margin

      $('html, body')
        .stop()
        .animate({ scrollTop: top > 0 ? top : 0 }, 250, function () {
          if (focus) {
            var invalidFieldSelector = _.isString(focus) ? focus : 'ng-invalid'
            var $field = $el.find(invalidFieldSelector).first()

            if ($field.length) {
              $field.focus()
            }
          }
        })

      return true
    }

    Helper.scrollToError = function (focus) {
      return Helper.scrollTo('.has-error:first', focus)
    }

    Helper.setPageTitle = function (title, only) {
      if (!title) {
        $document[0].title = 'Citifyd'
        return
      }

      $document[0].title = title + (only ? '' : ' - Citifyd')
    }

    // Transform an array of spaces to an object.
    // Example input: [{ name: 'regular', max: 50 }, { name: 'ada', max: 5 }]
    // Example output: { regular: 50, ada: 5 }
    Helper.spacesArrayToObject = function (spaces) {
      return _(spaces)
        .indexBy('name')
        .mapValues(function (space) {
          return space.max
        })
        .value()
    }

    // Transform a spaces object to an array of spaces.
    // Example input: { regular: 50, ada: 5 }
    // Example output: [{ name: 'regular', max: 50 }, { name: 'ada', max: 5 }]
    Helper.spacesObjectToArray = function (spaces) {
      return _.map(spaces, function (value, key) {
        return { name: key, max: parseInt(value, 10) }
      })
    }

    // Generate an array with the day times from 30 to 30 minutes.
    // Pass a string as argument to limit the start of the times array, e.g. by passing "14:30" (2:30pm)
    // you'll only get values starting from "15:00" (3pm)
    Helper.generateTimesArray = function (startAfter) {
      var times = []
      var date = moment().startOf('day')

      if (_.isString(startAfter)) {
        startAfter = parseInt(startAfter.replace(':', ''), 10)
      }

      for (var i = 0; i < 48; i++) {
        var number = parseInt(date.locale('en').format('HHmm'), 10)

        if (!startAfter || number > startAfter) {
          times.push({
            time: date.locale('en').format('HH:mm'),
            label: $i18next.t('timeSelector.displayFormat', { time: date }),
            number: number
          })
        }

        date.add(30, 'minutes')
      }

      return times
    }

    // Extract hours and minutes from string.
    // '14:30' becomes { hour: 14, minutes: 30, minutesOfDay: 870 }
    Helper.timeStringToObject = function (str) {
      var time = str.split(':')
      var hours = parseInt(time[0], 10)
      var minutes = parseInt(time[1], 10)

      return {
        hours: hours,
        minutes: minutes,
        minutesOfDay: 60 * hours + minutes
      }
    }

    // Transforms the date, time and timezone arguments into a moment() object.
    // Example input: new Date(2016, 5, 4), '11:00', 'America/Los_Angeles'
    Helper.dateAndTimeToMomentObject = function (date, time, timezoneName) {
      var timeObj = _.isString(time) ? Helper.timeStringToObject(time) : time
      var momentObj = timezoneName
        ? moment.tz(
            moment(date)
              .locale('en')
              .format('YYYY-MM-DD'),
            timezoneName
          )
        : moment(date)

      momentObj.set('hours', timeObj.hours)
      momentObj.set('minutes', timeObj.minutes)

      return momentObj
    }

    // Get the local timezone name. Example: "America/Los_Angeles"
    Helper.getLocalTimezoneName = function () {
      return jstz.determine().name()
    }

    // Get list of timezones
    Helper.getTimezoneList = function () {
      var zones = _.map(moment.tz.names(), function (zoneName) {
        var obj = {
          label: zoneName.replace(/_/g, ' '),
          name: zoneName,
          offset:
            'UTC' +
            moment()
              .tz(zoneName)
              .locale('en')
              .format('Z')
        }

        obj.labelWithOffset = obj.label + ' (' + obj.offset + ')'

        return obj
      })

      zones = _.filter(zones, function (zone) {
        return !zone.name.match(/^(gmt|etc)/i)
      })

      return _.sortBy(zones, 'offset')
    }

    // Check if the arguments happen on the same date.
    // You can pass a string, a Date object or a moment object.
    Helper.isSameDate = function (d1, d2, timezoneName) {
      var m1 = timezoneName ? moment.tz(d1, timezoneName) : moment(d1)
      var m2 = timezoneName ? moment.tz(d2, timezoneName) : moment(d2)

      return (
        m1.locale('en').format('YYYY-MM-DD') ===
        m2.locale('en').format('YYYY-MM-DD')
      )
    }

    // Gets the time of a string/moment/Date object and transforms it into a decimal value.
    // Examples:
    // 10:00 becomes 10
    // 10:15 becomes 10.25
    // 10:30 becomes 10.5
    // 10:45 becomes 10.75
    Helper.timeToDecimal = function (time) {
      time = moment(time).locale('en')
      var hours = time.get('hours')
      var minutes = time.get('minutes')
      return moment.duration(hours + ':' + minutes).asHours()
    }

    // Shows a browser alert, waiting the time for the loading spinner to hide if
    // it's the case
    Helper.showAlert = function (message) {
      $timeout(function () {
        $window.alert(message)
      }, 50)
    }

    // Shows an error message coming from the server
    Helper.showErrorAlert = function (response) {
      Helper.showAlert(Helper.getServerErrorMessage(response))
    }

    // Extract error message from the server
    Helper.getServerErrorMessage = function (response) {
      let message = _.get(response, 'error.message')

      if (!message) {
        message = $i18next.t('commonErrors.connectionProblem')
      }

      return message
    }

    // Checks if user is in mobile view
    Helper.inMobileView = function () {
      return $window.innerWidth < 767
    }

    // Returns a $http request object with an additional method "abort"
    // that cancels the request.
    Helper.abortableRequest = function (options) {
      var canceller = $q.defer()
      options.timeout = canceller.promise

      var request = $http(options)
      request.abort = function () {
        canceller.resolve()
      }

      return request
    }

    // Returns a promise that auto-resolves
    // Useful when we have a first request in our code that is optional.
    Helper.promise = function (value) {
      return $q(function (resolve) {
        resolve(value)
      })
    }

    // Extract hours and minutes from string.
    // '14:30' becomes { hour: 14, minutes: 30, minutesOfDay: 870 }
    Helper.getHoursAndMinutes = function (str) {
      var time = str.split(':')
      var hours = parseInt(time[0], 10)
      var minutes = parseInt(time[1], 10)

      return {
        hours: hours,
        minutes: minutes,
        minutesOfDay: 60 * hours + minutes
      }
    }

    // Transforms a time string to an object.
    // e.g. "15:20:10" becomes { hour: 15, minute: 20, second: 10 }
    Helper.timeToObject = function (time) {
      if (!_.isString(time)) {
        return
      }
      var m = moment(time, 'HH:mm:ss', true).locale('en')

      if (!m.isValid()) {
        return
      }

      return {
        hour: m.get('hour'),
        minute: m.get('minute'),
        second: m.get('second')
      }
    }

    Helper.capitalize = function (str) {
      return str.length ? str[0].toUpperCase() + str.slice(1).toLowerCase() : ''
    }

    Helper.calculateRateDifference = function (a, b) {
      return a.value + a.fee - (b.value + b.fee)
    }

    // Flattens an object using dot notation.
    // e.g. { a: { b: 'c' } } becomes { 'a.b': 'c' }
    Helper.flattenWithDotNotation = function (obj, prefix) {
      let ret = {}

      if (_.isPlainObject(obj)) {
        let propName = prefix ? `${prefix}.` : ''

        for (let attr in obj) {
          if (_.isPlainObject(obj)) {
            _.extend(
              ret,
              Helper.flattenWithDotNotation(obj[attr], propName + attr)
            )
          } else {
            ret[propName + attr] = obj[attr]
          }
        }
      } else {
        ret[prefix] = obj
      }

      return ret
    }

    // Expands object that uses dot notation (like the ones produced by Helper.flattenWithDotNotation)
    // e.g. { 'a.b': 'c' } becomes { a: { b: 'c' } }
    Helper.expandObject = function (obj) {
      let res = {}

      for (let key in obj) {
        _.set(res, key, obj[key])
      }

      return res
    }

    Helper.deepDiff = function (obj, base) {
      function changes (obj, base) {
        return _.transform(obj, (result, value, key) => {
          if (!_.isEqual(value, base[key])) {
            result[key] =
              _.isObject(value) && _.isObject(base[key])
                ? changes(value, base[key])
                : value
          }
        })
      }

      return changes(obj, base)
    }

    Helper.planifyCountries = function (countriesByContinent) {
      return _(countriesByContinent)
        .map(continent => {
          continent.countries.forEach(country => {
            country.continent = continent.name
          })

          return continent.countries
        })
        .flatten()
        .sortBy(country => country.name)
        .value()
    }

    Helper.navigate = function ($event, to, params, options) {
      // If the command key is down or it's a middle click,
      // open the URL in a new tab
      if ($event && ($event.metaKey || $event.which === 2)) {
        var url = $state.href(to, params, { absolute: true })
        window.open(url, '_blank')
      } else {
        $state.go(to, params, options)
      }
    }

    // Group close items by a certain condition
    // Example of input:
    // groupCloseItemsBy(
    //   ['ace', 'acorn', 'acrobat', 'baby', 'ball', 'angel', 'apple'], // items to be grouped
    //   item => item[0] === 'a', // group close items that start with "a"
    //   items => ({ type: 'group', group: items }) // creates group with this format
    // )
    // returns
    // [
    //   { type: 'group', group: ['ace', 'acorn', 'acrobat'] },
    //   'baby',
    //   'ball',
    //   { type: 'group', group: ['angel', 'apple'] }
    // ]
    Helper.groupCloseItemsBy = function (
      list,
      groupConditionFn,
      groupObjectFn
    ) {
      const newList = []
      let currentGroup = null

      const addCurrentGroupToList = () => {
        if (currentGroup) {
          newList.push(groupObjectFn(currentGroup))
          currentGroup = null
        }
      }

      for (const item of list) {
        if (groupConditionFn(item)) {
          if (!currentGroup) {
            currentGroup = []
          }

          currentGroup.push(item)
        } else {
          addCurrentGroupToList()
          newList.push(item)
        }
      }

      addCurrentGroupToList()
      return newList
    }

    Helper.milesToKilometers = function (miles) {
      return miles / 0.62137
    }

    // Generate object used to clear default headers for HTTP requests.
    // It can be commonly used when calling resources that have restrictions
    // to Access-Control-Allow-Headers
    Helper.generateDefaultHeadersClearObject = function () {
      return _.mapValues($http.defaults.headers.common, () => undefined)
    }

    Helper.uploadImage = function (formIdOrElement, uploadPresetSuffix) {
      const formElement =
        formIdOrElement instanceof Element ||
        formIdOrElement instanceof HTMLDocument
          ? formIdOrElement
          : document.getElementById(formIdOrElement)

      if (!formElement) {
        throw new Error(
          `Could not find form with id ${formId} for image upload.`
        )
      }

      // Form passed as reference must have file-type input with name "file" inside.
      const formData = new $window.FormData(formElement)
      formData.append(
        'upload_preset',
        `${ENV.cloudinaryUploadPresetPrefix}-${uploadPresetSuffix}`
      )

      const cloudName = 'citifyd'

      const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`

      return $http
        .post(uploadUrl, formData, {
          // Tells angular.js to leave FormData intact instead of trying to convert
          // to JSON.
          transformRequest: angular.identity,

          headers: {
            // Remove our default headers such as Citifyd app version, etc.
            ...Helper.generateDefaultHeadersClearObject(),

            // set Content-Type to undefined so that angular.js uses multipart/form-data
            // do not set multipart/form-data here, leave it to angular.js otherwise it
            // won't fill the boundary parameter of the request properly
            // source:
            // https://withintent.uncorkedstudios.com/multipart-form-data-file-upload-with-angularjs-c23bf6eeb298
            'Content-Type': undefined
          }
        })
        .then(response => ({
          cloudName,
          format: response.data['format'],
          publicId: response.data['public_id'],
          url: response.data['secure_url']
        }))
    }

    Helper.generateStripeCustomerUrl = stripeCustomerId => {
      const parts = [
        'https://dashboard.stripe.com',
        ENV.name !== 'production' ? 'test' : null,
        'customers',
        stripeCustomerId
      ]
      return parts.filter(p => p).join('/')
    }

    Helper.normalize = str => _.deburr(str).toLowerCase()

    return Helper
  }
)
