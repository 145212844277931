'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Plans', (Helper, API, Authentication, $http) => {
  function createPlan (data) {
    return $http({
      method: 'POST',
      url: API.base + '/admin/plans',
      headers: Authentication.generateHeaders(),
      data: { plan: data }
    })
  }

  function updatePlan (data) {
    return $http({
      method: 'PUT',
      url: API.base + '/admin/plans/' + data.id,
      headers: Authentication.generateHeaders(),
      data: { plan: data }
    })
  }

  function deletePlan (planId) {
    return $http({
      method: 'DELETE',
      url: API.base + '/admin/plans/' + planId,
      headers: Authentication.generateHeaders()
    })
  }

  function findAllPlans (options) {
    return $http({
      method: 'GET',
      params: options,
      url: API.base + '/admin/plans',
      headers: Authentication.generateHeaders()
    }).then(function (response) {
      return response.data.plans
    })
  }

  return {
    findAll: findAllPlans,
    create: createPlan,
    update: updatePlan,
    delete: deletePlan
  }
})
