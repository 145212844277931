'use strict'

import React, { useMemo, useEffect, useState, Fragment } from 'react'
import { ButtonGroup } from '@citifyd/style'

import { useDispatch, useSelector, useStore, Provider } from 'react-redux'
import { changeMode, fetchData } from '../redux/thunk-actions'
import createStore from '../redux/store'

import RulesTable from './RulesTable.jsx'
import Preview from './Preview.jsx'
import Footer from './Footer.jsx'

import PageHeader from '../../../shared/react/components/PageHeader'
import BoxContent from '../../../shared/react/components/BoxContent'
import LoadingManager from '../../../shared/react/components/LoadingManager'

import { useTranslator } from '../../../shared/react/hooks'
import { getAngularService, getTranslator } from '../../../shared/react/utils'

const Page = ({ $scope }) => {
  const [store, setStore] = useState(null)
  useEffect(() => setStore(createStore()), [])

  if (!store) {
    return null
  }

  return (
    <Provider store={store}>
      <PageContent angularScope={$scope} />
    </Provider>
  )
}

const PageContent = ({ angularScope }) => {
  const dispatch = useDispatch()

  const store = useStore()
  const isLoading = useSelector(state => state.isLoading)
  const loadingMessage = useSelector(state => state.loadingMessage)
  const rateDefinition = useSelector(state => state.rateDefinition)
  const lot = useSelector(state => state.lot)

  useEffect(() => {
    const $stateParams = getAngularService('$stateParams')
    const { lotId, advancedRateDefinitionId } = $stateParams
    dispatch(fetchData({ lotId, advancedRateDefinitionId }))
  }, [])

  useEffect(() => {
    const { setPageUnloadConfirmation } = getAngularService('Helper')
    setPageUnloadConfirmation(angularScope, () =>
      generateUnsavedRuleConfirmationMessageIfNeeded(store)
    )
  }, [])

  return (
    <div>
      {!rateDefinition && (
        <LoadingManager isLoading={isLoading} loadingMessage={loadingMessage} />
      )}

      {rateDefinition && (
        <Fragment>
          <PageHeader>
            <PageHeader.Title
              defaultState='schedule'
              defaultParams={{ property: lot.id }}
            />
          </PageHeader>
          <RulesEditor />
        </Fragment>
      )}
    </div>
  )
}

const RulesEditor = () => {
  const t = useTranslator()
  const dispatch = useDispatch()
  const mode = useSelector(state => state.mode)
  const isLoading = useSelector(state => state.isLoading)
  const loadingMessage = useSelector(state => state.loadingMessage)
  const editEnabled = useSelector(state => state.editEnabled)
  const lot = useSelector(state => state.lot)

  const actionButtons = useMemo(() => {
    return ['view', editEnabled && 'edit', 'preview']
      .filter(item => item)
      .map(item => ({
        text: t(`advancedRateEditor.buttons.mode.${item}`),
        onClick: () => dispatch(changeMode(item)),
        active: item === mode
      }))
  }, [mode, editEnabled])

  return (
    <>
      <BoxContent>
        <BoxContent.Header
          title={t('advancedRateEditor.pageTitleWithLotName', {
            lotName: lot.name
          })}
        >
          <BoxContent.HeaderActions>
            <ButtonGroup disabled={isLoading} buttons={actionButtons} />
          </BoxContent.HeaderActions>
        </BoxContent.Header>

        <BoxContent.Content noHorizontalPadding>
          {isLoading && (
            <LoadingManager
              isLoading={isLoading}
              loadingMessage={loadingMessage}
            />
          )}
          {!isLoading && (
            <Fragment>
              {['view', 'edit'].includes(mode) && <RulesTable />}
              {mode === 'preview' && <Preview />}
            </Fragment>
          )}
        </BoxContent.Content>
      </BoxContent>

      <Footer />
    </>
  )
}

const generateUnsavedRuleConfirmationMessageIfNeeded = store => {
  const t = getTranslator()
  const { hasUnsavedRules } = store.getState()
  if (hasUnsavedRules) {
    return t('advancedRateEditor.messages.exitWithoutSavingConfirmation')
  }
}

export default Page
