'use strict'

import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { NewGrid, Label, Button, Icon, Text } from '@citifyd/style'
import moment from 'moment-timezone'
import { useMutation } from '@apollo/client'

import { formatPhoneNumber } from '../../../../shared/utils/phone-number-formatting'
import LoadingManager from '../../../../shared/react/components/LoadingManager'
import ApolloClient from '../../../../shared/services/apolloClient'
import FormikProvider from '../../../../shared/react/components/FormikProvider'
import FormikField, {
  FIELD_TYPE
} from '../../../../shared/react/components/FormikField'
import RouterLink from '../../../../shared/react/components/RouterLink'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import styles from '../../AddPermit.module.scss'
import StickyFooter from '../../../../shared/react/components/StickyFooter'
import PhoneField from '../../../../shared/react/components/PhoneField'
import ADMIN_CREATE_PERMIT_PASS from '../../../../graphql/permitPass/mutations/AdminCreatePermitPass'
import ADMIN_UPDATE_PERMIT_PASS from '../../../../graphql/permitPass/mutations/AdminUpdatePermitPass'

const apolloClient = ApolloClient()

const AddPermitForm = ({ permit, countries, properties }) => {
  const t = useTranslator()
  const Authentication = useAngularService('Authentication')
  const Helper = useAngularService('Helper')
  const $state = useAngularService('$state')
  const loadingMessage = permit
    ? t('permits.form.updating')
    : t('permits.form.creating')
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [addPermit] = useMutation(ADMIN_CREATE_PERMIT_PASS, {
    client: apolloClient
  })

  const [editPermit] = useMutation(ADMIN_UPDATE_PERMIT_PASS, {
    client: apolloClient
  })

  const user = Authentication.getLoggedUser()
  const times = Helper.generateTimesArray()

  const lotOptions = properties.map(lot => ({
    value: lot.id,
    label: lot.name
  }))

  const formatDate = (date, time, timezone) =>
    moment(date)
      .set(getTimeObject(time))
      .tz(timezone)
      .format()

  const onSubmit = async values => {
    setIsLoading(true)

    const {
      countryCode,
      phoneNumber,
      lot,
      startDate,
      startTime,
      endDate,
      endTime
    } = values

    const phoneNumberFormatted = phoneNumber?.replace(/\s/g, '')

    const lotSelected = properties.filter(
      property => property.id === parseInt(lot)
    )[0]
    const start = formatDate(startDate, startTime, lotSelected?.timezoneName)
    const end = formatDate(endDate, endTime, lotSelected?.timezoneName)

    const payload = {
      lotId: parseInt(lot),
      startTime: start,
      endTime: end
    }

    try {
      const { data } = permit
        ? await editPermit({
            variables: {
              id: parseInt(permit.id),
              ...payload
            }
          })
        : await addPermit({
            variables: {
              phoneNumber: phoneNumberFormatted,
              phoneCountryCode: countryCode,
              ...payload
            }
          })

      if (data) {
        $state.go('permit', {
          permitId: permit
            ? data.admin_updatePermitPass?.id
            : data.admin_createPermitPass?.id
        })
      }
    } catch (err) {
      setErrorMessage(err?.message)
      setHasError(true)
      setIsLoading(false)
    }
  }

  const timeOptions = times.map(time => ({
    value: time.time,
    label: time.label
  }))

  const getTimeObject = str => {
    const parts = str.split(':')
    return {
      hour: parts[0],
      minute: parts[1],
      second: 0
    }
  }

  const getFormattedDate = (date, time) =>
    Helper.dateAndTimeToMomentObject(date, time).toDate()

  const getDate = date =>
    permit
      ? moment(permit[date])
          .tz(permit.lot.timezoneName)
          .toDate()
      : new Date()

  const getTime = time =>
    permit
      ? moment
          .tz(permit[time], permit.lot.timezoneName)
          .locale('en')
          .format('HH:mm')
      : time === 'startTime'
      ? '12:00'
      : '13:00'

  const formikbag = useFormik({
    initialValues: {
      countryCode: user?.phoneCountryCode || 'us',
      phoneNumber: permit
        ? permit.phoneNumber?.length > 2
          ? formatPhoneNumber(permit.phoneNumber, permit.phoneCountryCode)
          : ''
        : '',
      lot: permit ? permit.lot.id : '',
      startDate: getDate('startTime'),
      startTime: getTime('startTime'),
      endDate: getDate('endTime'),
      endTime: getTime('endTime'),
      frequency: 'one'
    },
    onSubmit,
    validationSchema: yup.object().shape({
      countryCode: yup.string().required(),
      phoneNumber: yup.string().required(),
      lot: yup.string().required(),
      startDate: yup.date().required(),
      startTime: yup
        .string()
        .test('is-before', t('permits.form.startError'), function (value) {
          const { startDate } = this.parent
          const start = getFormattedDate(startDate, value)

          return moment(start).isAfter(moment(new Date()))
        }),
      endDate: yup
        .date()
        .test('is-after', t('permits.form.endError'), function (value) {
          const { startDate, startTime, endTime } = this.parent
          const start = getFormattedDate(startDate, startTime)
          const end = getFormattedDate(value, endTime)

          return moment(end).isAfter(moment(start))
        }),
      endTime: yup
        .string()
        .test('is-after', t('permits.form.endError'), function (value) {
          const { startDate, startTime, endDate } = this.parent
          const start = getFormattedDate(startDate, startTime)
          const end = getFormattedDate(endDate, value)

          return moment(end).isAfter(moment(start))
        }),
      frequency: yup.string().required()
    })
  })

  const {
    handleSubmit,
    setFieldValue,
    values,
    setErrors,
    setTouched
  } = formikbag

  const onClickTryAgain = () => {
    setHasError(false)
    setErrorMessage(null)
  }

  const onStartDateChange = value => {
    const endDateTime = getFormattedDate(values?.endDate, values?.endTime)
    const startDateTime = getFormattedDate(value, values?.startTime)

    if (moment(endDateTime).isBefore(startDateTime)) {
      const newEndDate = moment(startDateTime)
        .add(1, 'hour')
        .toDate()

      setFieldValue('endDate', newEndDate)
      setErrors({})
      setTouched({})
    }
  }

  const onStartTimeChange = e => {
    const { value } = e.target

    const startDateTime = getFormattedDate(values?.startDate, value)
    const endDateTime = getFormattedDate(values?.endDate, values?.endTime)

    if (moment(startDateTime).isSameOrAfter(endDateTime)) {
      const newEndDateTime = moment(startDateTime).add(1, 'hour')

      setFieldValue('endTime', moment(newEndDateTime).format('HH:mm'))
      setFieldValue('endDate', moment(newEndDateTime).toDate())
      setErrors({})
      setTouched({})
    }
  }

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
        errorMessage={errorMessage}
        onClickTryAgain={onClickTryAgain}
      />
    )
  }

  return (
    <FormikProvider value={formikbag}>
      <NewGrid.Row>
        <NewGrid.Col xs={12} md={6}>
          <PhoneField
            placeholder={t('permits.form.phoneNumber')}
            countries={countries}
            countryCodeDefault={
              permit?.phoneCountryCode || user?.phoneCountryCode || 'us'
            }
            disabled={!!permit}
            label={t('permits.form.phone')}
          />
          <br />
        </NewGrid.Col>
        <NewGrid.Col xs={12} md={6}>
          <FormikField
            as={FIELD_TYPE.SELECT}
            placeholder={t('permits.form.selectProperty')}
            options={lotOptions}
            label={t('permits.form.property')}
            name='lot'
          />
          <br />
        </NewGrid.Col>
      </NewGrid.Row>

      <NewGrid.Row marginBottom>
        <NewGrid.Col xs={12} md={6}>
          <Label>{t('permits.form.start')}</Label>
          <NewGrid.Row gutterWidth={12}>
            <NewGrid.Col xs={8} md={8}>
              <FormikField
                as={FIELD_TYPE.DATEPICKER}
                name='startDate'
                shouldResetTime
                minDate={new Date()}
                handleChange={onStartDateChange}
              />
              <br />
            </NewGrid.Col>
            <NewGrid.Col xs={4} lg={4}>
              <FormikField
                as={FIELD_TYPE.SELECT}
                options={timeOptions}
                name='startTime'
                handleChange={onStartTimeChange}
              />
            </NewGrid.Col>
          </NewGrid.Row>
        </NewGrid.Col>
        <NewGrid.Col xs={12} md={6}>
          <Label>{t('permits.form.end')}</Label>
          <NewGrid.Row gutterWidth={12}>
            <NewGrid.Col xs={8} md={8}>
              <FormikField
                as={FIELD_TYPE.DATEPICKER}
                name='endDate'
                shouldResetTime
                minDate={new Date()}
              />
              <br />
            </NewGrid.Col>
            <NewGrid.Col xs={4} lg={4}>
              <FormikField
                as={FIELD_TYPE.SELECT}
                options={timeOptions}
                name='endTime'
              />
            </NewGrid.Col>
          </NewGrid.Row>
        </NewGrid.Col>
      </NewGrid.Row>

      {/* <Grid sm={12} md={4}>
        <Select
          options={frequencyOptions}
          disabled
          label={t('permits.form.frequency')}
        />
      </Grid> */}

      <StickyFooter justifyContent='space-between'>
        <RouterLink
          state={permit ? 'permit' : 'permits'}
          params={permit ? { permitId: permit.id } : null}
          className={styles.backLink}
        >
          <Icon size='subtitle' icon='chevron-left' appearance='white' />
          <Text variant='subtitle' appearance='white'>
            {t('commonButtons.goBackWithoutSaving')}
          </Text>
        </RouterLink>
        <Button uppercase onMouseDown={handleSubmit} extraPadding>
          {t('permits.form.save')}
        </Button>
      </StickyFooter>
    </FormikProvider>
  )
}

export default AddPermitForm
