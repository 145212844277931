import { max, forEach, forEachRight } from 'lodash'

const adjustPercentage = n => {
  return n > 0 && n < 1 ? 1 : Math.round(n)
}

const generateChartValue = (previousValue, portion, total) => {
  const percentage = total > 0 ? adjustPercentage((portion / total) * 100) : 0
  const value = previousValue + percentage
  return value + (value === 100 ? 1 : 0)
}

const generateChartStyleObject = value => ({
  'stroke-dasharray': value + ' 100'
})

const fillChart = values => {
  const maxValue = max(values)

  forEach(values, (value, index) => {
    if (value === maxValue && maxValue !== 0) {
      // we use 101 instead of 100 otherwise the chart won't be filled
      values[index] = 101
    }
  })
}

export const calculateChartStyles = (list, keys, total) => {
  let chartValues = {}
  let chartStyles = {}
  let previousKey = null

  keys.forEach(key => {
    let previousChartValue = previousKey ? chartValues[previousKey] : 0

    chartValues[key] = generateChartValue(previousChartValue, list[key], total)

    previousKey = key
  })

  fillChart(chartValues)

  forEachRight(chartValues, (value, key) => {
    chartStyles[key] = generateChartStyleObject(value)
  })

  return chartStyles
}
