'use strict'

import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone'
import { sum, find } from 'lodash'

import LoadingManager from '../../../../shared/react/components/LoadingManager'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import { getAngularService } from '../../../../shared/react/utils'
import RevenueFilter from '../RevenueFilter'
import RevenueEventsTable from '../RevenueEventsTable'
import { getFilterSelectionFromState } from '../../utils'

const RevenueEvents = ({ className, lots, currencies }) => {
  const Revenue = useAngularService('Revenue')
  const CitifydModal = useAngularService('CitifydModal')
  const t = useTranslator()
  const $state = useAngularService('$state')
  const [params, setParams] = useState($state.params)
  const currentRequest = useRef(null)
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [totals, setTotals] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null)

  const getQueryOptionsFromFilterSelection = filterSelection => {
    return {
      rangeStart: moment(filterSelection.dateRange.start).format('YYYY-MM-DD'),
      rangeEnd: moment(filterSelection.dateRange.end).format('YYYY-MM-DD'),
      currency: filterSelection.currency,
      lotId: filterSelection.lotId
    }
  }

  const calculateTotals = events => {
    const fields = ['netRevenueAmount', 'totalTickets', 'totalSpaces']

    let totals = {}

    for (const field of fields) {
      totals[field] = sum(events, event => {
        return event[field]
      })
    }

    setTotals(totals)
  }

  const load = async () => {
    setIsLoading(true)

    if (currentRequest.current) {
      currentRequest.current.abort()
      currentRequest.current = null
    }

    const filterSelection = getFilterSelectionFromState($state)
    const options = getQueryOptionsFromFilterSelection(filterSelection)

    setCurrentFilter(filterSelection)

    if (currentRequest.current) {
      currentRequest.current.abort()
    }

    try {
      currentRequest.current = Revenue.fetchEvents(options)
      const response = await currentRequest.current
      setEvents(response.data.events)
      calculateTotals(response.data.events)
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
      currentRequest.current = null
    }
  }

  useEffect(() => {
    const $rootScope = getAngularService('$rootScope')
    const unsubscribe = $rootScope.$watch(
      () => {
        return getAngularService('$state').params
      },
      params => {
        setParams(params)
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (params.dateRange) {
      load()
    }
  }, [params])

  const generateCsvReportDescription = filterSelection => {
    const options = {
      startDate: moment(filterSelection.dateRange.start),
      endDate: moment(filterSelection.dateRange.end)
    }

    if (filterSelection.lotId) {
      const lot = find(lots, lot => lot.id === filterSelection.lotId)
      return t('revenue.csvReportName.events.lot', {
        ...options,
        lotName: lot.name
      })
    } else {
      return t('revenue.csvReportName.events.currency', {
        ...options,
        currency: filterSelection.currency.toUpperCase()
      })
    }
  }

  const generateCsvClicked = () => {
    const filterSelection = getFilterSelectionFromState($state)
    const options = getQueryOptionsFromFilterSelection(filterSelection)

    CitifydModal.open('generate-report', {
      fileFormat: 'csv',
      filterDescription: generateCsvReportDescription(filterSelection),
      reportUrl: Revenue.fetchEventsCsvUrl(options)
    })
  }

  return (
    <div className={className}>
      <RevenueFilter
        onClickGenerateCsv={generateCsvClicked}
        enableLotSelection
        lots={lots}
        currencies={currencies}
      />
      {isLoading ? (
        <LoadingManager isLoading={isLoading} />
      ) : (
        <RevenueEventsTable
          events={events}
          totals={totals}
          currentFilter={currentFilter}
          params={params}
        />
      )}
    </div>
  )
}

export default RevenueEvents
