'use strict'

import React, { useEffect, useState } from 'react'
import { NewGrid } from '@citifyd/style'
import { find } from 'lodash'

import PageHeader from '../../shared/react/components/PageHeader'
import LoadingManager from '../../shared/react/components/LoadingManager'
import { useTranslator, useAngularService } from '../../shared/react/hooks'
import BundlePurchaseData from './components/BundlePurchaseData'

const BundlePurchase = () => {
  const t = useTranslator()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const $stateParams = useAngularService('$stateParams')
  const BundlePurchases = useAngularService('BundlePurchases')
  const Helper = useAngularService('Helper')
  const [bundlePurchase, setBundlePurchase] = useState()
  const bundlePurchaseId = $stateParams.id

  const loadBundlePurchase = async () => {
    setHasError(false)
    setIsLoading(true)

    try {
      const response = await BundlePurchases.load(bundlePurchaseId)

      const bundlePurchase = response.data.bundlePurchase

      bundlePurchase.purchaseTransaction = find(bundlePurchase.transactions, {
        type: 'purchase'
      })

      Helper.setPageTitle(
        t('bundlePurchase.pageTitleWithNumber', {
          packageNumber: bundlePurchase.id
        })
      )

      setBundlePurchase(bundlePurchase)
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadBundlePurchase()
  }, [])

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={t('ticket.loadingPass')}
        onClickTryAgain={loadBundlePurchase}
      />
    )
  }

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          defaultState='dashboard'
          title={t('back.backDashboard')}
        />
      </PageHeader>

      <BundlePurchaseData bundlePurchase={bundlePurchase} />
    </NewGrid>
  )
}

export default BundlePurchase
