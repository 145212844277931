'use strict'

import React, { useMemo } from 'react'
import { Modal } from '@citifyd/style'

import PhoneVerification from '../../react/components/PhoneVerification'
import { useAngularService } from '../../react/hooks'

const VerifyPhoneNumberModal = ({
  user,
  title,
  open,
  onClose,
  logoutLink,
  closeOnEscape
}) => {
  const Authentication = useAngularService('Authentication')

  const onVerify = async () => {
    await Authentication.reloadUser()
    onClose()
  }

  const onLogout = useMemo(() => {
    if (logoutLink) {
      return () => {
        Authentication.logout()
        window.location.reload()
      }
    } else {
      return null
    }
  }, [logoutLink])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={closeOnEscape}
      closeIcon={!onLogout}
    >
      <PhoneVerification
        onLogout={onLogout}
        onVerify={onVerify}
        user={user}
        title={title}
        onCancel={onClose}
      />
    </Modal>
  )
}

export default VerifyPhoneNumberModal
