'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('OnDemandScheduleCtrl', function (
  Properties,
  AvailabilityGroupGenerator,
  CitifydModal,
  $q,
  $scope,
  $state
) {
  $scope.editSchedule = function (propertyId, availabilityGroup) {
    var params = {
      propertyId: propertyId,
      type: availabilityGroup.filter.type,
      period: availabilityGroup.filter.period
    }

    CitifydModal.open('bulk-availability-editor', params, function (result) {
      if (result.action === 'success') {
        loadData()
      }
    })
  }

  function loadData () {
    if (
      !$scope.selectedProperty ||
      _.get($scope.selectedProperty, 'advancedRateDefinitionEnabled')
    ) {
      $scope.isLoading = false
      return
    }

    $scope.isLoading = true

    $q.all([getSchedule()])
      .then(function (results) {
        $scope.availabilityGroups = results[0]
      })
      .finally(function () {
        $scope.isLoading = false
      })
  }

  function getSchedule () {
    return Properties.fetchWeeklySchedule($scope.selectedProperty.id).then(
      function (response) {
        var availabilities = response.data.availabilities

        $scope.hasRatesDefiniton = availabilities.length > 0

        return AvailabilityGroupGenerator.generate(
          $scope.selectedProperty.id,
          availabilities,
          $scope.selectedProperty.timezoneName
        )
      }
    )
  }

  function init () {
    $scope.isLoading = false
    $scope.hasRatesDefiniton = false
    $scope.selectedAvailabilityGroup = null

    $scope.$watch('selectedProperty', () => loadData())

    $scope.$watch(
      () => $state.params.property,
      () => loadData()
    )
  }

  init()
})
