'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.animation('.animation-slide', function () {
  return {
    addClass: function (element, className, done) {
      element.slideUp(250, done)

      return function (cancel) {
        if (cancel) {
          element.stop()
        }
      }
    },

    removeClass: function (element, className, done) {
      element.hide().slideDown(250, done)

      return function (cancel) {
        if (cancel) {
          element.stop()
        }
      }
    }
  }
})
