import React, { useState, useEffect, useRef } from 'react'
import { Tab, NewGrid } from '@citifyd/style'

import Table from '../../../../shared/react/components/Table'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import { calculateChartStyles, generateData } from './utils'
import TabName from '../../../../shared/react/components/TabName'
import UsersChart from '../UsersChart'
import LoadingIndicator from '../../../../shared/react/components/LoadingIndicator'
import LoadingManager from '../../../../shared/react/components/LoadingManager'
import styles from './UsersStats.module.scss'

const UsersStats = () => {
  const t = useTranslator()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingFilter, setIsLoadingFilter] = useState(false)
  const [loading, setLoading] = useState({})
  const [cache, setCache] = useState({})
  const currentFilter = useRef(null)
  const [values, setValues] = useState({})
  const [mainChartValues, setMainChartValues] = useState(null)
  const [transferChartValues, setTransferChartValues] = useState(null)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const Users = useAngularService('Users')
  const [hasError, setHasError] = useState(false)
  const [counts, setCounts] = useState(null)

  const isFilterSelected = filterName => currentFilter.current === filterName

  const updateValues = values => {
    setValues(values)

    setMainChartValues(
      calculateChartStyles(
        values,
        ['parked', 'cancelled', 'expired', 'purchased'],
        values.total,
        true
      )
    )

    setTransferChartValues(
      calculateChartStyles(
        values,
        ['redeemed', 'pendingTransfer'],
        values.total,
        false
      )
    )
  }

  const selectFilter = async filterName => {
    setSelectedFilter(filterName)
    setHasError(false)
    currentFilter.current = filterName

    if (cache[filterName]) {
      if (isFilterSelected(filterName)) {
        setIsLoadingFilter(false)
      }

      setTimeout(() => {
        updateValues(cache[filterName])
      }, 100)
      return false
    }

    setIsLoadingFilter(true)

    if (loading[filterName]) {
      return false
    }

    setLoading({ [filterName]: true })

    try {
      const response = await Users.fetchStats({ filter: filterName })
      const values = response.data.values

      setCache(old => ({ ...old, [filterName]: values }))

      if (isFilterSelected(filterName)) {
        updateValues(values)
      }
    } catch (err) {
      setHasError(true)
    } finally {
      setLoading({ [filterName]: false })
      if (isFilterSelected(filterName)) {
        setIsLoadingFilter(false)
      }
    }
  }

  const fetchStats = async () => {
    try {
      const response = await Users.fetchStats({ includeCounts: true })

      const values = response.data.values

      setCounts(response.data.counts)
      updateValues(values)
      setSelectedFilter(response.data.counts[0]?.name)
      currentFilter.current = response.data.counts[0]?.name
      setCache({ [null]: values })
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchStats()
  }, [])

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />
  }

  const {
    columnsTotal,
    columnsTransferred,
    dataTotal,
    dataTransferred
  } = generateData(values, t)

  return (
    <Tab className={styles.tab} stateless>
      {counts.map((count, index) => {
        const isActive = selectedFilter === count.name

        const tabName = {
          big: count.count,
          small: count.label
        }

        return (
          <Tab.Item
            key={index}
            active={isActive}
            name={<TabName item={tabName} active={isActive} />}
            onClick={() => selectFilter(count.name)}
          >
            {isLoadingFilter && (
              <div className={styles.boxLoading}>
                <LoadingIndicator noMargin />
              </div>
            )}
            {!isLoadingFilter && (
              <NewGrid.Row align='center' onClick={e => e.stopPropagation()}>
                <NewGrid.Col className={styles.boxTables}>
                  <Table
                    borderBottom
                    cleanMode
                    data={dataTotal}
                    columns={columnsTotal}
                  />
                  <Table
                    borderBottom
                    cleanMode
                    data={dataTransferred}
                    columns={columnsTransferred}
                  />
                </NewGrid.Col>
                <NewGrid.Col>
                  <UsersChart
                    mainChartValues={mainChartValues}
                    transferChartValues={transferChartValues}
                  />
                </NewGrid.Col>
              </NewGrid.Row>
            )}
          </Tab.Item>
        )
      })}
    </Tab>
  )
}

export default UsersStats
