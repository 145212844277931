'use strict'

import React from 'react'
import { useTranslator } from '../../hooks'

import './style.scss'

import LoadingIndicator from '../LoadingIndicator'

function LoadingManager ({
  isLoading,
  hasError,
  errorMessage,
  loadingMessage,
  onClickTryAgain
}) {
  const t = useTranslator()

  if (hasError) {
    return (
      <div className='component-loading-manager component-loading-manager--error'>
        <p className='component-loading-manager__message'>
          {errorMessage || t('commonErrors.connectionProblem')}
        </p>

        {onClickTryAgain && (
          <button className='regular-button' onClick={onClickTryAgain}>
            {t('commonButtons.tryAgain')}
          </button>
        )}
      </div>
    )
  } else if (isLoading) {
    return (
      <div className='component-loading-manager component-loading-manager--loading'>
        <LoadingIndicator />
        {loadingMessage && (
          <p className='component-loading-manager__message'>{loadingMessage}</p>
        )}
      </div>
    )
  }

  return null
}

export default LoadingManager
