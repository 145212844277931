'use strict'

import angular from 'angular'

import Header from '../../react/components/Header'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctHeader',
  react2angular(Header, ['showMenu', 'loggedUser', 'originalUser'])
)
