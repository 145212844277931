'use strict'

import angular from 'angular'
import loadScript from 'load-script-global'
import moment from 'moment-timezone'

import { getFullPhoneNumber } from '../utils/phone-number-formatting'

const app = angular.module('citifydSellerApp')

app.factory('Intercom', (ENV, API, Authentication, $http) => {
  let userHash = null

  function intercomSettings (user) {
    const data = {
      user_id: String(user.id),
      name: user.name,
      email: user.email,
      phone: getFullPhoneNumber(user.phoneNumber, user.phoneCountryCode),
      created_at: moment(user.createdAt).unix(),
      unsubscribed_from_emails: !user.receiveEmailNotifications,
      language_override: user.language,
      is_facebook_user: user.isFacebookUser
    }

    if (user.organization) {
      data.companies = [
        {
          company_id: user.organization.id,
          name: user.organization.name,
          country: user.organization.countryCode,
          has_cashier_report: user.organization.hasCashierReport,
          has_crm_access: user.organization.hasCashierReport,
          has_event_creation_access: user.organization.hasEventCreationAccess
        }
      ]
    }
    return data
  }

  function getUserHash () {
    return $http({
      method: 'GET',
      url: API.base + '/me/hash',
      headers: Authentication.generateHeaders()
    })
  }

  function runOnIntercom (callback) {
    if (window.Intercom) {
      callback(window.Intercom)
    }
  }

  function saveUserData (data) {
    runOnIntercom(Intercom => {
      const user = intercomSettings(data)

      if (user && !userHash) {
        getUserHash().then(response => {
          userHash = response.data.hash
          Intercom('boot', {
            user_id: String(user.user_id),
            user_hash: userHash
          })
        })
      } else {
        update(user)
      }
    })
  }

  function logout () {
    runOnIntercom(Intercom => {
      Intercom('shutdown')
    })
  }

  function update (user = null) {
    runOnIntercom(Intercom => {
      if (user) {
        Intercom('update', user)
      } else {
        Intercom('update')
      }
    })
  }

  function load () {
    loadScript(
      {
        url: `https://widget.intercom.io/widget/${ENV.intercomAppKey}`,
        global: 'Intercom'
      },
      (err, Intercom) => {
        if (Intercom) {
          Intercom('boot', {
            app_id: ENV.intercomAppKey
          })
        }

        if (err) {
          console.warn(err)
        }
      }
    )
  }

  return {
    load,
    logout,
    update,
    saveUserData
  }
})
