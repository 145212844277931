'use strict'

import moment from 'moment-timezone'

const initialValues = ({ mode }) => ({
  mode,
  name: '',
  venueId: '',
  startDate: getInitialStartDate(),
  startTime: getInitialStartTime(),
  previousStartDate: getInitialStartDate(),
  previousStartTime: getInitialStartTime(),
  defaultAvailabilityStartDate: new Date(),
  defaultAvailabilityStartTime: '12:00',
  defaultAvailabilityEndDate: moment(new Date())
    .startOf('day')
    .add(1, 'day')
    .toDate(),
  defaultAvailabilityEndTime: '00:00',
  waitlist: 'true',
  hasImage: false,
  isEditable: true,
  isPastEvent: false
})

const getInitialStartDate = () => new Date()
const getInitialStartTime = () => '19:00'

export default initialValues
