import { getTranslator } from '../../../shared/react/utils'

const getInformationUnderReviewContent = stripeConnectedAccount => {
  const t = getTranslator()

  const title = t('stripeAccountSetupResult.informationUnderReview.title')

  const body = [
    {
      type: 'paragraph',
      content: t(
        'stripeAccountSetupResult.informationUnderReview.yourInformationIsBeingVerified'
      )
    }
  ]

  if (
    !stripeConnectedAccount.chargesEnabled &&
    !stripeConnectedAccount.payoutsEnabled
  ) {
    body.push({
      type: 'paragraph',
      content: t(
        'stripeAccountSetupResult.informationUnderReview.chargesAndPayoutsDisabled'
      )
    })
  } else if (!stripeConnectedAccount.chargesEnabled) {
    body.push({
      type: 'paragraph',
      content: t(
        'stripeAccountSetupResult.informationUnderReview.chargesDisabled'
      )
    })
  } else if (!stripeConnectedAccount.payoutsEnabled) {
    body.push({
      type: 'paragraph',
      content: t(
        'stripeAccountSetupResult.informationUnderReview.payoutsDisabled'
      )
    })
  }

  body.push(
    {
      type: 'title',
      content: t('stripeAccountSetupResult.whyDoWeNeedYourInfo.title')
    },
    {
      type: 'paragraph',
      content: t('stripeAccountSetupResult.whyDoWeNeedYourInfo.paragraph')
    },
    {
      type: 'button',
      content: t('stripeAccountSetupResult.buttons.goToDashboard'),
      state: 'dashboard'
    }
  )

  return { title, body }
}

export default getInformationUnderReviewContent
