import { useEffect, useState } from 'react'
import { useAngularService } from '../../../shared/react/hooks'

const useVenues = ({ trackLoading, untrackLoading }) => {
  const Authentication = useAngularService('Authentication')
  const Venues = useAngularService('Venues')

  const [venues, setVenues] = useState([])

  const loadVenues = async () => {
    const user = Authentication.getLoggedUser()
    const promise = user.isAdmin ? Venues.findAll() : Venues.findMyVenues()
    trackLoading('venues')
    const response = await promise
    setVenues(response.data)
    untrackLoading('venues')
  }

  useEffect(() => {
    loadVenues()
  }, [])

  return { venues }
}

export default useVenues
