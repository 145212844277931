'use strict'

import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone'
import { sum, find, escape } from 'lodash'

import AlertBox from '../../../../shared/react/components/AlertBox'
import LoadingManager from '../../../../shared/react/components/LoadingManager'
import { getAngularService } from '../../../../shared/react/utils'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import RevenueFilter from '../RevenueFilter'
import RevenuePayoutsTable from '../RevenuePayoutsTable'
import { getFilterSelectionFromState } from '../../utils'

const RevenuePayouts = ({ className, lots, currencies }) => {
  const t = useTranslator()
  const Revenue = useAngularService('Revenue')
  const CitifydModal = useAngularService('CitifydModal')
  const $state = useAngularService('$state')
  const $stateParams = useAngularService('$stateParams')
  const [params, setParams] = useState($state.params)
  const Settings = useAngularService('Settings')
  const Authentication = useAngularService('Authentication')
  const loggedUser = Authentication.getLoggedUser()
  const lotId = $stateParams.lot
  const [failedPayoutMessage, setFailedPayoutMessage] = useState(null)
  const currentRequest = useRef(null)
  const [payouts, setPayouts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [totals, setTotals] = useState([])
  const [currentFilter, setCurrentFilter] = useState(null)

  const getQueryOptionsFromFilterSelection = filterSelection => {
    return {
      rangeStart: moment(filterSelection.dateRange.start).format('YYYY-MM-DD'),
      rangeEnd: moment(filterSelection.dateRange.end).format('YYYY-MM-DD'),
      currency: filterSelection.currency,
      lotId: filterSelection.lotId
    }
  }

  const calculateTotals = payouts => {
    const fields = ['amount', 'amountForSelectedLot']

    let totals = {}

    for (const field of fields) {
      totals[field] = sum(payouts, payout => {
        return payout[field]
      })
    }

    setTotals(totals)
  }

  const loadFailedPayoutMessage = async () => {
    const { countryCode } = loggedUser.organization

    try {
      const country = await Settings.getDetailedCountryData(countryCode)

      const { supportPhoneNumber, supportEmail } = country.citifydLocalInfo

      const rawMessage = t('revenue.payoutFailed', {
        phoneNumber: supportPhoneNumber.value,
        email: '[email]'
      })

      const failedPayoutMessage = escape(rawMessage).replace(
        /\[email\]/g,
        `<a href="mailto:${supportEmail.value}">${supportEmail.value}</a>`
      )

      setFailedPayoutMessage(failedPayoutMessage)
    } catch (err) {
      console.log(`${countryCode}: country information could not be lodaded`)
    }
  }

  const load = async () => {
    setIsLoading(true)
    if (currentRequest.current) {
      currentRequest.current.abort()
      currentRequest.current = null
    }

    const filterSelection = getFilterSelectionFromState($state)
    const options = getQueryOptionsFromFilterSelection(filterSelection)

    setCurrentFilter(filterSelection)

    if (currentRequest.current) {
      currentRequest.current.abort()
    }

    try {
      currentRequest.current = Revenue.fetchPayouts(options)
      const response = await currentRequest.current
      const payouts = response.data.payouts

      setPayouts(payouts)
      calculateTotals(payouts)

      const hasFailedPayment = Boolean(
        find(payouts, payout => payout.status === 'failed')
      )

      if (loggedUser.organization && hasFailedPayment) {
        loadFailedPayoutMessage()
      }
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
      currentRequest.current = null
    }
  }

  useEffect(() => {
    const $rootScope = getAngularService('$rootScope')
    const unsubscribe = $rootScope.$watch(
      () => {
        return getAngularService('$state').params
      },
      params => {
        setParams(params)
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (params.dateRange) {
      load()
    }
  }, [params])

  function generateCsvReportDescription (filterSelection) {
    const options = {
      startDate: moment(filterSelection.dateRange.start),
      endDate: moment(filterSelection.dateRange.end)
    }

    if (filterSelection.lotId) {
      const lot = find(lots, lot => lot.id === filterSelection.lotId)
      return t('revenue.csvReportName.payouts.lot', {
        ...options,
        lotName: lot.name
      })
    } else {
      return t('revenue.csvReportName.payouts.currency', {
        ...options,
        currency: filterSelection.currency.toUpperCase()
      })
    }
  }

  const generateCsvClicked = () => {
    const filterSelection = getFilterSelectionFromState($state)
    const options = getQueryOptionsFromFilterSelection(filterSelection)

    CitifydModal.open('generate-report', {
      fileFormat: 'csv',
      filterDescription: generateCsvReportDescription(filterSelection),
      reportUrl: Revenue.fetchPayoutsCsvUrl(options)
    })
  }

  return (
    <div className={className}>
      <RevenueFilter
        onClickGenerateCsv={generateCsvClicked}
        enableLotSelection
        lots={lots}
        currencies={currencies}
      />
      {failedPayoutMessage && (
        <AlertBox>
          <p dangerouslySetInnerHTML={{ __html: failedPayoutMessage }}></p>
        </AlertBox>
      )}
      {isLoading ? (
        <LoadingManager isLoading={isLoading} />
      ) : (
        <RevenuePayoutsTable
          payouts={payouts}
          totals={totals}
          currentFilter={currentFilter}
          loggedUser={loggedUser}
          lotId={lotId}
        />
      )}
    </div>
  )
}

export default RevenuePayouts
