'use strict'

import { useMemo, useState } from 'react'

const useLoadTracking = (config = {}) => {
  const [tracking, setTracking] = useState([])

  const trackLoading = key => {
    setTracking(tracking => [...tracking, key])
  }

  const untrackLoading = key => {
    setTracking(tracking => tracking.filter(trackingKey => trackingKey !== key))
  }

  return useMemo(() => {
    const isLoading = tracking.length > 0
    const loadingMessage = (isLoading && config[tracking[0]]?.message) || null
    return { isLoading, loadingMessage, trackLoading, untrackLoading }
  }, [tracking])
}

export default useLoadTracking
