'use strict'

import angular from 'angular'
import _ from 'lodash'

import templateUrl from './add-property.html'
import './add-property.scss'

import firstStepTemplateUrl from './views/step-1.html'
import secondStepTemplateUrl from './views/step-2.html'
import thirdStepTemplateUrl from './views/step-3.html'

const app = angular.module('citifydSellerApp')

app.controller('AddPropertyCtrl', function (
  Settings,
  Helper,
  Properties,
  $i18next,
  $location,
  $log,
  $rootScope,
  $stateParams,
  $state,
  $scope,
  Authentication,
  Permissions
) {
  var user = Authentication.getLoggedUser()

  $scope.canConfigureGates = Permissions.userMayConfigureGates()
  $scope.amountOfSteps = $scope.canConfigureGates ? 3 : 2

  // Goes to the step passed as argument
  $scope.goToStep = function (step) {
    $scope.isLoading = false
    $state.transitionTo('edit-property-step', {
      lotId: $stateParams.lotId,
      step: step
    })
  }

  // Submits the current form
  $scope.submitForm = function () {
    $rootScope.$broadcast('add-property-step-' + $scope.step + '-submitted')
  }

  function getDefaultCountryCodeForNewLot (user) {
    let countryCode = null

    if (user.organization) {
      countryCode = user.organization.countryCode
    } else {
      countryCode = user.phoneCountryCode
    }

    return Settings.getCountries().then(countries => {
      const countryList = Helper.planifyCountries(countries)
      const country = _.find(countryList, c => c.isoCode === countryCode)

      if (!country) {
        countryCode = null
      } else if (!country.lotAllowed) {
        // If lot creation is not allowed for this country, look for another country in the same continent
        const sameContinentCountry = _.find(
          countryList,
          c => c.lotAllowed && c.continent === country.continent
        )
        countryCode = sameContinentCountry ? sameContinentCountry.isoCode : null
      }

      return countryCode || 'us' // default to US if no country code is found
    })
  }

  // Load lot, returning a promise that is resolved when loading is finished
  function loadLot () {
    $scope.isLoading = true

    if ($scope.mode === 'add') {
      return getDefaultCountryCodeForNewLot($scope.user).then(countryCode => {
        $scope.isLoading = false

        $scope.lot = {
          name: '',
          countryCode: countryCode,
          address: null,
          notes: '',
          maxSpots: '',
          notifyAboutEvents: false,
          reservedParkingEnabled: false,
          setupIsComplete: false,
          addressCanBeChanged: true,
          extraInfo: {
            gates: { entryOnly: '', exitOnly: '', mixedUse: '' },
            scan: { entering: false, exiting: false, no: true }
          }
        }
      })
    } else {
      return Properties.find($stateParams.lotId).then(
        function (response) {
          $scope.isLoading = false
          $scope.lot = response.data.lot

          if (user.role === 'localManager') {
            $scope.lot.addressCanBeChanged = false
          }

          Helper.setPageTitle(
            $i18next.t('addProperty.editPropertyPageTitleName', {
              name: $scope.lot.name
            })
          )
        },

        function () {
          $log.info('Lot not found')
          $location.path('/')
        }
      )
    }
  }

  function init () {
    $scope.forms = {}
    $scope.mode = $stateParams.lotId ? 'edit' : 'add'
    $scope.step = $scope.mode === 'add' ? 1 : $stateParams.step || 1

    $scope.firstStepTemplateUrl = firstStepTemplateUrl
    $scope.secondStepTemplateUrl = secondStepTemplateUrl
    $scope.thirdStepTemplateUrl = thirdStepTemplateUrl

    $scope.shouldShowCountryDropdown = !_.get($scope.user, 'organization')

    loadLot().then(function () {
      // Saves how the lot looked like when the page loaded for further comparison
      $scope.originalLot = angular.copy($scope.lot)
    })
  }

  init()
})

export const controller = 'AddPropertyCtrl'
export const auth = true
export { templateUrl }
