'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Snapshot', function SnapshotFactory (API, Authentication, $http) {
  function getProperties (options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/snapshot/lots',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function getParkers (options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/snapshot/parkers',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function getSchedule (options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/snapshot/schedule',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  return {
    getProperties: getProperties,
    getParkers: getParkers,
    getSchedule: getSchedule
  }
})
