'use strict'

import React, { useEffect, useState } from 'react'
import { Text } from '@citifyd/style'
import { useAngularService } from '../../shared/react/hooks'
import getSuccessContent from './functions/getSuccessContent'
import getPendingRequiredInformationContent from './functions/getPendingRequiredInformationContent'
import getInformationUnderReviewContent from './functions/getInformationUnderReviewContent'
import styles from './StripeAccountSetupResults.module.scss'
import RouterLinkButton from '../../shared/react/components/RouterLinkButton'
import LoadingManager from '../../shared/react/components/LoadingManager'

const StripeAccountSetupResults = () => {
  const state = useAngularService('$state')
  const stateParams = useAngularService('$stateParams')
  const StripeConnectedAccounts = useAngularService('StripeConnectedAccounts')
  const [contentResult, setContentResult] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    init()
  }, [])

  const updateStripeRequirements = async () => {
    const response = await StripeConnectedAccounts.getStripeConnectedAccounts()
    const stripeConnectedAccount = response.data.stripeConnectedAccounts[0]

    if (stripeConnectedAccount) {
      const responseUpdate = await StripeConnectedAccounts.updateStripeConnectedAccountRequirements(
        stripeConnectedAccount.stripeAccountId
      )
      setResult(responseUpdate.data.stripeConnectedAccount)
      setIsLoading(false)
      await StripeConnectedAccounts.loadStripeConnectedAccountsStatusToLoggedUser()
    }
  }

  const setResult = stripeConnectedAccount => {
    let content

    if (
      stripeConnectedAccount.chargesEnabled &&
      stripeConnectedAccount.payoutsEnabled
    ) {
      content = getSuccessContent(stripeConnectedAccount)
    } else if (stripeConnectedAccount.hasDueRequirements) {
      content = getPendingRequiredInformationContent(stripeConnectedAccount)
    } else {
      content = getInformationUnderReviewContent(stripeConnectedAccount)
    }
    const result = { stripeConnectedAccount, content }
    setContentResult(result)
  }

  const redirectToAccountSetup = () => {
    state.go('stripe-account-setup', {}, { location: 'replace' })
  }

  const init = () => {
    if (stateParams.status === 'failure') {
      redirectToAccountSetup()
    } else {
      try {
        updateStripeRequirements()
      } catch (err) {
        setHasError(true)
        console.log('Error updating Stripe requirements', err)
      }
    }
  }

  const content = contentResult?.content

  const tryAgain = () => {
    setIsLoading(true)
    setHasError(false)
    init()
  }

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        onClickTryAgain={tryAgain}
      />
    )
  }
  return (
    <div>
      <Text className={styles.text} variant='h1' weight='300'>
        {content?.title}
      </Text>
      {content?.body.map((item, i) => (
        <ContentItem key={i} {...item} />
      ))}
    </div>
  )
}

const ContentItem = ({ type, ...props }) => {
  if (type === 'title') {
    return (
      <Text variant='h5' weight='600'>
        {props.content}
      </Text>
    )
  }
  if (type === 'paragraph') {
    return (
      <Text className={styles.text} variant='h5' weight='400'>
        {props.content}
      </Text>
    )
  }

  if (type === 'button') {
    return (
      <RouterLinkButton state={props.state} uppercase>
        {props.content}
      </RouterLinkButton>
    )
  }

  return null
}

export default StripeAccountSetupResults
