'use strict'

import React from 'react'
import { Badge } from '@citifyd/style'
import { camelCase, kebabCase } from 'lodash'

import { useTranslator } from '../../../../shared/react/hooks'

const PermitBadge = ({ children, status }) => {
  const label = camelCase(children)
  const t = useTranslator()

  const getBadgeAppearance = () => {
    const kebabCaseStatus = kebabCase(status)
    let appearance

    switch (kebabCaseStatus) {
      case 'ongoing':
        appearance = { appearance: 'success', textAppearance: 'white' }
        break

      case 'future':
        appearance = { appearance: 'completed', textAppearance: 'white' }
        break

      case 'past':
        appearance = { appearance: 'tertiary', textAppearance: 'white' }
        break

      case 'removed':
        appearance = { appearance: 'gray_darker', textAppearance: 'white' }
        break

      default:
        appearance = { appearance: 'gray' }
        break
    }

    return appearance
  }

  return (
    <Badge {...getBadgeAppearance()}>
      {label === 'ongoing' ? t('permits.permitsTable.active') : label}
    </Badge>
  )
}

export default PermitBadge
