'use strict'

import angular from 'angular'
import _ from 'lodash'

import templateUrl from './user-purchase-ticket.html'
import './user-purchase-ticket.scss'

import eventSelectionTemplateUrl from './views/event-selection.html'
import lotSelectionTemplateUrl from './views/lot-selection.html'
import paymentMethodTemplateUrl from './views/payment-method.html'
import purchaseReportTemplateUrl from './views/purchase-report.html'

const app = angular.module('citifydSellerApp')

app.controller('UserPurchaseTicketCtrl', function (
  Users,
  $stateParams,
  $scope
) {
  $scope.step = 1

  $scope.changeStep = function (stepNumber, info) {
    $scope.step = stepNumber
    $scope.$broadcast('init-step-' + stepNumber, _.cloneDeep(info || {}))
  }

  $scope.returnStep = function () {
    $scope.step--
  }

  $scope.eventSelectionTemplateUrl = eventSelectionTemplateUrl
  $scope.lotSelectionTemplateUrl = lotSelectionTemplateUrl
  $scope.paymentMethodTemplateUrl = paymentMethodTemplateUrl
  $scope.purchaseReportTemplateUrl = purchaseReportTemplateUrl
})

export const controller = 'UserPurchaseTicketCtrl'
export const auth = true
export const title = 'i18n:userPurchaseTicket.pageTitle'
export { templateUrl }
