'use strict'

import React from 'react'
import { useTranslator } from '../../hooks'
import { camelCase } from 'lodash'
import { Badge } from '@citifyd/style'

import styles from './SubscriptionStatusBadge.module.scss'

export default function SubscriptionStatusBadge ({ status }) {
  const t = useTranslator()

  const label = t('subscriptions.statuses.' + camelCase(status))

  const getAppearance = () => {
    switch (status) {
      case 'active':
        return 'completed'
      case 'cancelled':
        return 'tertiary'
      default:
        return 'primary'
    }
  }

  return (
    <Badge
      className={styles.badge}
      textAppearance={status ? 'white' : 'default'}
      appearance={getAppearance()}
    >
      {label}
    </Badge>
  )
}

export function SubscriptionStatusBadges ({ statuses }) {
  return statuses.map(status => (
    <SubscriptionStatusBadge key={status} status={status} />
  ))
}
