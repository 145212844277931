import { find, flatten, sortBy } from 'lodash'

import { getAngularService } from '../../../../shared/react/utils'

export const mountTicket = ticket => {
  const Helper = getAngularService('Helper')

  const getPurchaseTransaction = () => {
    if (ticket.bundlePurchase) {
      return find(ticket.bundlePurchase.transactions, { type: 'purchase' })
    } else {
      return find(ticket.transactions, { type: 'purchase' })
    }
  }

  const getVoucherHistoryItems = () => {
    return ticket.vouchers.map(voucher => {
      return {
        type: 'voucher',
        date: voucher.createdAt,
        voucher: voucher
      }
    })
  }

  const getExchangeHistoryItems = () => {
    return ticket.ticketExchanges.map(exchange => {
      return {
        type: 'exchange',
        date: exchange.createdAt,
        exchange: exchange
      }
    })
  }

  const groupDeletedVouchers = items => {
    return Helper.groupCloseItemsBy(
      items,
      item => item.type === 'voucher' && item.voucher.deletedAt,
      groupItems => ({
        type: 'deletedVoucherGroup',
        date: groupItems[0].date,
        vouchers: groupItems.map(item => item.voucher)
      })
    )
  }

  const mountTicketHistory = () => {
    let items = flatten([getVoucherHistoryItems(), getExchangeHistoryItems()])

    // sort by date
    items = sortBy(items, 'date')

    // group deleted vouchers
    items = groupDeletedVouchers(items)

    return items
  }

  const currentTicket = {
    ...ticket,
    user: ticket.user || ticket.purchaser,
    purchaseTransaction: getPurchaseTransaction(),
    history: mountTicketHistory()
  }

  return currentTicket
}
