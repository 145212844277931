'use strict'

import angular from 'angular'

import UserLink from '../../react/components/UserLink'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctUserLink',
  react2angular(UserLink, [
    'user',
    'routerOptions',
    'disabled',
    'underline',
    'appearance'
  ])
)
