'use strict'

import produce from 'immer'
import _ from 'lodash'

function invertOrderOf (propertyOrder) {
  propertyOrder[1] = propertyOrder[1] === 'asc' ? 'desc' : 'asc'
}

export function generateOrderForClickEvent ({
  currentOrder = [],
  propertyName,
  event
}) {
  return produce(currentOrder, currentOrder => {
    const existingPropertyOrder = _.find(
      currentOrder,
      item => item[0] === propertyName
    )

    if (event.shiftKey) {
      if (existingPropertyOrder) {
        invertOrderOf(existingPropertyOrder)
      } else {
        currentOrder.push([propertyName, 'asc'])
      }
    } else {
      let propertyOrder = existingPropertyOrder
      if (propertyOrder) {
        invertOrderOf(propertyOrder)
      } else {
        propertyOrder = [propertyName, 'asc']
      }

      currentOrder.splice(0, currentOrder.length)
      currentOrder.push(propertyOrder)
    }
  })
}

export function sort ({ items, order, sortFunctions = {} }) {
  const properties = order.map(propertyOrder => {
    const propertyName = propertyOrder[0]

    if (sortFunctions[propertyName]) {
      return obj => sortFunctions[propertyName](_.get(obj, propertyName))
    } else {
      return propertyName
    }
  })

  const orders = order.map(propertyOrder => propertyOrder[1])
  return _.sortByOrder(items, properties, orders)
}
