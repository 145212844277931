'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Subscriptions', (API, Authentication, $http) => {
  async function find (id) {
    const promise = $http({
      method: 'GET',
      url: `${API.base}/admin/subscriptions/${id}`,
      headers: Authentication.generateHeaders()
    })

    return promise.then(response => response.data.subscription)
  }

  return {
    find
  }
})
