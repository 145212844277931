'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Availabilities', (API, Authentication, $http) => {
  function getSchedule (options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/schedule',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  return {
    getSchedule: getSchedule
  }
})
