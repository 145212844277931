'use strict'

import React from 'react'
import { NewGrid, Select } from '@citifyd/style'
import { find } from 'lodash'

import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'
import { getAngularService } from '../../../../shared/react/utils'
import PDFDownload from '../../../../shared/react/components/PDFDownload'
import CSVDownload from '../../../../shared/react/components/CSVDownload'

import styles from './RevenueOfEventSubHeader.module.scss'

const RevenueOfEventSubHeader = ({
  event,
  lotId,
  lots = [],
  lotSelected,
  setLotSelected,
  canExportToPdf,
  currency
}) => {
  const t = useTranslator()
  const $state = useAngularService('$state')
  const CitifydModal = useAngularService('CitifydModal')
  const Authentication = useAngularService('Authentication')
  const Helper = useAngularService('Helper')
  const Revenue = useAngularService('Revenue')

  const generatePdfClicked = () => {
    const accessToken = Authentication.getAccessToken()
    const options = {
      lotId,
      currency
    }

    const url = Revenue.fetchForEventPdfUrl(event.id, options)
    Helper.sendHiddenForm(
      url,
      { access_token: accessToken },
      '_report_' + event.id
    )
  }

  const generateCsvReportDescription = () => {
    if (lotId) {
      const lot = find(lots, lot => lot.id === lotId)
      return t('revenue.csvReportName.event.lot', {
        eventName: event.name,
        lotName: lot.name
      })
    } else {
      return t('revenue.csvReportName.event.currency', {
        eventName: event.name,
        currency
      })
    }
  }

  const generateCsvClicked = () => {
    const options = {
      lotId,
      currency
    }

    CitifydModal.open('generate-report', {
      fileFormat: 'csv',
      filterDescription: generateCsvReportDescription(),
      reportUrl: Revenue.fetchForEventCsvUrl(event.id, options)
    })
  }

  const options = [
    {
      label: t('revenue.revenueFilter.fields.allProperties'),
      value: ''
    },
    ...lots.map(lot => ({ label: lot.name, value: lot.id }))
  ]

  const fieldChanged = e => {
    const $stateParams = getAngularService('$stateParams')

    const lotId = e.target.value

    const params = {
      event: $stateParams.event,
      dateRange: $stateParams.dateRange,
      currentLot: $stateParams.currentLot || undefined
    }

    if (lotId) {
      const lot = lots.filter(lot => lot.id === lotId)[0]

      params.lot = lotId
      params.currency = lot.country.currency
    } else {
      params.currency = currency
    }

    setLotSelected(lotId)

    $state.transitionTo($state.current.name, params, {
      location: 'replace',
      notify: false,
      inherit: false
    })
  }

  return (
    <NewGrid.Row>
      <NewGrid.Col lg={4} md={6} xs={12} className={styles.select}>
        <Select
          fullWidth
          name='lotId'
          label={t('revenue.revenueFilter.fields.property')}
          options={options}
          value={lotSelected || ''}
          onChange={e => fieldChanged(e)}
        />
      </NewGrid.Col>
      <NewGrid.Col className={styles.exportButtons}>
        {canExportToPdf && <PDFDownload onClick={generatePdfClicked} />}
        <CSVDownload onClick={generateCsvClicked} />
      </NewGrid.Col>
    </NewGrid.Row>
  )
}

export default RevenueOfEventSubHeader
