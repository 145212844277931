'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

import templateUrl from './edit-event-properties.html'
import './edit-event-properties.scss'

const app = angular.module('citifydSellerApp')

app.controller('EditEventPropertiesCtrl', function (
  Authentication,
  Currencies,
  Helper,
  Permissions,
  Events,
  Settings,
  $log,
  $scope,
  $state,
  $stateParams,
  $location,
  $i18next,
  $window
) {
  const EMPTY_RATE = { type: 'fixed', value: null }

  var eventId = $stateParams.eventId
  var maxDistance = $location.search().maxDistance || '1'
  var user = Authentication.getLoggedUser()

  $scope.canConfigureGates = Permissions.userMayConfigureGates()
  $scope.canEditPosRates = Permissions.userMayManageLotAvailabilitiesPosRates()

  $scope.isSameDate = function (d1, d2) {
    return Helper.isSameDate(d1, d2, $scope.event.timezoneName)
  }

  $scope.toggleLot = function (lot) {
    lot.availability.selected = !lot.availability.selected
  }

  $scope.hasCompleteInfo = function (lot) {
    var hasSpaces = !isNaN(parseInt(lot.availability.spaces.regular, 10))
    var hasPrice = !isNaN(parseInt(lot.availability.rate.value, 10))

    return hasSpaces && hasPrice
  }

  $scope.hasLotsSelected = function () {
    return (
      $scope.event &&
      _.some($scope.event.nearbyLots, function (lot) {
        return lot.availability.selected
      })
    )
  }

  $scope.addPosRate = function (lotId) {
    if ($scope.canAddPosRate(lotId)) {
      const id = $scope.posRates[lotId]?.length
        ? $scope.posRates[lotId][$scope.posRates[lotId].length - 1].id + 1
        : 0

      $scope.posRates[lotId] = [
        ...($scope.posRates[lotId] ? $scope.posRates[lotId] : []),
        {
          id: id,
          name: '',
          label: '',
          allowReentry: false,
          rate: { type: 'fixed', value: '', fee: '' }
        }
      ]
    }
  }

  $scope.isEmpty = function (rate) {
    const fields = ['label']
    return _.every(fields, field => rate[field].trim() === '')
  }

  $scope.canAddPosRate = function (lotId) {
    const last = _.last($scope.posRates[lotId])
    return !last || !$scope.isEmpty(last)
  }

  $scope.removePosRate = function (lotId, posRateId) {
    $scope.posRates[lotId] = $scope.posRates[lotId].filter(
      item => item.id !== posRateId
    )
  }

  $scope.shouldShowSaveButton = function () {
    return (
      !$scope.isLoading &&
      ($scope.hasLotsSelected() ||
        ($scope.event && $scope.event.lots.length > 0))
    )
  }

  $scope.shouldShowDisableServiceFeeCheckbox = function () {
    return Permissions.userMayDisableLotAvailabilityServiceFee()
  }

  $scope.shouldShowBarcodeActivationCheckbox = function () {
    return Permissions.userMayUpdateLotAvailabilityBarcodeActivation()
  }

  $scope.save = function () {
    var added = getAddedLotAvailabilities($scope.event)
    var removed = getRemovedLotAvailabilities($scope.event)

    if (!validateLotAvailabilities($scope.event, added)) {
      $log.info('Validation error')
      return
    }

    saveProperties(added, removed)
      .then(function () {
        return saveEventGates(removed)
      })
      .then(function () {
        $scope.isLoading = true
        $scope.loadingMessage = $i18next.t(
          'eventProperties.loadingMessages.redirecting'
        )

        if (Permissions.userMayEditEvent()) {
          $state.transitionTo('events')
        } else {
          $state.transitionTo('appendable-events', {
            maxDistance: maxDistance
          })
        }
      })
      .catch(function (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)
      })
  }

  function saveProperties (added, removed) {
    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'eventProperties.loadingMessages.makingPropertiesAvailable'
    )

    return Events.appendToEvent(eventId, {
      add: added,
      remove: removed
    }).then(function () {
      $scope.isLoading = false
    })
  }

  function saveEventGates (removedLotAvailabilities) {
    var editEventGates = angular.copy($scope.editEventGates)

    _.forEach(removedLotAvailabilities, function (lot) {
      var eventGates = _.filter(editEventGates, { lotId: lot.lotId })

      _.forEach(eventGates, function (eventGate) {
        eventGate.selected = false
      })
    })

    var eventGates = transformToEventGatesArray(editEventGates)

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'eventProperties.loadingMessages.settingUpGates'
    )

    return Events.updateEventGates(eventId, eventGates).then(function () {
      $scope.isLoading = false
    })
  }

  function validateLotAvailabilities (event, lotAvailabilities) {
    var error = ''

    lotAvailabilities.forEach(function (lotAvailability) {
      var name = _.findWhere(event.nearbyLots, { id: lotAvailability.lotId })
        .name

      if (
        lotAvailability.publicSale &&
        (Helper.isNil(lotAvailability.rate.value) ||
          lotAvailability.rate.value === 0)
      ) {
        error = $i18next.t('eventProperties.errors.rateRequired', {
          lotName: name
        })
        return false
      }

      if (
        isNaN(lotAvailability.spaces[0].max) ||
        lotAvailability.spaces[0].max < 0
      ) {
        error = $i18next.t('eventProperties.errors.spacesRequired', {
          lotName: name
        })
        return false
      }

      if (
        moment(lotAvailability.startsAt).isAfter(
          event.lotsLatestOpeningTimeAllowed
        )
      ) {
        error = $i18next.t('eventProperties.errors.invalidOpeningTime', {
          lotName: name,
          dateTime: moment.tz(
            event.lotsLatestOpeningTimeAllowed,
            event.timezoneName
          )
        })
        return false
      }

      if (
        moment(lotAvailability.endsAt).isBefore(
          event.lotsEarliestClosingTimeAllowed
        )
      ) {
        error = $i18next.t('eventProperties.errors.invalidClosingTime', {
          lotName: name,
          dateTime: moment.tz(
            event.lotsEarliestClosingTimeAllowed,
            event.timezoneName
          )
        })
        return false
      }
    })

    if (error) {
      $window.alert(error)
      return false
    }

    return true
  }

  function getAddedLotAvailabilities (event) {
    var added = event.nearbyLots.filter(function (lot) {
      return lot.availability.selected
    })

    return added.map(function (lot) {
      let rate

      if (lot.availability.publicSale) {
        rate = Currencies.transformRateToInteger(
          lot.availability.rate,
          lot.country.currency
        )
      } else {
        rate = { type: 'fixed', value: 0 }
      }

      return {
        lotId: lot.id,
        availabilityId: lot.availability.id,
        allowReentry: lot.availability.allowReentry,
        gpsVerification: lot.availability.gpsVerification,
        barcodeActivation: lot.availability.barcodeActivation,
        serviceFeeDisabled: lot.availability.serviceFeeDisabled,
        publicSale: lot.availability.publicSale,
        rate: rate,
        posRates: formatPosRatesToSubmit(lot),
        spaces: Helper.spacesObjectToArray(lot.availability.spaces),
        startsAt: Helper.dateAndTimeToMomentObject(
          lot.availability.startDate,
          lot.availability.startTime,
          event.timezoneName
        )
          .locale('en')
          .format(),
        endsAt: Helper.dateAndTimeToMomentObject(
          lot.availability.endDate,
          lot.availability.endTime,
          event.timezoneName
        )
          .locale('en')
          .format()
      }
    })
  }

  function getRemovedLotAvailabilities (event) {
    // event.lots contains the current available lots from this owner.
    // We check equivalent lot availabilities on event.nearbyLots[i] where
    // availability.selected = false to check which ones have been removed by the user.
    var removed = event.lots.filter(function (lot) {
      var selected = _.find(event.nearbyLots, function (nearbyLot) {
        return (
          nearbyLot.id === lot.id &&
          nearbyLot.availability.selected &&
          nearbyLot.availability.id === lot.availability.id
        )
      })

      return !selected
    })

    return removed.map(function (lot) {
      return { lotId: lot.id, availabilityId: lot.availability.id }
    })
  }

  function populateCurrencySettings (lots) {
    lots.forEach(lot => {
      lot.currencySettings = Currencies.getCurrencySettings(
        lot.country.currency
      )
    })
  }

  function adjustLotDistancesBasedOnMeasurementSystem (
    lots,
    measurementSystem
  ) {
    // by default the back-end returns the distances as miles, so let's convert to km
    // if the measurement system selected is kilometers
    if (measurementSystem === 'metric') {
      lots.forEach(lot => {
        lot.distance = Helper.milesToKilometers(lot.distance)
      })
    }
  }

  function loadData () {
    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'eventProperties.loadingMessages.loadingProperties'
    )
    $scope.measurementSystem = Settings.getMeasurementSystemFromCountryCode(
      user.phoneCountryCode
    )

    Events.find(eventId, { maxDistance: maxDistance }).then(function (
      response
    ) {
      $scope.isLoading = false
      $scope.event = response.data.event

      setLotAvailabilities($scope.event)
      populateEditEventGatesObject($scope.editEventGates)
      populateEditPosRatesObject()
      populateCurrencySettings($scope.event.nearbyLots)
      adjustLotDistancesBasedOnMeasurementSystem(
        $scope.event.nearbyLots,
        $scope.measurementSystem
      )

      // If there's only one property nearby, let's select it by default.
      if ($scope.event.nearbyLots.length === 1) {
        $scope.event.nearbyLots[0].availability.selected = true
      }

      // Remove system event gates from list so that they won't appear on UI
      $scope.event.nearbyLots.forEach(function (lot) {
        lot.gates = _.filter(lot.gates, function (gate) {
          return !gate.isSystemGate
        })
      })
    })
  }

  function setLotAvailabilities (event) {
    var getLocalDate = function (date, timezoneName) {
      var ymd = moment
        .tz(date, timezoneName)
        .locale('en')
        .format('YYYY-MM-DD')
      return moment(ymd).toDate()
    }

    event.nearbyLots = event.nearbyLots
      .map(function (lot) {
        var availableLot = _.findWhere(event.lots, { id: lot.id })
        var availability = { id: _.get(availableLot, 'availability.id', null) }

        if (availableLot) {
          availability.selected = true
          availability.serviceFeeDisabled = availableLot.serviceFeeDisabled
          availability.rateEditingDisabled = availableLot.rateEditingDisabled
          availability.allowReentry = availableLot.allowReentry
          availability.gpsVerification = availableLot.gpsVerification
          availability.publicSale = availableLot.publicSale
          availability.barcodeActivation = availableLot.barcodeActivation
          availability.rate = availability.publicSale
            ? Currencies.transformRateToDecimal(
                availableLot.rate,
                lot.country.currency
              )
            : EMPTY_RATE
          availability.spaces = Helper.spacesArrayToObject(availableLot.spaces)
          availability.startDate = getLocalDate(
            availableLot.availability.start,
            lot.timezoneName
          )
          availability.startTime = moment
            .tz(availableLot.availability.start, lot.timezoneName)
            .locale('en')
            .format('HH:mm')
          availability.endDate = getLocalDate(
            availableLot.availability.end,
            lot.timezoneName
          )
          availability.endTime = moment
            .tz(availableLot.availability.end, lot.timezoneName)
            .locale('en')
            .format('HH:mm')
        } else {
          availability.selected = false
          availability.serviceFeeDisabled =
            lot.organization.serviceFeeDisabledDefaultValue
          availability.barcodeActivation = _.get(
            event,
            'extra.barcodeActivation',
            false
          )
          availability.rateEditingDisabled = false
          availability.allowReentry = true
          availability.gpsVerification =
            lot.organization.gpsVerificationDefaultValue
          availability.publicSale = true
          availability.rate = _.cloneDeep(EMPTY_RATE)
          availability.spaces = { regular: lot.maxSpots }

          var start =
            $location.search().start ||
            event.availabilityDefaultStartTime ||
            event.lotsLatestOpeningTimeAllowed
          var end =
            $location.search().end ||
            event.availabilityDefaultEndTime ||
            event.lotsEarliestClosingTimeAllowed

          availability.startDate = getDateObject(
            moment.tz(start, lot.timezoneName).startOf('day')
          )
          availability.startTime = moment
            .tz(start, lot.timezoneName)
            .locale('en')
            .format('HH:mm')
          availability.endDate = getDateObject(
            moment.tz(end, lot.timezoneName).startOf('day')
          )
          availability.endTime = moment
            .tz(end, lot.timezoneName)
            .locale('en')
            .format('HH:mm')
        }

        lot.availability = availability

        return lot
      })
      .sort((a, b) => a.distance - b.distance)
  }

  function getDateObject (momentObject) {
    return new Date(
      momentObject.year(),
      momentObject.month(),
      momentObject.date()
    )
  }

  function populateEditEventGatesObject (editEventGates) {
    _.forEach($scope.event.nearbyLots, function (lot) {
      _.forEach(lot.gates, function (gate) {
        // System gates (e.g. GPS Entry) cannot be edited by users
        if (gate.isSystemGate) {
          return
        }

        const eventGate = _.findWhere($scope.event.eventGates, {
          gateId: gate.id
        })
        const isLotSelected = lot.availability.selected

        // The event gate is selected upon loading (check-box marked) if:
        // - There's an existing event gate (lot is already appended to event and there's an event gate set up)
        // - There's no existing event gate but the lot isn't selected for event. In this case we pre-select the event
        // gate so that, if this lot is made available for the event, the event gates will be all pre-selected.
        // They will be pre-selected even if the user doesn't have permission to edit the event gates (canConfigureGates is false).
        const eventGateSelected = Boolean(eventGate) || !isLotSelected

        editEventGates[gate.id] = {
          id: _.get(eventGate, 'id'),
          lotId: lot.id,
          gateId: gate.id,
          scannerCode: _.get(eventGate, 'scannerCode', gate.scannerCode),
          beaconUids: _.get(eventGate, 'beaconUids', gate.beaconUids) || [],
          type: _.get(eventGate, 'type', gate.type),
          armedGate: _.get(eventGate, 'armedGate', gate.armedGate),
          reportsStatus: _.get(eventGate, 'reportsStatus', gate.reportsStatus),
          hasBarrier: _.get(eventGate, 'hasBarrier', gate.hasBarrier),
          automaticActivation: _.get(
            eventGate,
            'automaticActivation',
            gate.automaticActivation
          ),
          hasAttendant: _.get(eventGate, 'hasAttendant', gate.hasAttendant),
          selected: eventGateSelected
        }

        buildSelectizeOptionsForGate(gate, eventGate)
      })
    })
  }

  function formatPosRatesToSubmit (lot) {
    if (!$scope.posRates[lot.id]) return null

    return $scope.posRates[lot.id]
      .map(rate => ({
        label: rate.label,
        name: rate.name || rate.label.replace(/\s+/g, '-').toLowerCase(),
        allowReentry: rate.allowReentry,
        rate: Currencies.transformRateToInteger(
          {
            type: rate.rate.type,
            value: rate.rate.value,
            fee: rate.rate.fee
          },
          lot.country.currency
        )
      }))
      .filter(item => item.name)
  }

  function populateEditPosRatesObject () {
    $scope.event.lots.map(lot => {
      if (!lot.posRates) return

      $scope.posRates[lot.id] = lot.posRates.map((rate, index) => ({
        ...rate,
        id: index,
        rate: Currencies.transformRateToDecimal(
          {
            type: rate.rate.type,
            value: rate.rate.value,
            fee: rate.rate.fee
          },
          lot.country.currency
        )
      }))
    })
  }

  function buildSelectizeOptionsForGate (gate, eventGate) {
    gate.selectizeOptions = []

    gate.beaconUids.forEach(function (uid) {
      gate.selectizeOptions.push({ text: uid, value: uid })
    })

    // We need to add the UIDs associated with the event gate as options
    // otherwise Selectize won't show the values when the page loads
    if (eventGate) {
      eventGate.beaconUids.forEach(function (uid) {
        gate.selectizeOptions.push({ text: uid, value: uid })
      })
    }
  }

  function transformToEventGatesArray (editEventGates) {
    return _(editEventGates)
      .filter(function (obj) {
        // Send to server only existent event gates (in case we want to edit or delete them)
        // OR new event gates
        return obj.id || obj.selected
      })
      .map(function (eventGate) {
        var obj = {
          gateId: eventGate.gateId,
          scannerCode: _.isEmpty(eventGate.scannerCode)
            ? null
            : eventGate.scannerCode,
          type: eventGate.type,
          armedGate: eventGate.armedGate,
          reportsStatus: eventGate.reportsStatus,
          hasBarrier: eventGate.hasBarrier,
          automaticActivation: eventGate.automaticActivation,
          hasAttendant: eventGate.hasAttendant
        }

        obj.beaconUids = (eventGate.beaconUids || [])
          .map(function (gate) {
            return gate.trim()
          })
          .filter(function (gate) {
            return gate !== ''
          })

        if (eventGate.id) {
          obj.id = eventGate.id

          if (!eventGate.selected) {
            obj.destroy = true
          }
        }

        return obj
      })
      .value()
  }

  function setUserPermissionFlags () {
    $scope.userMaySetHasAttendantFlag = Permissions.userMaySetHasAttendantFlag()
    $scope.userMaySetRequiresBluetoothFlag = Permissions.userMaySetRequiresBluetoothFlag()
  }

  function setDefaultBackUrl () {
    if (Permissions.userMayEditEvent()) {
      $scope.defaultBackUrl = {
        state: 'edit-event',
        params: { eventId: eventId }
      }
    } else {
      $scope.defaultBackUrl = { state: 'append-to-event' }
    }
  }

  function initialize () {
    $scope.isLoading = false
    $scope.event = null
    $scope.editEventGates = {}
    $scope.posRates = {}
    $scope.lotAvailabilities = []
    $scope.times = Helper.generateTimesArray()
    $scope.selectizeConfig = { delimiter: ';', create: true }

    setUserPermissionFlags()
    setDefaultBackUrl()
    loadData()
  }

  initialize()
})

export const controller = 'EditEventPropertiesCtrl'
export const auth = true
export const title = 'i18n:eventProperties.pageTitle'
export { templateUrl }
