'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.directive('ctAutoFocus', function ($timeout) {
  // How to use:
  // - use <input ct-auto-focus> to focus on input when it's added to the page
  // - use <input ct-auto-focus="{{ booleanExpression }}"> if you want to focus conditionally when input
  // is added to page

  return {
    restrict: 'AC',

    link: function (scope, element, attr) {
      var autoFocus = attr.ctAutoFocus

      if (autoFocus !== 'false') {
        $timeout(function () {
          element[0].focus()
        }, 10)
      }
    }
  }
})
