'use strict'
import React, { useEffect, useMemo } from 'react'

import { Button, Icon, Text, Modal } from '@citifyd/style'
import FormikProvider from '../../../react/components/FormikProvider'
import FormikField, { FIELD_TYPE } from '../../../react/components/FormikField'
import { useAngularService, useTranslator } from '../../../react/hooks'
import useManageOrganizationUser from '../Hooks/useManageOrganizationUser'
import Mode from '../../../utils/mode'
import styles from './Form.module.scss'
import View from '../Utils/view'

const ROLE_OPTIONS = [
  { label: 'User', value: 'user' },
  { label: 'Admin', value: 'admin' },
  { label: 'Permit', value: 'permitter' }
]
const Form = ({
  mode,
  onClose,
  userOrInvitation,
  setIsSubmitting,
  setView
}) => {
  const t = useTranslator()
  const Authentication = useAngularService('Authentication')

  const isEditingMyself = useMemo(() => {
    return (
      mode === Mode.EDIT &&
      userOrInvitation.type === 'user' &&
      userOrInvitation.id === Authentication.getLoggedUser().id
    )
  }, [userOrInvitation, mode])

  const isEditingAnotherUser = useMemo(() => {
    return (
      mode === Mode.EDIT &&
      userOrInvitation.type === 'user' &&
      userOrInvitation.id !== Authentication.getLoggedUser().id
    )
  }, [userOrInvitation, mode])

  const formikbag = useManageOrganizationUser({
    onClose,
    mode,
    isEditingMyself,
    userOrInvitation,
    setIsSubmitting,
    setView
  })

  useEffect(() => {
    if (mode === Mode.EDIT) {
      formikbag.setFieldValue('name', userOrInvitation.name)
      formikbag.setFieldValue('email', userOrInvitation.email)
      formikbag.setFieldValue(
        'organizationRole',
        userOrInvitation.organizationRole
      )
    }
  }, [])

  const { handleSubmit } = formikbag

  return (
    <FormikProvider value={formikbag}>
      <Modal.Title>
        {mode === Mode.ADD
          ? t('modalManageOrganizationUser.add')
          : t('modalManageOrganizationUser.edit')}
      </Modal.Title>
      <Modal.Content>
        <FormikField
          gutterBottom
          label={t('modalManageOrganizationUser.name')}
          name='name'
          as={FIELD_TYPE.INPUT}
          disabled={isEditingAnotherUser}
        />
        <FormikField
          gutterBottom
          label={t('modalManageOrganizationUser.email')}
          name='email'
          as={FIELD_TYPE.INPUT}
          disabled={isEditingAnotherUser}
        />
        {!isEditingMyself && (
          <FormikField
            gutterBottom
            label={t('modalManageOrganizationUser.accessType')}
            name='organizationRole'
            options={ROLE_OPTIONS}
            as={FIELD_TYPE.SELECT}
          />
        )}
        {!isEditingMyself && (
          <Text appearance='secondary' italic textAlign='center' weight='400'>
            {t('modalManageOrganizationUser.granting')}
          </Text>
        )}
      </Modal.Content>
      <Modal.Actions>
        {mode === Mode.EDIT && !isEditingMyself && (
          <Button
            onMouseDown={() => setView(View.DELETE_CONFIRMATION)}
            type='button'
            appearance='tertiary'
            justifyContent='center'
            className={styles.icon}
          >
            <Icon appearance='white' icon='trash' size='h4' />
          </Button>
        )}
        <Button
          onMouseDown={onClose}
          type='button'
          textColor='white'
          appearance='secondary'
          fullWidth
          justifyContent='center'
          uppercase
        >
          {t('commonButtons.cancel')}
        </Button>
        <Button
          onMouseDown={handleSubmit}
          type='button'
          fullWidth
          justifyContent='center'
          uppercase
        >
          {t('commonButtons.continue')}
        </Button>
      </Modal.Actions>
    </FormikProvider>
  )
}

export default Form
