'use strict'

import React, { useState } from 'react'
import { Button, NewGrid } from '@citifyd/style'

import RouterLinkButton from '../../../../shared/react/components/RouterLinkButton'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import Table from '../../../../shared/react/components/Table'
import styles from './UserTicketsStats.module.scss'
import { getData, getEventData, getPassesByEvent } from './utils'
import UserChart from '../UserChart'
import LoadingManager from '../../../../shared/react/components/LoadingManager'
import classNames from 'classnames'

const UserTicketsStats = ({ user }) => {
  const t = useTranslator()
  const [activeTab, setActiveTab] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [loadedPassesByEvent, setLoadedPassesByEvent] = useState(false)
  const Authentication = useAngularService('Authentication')
  const Users = useAngularService('Users')
  const [eventsData, setEventsData] = useState({})

  const shouldShowPassesByEventTab = Boolean(
    Authentication.getLoggedUser().organizationId
  )

  const {
    passesByStatus,
    passesByStatusColumns,
    passesByStatusData,
    redeemedPasses,
    redeemedPassesColumns,
    redeemedPassesData
  } = getData({ user })

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <TicketsStats
            passes={passesByStatus.chartStyles}
            columns={passesByStatusColumns}
            data={passesByStatusData}
            items={[
              'purchased',
              'expired',
              'cancelled',
              'redeemed',
              'transferred',
              'parked'
            ]}
          />
        )
      case 2:
        return (
          <TicketsStats
            passes={eventsData.passesByEvent?.chartStyles}
            columns={eventsData.columns}
            data={eventsData.data}
            items={getPassesByEvent(eventsData.passesByEvent?.values)}
            withClass={false}
          />
        )
      case 3:
        return (
          <TicketsStats
            passes={redeemedPasses.chartStyles}
            columns={redeemedPassesColumns}
            data={redeemedPassesData}
            items={['expired', 'parked', 'future']}
          />
        )
    }
  }

  const isActive = tab => tab === activeTab

  const handleEventsTab = async () => {
    setActiveTab(2)

    if (loadedPassesByEvent || isLoading) {
      return
    }

    setIsLoading(true)

    try {
      const response = await Users.fetchUserTicketsByEvent(user.id)
      const data = getEventData({ user, response })

      setEventsData(data)
    } catch (err) {
    } finally {
      setLoadedPassesByEvent(true)
      setIsLoading(false)
    }
  }

  return (
    <div
      className={classNames({
        [styles.wrapper]: user.tickets.length
      })}
    >
      <ButtonTab
        isActive={isActive(1)}
        onClick={() => setActiveTab(1)}
        label={t('user.passesStatus')}
        className={styles.button}
      />
      {shouldShowPassesByEventTab && (
        <ButtonTab
          isActive={isActive(2)}
          onClick={() => handleEventsTab()}
          label={t('user.passesEvent')}
        />
      )}
      <ButtonTab
        isActive={isActive(3)}
        onClick={() => setActiveTab(3)}
        label={t('user.reeemedPasses')}
      />
      {isLoading ? (
        <LoadingManager isLoading={isLoading} />
      ) : (
        renderTabContent()
      )}
    </div>
  )
}

const ButtonTab = ({ isActive, label, onClick, ...rest }) => (
  <Button
    className={styles.buttonTab}
    appearance={isActive ? 'primary' : 'gray'}
    onClick={onClick}
    rounded
    size='small'
    uppercase
    {...rest}
  >
    {label}
  </Button>
)

const TicketsStats = ({ columns, data, passes, items, withClass = true }) => {
  const t = useTranslator()
  const $stateParams = useAngularService('$stateParams')
  const Permissions = useAngularService('Permissions')
  const { phoneCountryCode, phoneNumber, id: userId } = $stateParams

  const shouldShowPurchaseTicketButton = Permissions.userMayPurchaseTickets()

  const nonCustomer = phoneCountryCode && phoneNumber
  const userParams = nonCustomer
    ? {
        id: userId,
        phoneCountryCode,
        phoneNumber
      }
    : { id: userId }

  return (
    <NewGrid.Row gutterBottom>
      <NewGrid.Col sm={12} lg={6}>
        <Table
          gutterBottom
          borderBottom
          cleanMode
          data={data}
          columns={columns}
        />
        {shouldShowPurchaseTicketButton && (
          <RouterLinkButton
            size='small'
            uppercase
            shadow
            extraPadding
            state={
              nonCustomer
                ? 'non-customer-purchase-ticket'
                : 'user-purchase-ticket'
            }
            params={userParams}
          >
            {t('user.purchasePass')}
          </RouterLinkButton>
        )}
      </NewGrid.Col>
      <NewGrid.Col sm={12} lg={6}>
        <UserChart items={items} passes={passes} withClass={withClass} />
      </NewGrid.Col>
    </NewGrid.Row>
  )
}

export default UserTicketsStats
