'use strict'

import angular from 'angular'

import ReservedParkingPassRates from '../../react/components/ReservedParkingPassRates'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctReservedParkingPassRates',
  react2angular(ReservedParkingPassRates, ['feeBreakdown'])
)
