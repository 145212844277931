'use strict'

import React, { useEffect, useState } from 'react'
import { Button, Text } from '@citifyd/style'
import { useFormik } from 'formik'
import * as yup from 'yup'

import FormikProvider from '../../shared/react/components/FormikProvider'
import StickyFooter from '../../shared/react/components/StickyFooter'
import PageHeader from '../../shared/react/components/PageHeader'
import LoadingManager from '../../shared/react/components/LoadingManager'
import { useTranslator, useAngularService } from '../../shared/react/hooks'
import TicketRefundSummary from './components/TicketRefundSummary'
import TicketRefundDestination from './components/TicketRefundDestination'
import TicketRefundReason from './components/TicketRefundReason'
import TicketRefundSource from './components/TicketRefundSource'
import TicketTransactionData from '../ticket/components/TicketTransactionData'
import styles from './TicketRefund.module.scss'
import {
  calculateRefund,
  calculateAmountToRefund,
  getCanSeeCardExpiration,
  calculateCanToggleStripeProcessingFeeRetention,
  calculateOriginalAmount
} from './utils'

const TicketRefund = () => {
  const t = useTranslator()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(
    t('ticketRefund.loadingPass')
  )
  const [refundOptions, setRefundOptions] = useState({
    refunder: null, // will be set when loading the default cancellation options
    stripeFeePayer: null, // will be set when loading the default cancellation options
    feeRetentionMethod: null, // will be set when loading the default cancellation options
    reason: '',
    requestedByPhone: false,
    requestedByEmail: false
  })
  const $stateParams = useAngularService('$stateParams')
  const $state = useAngularService('$state')
  const Tickets = useAngularService('Tickets')
  const Authentication = useAngularService('Authentication')
  const Helper = useAngularService('Helper')
  const ticketId = $stateParams.id
  const [ticket, setTicket] = useState()
  const [isCashPayment, setIsCashPayment] = useState(null)
  const [refundDestinations, setRefundDestinations] = useState([])
  const [calculateRefundState, setCalculateRefundState] = useState(null)
  const [canSeeCardExpiration, setCanSeeCardExpiration] = useState(null)
  const [
    canToggleStripeProcessingFeeRetention,
    setCanToggleStripeProcessingFeeRetention
  ] = useState(null)
  const user = Authentication.getLoggedUser()

  const loadDefaultCancellationOptions = async () => {
    try {
      const response = await Tickets.getDefaultCancellationOptions(ticketId)
      const { defaultCancellationOptions } = response.data

      setRefundOptions(old => ({
        ...old,
        ...defaultCancellationOptions
      }))
    } catch (err) {
      setHasError(err)
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }

  const load = async () => {
    setIsLoading(true)
    setHasError(false)

    try {
      const response = await Tickets.load(ticketId)

      setTicket(response.data.ticket)

      loadDefaultCancellationOptions()
    } catch (err) {
      console.log('err', err)
      setHasError(true)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    load()
  }, [])

  const calculateRefundPreview = async () => {
    setIsLoading(true)
    setLoadingMessage(t('refundPages.calculatingValues'))

    try {
      const {
        transfersReversedAmount,
        stripeProcessingFee,
        refundDestinations
      } = await calculateRefund(ticket.id, refundOptions.feeRetentionMethod)

      const originalAmount = calculateOriginalAmount(ticket)
      const amountToRefund = calculateAmountToRefund(refundDestinations)
      const canSeeCardExpiration = getCanSeeCardExpiration(refundDestinations)

      setCanSeeCardExpiration(canSeeCardExpiration)
      setCanToggleStripeProcessingFeeRetention(
        calculateCanToggleStripeProcessingFeeRetention(
          user,
          stripeProcessingFee
        )
      )
      setIsCashPayment(isCashPayment)
      setRefundDestinations(refundDestinations)
      setCalculateRefundState({
        originalAmount,
        amountToRefund,
        stripeProcessingFee,
        transfersReversedAmount
      })
    } catch (err) {
      setIsLoading(false)
      setHasError(true)
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (ticket && refundOptions.feeRetentionMethod) {
      calculateRefundPreview()
    }
  }, [refundOptions.feeRetentionMethod])

  const onSubmit = async values => {
    const refundOptions = values
    setLoadingMessage(t('ticketRefund.refundingPass'))
    setIsLoading(true)

    if (refundOptions.feeRetentionMethod === 'stripe_processing_fee') {
      refundOptions.stripeFeePayer = 'customer'
    }

    try {
      await Tickets.cancel(ticketId, refundOptions)
    } catch (err) {
      console.log('err', err)
      Helper.showErrorAlert(err.data)
    } finally {
      $state.go('ticket', { id: ticketId }, { location: 'replace' })
    }
  }

  const formikbag = useFormik({
    initialValues: refundOptions,
    onSubmit,
    validationSchema: yup.object().shape({
      reason: yup
        .string()
        .trim()
        .required()
    }),
    enableReinitialize: true
  })

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    )
  }

  const { handleSubmit } = formikbag

  return (
    <FormikProvider value={formikbag}>
      <PageHeader>
        <PageHeader.Title
          defaultState='ticket'
          defaultParams={{ id: ticket?.id }}
          title={t('ticketRefund.refundParking')}
        />
      </PageHeader>

      {!isCashPayment && (
        <TicketRefundSummary
          ticket={ticket}
          calculateRefundState={calculateRefundState}
          canToggleStripeProcessingFeeRetention={
            canToggleStripeProcessingFeeRetention
          }
          refundOptions={refundOptions}
          setRefundOptions={setRefundOptions}
        />
      )}

      <TicketRefundDestination
        refundDestinations={refundDestinations}
        isCashPayment={isCashPayment}
        canSeeCardExpiration={canSeeCardExpiration}
        currency={ticket?.currency}
      />

      {user.isAdmin && !isCashPayment && <TicketRefundSource />}

      <TicketRefundReason />

      <div className={styles.transactionData}>
        <Text component='h5' variant='subtitle'>
          {t('refundPages.transactionHistory')}
        </Text>

        <TicketTransactionData ticket={ticket} actionsEnabled={false} />
      </div>

      <StickyFooter justifyContent='flex-end'>
        <Button uppercase letterSpacing onMouseDown={handleSubmit} extraPadding>
          {t('ticketRefund.refundPass')}
        </Button>
      </StickyFooter>
    </FormikProvider>
  )
}

export default TicketRefund
