'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

import templateUrl from './property-snapshot.html'
import './property-snapshot.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctPropertySnapshot', function (
  $i18next,
  $log,
  $timeout,
  $document,
  Authentication,
  Snapshot
) {
  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {},

    link: function (scope) {
      var body = angular.element($document[0].body)

      body.on('click', function () {
        if (scope.showBreakdown) {
          scope.showBreakdown = null
          scope.$digest()
        }
      })

      scope.$on('$destroy', function () {
        body.off('click')
      })
    },

    controller: function ($scope) {
      var refreshTimeout
      var refreshRate =
        Authentication.getLoggedUser().role === 'localManager' ? 10 : 3
      var loadedInitialData = false
      var destroyed = false

      $scope.isLoading = false

      $scope.today = $i18next.t('propertySnapshot.dateTitle', {
        today: moment()
      })

      $scope.showBreakdownClicked = function (propertyId) {
        if ($scope.showBreakdown === propertyId) {
          $scope.showBreakdown = null
        } else {
          $scope.showBreakdown = propertyId
        }
      }

      function log (message) {
        $log.log('property-snapshot: ' + message)
      }

      function emitInitialDataLoadingEvent () {
        if (!loadedInitialData) {
          $scope.$emit('property-snapshot:initial-load-completed')
        }

        loadedInitialData = true
      }

      function load () {
        if (destroyed) {
          return
        }

        if (!$scope.propertiesWithOccupation) {
          $scope.isLoading = true
        }

        log('refreshing...')

        Snapshot.getProperties()
          .then(function (response) {
            emitInitialDataLoadingEvent()

            var properties = response.data.lots

            properties.forEach(function (property) {
              property.occupied =
                property.onDemandParkersCount +
                property.reservedParkingCount +
                property.permitPassCount +
                property.monthlyParkersCount +
                property.ticketsPurchasedCount
              property.percentageOccupied = adjustPercentage(
                (property.occupied / property.maxSpots) * 100
              )

              property.available = property.maxSpots - property.occupied
              property.pendingTicketsPurchasedCount =
                property.ticketsPurchasedCount - property.ticketsParkedCount

              generateGraphStylesForProperty(property)
            })

            $scope.total = {
              spaces: _.sum(properties, 'maxSpots'),
              occupied: _.sum(properties, 'occupied'),
              available: _.sum(properties, 'available'),
              ondemand: _.sum(properties, 'onDemandParkersCount'),
              reservedparking: _.sum(properties, 'reservedParkingCount'),
              permit: _.sum(properties, 'permitPassCount'),
              monthly: _.sum(properties, 'monthlyParkersCount'),
              purchased: _.sum(properties, 'pendingTicketsPurchasedCount'),
              parked: _.sum(properties, 'ticketsParkedCount')
            }

            $scope.isLoading = false
            $scope.propertiesWithOccupation = response.data.lots

            $scope.anyLotHasLongTermParkingPlan = _.some(properties, function (
              property
            ) {
              return property.hasLongTermParkingPlan
            })

            log('done')
          })
          .finally(function () {
            scheduleRefresh()
          })
      }

      function scheduleRefresh () {
        $timeout.cancel(refreshTimeout)

        if (!destroyed) {
          refreshTimeout = $timeout(load, refreshRate * 1000)
        }
      }

      function init () {
        load()

        $scope.$on('$destroy', function () {
          if (refreshTimeout) {
            $timeout.cancel(refreshTimeout)
          }

          destroyed = true
          log('destroyed')
        })
      }

      init()
    }
  }
})

// makes sure we always round up to 1% if we have 0.x%
// otherwise uses regular Math.round
function adjustPercentage (n) {
  return n > 0 && n < 1 ? 1 : Math.round(n)
}

function generateGraphValue (previousValue, portion, total) {
  var percentage = adjustPercentage((portion / total) * 100)
  var value = previousValue + percentage
  return value
}

function generateGraphStyleObject (value) {
  return { 'stroke-dasharray': value + ' 100' }
}

function generateGraphStylesForProperty (property) {
  var parked = generateGraphValue(
    0,
    property.ticketsParkedCount,
    property.maxSpots
  )
  var purchased = generateGraphValue(
    parked,
    property.pendingTicketsPurchasedCount,
    property.maxSpots
  )
  var monthly = generateGraphValue(
    purchased,
    property.monthlyParkersCount,
    property.maxSpots
  )
  var ondemand = generateGraphValue(
    monthly,
    property.onDemandParkersCount,
    property.maxSpots
  )
  var permit = generateGraphValue(
    ondemand,
    property.permitPassCount,
    property.maxSpots
  )
  var reservedparking = generateGraphValue(
    permit,
    property.reservedParkingCount,
    property.maxSpots
  )

  // 100 doesn't fill the entire circle, so we have to add 1 in this case :|
  if (ondemand === 100) {
    ondemand++
  }

  if (reservedparking === 100) {
    reservedparking++
  }

  if (permit === 100) {
    permit++
  }

  property.graphStyles = {
    ondemand: generateGraphStyleObject(ondemand),
    reservedparking: generateGraphStyleObject(reservedparking),
    permit: generateGraphStyleObject(permit),
    monthly: generateGraphStyleObject(monthly),
    purchased: generateGraphStyleObject(purchased),
    parked: generateGraphStyleObject(parked)
  }
}
