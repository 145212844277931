'use strict'

import React from 'react'
import { NewGrid } from '@citifyd/style'

import styles from './NavBar.module.scss'

export default function NavBar ({ children }) {
  return (
    <div className={`${styles.topNotificationBar} fade-animation`}>
      <NewGrid>
        <NewGrid.Row>
          <NewGrid.Col sm={12} justifyContent='space-between'>
            {children}
          </NewGrid.Col>
        </NewGrid.Row>
      </NewGrid>
    </div>
  )
}
