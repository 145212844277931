'use strict'
import React, { useEffect, useState } from 'react'

import { Button, Modal, NewGrid } from '@citifyd/style'
import { useTranslator } from '../../../../react/hooks'
import useLoadTracking from '../../../../react/hooks/useLoadTracking'

import {
  loadCountries,
  loadUserData,
  loadSelectedCountryData,
  countryPostalCodeProperty,
  isStripeCardComplete
} from '../../functions/functions'

import FormikProvider from '../../../../react/components/FormikProvider'
import FormikField, {
  FIELD_TYPE
} from '../../../../react/components/FormikField'
import Card from '../Card/Card.jsx'
import LoadingIndicator from '../../../../react/components/LoadingIndicator'
import useCardForm from './useCardForm'
import { useRef } from 'react'

const Form = ({ onClose, userId }) => {
  const t = useTranslator()
  const cardRef = useRef()
  const [countries, setCountries] = useState([])
  const [stripeError, setStripeError] = useState(null)

  const { isLoading, trackLoading, untrackLoading } = useLoadTracking()

  const formikbag = useCardForm({
    trackLoading,
    untrackLoading,
    onClose,
    userId,
    hasStripeError: Boolean(stripeError)
  })

  const countryCode = formikbag?.values.cardCountryCode

  useEffect(() => {
    if (countryCode) {
      loadSelectedCountryData(
        countryCode,
        trackLoading,
        untrackLoading,
        formikbag
      )
    }
  }, [countryCode])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    trackLoading('init')

    const [countries, userResponse] = await Promise.all([
      loadCountries(),
      loadUserData(userId)
    ])

    setCountries(countries)
    formikbag.setFieldValue('cardCountryCode', userResponse.phoneCountryCode)

    untrackLoading('init')
  }

  const { handleSubmit: submitFormik } = formikbag

  const submitForm = () => {
    if (!stripeError && !isStripeCardComplete(cardRef)) {
      setStripeError({ message: null })
    }
    submitFormik()
  }

  return (
    <FormikProvider value={formikbag}>
      <Modal.Content>
        <Card
          ref={cardRef}
          stripeError={stripeError}
          setStripeError={setStripeError}
        />
        <FormikField
          name='name'
          label={t('modalAddCard.name')}
          as={FIELD_TYPE.INPUT}
          fullWidth
          gutterBottom
        />
        <NewGrid.Row>
          <NewGrid.Col sm={12} md={6}>
            <FormikField
              name='cardCountryCode'
              label={t('modalAddCard.country')}
              as={FIELD_TYPE.SELECT}
              fullWidth
              gutterBottom
              options={countries}
            />
          </NewGrid.Col>
          <NewGrid.Col sm={12} md={6}>
            <FormikField
              name='zipCode'
              label={
                countryPostalCodeProperty(
                  formikbag.values.selectedCountryData,
                  'name'
                ) ?? t('modalAddCard.postalCode')
              }
              as={FIELD_TYPE.INPUT}
              fullWidth
              gutterBottom
            />
          </NewGrid.Col>
        </NewGrid.Row>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onMouseDown={onClose}
          type='button'
          fullWidth
          justifyContent='center'
          uppercase
          textColor='white'
          appearance='secondary'
        >
          {t('commonButtons.cancel')}
        </Button>
        <Button
          onMouseDown={submitForm}
          type='button'
          fullWidth
          justifyContent='center'
          uppercase
        >
          {t('modalAddCard.addCard')}
        </Button>
      </Modal.Actions>
      {isLoading && <LoadingIndicator isLoading={isLoading} withOverlay />}
    </FormikProvider>
  )
}

export default Form
