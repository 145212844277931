'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.controller('LoginAuthenticationStepCtrl', function (
  $scope,
  $location,
  $window,
  $i18next,
  $timeout,
  Authentication,
  Users,
  CitifydModal,
  Helper
) {
  $scope.forgotPassword = function () {
    $scope.password = ''

    $timeout(() => {
      if ($scope.email === undefined || $scope.email.trim() === '') {
        $window.alert($i18next.t('login.passwordReset.pleaseEnterEmail'))
        return
      }

      if (!$window.confirm($i18next.t('login.passwordReset.areYouSure'))) {
        return
      }

      $scope.isLoading = true

      Users.requestPasswordReset($scope.email)
        .then(response => {
          $scope.isLoading = false
          Helper.showAlert(response.data.message)
        })
        .catch(function (response) {
          $scope.isLoading = false
          Helper.showErrorAlert(response.data)
        })
    })
  }

  $scope.tryAuthenticate = function () {
    if (!validate()) {
      return
    }

    $scope.isLoading = true

    const credentials = { email: $scope.email, password: $scope.password }

    Authentication.tryAuthenticate(credentials).then(
      function success (response) {
        if (response.twoFactorAuthentication) {
          $scope.changeStep('two-factor', { credentials }) // defined in login.js
        } else if (response.user) {
          $scope.handleSuccessfulLogin() // defined in login.js
        }
      },

      function error (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)
      }
    )
  }

  function validate () {
    if (!$scope.loginForm.$valid) {
      if ($scope.email === undefined) {
        $scope.$broadcast('emptyEmail')
        return false
      }

      if ($scope.password === undefined) {
        $scope.$broadcast('emptyPassword')
        return false
      }
    }

    return true
  }

  function init () {
    $scope.isLoading = false
    $scope.email = $location.search().email || ''
  }

  init()
})
