import { useState } from 'react'

import { useAngularService } from '../../../../shared/react/hooks'
import { transformReservations } from './utils'

const useReservations = () => {
  const Users = useAngularService('Users')
  const { id } = useAngularService('$stateParams')
  const [reservations, setReservations] = useState(null)
  const [reservationsLoaded, setReservationsLoaded] = useState(false)
  const [reservationsError, setReservationsError] = useState(false)

  const fetchReservations = async () => {
    try {
      const response = await Users.fetchReservations(id)
      setReservations(transformReservations(response.data.reservations))
    } catch (err) {
      setReservationsError(true)
    } finally {
      setReservationsLoaded(true)
    }
  }

  return {
    fetchReservations,
    reservations,
    reservationsLoaded,
    reservationsError
  }
}

export default useReservations
