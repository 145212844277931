'use strict'

import angular from 'angular'
import moment from 'moment'
import { trim, startCase } from 'lodash'
import ApolloClient from '../../../shared/services/apolloClient'

import ADMIN_LIST_RESERVED_PARKING_PASSES from '../../../graphql/reservedParkingPasses/queries/AdminListReservedParkingPasses'

const app = angular.module('citifydSellerApp')

app.controller('ReservedParkingScheduleCtrl', function (
  Authentication,
  API,
  CitifydModal,
  Helper,
  $timeout,
  Permissions,
  $scope,
  $state,
  $i18next
) {
  const PER_PAGE = 10

  $scope.generateEmptyListMessage = () => {
    const { status, searchQuery, selectedProperty } = $scope
    const localeOptions = {}

    let localeKey = `schedule.reservedParking.empty.${status?.toLowerCase() ||
      'default'}`

    if (selectedProperty) {
      localeKey += 'Lot'
      localeOptions.lotName = selectedProperty.name
    }
    if (searchQuery) {
      localeKey += 'Search'
      localeOptions.searchQuery = searchQuery
    }

    return $i18next.t(localeKey, localeOptions)
  }

  $scope.generateCsvClicked = () => {
    // var url =
    //   API.base +
    //   "/admin/events/csv?timezoneName=" +
    //   Helper.getLocalTimezoneName() +
    //   ($scope.selectedProperty
    //     ? "&lotId=" + $scope.selectedProperty.id
    //     : "") +
    //   ($scope.filter ? "&filter=" + $scope.filter : "");
    // CitifydModal.open("generate-report", {
    //   fileFormat: "csv",
    //   filterDescription: generateCsvReportDescription(),
    //   reportUrl: Authentication.mountAuthenticatedUrl("url"),
    // });
  }

  const generateCsvReportDescription = () => {
    const options = {
      startDate: moment($scope.startTime),
      endDate: moment($scope.endTime)
    }

    if ($scope.selectedProperty) {
      return $i18next.t('schedule.reservedParking.csvReportName.lot', {
        ...options,
        lotName: $scope.selectedProperty?.name
      })
    } else {
      return $i18next.t('schedule.reservedParking.csvReportName.date', {
        ...options
      })
    }
  }

  $scope.onFilterChange = ({ status, startTime, endTime }) => {
    if (!$scope.loadingReservedParkingPasses) {
      if (status) {
        $scope.status = status === 'all' ? null : status
      } else if (startTime) {
        $scope.startTime = startTime
      } else if (endTime) {
        $scope.endTime = moment(endTime).add(1, 'days')
      }

      $scope.endCursor = null

      loadData()
    }
  }

  $scope.loadMore = () => {
    if (
      !$scope.hasNextPage ||
      $scope.loadingReservedParkingPasses ||
      $scope.loadingMore
    ) {
      return
    }

    loadData(true)
  }

  const loadData = loadMore => {
    if (!loadMore) {
      $scope.loadingReservedParkingPasses = true
    } else {
      $scope.loadingMore = true
    }
    const formattedData = edges => {
      return edges.map(edge => ({
        ...edge.node,
        status: startCase(edge.node.status?.toLowerCase()),
        startTime: moment.tz(edge.node.startTime, edge.node.lot.timezoneName),
        endTime: moment.tz(edge.node.endTime, edge.node.lot.timezoneName),
        createdAt: moment.tz(edge.node.createdAt, edge.node.lot.timezoneName)
      }))
    }

    ApolloClient()
      .query({
        query: ADMIN_LIST_RESERVED_PARKING_PASSES,
        variables: {
          lotId: $scope.selectedPropertyId || parseInt($state.params.property),
          startDate: moment($scope.startTime).format('YYYY-MM-DD'),
          endDate: moment($scope.endTime).format('YYYY-MM-DD'),
          first: PER_PAGE,
          after: $scope.endCursor,
          searchTerm: trim($scope.searchQuery),
          status: $scope.status?.toUpperCase()
        }
      })
      .then(result => {
        const results = result.data?.admin_listReservedParkingPasses
        const total = results?.total
        $scope.endCursor = results?.pageInfo.endCursor
        $scope.hasNextPage = results?.pageInfo.hasNextPage

        if (total > 0) {
          const edges = results?.edges
          const data = formattedData(edges)

          if (loadMore) {
            $scope.reservedParkingPasses = [
              ...$scope.reservedParkingPasses,
              ...data
            ]
          } else {
            $scope.reservedParkingPasses = data
          }
        } else {
          $scope.reservedParkingPasses = []
        }

        $timeout(0)
      })
      .catch(() => ($scope.reservedParkingPasses = []))
      .finally(() => {
        $scope.loadingReservedParkingPasses = false
        $scope.loadingMore = false
      })
  }

  const watchSearchQuery = () => {
    var timer

    $scope.$watch('searchQuery', function (oldValue, newValue) {
      $scope.loadingReservedParkingPasses = true
      $scope.endCursor = null

      if (!angular.equals(oldValue, newValue)) {
        if (timer) {
          $timeout.cancel(timer)
        }

        timer = $timeout(() => loadData(), 500)
      }
    })
  }

  const init = () => {
    $scope.hasNextPage = true
    $scope.loadingReservedParkingPasses = true
    $scope.loadingMore = false
    $scope.status = null
    $scope.endCursor = null
    $scope.searchQuery = ''
    $scope.startTime = moment()
      .startOf('day')
      .format()
    $scope.endTime = moment()
      .add(3, 'months')
      .format()
    $scope.reservedParkingPasses = []
    $scope.canSeeUserInformation = Permissions.userMaySeeUserInformation()

    watchSearchQuery()
    loadData()

    $scope.$watch(
      () => $state.params.property,
      () => {
        $scope.endCursor = null
        if (!$scope.loadingReservedParkingPasses) {
          loadData()
        }
      }
    )
  }

  init()
})
