'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.filter('lowercaseFirstCharacter', function () {
  return function (input) {
    return input ? input.charAt(0).toLowerCase() + input.substr(1) : ''
  }
})
