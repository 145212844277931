import { useMemo } from 'react'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'

export const useTableColumns = () => {
  const t = useTranslator()
  const Permissions = useAngularService('Permissions')
  const userMaySeeUserInformation = Permissions.userMaySeeUserInformation()

  return useMemo(() => {
    const columns = [
      userMaySeeUserInformation
        ? {
            value: t('dailyRevenue.username'),
            key: 'name'
          }
        : null,
      {
        value: t('dailyRevenue.plate'),
        key: 'license'
      },
      {
        value: t('dailyRevenue.property'),
        key: 'lotName'
      },
      {
        value: t('dailyRevenue.start'),
        key: 'startTime'
      },
      {
        value: t('dailyRevenue.end'),
        key: 'endTime'
      },
      {
        value: t('dailyRevenue.duration'),
        key: 'duration'
      },
      {
        value: t('dailyRevenue.status'),
        key: 'status'
      },
      {
        value: t('dailyRevenue.revenue'),
        textAlign: 'right',
        key: 'revenueAmount'
      }
    ]

    return columns.filter(column => column !== null)
  }, [userMaySeeUserInformation])
}
