'use strict'

import React from 'react'
import { NewGrid, Text } from '@citifyd/style'
import { useTranslator } from '../../hooks'
import LanguageSelector from '../LanguageSelector'

import styles from './Footer.module.scss'

export default function Footer () {
  const t = useTranslator()
  const currentYear = new Date().getFullYear()

  // the dropdown directive uses the footer position to calculate overflow logic, this is the reason we added an ID here.
  return (
    <div id='footer' className={styles.siteFooterWrap}>
      <NewGrid nogutter>
        <NewGrid.Row nogutter justify='between'>
          <NewGrid.Col>
            <Text appearance='white' variant='subtitle'>
              {t('footer.copyright', { currentYear: currentYear })}
            </Text>
          </NewGrid.Col>
          <NewGrid.Col>
            <LanguageSelector />
          </NewGrid.Col>
        </NewGrid.Row>
      </NewGrid>
    </div>
  )
}
