'use strict'

import angular from 'angular'
import { sortBy, find } from 'lodash'

import templateUrl from './schedule.html'
import './schedule.scss'

import eventsTemplateUrl from './views/events.html'
import onDemandTemplateUrl from './views/on-demand.html'
import monthlyTemplateUrl from './views/monthly.html'
import reservedParkingTemplateUrl from './views/reservedParking.html'

const app = angular.module('citifydSellerApp')

app.controller('ScheduleCtrl', function (
  Authentication,
  Properties,
  $scope,
  $stateParams,
  $state
) {
  var user

  $scope.propertyChanged = function () {
    $state.transitionTo(
      'schedule',
      {
        ...$state.params,
        property: $scope.selectedPropertyId,
        tab: $scope.tab
      },
      { location: 'replace' }
    )
  }

  $scope.activateTab = function (tabName, changeState = true) {
    $scope.tab = tabName

    if (changeState) {
      $state.transitionTo(
        'schedule',
        {
          ...$state.params,
          property: $scope.selectedPropertyId,
          tab: tabName
        },
        { location: 'replace' }
      )
    }
  }

  $scope.isTabActive = function (tabName) {
    return $scope.tab === tabName
  }

  function fetchProperties () {
    var options = {}

    if (user.isAdmin) {
      options.all = true
    }

    return Properties.findAll(options).then(function (response) {
      return response.data.lots
    })
  }

  function init () {
    user = Authentication.getLoggedUser()

    $scope.loadingProperties = true
    $scope.selectedPropertyId = null
    $scope.selectedProperty = null
    $scope.tab = null

    $scope.eventsTemplateUrl = eventsTemplateUrl
    $scope.onDemandTemplateUrl = onDemandTemplateUrl
    $scope.monthlyTemplateUrl = monthlyTemplateUrl
    $scope.reservedParkingTemplateUrl = reservedParkingTemplateUrl

    fetchProperties().then(function (properties) {
      $scope.loadingProperties = false
      $scope.properties = sortBy(properties, 'name')

      $scope.activateTab($stateParams.tab || 'onDemand', false)

      $scope.$watch(
        () => $state.params.property,

        function (propertyId) {
          $scope.selectedPropertyId = propertyId
            ? parseInt(propertyId, 10)
            : null
          $scope.selectedProperty = null

          if ($scope.selectedPropertyId) {
            $scope.selectedProperty = find(
              $scope.properties,
              p => p.id === $scope.selectedPropertyId
            )
          }

          if (
            !$scope.selectedProperty &&
            !user.isAdmin &&
            $scope.properties.length === 1
          ) {
            $scope.selectedPropertyId = $scope.properties[0].id
            $scope.selectedProperty = $scope.properties[0]
          }

          $scope.propertyChanged()
        }
      )
    })
  }

  init()
})

export const controller = 'ScheduleCtrl'
export const auth = true
export const title = 'i18n:schedule.pageTitle'
export { templateUrl }
