'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Organizations', (API, Authentication, $http) => {
  function findMyOrganization () {
    return $http({
      method: 'GET',
      url: API.base + '/me/organization',
      headers: Authentication.generateHeaders()
    })
  }

  function updateMyOrganization (organizationData) {
    return $http({
      method: 'PUT',
      url: API.base + '/me/organization',
      headers: Authentication.generateHeaders(),
      data: { organization: organizationData }
    })
  }

  function findById (organizationId) {
    return $http({
      method: 'GET',
      url: API.base + '/organizations/' + organizationId,
      headers: Authentication.generateHeaders()
    })
  }

  return {
    findMyOrganization: findMyOrganization,
    updateMyOrganization: updateMyOrganization,
    findById: findById
  }
})
