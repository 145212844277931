'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.directive('equalTo', function () {
  return {
    require: 'ngModel',

    scope: {
      other: '=equalTo'
    },

    link: function (scope, element, attributes, ngModel) {
      ngModel.$validators.equalTo = function (modelValue) {
        return modelValue === scope.other
      }

      scope.$watch('other', function () {
        ngModel.$validate()
      })
    }
  }
})
