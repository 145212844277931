'use strict'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Icon, Text, NewGrid } from '@citifyd/style'

import { useTranslator } from '../../../../shared/react/hooks'
import { getAngularService } from '../../../../shared/react/utils'
import { calculateLotStatus } from '../../../../shared/utils/status-calculation'

import BoxContent from '../../../../shared/react/components/BoxContent'
import Address from '../../../../shared/react/components/Address'
import ToggleButton from '../../../../shared/react/components/ToggleButton'
import RouterLink from '../../../../shared/react/components/RouterLink'

import styles from './Lot.module.scss'

function Lot ({ lot, updateLotOnList, canEditLot }) {
  return (
    <BoxContent className={styles.lotBox}>
      <BoxContent.Header title={lot.name}>
        <BoxContent.HeaderActions>
          {canEditLot && <EditLotLink lot={lot} />}
        </BoxContent.HeaderActions>
      </BoxContent.Header>
      <BoxContent.Content>
        <LotInfo lot={lot} />
        <LotStatus lot={lot} updateLotOnList={updateLotOnList} />
      </BoxContent.Content>
    </BoxContent>
  )
}

function EditLotLink ({ lot }) {
  return (
    <RouterLink
      state='edit-property'
      params={{ lotId: lot.id }}
      className={styles.editLink}
    >
      <Icon icon='pencil-alt' appearance='white' />
    </RouterLink>
  )
}

function LotInfo ({ lot }) {
  return (
    <NewGrid.Row>
      <NewGrid.Col className={styles.lotInfo}>
        <Address className={styles.address} entity={lot} format='full' />
        <LotImage lot={lot} />
      </NewGrid.Col>
    </NewGrid.Row>
  )
}

function LotImage ({ lot }) {
  return <img src={lot.photoUrl} className={styles.photo} />
}

function LotStatus ({ lot: inputLot, updateLotOnList }) {
  const t = useTranslator()

  const [lot, setLot] = useState()
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => setLot(inputLot), [inputLot])

  const status = useMemo(() => lot && calculateLotStatus(lot), [lot])
  const toggleSelected = status === 'active'
  const toggleVisible = ['active', 'inactive'].includes(status)

  const toggle = useCallback(async () => {
    const Properties = getAngularService('Properties')
    const { showErrorAlert } = getAngularService('Helper')

    const isVisible = !lot.isVisible

    setIsUpdating(true)
    setLot({ ...lot, isVisible: isVisible })

    try {
      const response = await Properties.update({ id: lot.id, isVisible })
      updateLotOnList(lot.id, response.data.lot)
    } catch (err) {
      console.log('error', err)
      showErrorAlert(err.data)
      setLot(inputLot)
    } finally {
      setIsUpdating(false)
    }
  }, [lot, updateLotOnList])

  if (!lot) {
    return null
  }

  return (
    <div className={styles.visibilityToggle}>
      {toggleVisible && (
        <ToggleButton
          selected={toggleSelected}
          onChange={toggle}
          disabled={isUpdating}
        />
      )}
      {isUpdating && (
        <Text variant='subtitle'>
          {t('accountInfo.lots.statuses.updating')}
        </Text>
      )}
      {!isUpdating && (
        <Text variant='subtitle'>
          {t(`accountInfo.lots.statuses.${status}`)}
        </Text>
      )}
    </div>
  )
}

export default Lot
