import React from 'react'
import { Text } from '@citifyd/style'

import styles from './TabName.module.scss'

const TabName = ({ item, active }) => {
  const appearance = active ? 'default' : 'white'

  return (
    <div className={styles.tab}>
      <Text
        uppercase
        textAlign='center'
        appearance={appearance}
        className={styles.big}
      >
        {item.big}
      </Text>
      <Text
        uppercase
        textAlign='center'
        appearance={appearance}
        className={styles.small}
      >
        {item.small}
      </Text>
    </div>
  )
}

export default TabName
