'use strict'

import angular from 'angular'

import PasswordValidator from '../../shared/services/password-validator'

import templateUrl from './register.html'
import './register.scss'

const app = angular.module('citifydSellerApp')

app.controller('RegisterCtrl', function (
  $scope,
  $sce,
  Users,
  $i18next,
  $state,
  $window,
  Authentication,
  Languages,
  Settings,
  Intercom
) {
  $scope.currentStep = 1

  $scope.invalidPassword = false

  $scope.user = { sessionType: 'management' }

  $scope.passwordRules = null

  $scope.privacyPolicyUrl = Settings.getPrivacyPolicyUrl()

  $scope.termsAndConditionsUrl = $sce.trustAsResourceUrl(
    Settings.getTermsAndConditionsUrl()
  )

  $scope.privacyPolicyDisclaimerLine2 = $i18next.t(
    'privacyPolicyDisclaimer.line2',
    {
      hyperlinkStart: `<a href="${$scope.privacyPolicyUrl}" target="_blank">`,
      hyperlinkEnd: '</a>'
    }
  )

  function clear () {
    $scope.processing = true
    $scope.invalidPassword = false
    $scope.error = null
  }

  function handleError (responseError) {
    $scope.processing = false

    if (responseError.data.error) {
      $scope.error = responseError.data.error.message
    }
  }

  $scope.advanceStep = function () {
    $scope.currentStep++
    $scope.$broadcast('register:step-' + $scope.currentStep)
  }

  $scope.registerUser = function () {
    const passwordValidation = PasswordValidator.validate(
      $scope.user.password,
      $scope.passwordRules
    )
    if (!passwordValidation.isValid) {
      $scope.invalidPassword = true
      return
    }

    if (!$scope.userForm.$valid) {
      return
    }

    clear()

    $scope.user.language = Languages.getCurrent()

    Users.createUser($scope.user).then(function (response) {
      // set new user model based on API response so we can use the user ID
      $scope.user = response.data.user

      return Authentication.setToken($scope.user.accessToken).then(function () {
        $scope.processing = false
        $scope.advanceStep()
      })
    }, handleError)
  }

  $scope.acceptTerms = function () {
    clear()

    Users.updateUser($scope.user.id, { acceptedTerms: true }).then(function () {
      $scope.processing = false
      $scope.advanceStep()
    }, handleError)
  }

  $scope.phoneNumberVerified = function () {
    $state.go('dashboard', {}, { replace: true })
  }

  $scope.changePassword = function () {
    $scope.invalidPassword = false
  }

  $scope.leave = function () {
    if (!$window.confirm($i18next.t('register.leaveConfirmation'))) {
      return
    }

    Authentication.logout()
    Intercom.logout()
    $window.location = '/'
  }

  function init () {
    $scope.processing = true

    Users.fetchPasswordRules({
      potentialOrganizationUser: true
    }).then(rules => {
      $scope.processing = false
      $scope.passwordRules = rules
    })
  }

  init()
})

export const controller = 'RegisterCtrl'
export const auth = false
export const title = 'i18n:register.pageTitle'
export { templateUrl }
