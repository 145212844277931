'use strict'

import angular from 'angular'

import templateUrl from './reserved-parking-filter.html'
import './reserved-parking-filter.scss'

import _ from 'lodash'
import moment from 'moment-timezone'

const app = angular.module('citifydSellerApp')

const MAX_RANGE_ALLOWED_IN_MONTHS = 12

app.directive('ctReservedParkingFilter', function () {
  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {
      onClickGenerateCsv: '=',
      startTime: '=',
      searchQuery: '=',
      endTime: '=',
      onFilterChange: '='
    },

    controller ($i18next, $scope) {
      $scope.selection = {}
      $scope.isValid = true
      $scope.errors = {}

      $scope.generateCsvClicked = function () {
        validate()

        if ($scope.isValid && $scope.onClickGenerateCsv) {
          $scope.onClickGenerateCsv()
        }
      }

      const validate = () => {
        $scope.errors = {}

        if ($scope.dateRange) {
          const { dateRange } = $scope
          const startDate = moment(dateRange.startTime)
          const endDate = moment(dateRange.endTime)

          if (startDate.isAfter(endDate)) {
            $scope.errors['dateRange.endTime'] = $i18next.t(
              'schedule.reservedParkingFilter.errors.endDate.mustBeAfterStartDate'
            )
          }

          if (endDate.diff(startDate, 'months') > MAX_RANGE_ALLOWED_IN_MONTHS) {
            $scope.errors['dateRange.endTime'] = $i18next.t(
              'schedule.reservedParkingFilter.errors.endDate.rangeLimitReached',
              {
                maxMonths: MAX_RANGE_ALLOWED_IN_MONTHS
              }
            )
          }
        }

        $scope.isValid = _.isEmpty($scope.errors)
      }

      const init = () => {
        $scope.currentStatus = null
        $scope.dateRange = {
          startTime: $scope.startTime,
          endTime: $scope.endTime
        }

        $scope.statusList = [
          { label: 'Future' },
          { label: 'Active' },
          { label: 'Past' }
        ]

        $scope.$watch('currentStatus', currentStatus => {
          $scope.onFilterChange({
            status: currentStatus?.label || 'all'
          })
        })

        $scope.$watch('dateRange.startTime', startTime => {
          validate()
          if ($scope.isValid) {
            $scope.onFilterChange({ startTime: startTime })
          }
        })

        $scope.$watch('dateRange.endTime', endTime => {
          validate()
          if ($scope.isValid) {
            $scope.onFilterChange({ endTime: endTime })
          }
        })
      }

      init()
    }
  }
})
