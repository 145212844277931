'use strict'

import angular from 'angular'
import _ from 'lodash'
import moment from 'moment-timezone'

import pricingTemplateUrl from '../views/pricing.html'

const app = angular.module('citifydSellerApp')

app.directive('ctAddPropertyPricing', function () {
  return {
    restrict: 'E',
    templateUrl: pricingTemplateUrl,

    scope: {
      type: '@',
      period: '@',
      pricingObject: '=',
      selectedDays: '=',
      forms: '=',
      lot: '='
    },

    controller: function (Currencies, Helper, $i18next, $scope, $window) {
      $scope.formName =
        'pricing-' + $scope.type + '-' + $scope.period + '-' + $scope.$id
      $scope.times = Helper.generateTimesArray()

      $scope.getSummaryTimeRange = function () {
        const { data } = $scope.pricingObject

        const getTimeObject = str => {
          const parts = str.split(':')
          return {
            hour: parts[0],
            minute: parts[1],
            second: 0
          }
        }

        return $i18next.t('addProperty.pricing.summary.timeRange', {
          startTime: moment().set(getTimeObject(data.startTime)),
          endTime: moment().set(getTimeObject(data.endTime))
        })
      }

      $scope.refreshDiscountTimes = function () {
        var editData = $scope.pricingObject.editData

        if (!editData) {
          return
        }

        $scope.discountTimes = Helper.generateTimesArray(editData.startTime)

        if ($scope.discountTimes.length === 0) {
          editData.discount.enabled = false
        } else {
          var hasValue = Boolean(
            _.findWhere($scope.discountTimes, {
              time: editData.discount.arriveBefore
            })
          )

          if (!hasValue) {
            editData.discount.arriveBefore = $scope.discountTimes[0].time
          }
        }
      }

      $scope.getSelectedWeekDaysString = function () {
        if (!$scope.selectedDays) {
          return ''
        }

        var days
        var names

        if ($scope.type === 'weekend') {
          days = ['sat', 'sun']
          names = [
            Helper.getTranslatedDayName('Saturday'),
            Helper.getTranslatedDayName('Sunday')
          ]
        } else {
          days = ['mon', 'tue', 'wed', 'thu', 'fri']
          names = [
            Helper.getTranslatedDayName('Monday'),
            Helper.getTranslatedDayName('Tuesday'),
            Helper.getTranslatedDayName('Wednesday'),
            Helper.getTranslatedDayName('Thursday'),
            Helper.getTranslatedDayName('Friday')
          ]
        }

        var selectedDays = _.map(days, function (day) {
          return $scope.selectedDays[day]
        })

        var items = []

        _.forEach(selectedDays, function (value, index) {
          if (value) {
            var name = names[index]
            var lastItem = _.last(items)

            if (lastItem) {
              lastItem.end = name
            } else {
              items.push({ start: name, end: null })
            }
          } else {
            items.push(null)
          }
        })

        items = _.filter(items, function (item) {
          return item
        })

        items = _.map(items, function (item) {
          if (item.end) {
            return $i18next.t('addProperty.pricing.summary.weekdayRange', {
              firstWeekday: item.start,
              lastWeekday: item.end
            })
          } else {
            return item.start
          }
        })

        return items.join(', ')
      }

      $scope.getTimeMomentObject = function (time) {
        if (time) {
          const [hours, minutes] = time.split(':')
          return moment().set({ hours, minutes })
        }
      }

      $scope.edit = function () {
        var pricing = $scope.pricingObject
        var editData = angular.copy(pricing.data)

        if (!editData) {
          editData = {
            spaces: $scope.lot.maxSpots,
            rateValue: null,
            rateType: 'hourly',
            startTime: $scope.period === 'day' ? '06:00' : '18:00',
            endTime: $scope.period === 'day' ? '18:00' : '00:00',
            discount: {
              enabled: false,
              maxRate: null,
              arriveBefore: '08:00'
            }
          }
        }

        pricing.editing = true
        pricing.editData = editData

        _.defer(function () {
          $scope.getRateInput().focus()
        })
      }

      $scope.remove = function () {
        if (!$window.confirm($i18next.t('commonConfirmations.areYouSure'))) {
          return
        }

        var pricing = $scope.pricingObject
        pricing.editing = false
        pricing.editData = pricing.data = null
      }

      $scope.save = function () {
        $scope.$broadcast('show-errors-check-validity')

        if ($scope.forms[$scope.formName].$invalid) {
          return
        }

        var pricing = $scope.pricingObject
        pricing.data = pricing.editData
        pricing.editData = null
        pricing.editing = false
      }

      $scope.cancel = function () {
        var pricing = $scope.pricingObject
        pricing.editData = null
        pricing.editing = false
      }

      $scope.$watch('pricingObject.editData.startTime', function () {
        $scope.refreshDiscountTimes()
      })

      $scope.$on('add-property-step-2-submitted', function () {
        if ($scope.pricingObject.editing) {
          $scope.save()
        }
      })

      $scope.amountToInteger = amount => {
        return Currencies.transformToInteger(
          amount,
          $scope.lot.country.currency
        )
      }

      const currency = $scope.lot.country.currency
      $scope.currencySettings = Currencies.getCurrencySettings(currency)
      $scope.ratePlaceholder = 200
      $scope.maxRatePlaceholder = 1200
      $scope.discountMaxRatePlaceholder = 1000
    },

    link: function (scope, element) {
      scope.getRateInput = function () {
        return element.find('input[name=rate]')
      }
    }
  }
})
