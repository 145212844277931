'use strict'

import angular from 'angular'
import _ from 'lodash'

import plansTemplateUrl from '../views/plans.html'

const app = angular.module('citifydSellerApp')

app.controller('AddPropertyStep2Ctrl', function (
  Authentication,
  Currencies,
  Helper,
  Properties,
  Plans,
  AddPropertyAvailabilityGenerator,
  StripeConnectedAccounts,
  $state,
  $window,
  $scope,
  $i18next
) {
  var AvailabilityGenerator = AddPropertyAvailabilityGenerator

  function mountDayNames () {
    return {
      mon: Helper.getTranslatedDayName('Monday'),
      tue: Helper.getTranslatedDayName('Tuesday'),
      wed: Helper.getTranslatedDayName('Wednesday'),
      thu: Helper.getTranslatedDayName('Thursday'),
      fri: Helper.getTranslatedDayName('Friday'),
      sat: Helper.getTranslatedDayName('Saturday'),
      sun: Helper.getTranslatedDayName('Sunday')
    }
  }

  function mountSmallDayNames () {
    return {
      mon: Helper.getTranslatedDayName('Mon'),
      tue: Helper.getTranslatedDayName('Tue'),
      wed: Helper.getTranslatedDayName('Wed'),
      thu: Helper.getTranslatedDayName('Thu'),
      fri: Helper.getTranslatedDayName('Fri'),
      sat: Helper.getTranslatedDayName('Sat'),
      sun: Helper.getTranslatedDayName('Sun')
    }
  }

  function mountSmallestDayNames (dayNames) {
    const smallestDayNames = {}

    for (const day in dayNames) {
      let dayName = dayNames[day]
      let length = 1
      let hasEqual
      let partialDayName

      do {
        partialDayName = dayName.substr(0, length)

        hasEqual = _.some(
          dayNames,
          anotherDayName =>
            anotherDayName !== dayName &&
            anotherDayName.substr(0, length) === partialDayName
        )

        if (hasEqual) {
          length++
        }
      } while (hasEqual && length <= dayName.length)

      smallestDayNames[day] = partialDayName
    }

    return smallestDayNames
  }

  function isLotOpenOnAny (days) {
    return _.some(days, function (day) {
      return $scope.pricingSettings.days[day]
    })
  }

  function isLotOpenOnAnyWeekday () {
    return isLotOpenOnAny(['mon', 'tue', 'wed', 'thu', 'fri'])
  }

  function isLotOpenOnAnyWeekendDay () {
    return isLotOpenOnAny(['sat', 'sun'])
  }

  function hasWeekdayPricing () {
    return (
      $scope.pricingSettings.weekdays.data ||
      $scope.pricingSettings.weeknights.data
    )
  }

  function hasWeekendPricing () {
    return (
      $scope.pricingSettings.weekends.data ||
      $scope.pricingSettings.weekendEvenings.data
    )
  }

  // Set up initial variable for pricing
  function loadPricingSettings () {
    if (
      $scope.lot.setupIsComplete ||
      $scope.lot.advancedRateDefinitionEnabled
    ) {
      $scope.pricingSettings = null
      return
    }

    $scope.pricingSettings = {
      days: {
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
        sat: true,
        sun: true
      },

      weekdays: { data: null, editData: null, editing: false },
      weeknights: { data: null, editData: null, editing: false },
      weekends: { data: null, editData: null, editing: false },
      weekendEvenings: { data: null, editData: null, editing: false }
    }
  }

  function confirmSelectedDays () {
    if (isLotOpenOnAnyWeekday() && !hasWeekdayPricing()) {
      $window.alert(
        $i18next.t('addProperty.pricing.errorMessages.weekdayPricingMissing')
      )
      return false
    }

    if (isLotOpenOnAnyWeekendDay() && !hasWeekendPricing()) {
      $window.alert(
        $i18next.t('addProperty.pricing.errorMessages.weekendPricingMissing')
      )
      return false
    }

    return true
  }

  function saveLotPricing () {
    if (
      $scope.lot.setupIsComplete ||
      $scope.lot.advancedRateDefinitionEnabled
    ) {
      return Helper.promise(true)
    }

    if (!confirmSelectedDays()) {
      return Helper.promise(false)
    }

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t('addProperty.loadingMessages.setupDaily')

    var pricing = AvailabilityGenerator.generate(
      $scope.lot,
      $scope.pricingSettings
    )

    return Properties.update({
      id: $scope.lot.id,
      availabilities: pricing
    }).then(
      function (response) {
        $scope.lot = response.data.lot

        // clear the pricing object (which will remove the form from the screen)
        loadPricingSettings()

        return true
      },

      function (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)

        return false
      }
    )
  }

  function saveMonthlyPlan () {
    var promise
    var plan = $scope.planData.plan

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'addProperty.loadingMessages.savingMonthly'
    )

    if (!plan) {
      promise = Helper.promise()
    } else if (plan.id && plan.remove) {
      promise = Plans.delete(plan.id)
    } else {
      const currency = $scope.lot.country.currency

      plan = _.clone(plan)
      plan.lotId = $scope.lot.id
      plan.price = Currencies.transformToInteger(plan.price, currency)

      promise = plan.id ? Plans.update(plan) : Plans.create(plan)
    }

    return promise.then(
      function () {
        $scope.isLoading = false
        return true
      },

      function (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)

        return false
      }
    )
  }

  function submitForm () {
    // Checks if there is any invalid form under $scope.forms
    var hasInvalidForm = _.some($scope.forms, function (form) {
      return form && form.$invalid
    })

    // If there is, we stop here and scroll the user to the first error found.
    if (hasInvalidForm) {
      Helper.scrollToError(true)
      return
    }

    saveLotPricing()
      .then(function (success) {
        if (success) {
          return saveMonthlyPlan()
        }

        return Helper.promise(success)
      })
      .then(function (success) {
        if (success) {
          // Refresh notifications
          if ($scope.canConfigureGates) {
            $scope.goToStep(3)
          } else {
            $state.transitionTo('dashboard')
            StripeConnectedAccounts.loadStripeConnectedAccountsStatusToLoggedUser()
          }
        }
      })
  }

  $scope.goToAdvancedRateEditor = () => {
    $state.go(
      'edit-property-advanced-rates',
      { lotId: $scope.lot.id, advancedRateDefinitionId: 'latest' },
      { enableBackLink: true }
    )
  }

  function init () {
    $scope.forms = {}
    $scope.planData = {}

    $scope.plansTemplateUrl = plansTemplateUrl

    $scope.$on('add-property-step-2-submitted', function () {
      // Defer the function call, waiting for the other listeners to react first.
      _.defer(submitForm)
    })

    $scope.$watch('lot', function () {
      if ($scope.lot) {
        loadPricingSettings()
      }
    })

    $scope.dayNames = mountDayNames() // Monday, Tuesday, Wednesday, Friday, Saturday, Sunday (localized)
    $scope.smallDayNames = mountSmallDayNames() // Mon, Tue, Wed, Fri, Sat, Sun (localized)
    $scope.smallestDayNames = mountSmallestDayNames($scope.dayNames) // M, Tu, W, Th, F, Sa, Su (localized)
  }

  init()
})
