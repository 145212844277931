import React from 'react'
import { useField } from 'formik'
import { sortByOrder } from 'lodash'
import { Icon } from '@citifyd/style'
import classNames from 'classnames'
import moment from 'moment-timezone'

import Table from '../../../../shared/react/components/Table'
import BoxContent from '../../../../shared/react/components/BoxContent'
import ToggleButton from '../../../../shared/react/components/ToggleButton'
import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'
import styles from './MyAccountDevices.module.scss'

const MyAccountDevices = ({
  editUser,
  twoFactorDevices,
  setTwoFactorDevices,
  devicesToRemove
}) => {
  const t = useTranslator()
  const [field, , helpers] = useField('twoFactorAuthenticationEnabled')
  const Sessions = useAngularService('Sessions')
  const Authentication = useAngularService('Authentication')
  const loggedUser = Authentication.getLoggedUser()
  const twoFactorDevicesSorted = sortByOrder(
    twoFactorDevices,
    ['lastRenewalCheckAt'],
    ['desc']
  )

  const twoFactorState = () => {
    if (!editUser) {
      return ''
    }

    return field.value
      ? t('myAccount.twoFactorOn')
      : t('myAccount.twoFactorOff')
  }

  const getClientDescription = session =>
    Sessions.getClientDescription(session, '\n')

  const columns = [
    {
      value: t('myAccount.trusted'),
      key: 'trusted',
      uppercase: false,
      textColor: 'gray_dark'
    },
    {
      value: t('myAccount.lastAccess'),
      nowrap: 'nowrap',
      key: 'lastAccess',
      uppercase: false,
      textColor: 'gray_dark'
    },
    {
      key: 'action'
    }
  ]

  const handleRemoveDevice = id => {
    if (window.confirm(t('myAccount.removeDeviceConfirm'))) {
      setTwoFactorDevices(old => [...old.filter(device => device.id !== id)])
      devicesToRemove.push(id)
    }
  }

  const data = twoFactorDevicesSorted.map(device => {
    return {
      trusted: {
        value: getClientDescription(device),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      lastAccess: {
        value: !device.lastRenewalCheckAt
          ? t('myAccount.unknown')
          : t('defaultFormats.datetime', {
              date: moment.tz(
                device.lastRenewalCheckAt,
                loggedUser.timezoneName
              )
            }),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      action: {
        value: (
          <Icon
            size='h3'
            className={styles.icon}
            appearance='tertiary'
            onClick={() => handleRemoveDevice(device.id)}
            icon='times-circle'
            title={t('myAccount.removeDevice')}
          />
        ),
        verticalAlign: 'middle'
      }
    }
  })

  const hasTwoFactorDevices = () => {
    if (!editUser || !twoFactorDevices) {
      return false
    }

    return !!twoFactorDevices.length && field.value
  }

  const maxHeight = data.length * 60 + 70

  return (
    <BoxContent
      className={classNames(styles.boxContent, {
        [styles.noDevices]: !hasTwoFactorDevices()
      })}
    >
      <BoxContent.Header small title={twoFactorState()}>
        <BoxContent.HeaderActions>
          <ToggleButton
            selected={field?.value}
            onChange={helpers?.setValue}
            customClassName={styles.toggleButton}
            offColor='transparent-white'
            disabled={editUser?.twoFactorAuthenticationRequired}
          />
        </BoxContent.HeaderActions>
      </BoxContent.Header>
      <div
        style={{ maxHeight }}
        className={classNames(styles.boxTable, {
          [styles.hide]: !hasTwoFactorDevices()
        })}
      >
        <BoxContent.Content noHorizontalPadding>
          <Table
            className={styles.table}
            columns={columns}
            data={data}
            spacing
            headerAppearance='white'
            responsive
          />
        </BoxContent.Content>
      </div>
    </BoxContent>
  )
}

export default MyAccountDevices
