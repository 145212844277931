'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('UserPurchaseTicketEventSelectionCtrl', function (
  AssistedPurchases,
  Users,
  $stateParams,
  $scope
) {
  var userId = $stateParams.id

  $scope.toggleSelect = function (item) {
    if (item.soldOut) {
      return
    }

    item.selected = !item.selected

    // cache this value for use in $scope.isEventInSelectedBundle
    $scope.eventIdsFromSelectedBundles = getEventIdsFromSelectedBundles()
    unselectEvents($scope.eventIdsFromSelectedBundles)
  }

  $scope.isEventInSelectedBundle = function (event) {
    return _.includes($scope.eventIdsFromSelectedBundles, event.id)
  }

  $scope.hasSelections = function () {
    return (
      _.some($scope.bundleGroups, 'selected') ||
      _.some($scope.events, 'selected')
    )
  }

  $scope.submitForm = function () {
    var data = {
      selectedBundleGroups: _.filter($scope.bundleGroups, 'selected'),
      selectedEvents: _.filter($scope.events, 'selected')
    }

    $scope.changeStep(2, data)
  }

  function getEventIdsFromSelectedBundles () {
    var selectedBundleGroups = _.filter($scope.bundleGroups, 'selected')

    var eventIds = _(selectedBundleGroups)
      .map(function (bundleGroup) {
        return _.pluck(bundleGroup.bundles, 'eventIds')
      })
      .flattenDeep()
      .uniq()
      .value()

    return eventIds
  }

  function unselectEvents (ids) {
    $scope.events.forEach(function (event) {
      if (_.includes(ids, event.id)) {
        event.selected = false
      }
    })
  }

  function mountBundleGroups (bundles) {
    bundles.forEach(function (bundle) {
      if (!bundle.groupName) {
        bundle.groupName = Math.random()
        bundle.noGroup = true
      }
    })

    var bundlesByGroup = _.groupBy(bundles, 'groupName')

    return _.map(bundlesByGroup, function (bundles, groupName) {
      return {
        name: bundles[0].noGroup ? bundles[0].name : groupName,
        bundles: bundles,
        selected: false
      }
    })
  }

  function parseEvents (events) {
    return events.map(event => {
      event.lots.forEach(lot => {
        const regular = _.find(lot.spaces, { name: 'regular' })
        const max = _.get(regular, 'max', 0)
        const occupied = _.get(regular, 'occupied', 0)

        lot.soldOut = max - occupied === 0
      })

      event.soldOut = _.every(event.lots, 'soldOut')

      return event
    })
  }

  function load () {
    $scope.isLoading = true

    let id
    if ($stateParams.phoneNumber) {
      id = `${userId}-${$stateParams.phoneCountryCode}-${$stateParams.phoneNumber}`
    } else {
      id = userId
    }

    AssistedPurchases.getAvailableEvents(id).then(function (response) {
      $scope.isLoading = false

      $scope.events = parseEvents(response.data.events)
      $scope.bundleGroups = mountBundleGroups(response.data.bundles)
    })
  }

  function initialize () {
    $scope.isLoading = false
    $scope.userId = userId

    load()
  }

  initialize()
})
