'use strict'

import angular from 'angular'

import './reserved-parking-pass-rates.html'
import './reserved-parking-pass-rates.scss'

const app = angular.module('citifydSellerApp')

app.controller('ReservedParkingPassRatesModalCtrl', function (
  $scope,
  $element,
  $timeout,
  Helper,
  params,
  close
) {
  $scope.close = function () {
    $element.remodal().close()
  }

  function initialize () {
    $scope.feeBreakdown = params.feeBreakdown

    // When remodal is closed, we send a close message to the controller
    // that opened this modal.
    $element.on('closed', function (e) {
      close(e.reason)
    })
  }

  initialize()
})
