'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('EventWaitlistCtrl', function (
  Authentication,
  Permissions,
  Events,
  CitifydModal,
  Helper,
  $i18next,
  $scope,
  $timeout,
  $log,
  $state,
  $stateParams
) {
  const eventId = $stateParams.id

  let dataUpdateTimer
  let scopeDestroyed = false

  function fetchEventData () {
    $timeout.cancel(dataUpdateTimer)
    $log.info('Retrieving data for event ' + eventId)

    if (scopeDestroyed) {
      return
    }

    return Events.findMonitoringData(eventId, {
      include_waitlist_entries: true,
      include_tickets: false
    })
      .then(response => {
        const data = response.data

        Helper.setPageTitle('Event: ' + data.name)

        if (data.hash === $scope.lastHash) {
          $log.info("Event data didn't change")
          return
        }

        $scope.loadedInitialData = true
        $scope.waitlistEntries = prepareWaitlistEntries(data.waitlistEntries)

        $scope.lastHash = data.hash
        $log.info('Event data updated')
      })
      .catch(function (err) {
        $log.error('Error retrieving the event data', err)
      })
      .finally(function () {
        dataUpdateTimer = $timeout(fetchEventData, 5000)
      })
  }

  function prepareWaitlistEntries (waitlistEntries) {
    return _.map(waitlistEntries, function (waitlistEntry) {
      return {
        id: waitlistEntry.id,
        userName: waitlistEntry.User.name,
        email: waitlistEntry.User.email,
        phoneNumber: waitlistEntry.User.phoneNumber,
        phoneCountryCode: waitlistEntry.User.phoneCountryCode,
        createdAt: waitlistEntry.createdAt,
        deletedAt: waitlistEntry.deletedAt,
        expiresAt: waitlistEntry.expiresAt,
        activatedAt: waitlistEntry.activatedAt,
        status: calculateWaitlistEntryStatus(waitlistEntry)
      }
    })
  }

  function calculateWaitlistEntryStatus (waitlistEntry) {
    if (waitlistEntry.deletedAt) {
      if (waitlistEntry.TicketId) {
        return $i18next.t('event.waitlistStatuses.purchased')
      }

      if (
        waitlistEntry.expiresAt !== null &&
        moment(waitlistEntry.deletedAt) >= moment(waitlistEntry.expiresAt)
      ) {
        return $i18next.t('event.waitlistStatuses.expired')
      }

      return $i18next.t('event.waitlistStatuses.cancelled')
    }

    if (waitlistEntry.activatedAt) {
      return $i18next.t('event.waitlistStatuses.active')
    }

    return $i18next.t('event.waitlistStatuses.waiting')
  }

  function init () {
    $scope.loadedInitialData = false
    $scope.waitlistEntries = []
    $scope.lastHash = null

    fetchEventData()

    $scope.$on('$destroy', function () {
      scopeDestroyed = true
      $timeout.cancel(dataUpdateTimer)
      $log.info('EventWaitlistCtrl scope destroyed')
    })
  }

  init()
})
