import { useFormik } from 'formik'
import * as yup from 'yup'
import { getAngularService } from '../../../react/utils'
import Mode from '../../../utils/mode'

const useReferralCodeForm = ({ mode, setIsSubmiting, onClose }) => {
  const Helper = getAngularService('Helper')
  const ReferralCodes = getAngularService('ReferralCodes')

  return useFormik({
    initialValues: {
      code: '',
      incentiveAmount: '',
      maxReferrals: ''
    },
    validationSchema: yup.object().shape({
      code: yup.string().required(),
      incentiveAmount: yup.number().required(),
      maxReferrals: yup
        .number()
        .positive()
        .min(0)
    }),
    onSubmit: async values => {
      const data = {
        code: values.code,
        incentiveAmount: parseFloat(values.incentiveAmount) * 100,
        maxReferrals: values.maxReferrals || null
      }
      try {
        setIsSubmiting(true)
        if (mode === Mode.EDIT) {
          await ReferralCodes.update(data.code, data)
        } else {
          await ReferralCodes.create(data)
        }
        onClose({ refreshUsers: true })
      } catch (data) {
        Helper.showErrorAlert(data.data)
      } finally {
        setIsSubmiting(false)
      }
    }
  })
}

export default useReferralCodeForm
