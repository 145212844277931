'use strict'

import React from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import moment from 'moment-timezone'
import { List, NewGrid, Text } from '@citifyd/style'

import GuestBadge from '../GuestBadge'
import UserLink from '../UserLink'
import ReservedParkingStatusBadge from '../ReservedParkingStatusBadge'
import BoxContent from '../BoxContent'
import Address from '../Address'
import PaymentMethod from '../PaymentMethod'

import { calculateReservedParkingStatus } from '../../../utils/status-calculation'
import { useTranslator } from '../../hooks'
import { getCurrentLanguage } from '../../utils'
import styles from './ReservedParkingFullInformation.module.scss'

function ReservedParkingPassTransactionBox ({ reservedParkingPass }) {
  const t = useTranslator()

  return (
    <BoxContent>
      <BoxContent.Header
        title={t('reservedParkingPage.transactionBox.transactionNumber', {
          transactionNumber: reservedParkingPass.transactionNumber
        })}
      />
      <BoxContent.Content>
        <div className={styles.transactionHeader}>
          <Text component='span' variant='h3' weight='700'>
            {reservedParkingPass.lot.name}
          </Text>
          <ReservedParkingStatusBadge
            status={calculateReservedParkingStatus(reservedParkingPass)}
          />
        </div>
        <NewGrid.Row>
          <NewGrid.Col sm={12} md={6}>
            <List border fullWidth className={styles.list}>
              <List.Divisor />
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.purchasedBy')}
                </Text>
                {reservedParkingPass.user &&
                !reservedParkingPass.user?.isGuest ? (
                  <UserLink
                    appearance='tertiary'
                    underline={false}
                    user={reservedParkingPass.user}
                    routerOptions={{ enableBackLink: true }}
                  ></UserLink>
                ) : (
                  <GuestBadge className={styles.guestBadge} />
                )}
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.vehiclePlate')}
                </Text>
                <span>
                  {reservedParkingPass.vehicle
                    ? reservedParkingPass.vehicle.license
                    : '—'}
                </span>
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.startTime')}
                </Text>
                <span>
                  {t('defaultFormats.datetimeWithTimezone', {
                    date: moment.tz(
                      reservedParkingPass.startTime,
                      reservedParkingPass.lot.timezoneName
                    )
                  })}
                </span>
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.endTime')}
                </Text>
                <span>
                  {t('defaultFormats.datetimeWithTimezone', {
                    date: moment.tz(
                      reservedParkingPass.endTime ||
                        reservedParkingPass.expirationTime,
                      reservedParkingPass.lot.timezoneName
                    )
                  })}
                </span>
              </List.Item>
              <List.Item justifyContent='space-between' alignItems='flex-start'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.lotAddress')}
                </Text>
                <Address
                  className={styles.alignRight}
                  entity={reservedParkingPass.lot}
                  addressFieldName='rawAddress'
                  format='full'
                />
              </List.Item>
            </List>
          </NewGrid.Col>
          <NewGrid.Col sm={12} md={6}>
            <List border fullWidth className={styles.list}>
              <List.Divisor />
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.paymentStatus.label')}
                </Text>
                {t(
                  'reservedParkingPage.transactionBox.paymentStatus.values.completed'
                )}
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.amount')}
                </Text>
                <ReservedParkingPassAmount
                  reservedParkingPass={reservedParkingPass}
                />
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.paymentMethod')}
                </Text>
                <span>
                  <PaymentMethod card={reservedParkingPass.card} />
                </span>
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservedParkingPage.transactionBox.lastNameOnCard')}
                </Text>
                <CardLastName card={reservedParkingPass.card} />
              </List.Item>
            </List>
          </NewGrid.Col>
        </NewGrid.Row>
      </BoxContent.Content>
    </BoxContent>
  )
}

function CardLastName ({ card }) {
  const cardName = card && card.name
  return (
    (cardName || '')
      .trim()
      .split(' ')
      .reverse()[0] || '—'
  )
}

function ReservedParkingPassAmount ({ reservedParkingPass }) {
  const language = getCurrentLanguage()
  return CurrencyFormatter.format(reservedParkingPass.calculatedPrice?.total, {
    currency: reservedParkingPass.currency,
    language
  })
}

export default ReservedParkingPassTransactionBox
