'use strict'

import angular from 'angular'
import _ from 'lodash'

import './edit-payment.html'
import './edit-payment.scss'

const app = angular.module('citifydSellerApp')

app.controller('EditPaymentModalCtrl', function (
  Authentication,
  StripeConnectedAccounts,
  Settings,
  Helper,
  Stripe,
  $state,
  $scope,
  $element,
  $timeout,
  $q,
  $log,
  $window,
  $i18next,
  close
) {
  let stripeInstance

  $scope.save = function () {
    let bankAccountToken = null
    let promise = Helper.promise()

    $scope.$broadcast('show-errors-check-validity')

    if (!validate()) {
      return false
    }

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t('modalEditPayment.savingInformation')

    if (changedBankInfo()) {
      const countryCode = $scope.country.isoCode
      const currency = $scope.country.currency

      $log.info(`Got it, country = ${countryCode}, currency = ${currency}`)
      $log.info('Sending data to Stripe...')

      const tokenData = {
        country: countryCode.toUpperCase(),
        currency: currency.toUpperCase(),
        account_number: $scope.bankAccount.accountNumber,
        account_holder_type: $scope.bankAccount.accountHolderType,
        account_holder_name: $scope.bankAccount.accountHolderName
      }

      if ($scope.bankAccount.routingNumber) {
        tokenData.routing_number = $scope.bankAccount.routingNumber
      }

      promise = stripeInstance
        .createToken('bank_account', tokenData)
        .then(result => {
          if (result.error) {
            throw result.error
          }

          bankAccountToken = result.token.id
        })
        .catch(err => {
          $log.info('Stripe create bank account token error', err)

          if (err.code === 'account_number_invalid') {
            throw new Error(
              $i18next.t('modalEditPayment.errors.invalidAccountNumber')
            )
          }

          if (err.code === 'routing_number_invalid') {
            throw new Error(
              $i18next.t('modalEditPayment.errors.invalidRoutingNumber')
            )
          }

          if (err.message.match(/must only contain katakana/i)) {
            throw new Error(
              $i18next.t('modalEditPayment.errors.katakanaOnlyRequired')
            )
          }

          throw err
        })
    }

    $log.info('Sending data to API...')

    promise
      .then(function () {
        if (!$scope.stripeConnectedAccount) {
          return StripeConnectedAccounts.createStripeConnectedAccount({
            bankAccountToken,
            countryCode: $scope.country.isoCode
          })
        } else if (bankAccountToken) {
          return StripeConnectedAccounts.updateStripeConnectedAccount(
            $scope.stripeConnectedAccount.stripeAccountId,
            { bankAccountToken }
          )
        }
      })
      .then(stripeConnectedAccount => {
        if (shouldRedirectToStripeAccountVerification(stripeConnectedAccount)) {
          StripeConnectedAccounts.clearStripeConnectedAccountsStatusFromLoggedUser()
          $element.remodal().close({ action: 'saved_and_redirecting' })
          $state.go('stripe-account-setup')
        } else {
          $scope.isLoading = false
          $scope.loadingMessage = null
          $element.remodal().close({ action: 'saved' })
          StripeConnectedAccounts.clearStripeConnectedAccountsStatusFromLoggedUser()
        }
      })
      .catch(function (err) {
        $scope.isLoading = false
        $scope.loadingMessage = null

        if (err.message) {
          Helper.showAlert(err.message)
        } else {
          Helper.showErrorAlert(err.data)
        }
      })
  }

  $scope.cancel = function () {
    $element.remodal().close({ action: 'cancel' })
  }

  $scope.setAccountHolderType = function (accountHolderType) {
    $scope.bankAccount.accountHolderType = accountHolderType
  }

  function shouldRedirectToStripeAccountVerification (stripeConnectedAccount) {
    // If this is the first bank account created, we should always redirect the user to the Stripe verification
    if (!$scope.previousBankAccount) {
      return true
    }

    // Otherwise, only redirect if Stripe says the account has currently due requirements
    return (
      stripeConnectedAccount &&
      stripeConnectedAccount.hasCurrentlyDueRequirements
    )
  }

  function setFieldLabelsAndRules () {
    $scope.modalTitle = getModalTitle()
    $scope.accountNumberLabel = getAccountNumberLabel()
    $scope.routingNumberLabel = getRoutingNumberLabel()
    $scope.isRountingNumberRequired = isRountingNumberRequired()
  }

  function getAccountNumberLabel () {
    let continent = _.get($scope.country, 'continent')
    if (continent === 'europe') {
      return $i18next.t('modalEditPayment.commonFields.accountNumberIBAN')
    } else {
      return $i18next.t('modalEditPayment.commonFields.accountNumber')
    }
  }

  function getRoutingNumberLabel () {
    let continent = _.get($scope.country, 'continent')
    if (continent === 'europe') {
      return $i18next.t('modalEditPayment.commonFields.routingNumberOptional')
    } else {
      return $i18next.t('modalEditPayment.commonFields.routingNumber')
    }
  }

  function getModalTitle () {
    if ($scope.previousBankAccount) {
      return $i18next.t('modalEditPayment.edit')
    } else {
      return $i18next.t('modalEditPayment.setUp')
    }
  }

  function isRountingNumberRequired () {
    let continent = _.get($scope.country, 'continent')
    return continent !== 'europe'
  }

  function changedBankInfo () {
    if (!$scope.previousBankAccount) {
      return true
    }

    return _.some(
      $scope.bankAccount,
      (value, key) => _.trim(value) !== _.trim($scope.previousBankAccount[key]) // use _.trim instead of String#trim to handle null values
    )
  }

  function validate () {
    const { bankAccount } = $scope

    if (!$scope.paymentDataForm.$valid) {
      return false
    }

    if (changedBankInfo()) {
      if (isRountingNumberRequired() && !bankAccount.routingNumber) {
        $window.alert(
          $i18next.t('modalEditPayment.errors.invalidRoutingNumber')
        )
        return false
      }

      if (bankAccount.accountNumber.indexOf('*') !== -1) {
        $window.alert(
          $i18next.t('modalEditPayment.errors.reenterAccountNumber')
        )
        return false
      }
    }

    return true
  }

  function setBankAccount (bankAccount) {
    if (bankAccount) {
      $scope.bankAccount = {
        routingNumber: bankAccount.routingNumber,
        accountNumber: '********' + bankAccount.last4,
        accountHolderName: bankAccount.accountHolderName,
        accountHolderType: bankAccount.accountHolderType
      }

      $scope.previousBankAccount = _.cloneDeep($scope.bankAccount)
    } else {
      $scope.bankAccount = {
        accountNumber: '',
        routingNumber: '',
        accountHolderType: 'individual',
        accountHolderName: ''
      }

      $scope.previousBankAccount = null
    }
  }

  function preloadCountry () {
    let countryCode

    if ($scope.stripeConnectedAccount) {
      countryCode = $scope.stripeConnectedAccount.countryCode
    } else {
      let loggedUser = Authentication.getLoggedUser()
      countryCode = loggedUser.organization.countryCode
    }

    return Settings.getDetailedCountryData(countryCode).then(country => {
      $scope.country = country
    })
  }

  function preloadStripeConnectedAccount () {
    return StripeConnectedAccounts.getStripeConnectedAccounts()
      .then(response => {
        const account = response.data.stripeConnectedAccounts[0]
        if (account) {
          return StripeConnectedAccounts.getStripeConnectedAccount(
            account.stripeAccountId
          ).then(response => response.data.stripeConnectedAccount)
        } else {
          return null
        }
      })
      .then(stripeConnectedAccount => {
        $scope.stripeConnectedAccount = stripeConnectedAccount
      })
  }

  function preload () {
    $scope.country = null
    $scope.stripeConnectedAccount = null
    $scope.bankAccount = null
    $scope.previousBankAccount = null
    $scope.modalTitle = getModalTitle()

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'modalEditPayment.loadingPaymentInformation'
    )

    Stripe.load()
      .then(stripe => {
        stripeInstance = stripe
      })
      .then(preloadStripeConnectedAccount)
      .then(preloadCountry)
      .then(() => {
        setBankAccount(_.get($scope.stripeConnectedAccount, 'bankAccount'))
        setFieldLabelsAndRules()
      })
      .then(() => {
        $scope.isLoading = false
        $scope.loadingMessage = null
      })
      .catch(err => {
        $log.log('Error on preload', err)
        $timeout($scope.cancel, 100)
        Helper.showErrorAlert(err.data)
      })
  }

  function initialize () {
    preload()

    // When remodal is closed, we send a close message to the controller
    // that opened this modal.
    $element.on('closed', function (e) {
      close(e.reason)
    })
  }

  initialize()
})
