'use strict'

import React from 'react'
import { Select, NewGrid } from '@citifyd/style'
import { useTranslator } from '../../../shared/react/hooks'

const AllPropertiesSelect = ({ disabled, properties, onChange, selected }) => {
  const t = useTranslator()

  return (
    <NewGrid.Row>
      <NewGrid.Col xs={12} md={4}>
        <Select
          value={selected}
          disabled={disabled}
          onChange={e => onChange(e)}
          labelSize='small'
          fullWidth
          label={t('revenue.revenueFilter.fields.property')}
          options={properties}
        />
      </NewGrid.Col>
    </NewGrid.Row>
  )
}

export default AllPropertiesSelect
