'use strict'

import angular from 'angular'
import templateUrl from './login.html'

import './login.scss'

import authenticationStepTemplateUrl from './views/authentication-step.html'
import twoFactorStepTemplateUrl from './views/two-factor.html'
import parkingAppTemplateUrl from './views/parking-app.html'
import VerifyPhoneNumberModal from '../../shared/modals/VerifyPhoneNumberModal'

const app = angular.module('citifydSellerApp')

app.controller('LoginCtrl', function (
  $rootScope,
  $scope,
  $location,
  $timeout,
  Authentication,
  StripeConnectedAccounts,
  Intercom,
  CitifydModal,
  Languages
) {
  $scope.step = null
  $scope.authenticationStepTemplateUrl = authenticationStepTemplateUrl
  $scope.twoFactorStepTemplateUrl = twoFactorStepTemplateUrl
  $scope.parkingAppTemplateUrl = parkingAppTemplateUrl

  $scope.currentLanguage = Languages.getCurrent()

  function showPasswordChangeSuggestion () {
    $rootScope.passwordChangeSuggestionDisplayed = true
  }

  function showPasswordChangeRequirementModal () {
    CitifydModal.open('change-password', {
      changeRequired: true,
      closeOnEscape: false
    })
  }

  function showVerifyPhoneNumberModal (user) {
    CitifydModal.openReactModal(VerifyPhoneNumberModal, {
      user: user,
      logoutLink: true,
      closeOnEscape: false
    })
  }

  function redirectToSource () {
    var redirectTo = $location.search().source || '/'
    $location
      .search('')
      .url(decodeURIComponent(redirectTo))
      .replace()
  }

  $scope.changeStep = function (step, data) {
    $scope.step = step
    $timeout(() => $scope.$broadcast('step-changed', data), 50)
  }

  $scope.handleSuccessfulLogin = function () {
    var user = Authentication.getLoggedUser()

    if (!user.verifiedPhoneNumber) {
      showVerifyPhoneNumberModal(user)
    } else if (user.passwordChangeRequired) {
      showPasswordChangeRequirementModal()
    } else if (user.passwordChangeSuggested) {
      showPasswordChangeSuggestion()
    }

    const source = $location.search().source
    if (!source || source.indexOf('stripe-account-setup') === -1) {
      StripeConnectedAccounts.loadStripeConnectedAccountsStatusToLoggedUser()
    }

    Intercom.saveUserData(user)

    redirectToSource()
  }

  function verifyAuthentication () {
    const token = $location.search().token

    const notLoggedIn = () => {
      $scope.changeStep('authentication')
    }

    if (token) {
      Authentication.setToken(token).then(
        $scope.handleSuccessfulLogin,
        notLoggedIn
      )
    } else {
      Authentication.verifyAuthentication(
        $scope.handleSuccessfulLogin,
        notLoggedIn
      )
    }
  }

  verifyAuthentication()
})

export const controller = 'LoginCtrl'
export const auth = false
export const title = 'i18n:login.login'
export { templateUrl }
