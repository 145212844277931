import React from 'react'
import { forEach, forEachRight, max as _max } from 'lodash'
import { Text } from '@citifyd/style'
import classNames from 'classnames'

import styles from './UsersStats.module.scss'

const adjustPercentage = n => {
  return n > 0 && n < 1 ? 1 : Math.round(n)
}

const generateChartValue = (previousValue, portion, total) => {
  let percentage = total > 0 ? adjustPercentage((portion / total) * 100) : 0
  let value = previousValue + percentage
  return value + (value === 100 ? 1 : 0)
}

const fillChart = values => {
  let max = _max(values)

  forEach(values, function (value, index) {
    if (value === max && max !== 0) {
      // we use 101 instead of 100 otherwise the chart won't be filled
      values[index] = 101
    }
  })
}

const generateChartStyleObject = value => {
  return { strokeDasharray: value + ' 100' }
}

export const calculateChartStyles = (list, keys, total, shouldFillChart) => {
  let chartValues = {}
  let chartStyles = {}
  let previousKey = null

  keys.forEach(function (key) {
    let previousChartValue = previousKey ? chartValues[previousKey] : 0

    chartValues[key] = generateChartValue(previousChartValue, list[key], total)

    previousKey = key
  })

  if (shouldFillChart) {
    fillChart(chartValues)
  }

  forEachRight(chartValues, function (value, key) {
    chartStyles[key] = generateChartStyleObject(value)
  })

  return chartStyles
}

const formatNumber = n => (n ? n.toLocaleString() : '0')

const getLegend = (t, label) => (
  <span className={styles.boxLegend}>
    <span className={classNames(styles.circle, styles[label])}></span>
    {t(`users.${label}`)}
  </span>
)

export const generateData = (values, t) => {
  const columnsTotal = [
    {
      value: (
        <Text variant='subtitle' weight='600'>
          {t('users.numberPasses')}
        </Text>
      ),
      uppercase: false,
      key: 'numberPasses'
    },
    {
      value: (
        <Text variant='subtitle' weight='600' textAlign='right'>
          {formatNumber(values.total)}
        </Text>
      ),
      key: 'total'
    }
  ]

  const dataTotal = [
    {
      numberPasses: getLegend(t, 'purchased'),
      total: {
        value: formatNumber(values.purchased),
        textAlign: 'right'
      }
    },
    {
      numberPasses: getLegend(t, 'parked'),
      total: {
        value: formatNumber(values.parked),
        textAlign: 'right'
      }
    },
    {
      numberPasses: getLegend(t, 'cancelled'),
      total: {
        value: formatNumber(values.cancelled),
        textAlign: 'right'
      }
    },
    {
      numberPasses: getLegend(t, 'expired'),
      total: {
        value: formatNumber(values.expired),
        textAlign: 'right'
      }
    }
  ]

  const columnsTransferred = [
    {
      value: <Text bold>{t('users.purchasesTransferred')}</Text>,
      uppercase: false,
      key: 'purchasesTransferred'
    },
    {
      value: (
        <Text bold textAlign='right'>
          {formatNumber(values.transferred)}
        </Text>
      ),
      key: 'transferred'
    }
  ]

  const dataTransferred = [
    {
      purchasesTransferred: getLegend(t, 'redeemed'),
      transferred: {
        value: formatNumber(values.redeemed),
        textAlign: 'right'
      }
    },
    {
      purchasesTransferred: getLegend(t, 'pendingTransfers'),
      transferred: {
        value: formatNumber(values.pendingTransfer),
        textAlign: 'right'
      }
    }
  ]

  return {
    columnsTotal,
    columnsTransferred,
    dataTotal: dataTotal.map(cell => ({
      ...cell,
      borderBottom: true
    })),
    dataTransferred: dataTransferred.map(cell => ({
      ...cell,
      borderBottom: true
    }))
  }
}
