'use strict'

import angular from 'angular'

import '../services/authentication'
import '../services/citifyd-modal'
import '../services/users'
import '../services/permissions'
import '../services/properties'

const app = angular.module('citifydSellerApp')

app.controller('NavigationCtrl', function (
  $rootScope,
  $document,
  $location,
  $route,
  $i18next,
  $stateParams,
  $window,
  Authentication,
  Intercom,
  Users,
  $scope,
  CitifydModal,
  Properties,
  Permissions,
  ENV
) {
  var controller = this

  controller.openEditPaymentModal = function () {
    CitifydModal.open('edit-payment')
  }

  controller.openChangePasswordModal = () => {
    $rootScope.passwordChangeSuggestionDisplayed = false
    CitifydModal.open('change-password')
  }

  controller.dismissPasswordChangeSuggestion = () => {
    $rootScope.passwordChangeSuggestionDisplayed = false
  }

  controller.openLiveApp = () => {
    var url = ENV.liveAppUrl.replace(/\/$/, '')
    $window.open(
      `${url}/#!/user/login?token=${Authentication.getAccessToken()}`
    )
  }

  controller.openLimeDashboard = () => {
    var url = ENV.limeDashboardUrl.replace(/\/$/, '')
    $window.open(`${url}/signin?token=${Authentication.getAccessToken()}`)
  }

  controller.openPosDashboard = () => {
    var url = ENV.posDashboardUrl.replace(/\/$/, '')
    $window.open(
      `${url}/signin?token=${Authentication.getAccessToken()}&pos=true`
    )
  }

  controller.isInRoute = routeName => {
    return controller.route === routeName
  }

  controller.userMayChangePaymentInformation = () => {
    return Permissions.userMayChangePaymentInformation()
  }

  controller.userMayAppendLotToEvent = () => {
    return Permissions.userMayAppendLotToEvent()
  }

  controller.userMaySeeAllProperties = () => {
    return Permissions.userMaySeeAllProperties()
  }

  controller.userMayAccessRevenue = () => {
    return Permissions.userMayAccessRevenue()
  }

  controller.userMayAccessSchedule = () => {
    return Permissions.userMayAccessSchedule()
  }

  controller.userMayCreateEvent = () => {
    return Permissions.userMayCreateEvent()
  }

  controller.userMayAccessLimeDashboard = () => {
    return Permissions.userMayAccessLimeDashboard()
  }

  controller.userMayAccessPosDashboard = () => {
    return Permissions.userMayAccessPosDashboard()
  }

  controller.logout = () => {
    if (!$window.confirm($i18next.t('header.userNav.areYouSure'))) {
      return
    }

    Authentication.logout()
    Intercom.logout()
    $window.location = '/'
  }

  $rootScope.$on('$stateChangeSuccess', () => {
    controller.route = $location.path().substr(1)
  })

  // hide notifications bar on page change
  $rootScope.$on('$stateChangeStart', () => {
    $scope.showNotifications = false
  })

  $scope.$watch('showHeaderDropdown', () => {
    if ($scope.showHeaderDropdown) {
      $scope.$emit('citifyd-dropdown:open', 'header-navigation')
    }
  })

  // close this dropdown if any other dropdown on page is open
  $rootScope.$on('citifyd-dropdown:open', (e, dropdownId) => {
    if (dropdownId !== 'header-navigation') {
      $scope.showHeaderDropdown = false
    }
  })

  angular.element($document[0].body).on('click', () => {
    $scope.showHeaderDropdown = false
    $scope.$digest()
  })
})
