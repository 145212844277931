import { useState } from 'react'

import { useAngularService } from '../../../../shared/react/hooks'
import { transformSubscriptions } from './utils'

const useSubscriptions = () => {
  const { id } = useAngularService('$stateParams')
  const Users = useAngularService('Users')
  const [subscriptions, setSubscriptions] = useState(null)
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false)
  const [subscriptionsError, setSubscriptionsError] = useState(false)

  const fetchSubscriptions = async () => {
    try {
      const response = await Users.fetchSubscriptions(id)
      setSubscriptions(transformSubscriptions(response.data.subscriptions))
    } catch (err) {
      setSubscriptionsError(true)
    } finally {
      setSubscriptionsLoaded(true)
    }
  }

  return {
    fetchSubscriptions,
    subscriptions,
    subscriptionsLoaded,
    subscriptionsError
  }
}

export default useSubscriptions
