'use strict'

import React from 'react'
import { Text, Button } from '@citifyd/style'

import { useDispatch, useSelector } from 'react-redux'
import { focusOnLastRule } from '../redux/thunk-actions'
import { addRule, sortRules } from '../redux/actions'

import NonEditableTable from './NonEditableTable.jsx'
import EditableTable from './EditableTable.jsx'
import { useTranslator } from '../../../shared/react/hooks'

import styles from '../styles/Page.module.scss'

export default function RulesTable () {
  const t = useTranslator()

  const dispatch = useDispatch()
  const hasRules = useSelector(state => state.editableRules.length > 0)
  const mode = useSelector(state => state.mode)

  const addRuleClicked = () => {
    dispatch(addRule())
    dispatch(focusOnLastRule())
  }

  const tableHeaderProps = {
    sortable: true,
    uppercase: false,
    textColor: 'gray_dark',
    orders: useSelector(state => state.rulesOrder),
    onClick: (event, key) => dispatch(sortRules({ event, propertyName: key }))
  }

  const columns = [
    {
      value: t('advancedRateEditor.fields.id.headerLabel'),
      sortableKey: 'id',
      key: 'id',
      ...tableHeaderProps
    },
    {
      value: t('advancedRateEditor.fields.name.headerLabel'),
      sortableKey: 'name',
      key: 'name',
      ...tableHeaderProps
    },
    {
      value: t('advancedRateEditor.fields.price.headerLabel'),
      sortableKey: 'price',
      width: '9%',
      key: 'price',
      ...tableHeaderProps
    },
    {
      value: t('advancedRateEditor.fields.end.headerLabel.type'),
      sortableKey: 'end',
      width: '10%',
      key: 'type',
      ...tableHeaderProps
    },
    {
      value: t('advancedRateEditor.fields.end.headerLabel.value'),
      sortableKey: 'end',
      key: 'end',
      ...tableHeaderProps
    },
    {
      value: t('advancedRateEditor.fields.time.headerLabel'),
      sortableKey: 'time',
      key: 'time',
      ...tableHeaderProps
    },
    {
      value: t('advancedRateEditor.fields.days.headerLabel'),
      sortableKey: 'days',
      key: 'days',
      ...tableHeaderProps
    },
    {
      key: 'actions'
    }
  ]

  return (
    <div>
      {hasRules && mode === 'view' && <NonEditableTable columns={columns} />}

      {hasRules && mode === 'edit' && <EditableTable columns={columns} />}

      {!hasRules && mode === 'edit' && (
        <div className={styles.noRules}>
          <Text textAlign='center' appearance='gray_dark' variant='subtitle'>
            {t('advancedRateEditor.noRules.addRate')}
          </Text>
        </div>
      )}

      {!hasRules && mode === 'view' && (
        <div className={styles.noRules}>
          <Text textAlign='center' appearance='gray_dark' variant='subtitle'>
            {t('advancedRateEditor.noRules.noRates')}
          </Text>
        </div>
      )}

      {mode === 'edit' && (
        <div className={styles.addRateBox}>
          <Button
            size='tiny'
            letterSpacing
            className={styles.addRateButton}
            shadow
            uppercase
            onClick={addRuleClicked}
          >
            {t('advancedRateEditor.buttons.addRate')}
          </Button>
        </div>
      )}
    </div>
  )
}
