'use strict'

import React, { useRef, useEffect } from 'react'
import styles from './ImageUpload.module.scss'
import { Icon } from '@citifyd/style'

import imageNotSelected from '../../../../../images/file-button.png'

const ImageUpload = ({
  inputRef,
  currentImage,
  onChange,
  errorImage,
  touchedImage
}) => {
  const previewImageRef = useRef()

  const resetPreviewImage = () => {
    if (previewImageRef.current) {
      previewImageRef.current.src = currentImage ?? imageNotSelected
    }
  }

  useEffect(() => {
    resetPreviewImage()
  }, [previewImageRef.current, currentImage])

  const handleChange = e => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader()
      reader.onload = function (e) {
        previewImageRef.current.src = e.target.result
      }

      reader.readAsDataURL(e.target.files[0])
      onChange({ hasImage: true })
    } else {
      resetPreviewImage()
      onChange({ hasImage: false })
    }
  }

  return (
    <>
      <form>
        <div
          className={`${styles.selectImage} ${
            errorImage && touchedImage ? styles.error : ''
          }`}
        >
          <div className={styles.icon}>
            <Icon icon='plus' size='h6' />
          </div>

          <img className={styles.image} ref={previewImageRef} />
          <input
            ref={inputRef}
            type='file'
            name='file'
            className={styles.input}
            onChange={e => handleChange(e)}
          />
        </div>
      </form>
    </>
  )
}

export default ImageUpload
