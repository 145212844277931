import { useFormik } from 'formik'
import * as yup from 'yup'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { getAngularService } from '../../../../react/utils'
import { countryPostalCodeProperty } from '../../functions/functions'

const useCardForm = ({
  trackLoading,
  untrackLoading,
  userId,
  onClose,
  hasStripeError
}) => {
  const stripe = useStripe()
  const elements = useElements()

  return useFormik({
    initialValues: {
      name: '',
      cardCountryCode: 'us',
      zipCode: '',
      selectedCountryData: null
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(),
      zipCode: yup
        .string()
        .required()
        .when('selectedCountryData', (selectedCountryData, schema) => {
          const minLength = countryPostalCodeProperty(
            selectedCountryData,
            'minLength'
          )
          const maxLength = countryPostalCodeProperty(
            selectedCountryData,
            'maxLength'
          )

          if (minLength) schema = schema.min(minLength)
          if (maxLength) schema = schema.max(maxLength)

          return schema
        })
    }),

    onSubmit: async values => {
      if (hasStripeError) {
        return
      }

      const Helper = getAngularService('Helper')
      const Cards = getAngularService('Cards')

      trackLoading('addCard')

      try {
        const cardElement = elements.getElement(CardElement)

        const { error: stripeError, token } = await stripe.createToken(
          cardElement,
          {
            data: {
              name: values.name,
              address_zip: values.zipCode,
              address_country: values.cardCountryCode
            }
          }
        )

        if (stripeError) {
          stripeError.data = { error: { message: stripeError.message } }
          throw stripeError
        }

        const response = await Cards.create(userId, { cardToken: token.id })

        onClose({
          action: 'added',
          card: response.data.card
        })
      } catch (response) {
        Helper.showErrorAlert(response.data)
      } finally {
        untrackLoading('addCard')
      }
    }
  })
}

export default useCardForm
