'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.directive('ctMenuItem', function ($location) {
  return {
    restrict: 'A',

    scope: {
      ctMenuItem: '@'
    },

    link: function (scope, element) {
      scope.location = $location

      var regex = new RegExp('^/' + (scope.ctMenuItem || '') + '$')

      scope.$watch('location.path()', function (currentPath) {
        element.toggleClass('active', regex.test(currentPath))
      })
    }
  }
})
