import { get } from 'lodash'

export const getVehicleLicense = vehicle => {
  if (vehicle?.license) {
    const countryCode = get(vehicle, 'country.vehiclePlateCode')

    if (countryCode) {
      return countryCode + ' ' + vehicle.license
    } else {
      return vehicle.license
    }
  }

  return ''
}
