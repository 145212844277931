'use strict'

import angular from 'angular'
import { react2angular } from 'react2angular'
import PasswordRulesIndicator from '../../react/components/PasswordRulesIndicator'

const app = angular.module('citifydSellerApp')

app.component(
  'ctPasswordRulesIndicator',
  react2angular(PasswordRulesIndicator, ['password', 'rules'])
)
