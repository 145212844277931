'use strict'

import angular from 'angular'
import templateUrl from './number-picker.html'

const app = angular.module('citifydSellerApp')

app.directive('ctNumberPicker', function () {
  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {
      name: '@',
      disabled: '=',

      // Here we specify the scope value we will sync the quantity specified by the user
      quantity: '=',

      // We can specify another scope value that cannot pass the maximum value when summed
      // with our quantity value
      sumWith: '=',

      // The minimum value allowed for the quantity
      min: '@',

      // The maximum value allowed for the quantity
      max: '@'
    },

    controller: [
      '$scope',
      function ($scope) {
        if ($scope.quantity === undefined) {
          $scope.quantity = $scope.min
        }
      }
    ],

    link: function (scope, element, attrs) {
      // The user may specify a "let-take-out" parameter. When this is set along with
      // "sum-with", we can always take out a number from the "sum-with" field.
      var letTakeOut = 'letTakeOut' in attrs

      element.find('[ng-model=quantity]').blur(function () {
        if (scope.disabled) {
          return
        }

        scope.quantity = parseInt(scope.quantity, 10)

        // Do not allow the user to type a invalid value or a number lower than the minimum value
        if (isNaN(scope.quantity) || getTotal() < scope.min) {
          scope.quantity = scope.min
          scope.$apply()
          return
        }

        if (isFull()) {
          if (scope.sumWith === undefined) {
            scope.quantity = scope.max
          } else if (letTakeOut) {
            scope.quantity = scope.max - scope.min
            scope.sumWith = scope.min
          } else {
            scope.quantity = scope.max - scope.sumWith
          }

          scope.$apply()
        }
      })

      // Click on minus icon
      var $minus = element.find('[data-type=minus]').click(function () {
        if (scope.disabled || !canDecrease()) {
          return
        }

        --scope.quantity
        scope.$apply()
      })

      // Click on plus icon
      var $plus = element.find('[data-type=plus]').click(function () {
        if (scope.disabled || !canIncrease()) {
          return
        }

        if (isFull() && letTakeOut) {
          --scope.sumWith
        }

        ++scope.quantity
        scope.$apply()
      })

      // Checks if the quantity is full
      function isFull () {
        return getTotal() >= scope.max
      }

      // Checks if it is allowed to increase the current field's quantity
      function canIncrease () {
        return (
          !isFull() ||
          (letTakeOut && scope.quantity < scope.max && scope.sumWith > 0)
        )
      }

      // Checks if it is allowed to decrease the current field's quantity
      function canDecrease () {
        return scope.quantity > 0 && getTotal() > scope.min
      }

      // Gets the total quantity, that is quantity + sumWith if sumWith is specified.
      function getTotal () {
        return scope.sumWith !== undefined
          ? parseInt(scope.quantity, 10) + parseInt(scope.sumWith, 10)
          : parseInt(scope.quantity, 10)
      }

      // Update the state of the buttons
      function updateButtons () {
        $minus.prop('disabled', !canDecrease())
        $plus.prop('disabled', !canIncrease())
      }

      updateButtons()
      scope.$watchGroup(['quantity', 'sumWith'], updateButtons)
    }
  }
})
