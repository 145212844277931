import React from 'react'
import { Field, useField } from 'formik'
import { Checkbox, Input, Textarea, Label, Select } from '@citifyd/style'
import CurrencyFormatter from '@citifyd/currency-formatter'
import NumberFormat from 'react-number-format'
import { omit } from 'lodash'

import FormikDatePicker from '../FormikDatePicker'
import { getCurrentLanguage } from '../../utils'

export const FIELD_TYPE = {
  TEXTAREA: 'textarea',
  INPUT: 'input',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  DATEPICKER: 'datepicker',
  CURRENCY: 'currency',
  CUSTOM: 'custom'
}

const FormikField = (
  {
    name,
    label,
    placeholder,
    as,
    type = 'text',
    description,
    info,
    currency,
    handleBlur,
    handleChange,
    customComponent,
    isMultiple,
    requireTouchForError = true,
    hasError: hasErrorProp,
    ...props
  },
  ref
) => {
  const renderLabel = hasError => {
    if (label && as !== FIELD_TYPE.CHECKBOX) {
      return <Label error={hasError}>{label}</Label>
    }

    return null
  }

  const renderField = (field, hasError, errorMessage, form) => {
    const { onBlur, onChange, ...rest } = field

    const fieldBlur = e => {
      onBlur(e)
      handleBlur && handleBlur()
    }

    const fieldChange = e => {
      if (as === FIELD_TYPE.SELECT) {
        form.setFieldValue(
          field.name,
          isMultiple ? e.map(item => item.value) : e.target?.value
        )
      } else {
        onChange(e)
      }
      handleChange && handleChange(e)
    }

    switch (as) {
      case FIELD_TYPE.TEXTAREA:
        return (
          <Textarea
            fullWidth
            placeholder={placeholder}
            info={info}
            error={hasError}
            onChange={fieldChange}
            onBlur={fieldBlur}
            ref={ref}
            {...rest}
            {...props}
          />
        )
      case FIELD_TYPE.SELECT:
        return (
          <Select
            fullWidth
            placeholder={placeholder}
            info={info}
            error={hasError}
            onChange={fieldChange}
            onBlur={fieldBlur}
            ref={ref}
            {...rest}
            {...props}
          />
        )
      case FIELD_TYPE.CHECKBOX:
        return (
          <Checkbox
            label={label}
            checked={field.value}
            placeholder={placeholder}
            error={hasError}
            onChange={fieldChange}
            ref={ref}
            {...rest}
            {...props}
          />
        )
      case FIELD_TYPE.DATEPICKER:
        return (
          <FormikDatePicker
            // locale={language}
            // errorMessage={t(errors['dateRange.end'])}
            error={hasError}
            errorMessage={errorMessage}
            className='disable-focus'
            onChange={handleChange}
            onBlur={fieldBlur}
            ref={ref}
            {...rest}
            {...props}
          />
        )
      case FIELD_TYPE.CURRENCY:
        const language = getCurrentLanguage()
        return (
          <NumberFormat
            fullWidth
            customInput={Input}
            prefix={CurrencyFormatter.getPrefix({ currency, language })}
            suffix={CurrencyFormatter.getSuffix({ currency, language })}
            thousandSeparator={false}
            decimalSeparator={CurrencyFormatter.getDecimalSeparator({
              language
            })}
            onValueChange={values => {
              form.setFieldValue(name, values.value)
            }}
            onBlur={fieldBlur}
            fixedDecimalScale
            decimalScale={CurrencyFormatter.isZeroDecimal({ currency }) ? 0 : 2}
            allowNegative={false}
            isNumericString
            error={hasError}
            errorMessage={errorMessage}
            info={info}
            placeholder={placeholder}
            {...omit(rest, ['onChange'])}
            {...props}
          />
        )
      case FIELD_TYPE.CUSTOM:
        return customComponent({
          ...rest,
          ...props,
          onBlur: fieldBlur,
          onChange: fieldChange,
          fullWidth: true,
          placeholder,
          info,
          error: hasError,
          type,
          ref
        })
      default:
        return (
          <Input
            onBlur={fieldBlur}
            onChange={fieldChange}
            fullWidth
            type={type}
            placeholder={placeholder}
            error={hasError}
            info={info}
            ref={ref}
            {...rest}
            {...props}
          />
        )
    }
  }

  return (
    <Field name={name}>
      {({ field, meta, form }) => {
        let hasError

        if (typeof hasErrorProp === 'boolean') {
          hasError = hasErrorProp
        } else {
          hasError = requireTouchForError
            ? meta.touched && meta.error
            : meta.error
        }

        return (
          <>
            {renderLabel(hasError)}
            {info && <p>{info}</p>}
            {renderField(field, hasError, meta.error, form)}
            {description && <p>{description}</p>}
          </>
        )
      }}
    </Field>
  )
}

export default React.forwardRef(FormikField)
