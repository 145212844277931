'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.config(($stateProvider, $urlRouterProvider, $i18nextProvider) => {
  $urlRouterProvider.otherwise('/')

  // We create a root state which all states reference as parent so that we
  // can have global resolvers
  $stateProvider.state({
    name: '_root',
    abstract: true,
    template: '<div ui-view></div>',
    resolve: {
      // resolvers go here
    }
  })

  const routes = {
    dashboard: {
      url: '/',
      page: require('./pages/dashboard/dashboard')
    },
    properties: {
      url: '/properties?open',
      page: require('./pages/properties/properties')
    },
    'add-property': {
      url: '/properties/new',
      title: 'i18n:addProperty.pageTitle',
      page: require('./pages/add-property/add-property')
    },
    'edit-property': {
      url: '/properties/:lotId',
      title: 'i18n:addProperty.editPropertyPageTitle',
      page: require('./pages/add-property/add-property')
    },
    'edit-property-step': {
      url: '/properties/:lotId/step/{step:int}?editPlan',
      title: 'i18n:addProperty.editPropertyPageTitle',
      page: require('./pages/add-property/add-property')
    },
    'edit-property-advanced-rates': {
      url:
        '/schedule/properties/:lotId/advanced-rates/:advancedRateDefinitionId?{_origin:string}',
      page: require('./pages/edit-property-advanced-rates/edit-property-advanced-rates')
    },
    'list-property-advanced-rates': {
      url: '/schedule/properties/:lotId/advanced-rates?{_origin:string}',
      page: require('./pages/list-property-advanced-rates/list-property-advanced-rates')
    },
    'revenue-tabs': {
      url: '/revenue/:type?{lot:int}&{currency:string}&{dateRange:string}',
      page: require('./pages/revenue/revenue')
    },
    revenue: {
      url: '/revenue',
      controller: [
        '$state',
        $state =>
          $state.go('revenue-tabs', { type: 'daily' }, { location: 'replace' })
      ]
    },
    'revenue-on-date': {
      url: '/revenue/daily/:date?{currency:string}&{lot:int}',
      page: require('./pages/revenue-on-date/revenue-on-date')
    },
    'revenue-of-event': {
      url:
        '/revenue/events/:event?{currency:string}&{currentLot:int}&{lot:int}&{dateRange:string}',
      page: require('./pages/revenue-of-event/revenue-of-event')
    },
    payout: {
      url: '/revenue/payouts/:payout?{lot:int}',
      page: require('./pages/payout/payout')
    },
    schedule: {
      url: '/schedule?property&{tab:string}',
      reloadOnSearch: false,
      page: require('./pages/schedule/schedule')
    },
    events: {
      url: '/events',
      controller: [
        '$state',
        $state => $state.go('schedule', null, { location: 'replace' })
      ]
    },
    'add-event': {
      url: '/events/new',
      title: 'i18n:addEvent.pageTitle',
      page: require('./pages/add-event/add-event')
    },
    'edit-event': {
      url: '/events/:eventId/edit',
      title: 'i18n:addEvent.editEventPageTitle',
      page: require('./pages/add-event/add-event')
    },
    'edit-event-properties': {
      url: '/events/:eventId/properties?maxDistance&start&end',
      page: require('./pages/edit-event-properties/edit-event-properties')
    },
    event: {
      url:
        '/events/:id?{tab:string}&{lot:int}&{category:string}&{search:string}',
      page: require('./pages/event/event')
    },
    'appendable-events': {
      url: '/append-to-event?maxDistance',
      page: require('./pages/appendable-events/appendable-events')
    },
    users: {
      url: '/users',
      page: require('./pages/users/users')
    },
    'non-customer-user': {
      url: '/users/:id-:phoneCountryCode-:phoneNumber',
      page: require('./pages/user/user')
    },
    'invite-parkers': {
      url: '/users/new',
      page: require('./pages/invite-parkers/invite-parkers')
    },
    user: {
      url: '/users/:id?{_origin:string}',
      page: require('./pages/user/user')
    },
    'user-purchase-ticket': {
      url: '/users/:id/purchase-ticket',
      page: require('./pages/user-purchase-ticket/user-purchase-ticket')
    },
    'non-customer-purchase-ticket': {
      url: '/users/:id-:phoneCountryCode-:phoneNumber/purchase-ticket',
      page: require('./pages/user-purchase-ticket/user-purchase-ticket')
    },
    ticket: {
      url: '/tickets/:id?{_origin:string}',
      page: require('./pages/ticket/ticket')
    },
    reservation: {
      url: '/reservations/:id?{_origin:string}',
      page: require('./pages/reservation/reservation')
    },
    'reservation-refund': {
      url: '/reservations/:id/refund?{_origin:string}',
      page: require('./pages/reservation-refund/reservation-refund')
    },
    subscription: {
      url: '/subscriptions/:id?{_origin:string}',
      page: require('./pages/subscription/subscription')
    },
    'reserved-parking-pass': {
      url: '/reserved-parking-passes/:id?{_origin:string}',
      page: require('./pages/reserved-parking-pass/reserved-parking-pass')
    },
    'reserved-parking-pass-refund': {
      url: '/reserved-parking-passes/:id/refund?{_origin:string}',
      page: require('./pages/reserved-parking-pass-refund/reserved-parking-pass-refund')
    },
    'ticket-transfer': {
      url: '/tickets/:id/transfer',
      page: require('./pages/ticket-transfer/ticket-transfer')
    },
    'ticket-exchange': {
      url: '/tickets/:id/exchange?sameEvent',
      page: require('./pages/ticket-exchange/ticket-exchange')
    },
    'ticket-refund': {
      url: '/tickets/:id/refund',
      page: require('./pages/ticket-refund/ticket-refund')
    },
    'bundle-purchase': {
      url: '/bundle-purchases/:id?{_origin:string}',
      page: require('./pages/bundle-purchase/bundle-purchase')
    },
    register: {
      url: '/register',
      page: require('./pages/register/register')
    },
    login: {
      url: '/login?source',
      page: require('./pages/login/login')
    },
    'signup-from-invitation': {
      url: '/signup/:code',
      page: require('./pages/signup-from-invitation/signup-from-invitation')
    },
    'all-properties': {
      url: '/all-properties',
      page: require('./pages/all-properties/all-properties')
    },
    organization: {
      url: '/organizations/:id',
      page: require('./pages/organization/organization')
    },
    'referral-codes': {
      url: '/referral-codes',
      page: require('./pages/referral-codes/referral-codes')
    },
    'my-account': {
      url: '/my-account',
      page: require('./pages/my-account/my-account')
    },
    'stripe-account-setup': {
      url: '/stripe-account-setup',
      page: require('./pages/stripe-account-setup/stripe-account-setup')
    },
    'stripe-account-setup-result': {
      url: '/stripe-account-setup/result?status',
      page: require('./pages/stripe-account-setup-result/stripe-account-setup-result')
    },
    permits: {
      url: '/permits?{status:string}&{search:string}&{dateRange:string}',
      page: require('./pages/permits/permits')
    },
    'add-permit': {
      url: '/permits/new',
      page: require('./pages/add-permit/add-permit')
    },
    'edit-permit': {
      url: '/permits/edit/:permitId',
      page: require('./pages/add-permit/add-permit')
    },
    permit: {
      url:
        '/permit/:permitId?{status:string}&{search:string}&{dateRange:string}',
      page: require('./pages/permit/permit')
    }
  }

  _.forEach(routes, (settings, routeName) => {
    let pageSettings = settings.page
    let routeConfig = _.omit(settings, ['page'])
    if (routeName.indexOf('.') === -1) {
      routeConfig.parent = '_root' // enables global resolvers
    }
    routeConfig = { ...routeConfig, ...pageSettings }
    $stateProvider.state(routeName, routeConfig)
  })
})
