'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Tickets', (API, Authentication, Languages, $http) => {
  function loadTicket (ticketId) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/tickets/' + ticketId,
      headers: Authentication.generateHeaders()
    })
  }

  function cancelTicket (ticketId, options) {
    return $http({
      method: 'POST',
      url: API.base + '/tickets/' + ticketId + '/cancel',
      headers: Authentication.generateHeaders(),
      data: { refund: options || {} }
    })
  }

  function transferTicket (ticketId, data) {
    return $http({
      method: 'POST',
      url: API.base + '/tickets/' + ticketId + '/transfer',
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  function updateTicketTransfer (ticketId, data) {
    return $http({
      method: 'PUT',
      url: API.base + '/tickets/' + ticketId + '/transfer',
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  function exchangeTicket (ticketId, data) {
    return $http({
      method: 'PUT',
      url: API.base + '/tickets/' + ticketId,
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  function getDefaultCancellationOptions (ticketId) {
    return $http({
      method: 'GET',
      url:
        API.base +
        '/admin/tickets/' +
        ticketId +
        '/default-cancellation-options',
      headers: Authentication.generateHeaders()
    })
  }

  function calculateRefundPreview (ticketId, options) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/tickets/' + ticketId + '/refund-preview',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function exportPurchaseReceipt (ticketId, language) {
    return $http({
      method: 'GET',
      url: `${API.base}/admin/tickets/${ticketId}/purchase-receipt/export?request_language=${language}`,
      headers: {
        ...Authentication.generateHeaders(),
        'Citifyd-accept-language': undefined
      },
      responseType: 'arraybuffer'
    })
  }

  return {
    load: loadTicket,
    cancel: cancelTicket,
    exchange: exchangeTicket,
    transfer: transferTicket,
    updateTransfer: updateTicketTransfer,
    calculateRefundPreview: calculateRefundPreview,
    exportPurchaseReceipt: exportPurchaseReceipt,
    getDefaultCancellationOptions: getDefaultCancellationOptions
  }
})
