'use strict'

import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import './LoadingIndicator.scss'

function LoadingIndicator ({ withOverlay, noMargin }) {
  const overlayClass = _.isString(withOverlay) ? withOverlay : 'white'

  const classes = classNames({
    'component-loading-indicator': true,
    'no-margin': noMargin,
    'loading-overlay': withOverlay,
    [overlayClass]: withOverlay
  })

  return (
    <div className={classes}>
      <div className='cssload-container'>
        <div className='cssload-speeding-wheel' />
      </div>
    </div>
  )
}

export default LoadingIndicator
