'use strict'

import React, { useEffect, useState } from 'react'
import { NewGrid } from '@citifyd/style'

import { useAngularService, useTranslator } from '../../shared/react/hooks'
import PageHeader from '../../shared/react/components/PageHeader'
import LoadingManager from '../../shared/react/components/LoadingManager'
import styles from './Organization.module.scss'
import OrganizationInfo from './components/OrganizationInfo'
import OrganizationLots from './components/OrganizationLots'
import OrganizationVenues from './components/OrganizationVenues'
import OrganizationUsers from './components/OrganizationUsers'

const Organization = () => {
  const t = useTranslator()
  const Organizations = useAngularService('Organizations')
  const Helper = useAngularService('Helper')
  const $stateParams = useAngularService('$stateParams')
  const [organization, setOrganization] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const fetchOrganization = async () => {
    setIsLoading(true)

    try {
      const response = await Organizations.findById($stateParams.id)

      const organization = response?.data?.organization

      setOrganization(organization)
      Helper.setPageTitle('Organization: ' + organization?.name)
    } catch (err) {
      console.log('err', err)
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchOrganization()
  }, [])

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />
  }

  return (
    <div className={styles.organization}>
      <NewGrid>
        <PageHeader>
          <PageHeader.Title
            backState
            title={`${t('organization.organization')} ${organization.name}`}
          />
        </PageHeader>
        <OrganizationInfo organization={organization} />
        <OrganizationLots organization={organization} />
        <OrganizationVenues organization={organization} />
        <OrganizationUsers organization={organization} />
      </NewGrid>
    </div>
  )
}

export default Organization
