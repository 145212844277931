'use strict'

import React, { useState } from 'react'
import { find } from 'lodash'
import { Table, Icon } from '@citifyd/style'
import TableRow from './TableRow.jsx'

export default function DefaultTable ({
  columns = [],
  data = [],
  striped,
  fixed,
  condensed,
  expanded,
  noPadding,
  showIconRight,
  verticalAlignIconRight,
  bordered,
  textColor,
  cleanMode,
  responsive,
  shadow,
  spacing,
  headerGap,
  showHeader = true,
  headerAppearance,
  ...props
}) {
  const renderColumns = () => {
    const [sortingBy, setSortingBy] = useState(columns[0].sortableKey || 0)

    return columns.map((column, index) => {
      const sortableKey = column.sortableKey || index
      const propertyOrder = find(
        column.orders,
        order => order[0] === sortableKey
      )

      return (
        <Table.HeaderCell
          uppercase={column.uppercase}
          sortable={column.sortable}
          colSpan={column.colSpan}
          rowSpan={column.rowSpan}
          clickable={column.clickable}
          verticalAlign={column.verticalAlign}
          border={column.border}
          nowrap={column.nowrap}
          onClick={e => {
            if (typeof column?.onClick === 'function') {
              column?.onClick(e, sortableKey)
            }

            if (column.sortable) {
              if (sortableKey !== sortingBy) {
                setSortingBy(sortableKey)
              }
            }
          }}
          textAlign={column?.textAlign}
          textColor={column?.textColor}
          width={column.width}
          key={index}
        >
          {propertyOrder && (
            <Icon
              appearance={column?.textColor}
              icon={propertyOrder[1] === 'asc' ? 'caret-up' : 'caret-down'}
            ></Icon>
          )}
          {column?.value || (typeof column === 'string' && column)}
        </Table.HeaderCell>
      )
    })
  }

  const renderRows = () => {
    return data.map((item, index) => (
      <TableRow
        key={index}
        item={item}
        spacing={spacing}
        noPadding={noPadding}
        showIconRight={showIconRight}
        verticalAlignIconRight={verticalAlignIconRight}
        columns={columns}
      />
    ))
  }

  return (
    <Table
      striped={striped}
      fixed={fixed}
      condensed={condensed}
      bordered={bordered}
      cleanMode={cleanMode}
      responsive={responsive}
      {...props}
    >
      {showHeader && (
        <Table.Head gap={headerGap}>
          <Table.Row appearance={headerAppearance}>
            {renderColumns()}
            {showIconRight && <Table.HeaderCell></Table.HeaderCell>}
          </Table.Row>
        </Table.Head>
      )}
      <Table.Body>{renderRows()}</Table.Body>
    </Table>
  )
}
