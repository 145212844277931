'use strict'

import React from 'react'
import {
  Icon,
  Text,
  Button,
  NewGrid,
  List,
  Tooltip,
  Link
} from '@citifyd/style'
import moment from 'moment-timezone'

import SmallLoadingIndicator from '../../../../shared/react/components/SmallLoadingIndicator'
import Dictionary, {
  DictionaryItem
} from '../../../../shared/react/components/Dictionary'
import GuestBadge from '../../../../shared/react/components/GuestBadge'
import { formatPhoneNumber } from '../../../../shared/utils/phone-number-formatting'
import BoxContent from '../../../../shared/react/components/BoxContent'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import {
  useModalOpener,
  withModalManager
} from '../../../../shared/react/modalManager'
import RouterLink from '../../../../shared/react/components/RouterLink'
import UserVehicleInformationModal from '../../../../shared/modals/UserVehicleInformationModal'
import UserUpdateEmailModal from '../../../../shared/modals/UserUpdateEmailModal'
import UserSessionInformationModal from '../../../../shared/modals/UserSessionInformationModal'
import {
  openMigrationTicketsInformation,
  getAddress,
  openUserUpdate
} from './utils'
import styles from './UserSummary.module.scss'

const UserSummary = ({
  user,
  nonCustomer,
  migrationTickets,
  migrationTicketsLoadError,
  loadUser
}) => {
  const Authentication = useAngularService('Authentication')

  const loggedUser = Authentication.getLoggedUser()

  return (
    <>
      <UserSummaryInfo
        user={user}
        nonCustomer={nonCustomer}
        loggedUser={loggedUser}
        loadUser={loadUser}
      />

      {loggedUser.isAdmin && (
        <UserSummaryAdditionalInfo
          user={user}
          migrationTickets={migrationTickets}
          migrationTicketsLoadError={migrationTicketsLoadError}
          loadUser={loadUser}
        />
      )}
    </>
  )
}

const UserSummaryInfo = ({ user, nonCustomer, loggedUser, loadUser }) => {
  const t = useTranslator()
  const Permissions = useAngularService('Permissions')
  const $state = useAngularService('$state')
  const Authentication = useAngularService('Authentication')
  const openModal = useModalOpener()
  const canAuthenticate = Permissions.userMayAuthenticateAsOthers()
  const canSeeUserInformation = Permissions.userMaySeeUserInformation()

  const authenticateAsUser = () => {
    Authentication.impersonateUser(user?.id).then(
      () => $state.transitionTo('dashboard'),
      () => window.alert(t('user.errorAuthenticatingAsUser'))
    )
  }

  const openSessionInformation = () =>
    openModal(UserSessionInformationModal, {
      user,
      onClose: ({ changedSession } = {}) => {
        if (changedSession) {
          loadUser()
        }
      }
    })

  const openVehicleInformation = () =>
    openModal(UserVehicleInformationModal, {
      user
    })

  return (
    <BoxContent>
      <BoxContent.Header
        title={t('user.userId', {
          id: user?.id
        })}
      >
        <BoxContent.HeaderActions className={styles.actions}>
          {!nonCustomer && canSeeUserInformation && (
            <Button appearance='transparent' onClick={openSessionInformation}>
              <Icon
                icon='mobile-alt'
                size='h2'
                appearance='white'
                title={t('user.deviceInformation')}
              />
            </Button>
          )}

          {!nonCustomer && !user?.isGuest && (
            <Button appearance='transparent' onClick={openVehicleInformation}>
              <Icon
                icon='car-alt'
                size='h2'
                appearance='white'
                title={t('user.vehicleInformation')}
              />
            </Button>
          )}

          {canAuthenticate && !user?.isGuest && (
            <Button
              size='small'
              onClick={authenticateAsUser}
              appearance='gray'
              rounded
            >
              {t('user.login')}
            </Button>
          )}
        </BoxContent.HeaderActions>
      </BoxContent.Header>

      <BoxContent.Content>
        <Text
          component='span'
          variant='h3'
          weight='700'
          className={styles.username}
        >
          {user?.name && <span>{user.name}</span>}
          {!user?.name && user?.phoneNumber && (
            <span>
              {formatPhoneNumber(user.phoneNumber, user.phoneCountryCode)}
            </span>
          )}
          {!user?.name && !user?.phoneNumber && (
            <span>{t('user.customer')}</span>
          )}
          {user?.isGuest && <GuestBadge className={styles.badge} />}
        </Text>

        <NewGrid.Row>
          <NewGrid.Col xs={12} md={6}>
            <List border className={styles.list}>
              <List.Divisor />
              {loggedUser.isAdmin && user?.organization && (
                <List.Item>
                  {t('user.organization')}
                  <RouterLink
                    appearance='tertiary'
                    state='organization'
                    params={{ id: user.organization?.id }}
                    options={{ enableBackLink: true }}
                    className={styles.badge}
                  >
                    {user.organization.name}
                  </RouterLink>
                </List.Item>
              )}

              {canSeeUserInformation && user?.phoneNumber && (
                <List.Item>
                  {formatPhoneNumber(user.phoneNumber, user.phoneCountryCode)}
                  {!user.isGuest && (
                    <TooltipIcon
                      id='verifiedPhoneNumber'
                      value={user?.verifiedPhoneNumber}
                    />
                  )}
                </List.Item>
              )}

              {canSeeUserInformation && !user?.phoneNumber && (
                <List.Item>{t('user.noPhoneNumber')}</List.Item>
              )}

              {canSeeUserInformation && !user?.isGuest && (
                <List.Item>
                  {user?.email}

                  <TooltipIcon
                    id='verifiedPhoneNumber'
                    value={user?.verifiedEmail}
                  />
                </List.Item>
              )}

              <List.Item>
                {t('user.registeredSince', {
                  date: user?.createdAt,
                  amTimezone: loggedUser.timezoneName
                })}
              </List.Item>

              {user?.market && (
                <List.Item>
                  {t('user.userMarket', { marketRegion: getAddress(user) })}
                </List.Item>
              )}
            </List>
          </NewGrid.Col>

          <NewGrid.Col xs={12} md={6}>
            {!nonCustomer && (
              <Dictionary>
                <Item label={t('user.lastTransaction')}>
                  {user?.lastTransactionDate &&
                    t('defaultFormats.date', {
                      date: moment.tz(
                        user.lastTransactionDate,
                        loggedUser.timezoneName
                      )
                    })}
                </Item>

                {user?.merchantValidationAmounts && (
                  <Item label={t('user.transactions')}>
                    {user?.formattedTotalBalance}
                  </Item>
                )}

                {!user?.isGuest && user?.merchantValidationAmounts && (
                  <>
                    <Item label={t('user.validations')}>
                      {user?.formattedTotalValidations}
                    </Item>

                    <Item label={t('user.balance')}>
                      {user?.formattedTotalAvailableForRefund}
                    </Item>
                  </>
                )}
              </Dictionary>
            )}
          </NewGrid.Col>
        </NewGrid.Row>
      </BoxContent.Content>
    </BoxContent>
  )
}

const UserSummaryAdditionalInfo = ({
  user,
  migrationTickets,
  migrationTicketsLoadError,
  loadUser
}) => {
  const t = useTranslator()
  const openModal = useModalOpener()

  const openUserUpdateEmail = () =>
    openModal(UserUpdateEmailModal, {
      user,
      onClose: ({ userUpdated } = {}) => {
        if (userUpdated) {
          loadUser()
        }
      }
    })

  const yesNo = value => (value ? t('yes') : t('no'))

  const getMigrationLabel = () => (
    <>
      {t('user.pendingMigration')}

      {migrationTickets && migrationTickets?.length > 0 && (
        <span
          onClick={() =>
            openMigrationTicketsInformation(migrationTickets, user)
          }
        >
          ({t('user.view')})
        </span>
      )}
    </>
  )

  return (
    <BoxContent>
      <BoxContent.Header title={t('user.additionalInfo')} />
      <BoxContent.Content>
        <NewGrid.Row marginBottom>
          <NewGrid.Col xs={12} md={6}>
            <Dictionary withDivisor={false}>
              <Item label={t('user.role')}>
                {user?.role === 'admin'
                  ? t('user.roleAdmin')
                  : t('user.roleUser')}
              </Item>

              {!user?.isGuest && (
                <Item label={t('user.fbLogin')}>
                  {yesNo(user?.facebookProfile !== null)}
                </Item>
              )}

              <Item label={t('user.stripeId')}>
                {user?.stripeCustomerId && (
                  <Link href={user.stripeCustomerUrl} target='_blank'>
                    {user?.stripeCustomerId}
                  </Link>
                )}
              </Item>

              {!user?.isGuest && (
                <Item label={getMigrationLabel(user)}>
                  {migrationTickets && migrationTickets?.length}
                  {migrationTicketsLoadError && t('user.error')}
                  {!migrationTicketsLoadError && !migrationTickets && (
                    <SmallLoadingIndicator />
                  )}
                </Item>
              )}
            </Dictionary>
          </NewGrid.Col>

          <NewGrid.Col xs={12} md={6}>
            <Dictionary withDivisor={false}>
              <Item label={t('user.marketingNotifications')}>
                {yesNo(user?.receiveMarketingNotifications)}
              </Item>

              {!user?.isGuest && (
                <>
                  <Item label={t('user.emailNotifications')}>
                    {yesNo(user?.receiveEmailNotifications)}
                  </Item>

                  <Item label={t('user.pushNotificications')}>
                    {yesNo(user?.receivePushNotifications)}
                  </Item>
                </>
              )}

              <Item label={t('user.textNotifications')}>
                {yesNo(user?.receiveTextMessageNotifications)}
              </Item>
            </Dictionary>
          </NewGrid.Col>
        </NewGrid.Row>

        {!user?.isGuest && (
          <Button uppercase size='small' onClick={openUserUpdateEmail}>
            {t('user.updateEmail')}
          </Button>
        )}

        <Button
          uppercase
          size='small'
          onClick={() =>
            openUserUpdate('admin-edit-user-phone-number', user, loadUser)
          }
        >
          {t('user.updatePhoneNumber')}
        </Button>
      </BoxContent.Content>
    </BoxContent>
  )
}

const TooltipIcon = ({ id, value }) => {
  const t = useTranslator()

  return (
    <Tooltip id={id} label={value ? t('user.verified') : t('user.notVerified')}>
      <Icon className={styles.icon} icon={value ? 'check' : 'times'} />
    </Tooltip>
  )
}

const Item = ({ children, label }) => (
  <DictionaryItem textComponent='span' label={label} labelWeight='400'>
    {children || '—'}
  </DictionaryItem>
)

export default withModalManager(UserSummary)
