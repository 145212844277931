import { getAngularService, getTranslator } from '../../../react/utils'

const getLanguages = ({ lot, purchaser }) => {
  const Authentication = getAngularService('Authentication')
  const Languages = getAngularService('Languages')
  const t = getTranslator()

  const loggedUser = Authentication.getLoggedUser()

  return Languages.getAvailable().map(language => {
    const languageName = t(`languages.${language.key}`)

    let languageLabel
    switch (language.key) {
      case purchaser?.language:
        languageLabel = t(
          `modalGenerateReceipt.languageSelection.labels.parker`,
          { language: languageName }
        )
        break

      case lot?.country?.language:
        languageLabel = t(`modalGenerateReceipt.languageSelection.labels.lot`, {
          language: languageName
        })
        break

      case loggedUser.language:
        languageLabel = t(
          `modalGenerateReceipt.languageSelection.labels.your`,
          { language: languageName }
        )
        break

      default:
        languageLabel = languageName
    }

    return {
      key: language.key,
      label: languageLabel
    }
  })
}

export default getLanguages
