'use strict'

import React, { useEffect, useState, useRef } from 'react'
import { NewGrid, Text, AlertMessage } from '@citifyd/style'
import moment from 'moment'
import classNames from 'classnames'

import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import useEventFormik from '../../hooks/useEventFormik'
import useVenues from '../../hooks/useVenues'

import Form from './Form/Form.jsx'
import FormikProvider from '../../../../shared/react/components/FormikProvider'
import ImageUpload from './ImageUpload/ImageUpload.jsx'
import Footer from './Footer/Footer.jsx'
import Mode from '../../mode'

import { isoStringToLocalDateAndTime } from '../../../../shared/react/dateUtils'
import useLoadTracking from '../../../../shared/react/hooks/useLoadTracking'
import LoadingManager from '../../../../shared/react/components/LoadingManager'

import styles from './AddEventForm.module.scss'

const AddEventForm = ({ mode, eventId }) => {
  const Events = useAngularService('Events')
  const t = useTranslator()

  const imageInputRef = useRef()
  const [currentImage, setCurrentImage] = useState(null)

  const {
    isLoading,
    loadingMessage,
    trackLoading,
    untrackLoading
  } = useLoadTracking({
    uploadImage: { message: t('addEvent.loadingMessages.uploadImage') },
    createEvent: {
      message: t('addEvent.loadingMessages.create')
    },
    updateEvent: { message: t('addEvent.loadingMessages.update') }
  })

  const { venues } = useVenues({ trackLoading, untrackLoading })

  const formikbag = useEventFormik({
    venues,
    mode,
    eventId,
    imageInputRef,
    trackLoading,
    untrackLoading
  })

  useEffect(() => {
    const loadEvent = async () => {
      trackLoading('event')
      const response = await Events.find(eventId)
      const eventData = response.data.event
      setCurrentImage(eventData.images.image1)

      const [startDate, startTime] = isoStringToLocalDateAndTime(
        eventData.start,
        eventData.timezoneName
      )

      formikbag.setValues(
        values => ({
          ...values,
          name: eventData.name,
          id: eventData.id,
          venueId: eventData?.venueId,
          waitlist: eventData.waitlistEnabled,
          startDate,
          startTime,
          previousStartDate: startDate,
          previousStartTime: startTime,
          isPastEvent: moment().isAfter(eventData.start),
          isEditable: eventData.isEditable
        }),
        false
      )
      untrackLoading('event')
    }

    if (mode === Mode.EDIT) {
      loadEvent()
    }
  }, [])

  useEffect(() => {
    if (venues.length && !formikbag.values.venue) {
      formikbag.setFieldValue('venueId', venues[0].id)
    }
  }, [venues])

  const handleImageChange = ({ hasImage }) => {
    formikbag.setFieldValue('hasImage', hasImage).then(() => {
      formikbag.validateField('hasImage')
      formikbag.setFieldTouched('hasImage')
    })
  }

  const errorImage = formikbag.errors.hasImage
  const touchedImage = formikbag.touched.hasImage

  return (
    <>
      {isLoading && (
        <LoadingManager isLoading={true} loadingMessage={loadingMessage} />
      )}

      {/*Hide instead of removing the form while loading, otherwise
      the image selection on the file input is lost */}
      <div className={classNames({ [styles.hideContent]: isLoading })}>
        <FormikProvider value={formikbag}>
          {formikbag.values.isPastEvent && (
            <AlertMessage className={styles.alertMessage}>
              <Text>{t('addEvent.eventAlreadyStarted')}</Text>
            </AlertMessage>
          )}
          <NewGrid.Row>
            <NewGrid.Col xs={12} sm={8} lg={7}>
              <Form venues={venues} mode={mode} formikbag={formikbag} />
            </NewGrid.Col>
            <NewGrid.Col xs={12} sm={4} lg={5}>
              <NewGrid.Row>
                <NewGrid.Col xs={12} lg={6}>
                  <ImageUpload
                    onChange={handleImageChange}
                    currentImage={currentImage}
                    inputRef={imageInputRef}
                    errorImage={errorImage}
                    touchedImage={touchedImage}
                  />
                  <br />
                </NewGrid.Col>
                <NewGrid.Col xs={12} lg={6}>
                  <EventThumbnail
                    errorImage={errorImage}
                    touchedImage={touchedImage}
                  />
                </NewGrid.Col>
              </NewGrid.Row>
            </NewGrid.Col>
          </NewGrid.Row>
          <Footer handleSubmit={formikbag.handleSubmit} />
        </FormikProvider>
      </div>
    </>
  )
}

const EventThumbnail = ({ errorImage, touchedImage }) => {
  const t = useTranslator()

  return (
    <div>
      <Text
        appearance={errorImage && touchedImage ? 'error' : 'default'}
        variant='h5'
      >
        {t('addEvent.labels.thumbnail')}
      </Text>
      <Text appearance={errorImage && touchedImage ? 'error' : 'default'}>
        {t('addEvent.thumbnailInfo', { width: 280, height: 280 })}
      </Text>
    </div>
  )
}

export default AddEventForm
