'use strict'

import moment from 'moment-timezone'

export function calculateLotStatus (lot) {
  if (!lot.isApproved) {
    return 'pending'
  } else if (lot.stripeChargeDisabled) {
    return 'inactivePendingAccountVerificationInformation'
  } else if (lot.isVisible) {
    return 'active'
  } else {
    return 'inactive'
  }
}

export function calculateReservationStatus (reservation) {
  // old reservations were deleted instead of cancelled
  if (reservation.cancelledAt || reservation.deletedAt) {
    return 'cancelled'
  } else if (reservation.endTime) {
    return 'complete'
  } else {
    return 'ongoing'
  }
}

export function calculateReservedParkingStatus (reservedParkingPass) {
  if (reservedParkingPass.cancelledAt || reservedParkingPass.deletedAt) {
    return 'cancelled'
  }

  switch (reservedParkingPass.status) {
    case 'FUTURE':
      return 'purchased'
    case 'ACTIVE':
      return 'ongoing'
    case 'PAST':
      return 'complete'
    default:
      return ''
  }
}

export function calculateSubscriptionStatuses (subscription) {
  const statuses = []

  if (subscription.suspendedAt) {
    statuses.push('cancelled')
  } else {
    if (moment().isBefore(subscription.expiresAt)) {
      statuses.push('active')
    }

    if (subscription.cancelledAt) {
      statuses.push('cancelled')
    }
  }

  return statuses
}
