'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.factory('AvailabilityGroupGenerator', (Helper, $i18next) => {
  function mountAvailabilityGroup (name, filter, availabilities, lotId) {
    var filteredAvailabilities = _.where(availabilities, filter)

    var availabilityGroups = _.groupBy(filteredAvailabilities, 'timeRange')
    availabilityGroups = _.sortBy(_.pairs(availabilityGroups), '0')

    if (!availabilityGroups.length) {
      return [
        {
          identifier: name,
          name: name,
          filter: filter,
          daysOfWeek: null,
          startTime: null,
          endTime: null,
          availabilitiesIds: [],
          reservationCount: 0,
          spacesCount: 0,
          rate: null,
          isActive: function () {
            return false
          }
        }
      ]
    }

    return _.map(availabilityGroups, function (group) {
      var availabilities = group[1]

      var daysOfWeek = _(availabilities)
        .map(function (availability) {
          return {
            number: availability.start.day(),
            name: moment(availability.start)
              .locale($i18next.i18n.language)
              .format('ddd')
          }
        })
        .sortBy('number')
        .map(function (day) {
          return day.name
        })

      var reservationCount = _.sum(availabilities, function (a) {
        return a.reservationCount || 0
      })

      const rates = _.uniq(
        availabilities.map(a => a.lots[0].rate),
        _.isEqual
      )

      return {
        identifier: name + '-' + availabilities.map(a => a.id).join(','),
        name: name,
        filter: filter,
        daysOfWeek: daysOfWeek.join(', '),
        startTime: $i18next.t('defaultFormats.time', {
          date: availabilities[0].start
        }),
        endTime: $i18next.t('defaultFormats.time', {
          date: availabilities[0].end
        }),
        availabilitiesIds: _.pluck(availabilities, 'id'),
        reservationCount: reservationCount,
        spacesCount: countActiveSpaces(availabilities, lotId),
        rate: rates.length === 1 ? rates[0] : null,

        isActive: function () {
          var now = moment().unix()

          return _.some(availabilities, function (availability) {
            return (
              now >= availability.start.unix() && now <= availability.end.unix()
            )
          })
        }
      }
    })
  }

  function countActiveSpaces (availabilities) {
    var now = moment().unix()

    var active = _.filter(availabilities, function (availability) {
      return now >= availability.start.unix() && now <= availability.end.unix()
    })

    if (!active.length) {
      active = [availabilities[0]]
    }

    var lots = _(active)
      .map(function (availability) {
        return availability.lots
      })
      .flatten()
      .value()

    var spaces = _(lots)
      .map(function (lot) {
        return _.findWhere(lot.spaces, { name: 'regular' })
      })
      .sum('max')

    return spaces
  }

  function moveDateToCurrentWeek (date, timezoneName) {
    return moment()
      .tz(timezoneName)
      .day(date.day())
      .hour(date.hour())
      .minute(date.minute())
      .second(date.second())
  }

  function generate (lotId, availabilities, timezoneName) {
    availabilities = _.cloneDeep(availabilities)

    availabilities.forEach(function (availability) {
      // Transform "start" and "end" of availabilities into moment() objects
      availability.start = moment.tz(availability.start, timezoneName)
      availability.end = moment.tz(availability.end, timezoneName)

      // Move dates to current week (so that we can detect if the availability is
      // happening right now)
      availability.start = moveDateToCurrentWeek(
        availability.start,
        timezoneName
      )
      availability.end = moveDateToCurrentWeek(availability.end, timezoneName)

      // Add startTime and endTime properties to availabilities
      availability.startTime = availability.start.locale('en').format('HH:mm')
      availability.endTime = availability.end.locale('en').format('HH:mm')
      availability.timeRange =
        availability.startTime + '-' + availability.endTime

      // Detects if it's weekday or weekend
      availability.type = _.includes([0, 6], availability.start.day())
        ? 'weekend'
        : 'weekday'
    })

    return _.flatten([
      mountAvailabilityGroup(
        $i18next.t('schedule.availabilityGroupNames.weekday'),
        { type: 'weekday', period: 'day' },
        availabilities,
        lotId
      ),

      mountAvailabilityGroup(
        $i18next.t('schedule.availabilityGroupNames.weeknight'),
        { type: 'weekday', period: 'evening' },
        availabilities,
        lotId
      ),

      mountAvailabilityGroup(
        $i18next.t('schedule.availabilityGroupNames.weekend'),
        { type: 'weekend', period: 'day' },
        availabilities,
        lotId
      ),

      mountAvailabilityGroup(
        $i18next.t('schedule.availabilityGroupNames.weekendEvening'),
        { type: 'weekend', period: 'evening' },
        availabilities,
        lotId
      )
    ])
  }

  return {
    generate: generate
  }
})
