'use strict'

import React from 'react'
import { capitalize } from 'lodash'
import { Select, NewGrid } from '@citifyd/style'

import { useReservationRefundContext } from './ReservationRefundContext.jsx'
import { useAngularService, useTranslator } from '../../../shared/react/hooks'
import styles from './ReservationRefund.module.scss'

export default function RefundSource () {
  const t = useTranslator()
  const {
    refundOptions,
    setRefundOptions,
    userInterfaceSettings
  } = useReservationRefundContext()
  const Authentication = useAngularService('Authentication')
  const user = Authentication.getLoggedUser()

  if (!user.isAdmin) {
    return null
  }

  if (!userInterfaceSettings || !userInterfaceSettings.allowChangingRefunder) {
    return null
  }

  const changeRefunder = e => {
    const newRefunder = e.target.value.toLowerCase()

    setRefundOptions({
      ...refundOptions,
      refunder: newRefunder,
      // Makes sure that whenever the user changes the refunder, that refunder will be responsible
      // for paying the Stripe processing fees by default.
      stripeFeePayer: newRefunder
    })
  }

  const changeStripeFeePayer = e =>
    setRefundOptions({ ...refundOptions, stripeFeePayer: e.target.value })

  return (
    <div className={styles.boxContent}>
      <NewGrid.Row>
        <NewGrid.Col
          xs={12}
          md={userInterfaceSettings.allowChangingStripeFeePayer ? 6 : 12}
        >
          <Select
            fullWidth
            label={t('refundPages.deductTicketFundsFrom.label')}
            onChange={changeRefunder}
            value={refundOptions.refunder}
            options={[
              {
                label: t('refundPages.deductTicketFundsFrom.values.seller'),
                value: 'seller'
              },
              {
                label: t('refundPages.deductTicketFundsFrom.values.citifyd'),
                value: 'citifyd'
              }
            ]}
          />
          <br />
        </NewGrid.Col>
        {userInterfaceSettings.allowChangingStripeFeePayer && (
          <NewGrid.Col xs={12} md={6}>
            <Select
              fullWidth
              label={t('refundPages.deductStripeProcessingFeeFundsFrom.label')}
              onChange={changeStripeFeePayer}
              value={refundOptions.stripeFeePayer}
              disabled={refundOptions.refunder === 'citifyd'}
              options={[
                {
                  label: t(
                    'refundPages.deductStripeProcessingFeeFundsFrom.values.seller'
                  ),
                  value: 'seller'
                },
                {
                  label: t(
                    'refundPages.deductStripeProcessingFeeFundsFrom.values.citifyd'
                  ),
                  value: 'citifyd'
                }
              ]}
            />
          </NewGrid.Col>
        )}
      </NewGrid.Row>
    </div>
  )
}
