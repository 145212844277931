'use strict'

import React from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import moment from 'moment-timezone'
import { List, NewGrid, Text } from '@citifyd/style'

import GuestBadge from '../GuestBadge'
import UserLink from '../UserLink'
import ReservationStatusBadge from '../ReservationStatusBadge'
import BoxContent from '../BoxContent'
import Address from '../Address'
import PaymentMethod from '../PaymentMethod'

import { calculateReservationStatus } from '../../../utils/status-calculation'
import { useTranslator } from '../../hooks'
import { getCurrentLanguage } from '../../utils'
import styles from './ReservationFullInformation.module.scss'

function ReservationTransactionBox ({ reservation }) {
  const t = useTranslator()

  return (
    <BoxContent>
      <BoxContent.Header
        title={t('reservationPage.transactionBox.transactionNumber', {
          transactionNumber: reservation.transactionNumber
        })}
      />
      <BoxContent.Content>
        <div className={styles.transactionHeader}>
          <Text component='span' variant='h3' weight='700'>
            {reservation.lot.name}
          </Text>
          <ReservationStatusBadge
            status={calculateReservationStatus(reservation)}
          />
        </div>
        <NewGrid.Row>
          <NewGrid.Col sm={12} md={6}>
            <List border fullWidth className={styles.list}>
              <List.Divisor />
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.purchasedBy')}
                </Text>
                {reservation.user ? (
                  <UserLink
                    appearance='tertiary'
                    underline={false}
                    user={reservation.user}
                    isUnverified={reservation.isUnverified}
                    routerOptions={{ enableBackLink: true }}
                  >
                    {reservation.isUnverified && (
                      <GuestBadge
                        className={styles.guestBadge}
                        label={t('reservationPage.unverifiedBadge')}
                      />
                    )}
                  </UserLink>
                ) : (
                  <GuestBadge className={styles.guestBadge} />
                )}
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.vehiclePlate')}
                </Text>
                <span>
                  {reservation.vehicle ? reservation.vehicle.license : '—'}
                </span>
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.startTime')}
                </Text>
                <span>
                  {t('defaultFormats.datetimeWithTimezone', {
                    date: moment.tz(
                      reservation.startTime,
                      reservation.lot.timezoneName
                    )
                  })}
                </span>
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.endTime')}
                </Text>
                <span>
                  {t('defaultFormats.datetimeWithTimezone', {
                    date: moment.tz(
                      reservation.endTime || reservation.expirationTime,
                      reservation.lot.timezoneName
                    )
                  })}
                </span>
              </List.Item>
              <List.Item justifyContent='space-between' alignItems='flex-start'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.lotAddress')}
                </Text>
                <Address
                  className={styles.alignRight}
                  entity={reservation.lot}
                  format='full'
                />
              </List.Item>
            </List>
          </NewGrid.Col>
          <NewGrid.Col sm={12} md={6}>
            <List border fullWidth className={styles.list}>
              <List.Divisor />
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.paymentStatus.label')}
                </Text>
                <PaymentStatus reservation={reservation} />
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.amount')}
                </Text>
                <ReservationAmount reservation={reservation} />
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.paymentMethod')}
                </Text>
                <span>
                  <PaymentMethod card={reservation.card} />
                </span>
              </List.Item>
              <List.Item justifyContent='space-between'>
                <Text weight='600'>
                  {t('reservationPage.transactionBox.lastNameOnCard')}
                </Text>
                <CardLastName card={reservation.card} />
              </List.Item>
            </List>
          </NewGrid.Col>
        </NewGrid.Row>
      </BoxContent.Content>
    </BoxContent>
  )
}

function CardLastName ({ card }) {
  const cardName = card && card.name
  return (
    (cardName || '')
      .trim()
      .split(' ')
      .reverse()[0] || '—'
  )
}

function PaymentStatus ({ reservation }) {
  const t = useTranslator()
  return reservation.userChargeComplete
    ? t('reservationPage.transactionBox.paymentStatus.values.completed')
    : t('reservationPage.transactionBox.paymentStatus.values.authorized')
}

function ReservationAmount ({ reservation }) {
  const language = getCurrentLanguage()
  return CurrencyFormatter.format(reservation.amountCharged, {
    currency: reservation.currency,
    language
  })
}

export default ReservationTransactionBox
