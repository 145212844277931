'use strict'

import angular from 'angular'

import LoadingIndicator from '../../react/components/LoadingIndicator/LoadingIndicator.jsx'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctLoadingIndicator',
  react2angular(LoadingIndicator, ['withOverlay', 'noMargin'])
)
