'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.filter('vehicleLicense', function () {
  return function (vehicle) {
    if (vehicle && vehicle.license) {
      var countryCode = _.get(vehicle, 'country.vehiclePlateCode')

      if (countryCode) {
        return countryCode + ' ' + vehicle.license
      } else {
        return vehicle.license
      }
    }

    return ''
  }
})
