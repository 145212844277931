'use strict'

import { useMemo } from 'react'
import { getAngularService, getTranslator } from './utils'

export function useAngularService (serviceName) {
  return useMemo(() => getAngularService(serviceName), [])
}

export function useTranslator () {
  return useMemo(() => getTranslator(), [])
}
