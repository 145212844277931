'use strict'

import angular from 'angular'

import PaymentMethod from '../../react/components/PaymentMethod'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctPaymentMethod',
  react2angular(PaymentMethod, ['card', 'isCashPayment'])
)
