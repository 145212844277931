'use strict'

import libphonenumber from 'google-libphonenumber'
import { includes } from 'lodash'

const PNF = libphonenumber.PhoneNumberFormat
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

export function formatPhoneNumber (phoneNumber, countryCode) {
  if (!phoneNumber) {
    return ''
  }

  let formattedPhoneNumber

  if (!countryCode || includes(['us', 'ca'], countryCode)) {
    countryCode = countryCode || 'us'
    const number = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      countryCode.toUpperCase()
    )
    formattedPhoneNumber = phoneUtil.format(number, PNF.NATIONAL)
  } else {
    const number = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      countryCode.toUpperCase()
    )
    formattedPhoneNumber = phoneUtil.format(number, PNF.INTERNATIONAL)
  }

  return formattedPhoneNumber
}

export function getFullPhoneNumber (phoneNumber, countryCode) {
  if (phoneNumber && countryCode) {
    const number = phoneUtil.parseAndKeepRawInput(
      phoneNumber,
      countryCode.toUpperCase()
    )
    return phoneUtil.format(number, PNF.E164)
  }
}
