'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

import templateUrl from './schedule-snapshot.html'
import './schedule-snapshot.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctScheduleSnapshot', function (
  $log,
  $timeout,
  $filter,
  $i18next,
  Authentication,
  Snapshot
) {
  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {
      properties: '='
    },

    controller: function ($scope) {
      var destroyed = false
      var refreshTimeout
      var loggedUser = Authentication.getLoggedUser()

      $scope.requirePropertySelection = loggedUser.role === 'localManager'

      function log (message) {
        $log.log('schedule-snapshot: ' + message)
      }

      function load () {
        if (destroyed) {
          return
        }

        $scope.isLoading = true

        cancelRefreshTimeout()
        log('refreshing...')

        if ($scope.requirePropertySelection && !$scope.selectedProperty) {
          $scope.propertiesWithAvailabilities = []
          $scope.hasAvailabilities = false
          return
        }

        var options = {}
        if ($scope.selectedProperty) {
          options.lotId = $scope.selectedProperty.id
        }

        Snapshot.getSchedule(options)
          .then(function (response) {
            $scope.finishedFirstLoad = true
            $scope.showLoadingIndicator = false

            $scope.isLoading = false
            $scope.propertiesWithAvailabilities = response.data.lots
            $scope.hasAvailabilities = _.some(response.data.lots, function (
              lot
            ) {
              return lot.availabilities.length > 0
            })

            adjustAvailabilities($scope.propertiesWithAvailabilities)

            log('done')
          })
          .finally(function () {
            scheduleRefresh()
          })
      }

      $scope.propertyChanged = function () {
        $scope.showLoadingIndicator = true
        $scope.propertiesWithAvailabilities = []
        $scope.hasAvailabilities = false
        load()
      }

      function adjustAvailabilities (properties) {
        properties.forEach(function (property) {
          property.availabilities.forEach(function (availability) {
            if (availability.events.length) {
              availability.name = availability.events[0].name
            } else {
              availability.name = _.trim(
                availability.name.replace(property.name, '')
              )
            }

            availability.reservationCount = availability.reservationCount || 0

            availability.start = moment.tz(
              availability.start,
              availability.timezoneName
            )
            availability.end = moment.tz(
              availability.end,
              availability.timezoneName
            )

            availability.startTime = $i18next.t('defaultFormats.time', {
              date: availability.start
            })
            availability.endTime = $i18next.t('defaultFormats.time', {
              date: availability.end
            })

            var now = moment().unix()
            availability.isActive =
              now >= availability.start.unix() && now <= availability.end.unix()

            var lotAvailability = _.findWhere(availability.lots, {
              id: property.id
            })
            availability.rate = formatRate(
              lotAvailability.rate,
              property.country.currency
            )
          })
        })
      }

      function formatRate (rate, currency) {
        const options = {
          amount: rate.value,
          currency: currency
        }

        let subtype = null

        if (rate.maxRate && rate.discount) {
          subtype = 'maxRateAndEarlyBird'
          options.maxRate = rate.maxRate
          options.earlyBirdMaxRate = rate.discount.maxRate
        } else if (rate.maxRate) {
          subtype = 'maxRate'
          options.maxRate = rate.maxRate
        } else if (rate.discount) {
          subtype = 'earlyBird'
          options.maxRate = rate.discount.maxRate
        } else {
          subtype = 'simple'
        }

        return $i18next.t(
          `availabilityRateDescription.${rate.type}.${subtype}`,
          options
        )
      }

      function calculateRefreshRate () {
        var refreshRate

        if ($scope.selectedProperty) {
          refreshRate = 3
        } else if (loggedUser.role === 'localManager') {
          refreshRate = 60
        } else {
          refreshRate = Math.max(3, $scope.properties.length)
        }

        return refreshRate
      }

      function cancelRefreshTimeout () {
        $timeout.cancel(refreshTimeout)
      }

      function scheduleRefresh () {
        cancelRefreshTimeout()

        var refreshRate = calculateRefreshRate()
        log('scheduling next refresh (refreshRate = ' + refreshRate + ')')
        refreshTimeout = $timeout(load, refreshRate * 1000)
      }

      function init () {
        $scope.finishedFirstLoad = false
        $scope.showLoadingIndicator = false
        $scope.selectedProperty = null
        $scope.propertiesWithAvailabilities = []

        load()
        scheduleRefresh()

        $scope.$on('$destroy', function () {
          if (refreshTimeout) {
            $timeout.cancel(refreshTimeout)
          }

          destroyed = true
          log('destroyed')
        })
      }

      init()
    }
  }
})
