'use strict'

import React, { useEffect } from 'react'
import { Button, Text } from '@citifyd/style'
import { useFormikContext } from 'formik'
import { find } from 'lodash'

import StickyFooter from '../../../../shared/react/components/StickyFooter'
import Table from '../../../../shared/react/components/Table'
import PageHeader from '../../../../shared/react/components/PageHeader'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import { getColumns, getData } from './utils'

const TicketExchangeEvent = ({ ticket, events, setStep }) => {
  const t = useTranslator()
  const $stateParams = useAngularService('$stateParams')
  const { setFieldValue, values } = useFormikContext()
  const { selectedEvent } = values

  const showSameEvent = Boolean($stateParams.sameEvent)

  useEffect(() => {
    if (showSameEvent) {
      const event = find(events, { id: ticket.event.id })

      setFieldValue('selectedEvent', event)
      setStep(2)
    }
  }, [])

  const handleSelectEvent = event => {
    setFieldValue('selectedEvent', event)
    setFieldValue('selectedLot', '')
  }

  const columns = getColumns()
  const data = getData(events, selectedEvent?.id, handleSelectEvent)

  const renderTable = () => {
    if (!events.length) {
      return <Text>{t('ticketExchange.noEvents')}</Text>
    }

    return <Table columns={columns} data={data} striped responsive />
  }

  const handleContinue = () => {
    setStep(2)
  }

  if (showSameEvent) {
    return null
  }

  return (
    <>
      <PageHeader>
        <PageHeader.Title
          title={t('ticketExchange.exchangePass')}
          defaultState='ticket'
          defaultParams={{ id: ticket.id }}
        />
      </PageHeader>

      {renderTable()}

      {selectedEvent && (
        <StickyFooter justifyContent='flex-end'>
          <Button uppercase onMouseDown={handleContinue} extraPadding>
            {t('commonButtons.continue')}
          </Button>
        </StickyFooter>
      )}
    </>
  )
}

export default TicketExchangeEvent
