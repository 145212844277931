import moment from 'moment-timezone'

export const getLotsOptions = (lots, t) => [
  {
    label: t('revenue.revenueFilter.fields.allProperties'),
    value: ''
  },
  ...lots.map(lot => ({ label: lot.name, value: lot.id }))
]

export const selectionToParams = (selection, enableDateRangeSelection) => {
  const { lotId, currency, dateRange } = selection

  let formattedDateRange
  if (enableDateRangeSelection) {
    const startDate = moment(dateRange.start).format('YYYY-MM-DD')
    const endDate = moment(dateRange.end).format('YYYY-MM-DD')
    formattedDateRange = `${startDate}:${endDate}`
  }

  return {
    lot: lotId || undefined,
    currency: currency,
    dateRange: formattedDateRange
  }
}
