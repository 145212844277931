'use strict'

import React from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import { Text } from '@citifyd/style'

import Table from '../Table'
import { formatAvailabilityAllocation } from '../../../utils/availability-allocation-utils'

import { getCurrentLanguage } from '../../utils'
import { useTranslator } from '../../hooks'
import styles from './ReservedParkingPassRates.module.scss'

const reservedParkingPassesRates = ({ feeBreakdown }) => {
  if (!feeBreakdown) return null

  const t = useTranslator()

  const content = availabilityAllocations({ feeBreakdown })
  const data = content.filter(el => el !== null && el !== undefined)

  return (
    <>
      <Text textAlign='center' variant='h1'>
        {t('reservedParkingPassRates.title')}
      </Text>
      <Table
        className={styles.breakDownTable}
        spacing
        showHeader={false}
        columns={[{ key: 'title' }, { key: 'amount' }]}
        data={data}
      />
    </>
  )
}
const availabilityAllocations = ({ feeBreakdown }) => {
  const { availabilityAllocationsWithPriceCalculation } = feeBreakdown.firstStep
  const { currency, lot } = feeBreakdown.reservedParkingPass
  const { timezoneName } = lot

  return availabilityAllocationsWithPriceCalculation.map(allocation =>
    availabilityAllocationRow({
      availabilityAllocation: allocation.priceCalculation,
      timezoneName: timezoneName,
      currency: currency
    })
  )
}

const availabilityAllocationRow = ({
  availabilityAllocation,
  timezoneName,
  currency
}) => {
  const formatted = formatAvailabilityAllocation({
    availabilityAllocation,
    timezoneName,
    currency
  })

  return {
    title: (
      <div>
        <Text gutterBottom appearance='gray_dark' variant='small'>
          {formatted.timeRange}
        </Text>
        {formatted.rate}
      </div>
    ),
    amount: (
      <div>
        <Price amount={availabilityAllocation?.total} currency={currency} />
      </div>
    )
  }
}

const Price = ({ amount, currency }) => {
  const language = getCurrentLanguage()
  return CurrencyFormatter.format(amount, { currency, language })
}

export default reservedParkingPassesRates
