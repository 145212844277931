'use strict'

import angular from 'angular'
import { react2angular } from 'react2angular'

import ToggleButton from '../../react/components/ToggleButton'

const app = angular.module('citifydSellerApp')

app.component(
  'reactToggleButton',
  react2angular(ToggleButton, ['selected', 'onChange', 'disabled', 'offColor'])
)

app.directive('ctToggleButton', function () {
  return {
    restrict: 'E',

    template: `
      <react-toggle-button
        on-change="internalOnChange"
        selected="ngModel"
        disabled="ngDisabled"
        off-color="offColor"
      >
      </react-toggle-button>
    `.trim(),

    scope: {
      ngModel: '=',
      ngChange: '=',
      ngDisabled: '=',
      offColor: '@'
    },

    controller ($scope) {
      $scope.internalOnChange = () => {
        $scope.$apply(() => {
          $scope.ngModel = !$scope.ngModel
          if ($scope.ngChange) {
            $scope.ngChange($scope.ngModel)
          }
        })
      }
    }
  }
})
