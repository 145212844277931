'use strict'

import React from 'react'
import { NewGrid } from '@citifyd/style'
import PageHeader from '../../../shared/react/components/PageHeader'
import AddEventForm from './AddEventForm/AddEventForm.jsx'
import { useTranslator, useAngularService } from '../../../shared/react/hooks'
import Mode from '../mode'
const AddEvent = () => {
  const t = useTranslator()
  const { eventId } = useAngularService('$stateParams')
  const mode = eventId ? Mode.EDIT : Mode.ADD

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          weight='300'
          title={
            mode === Mode.ADD
              ? t('addEvent.createEvent')
              : t('addEvent.editEventPageTitle')
          }
        />
      </PageHeader>
      <AddEventForm mode={mode} eventId={eventId} />
    </NewGrid>
  )
}

export default AddEvent
