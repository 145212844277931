import gql from 'graphql-tag'

import PermitCommonFields from '../fragments/PermitCommonFields'

export default gql`
  ${PermitCommonFields}
  query AdminGetPermitPassCommonFields($id: Int) {
    admin_getPermitPass(id: $id) {
      ...PermitCommonFields
      status
    }
  }
`
