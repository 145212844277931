'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.directive('ctFocusOn', function () {
  return function (scope, elem, attr) {
    scope.$on(attr.ctFocusOn, function () {
      // setTimeout is used to move the code to end of event loop.
      // This is required for the focus to work when the element to be focused
      // is inside an element for which the visibility is bound to the same
      // event.
      setTimeout(function () {
        elem[0].focus()
      }, 0)
    })
  }
})
