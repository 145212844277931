'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Users', (API, Authentication, Intercom, Helper, $q, $http) => {
  function requestPasswordReset (email) {
    return $http({
      method: 'POST',
      url: `${API.base}/password`,
      data: { email: email }
    })
  }

  function findAllUsers (options) {
    return Helper.abortableRequest({
      method: 'GET',
      url: API.base + '/admin/users',
      headers: Authentication.generateHeaders(),
      params: options
    })
  }

  function createUser (userData) {
    return $http({
      method: 'POST',
      url: `${API.base}/users`,
      data: userData
    })
  }

  function updateUser (userId, userData) {
    const promise = $http({
      method: 'PUT',
      url: API.base + '/users/' + userId,
      headers: Authentication.generateHeaders(),
      data: userData
    })

    return promise.then(response => {
      if (response.data && response.data.user) {
        Intercom.saveUserData(response.data.user)
      }
      return response
    })
  }

  function verifyUser (userId, userData) {
    return $http({
      method: 'POST',
      url: API.base + '/users/' + userId + '/verify',
      headers: Authentication.generateHeaders(),
      data: userData
    })
  }

  function resendVerificationCode (userId, userData) {
    return $http({
      method: 'POST',
      url: API.base + '/users/' + userId + '/verify/resend',
      headers: Authentication.generateHeaders(),
      data: userData
    })
  }

  function fetchUserData (id) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/users/' + id + '/data',
      headers: Authentication.generateHeaders()
    })
  }

  function fetchUserTickets (id) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/users/' + id + '/tickets',
      headers: Authentication.generateHeaders()
    })
  }

  function fetchUserReservations (id) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/users/' + id + '/reservations',
      headers: Authentication.generateHeaders()
    })
  }

  function fetchUserSubscriptions (id) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/users/' + id + '/subscriptions',
      headers: Authentication.generateHeaders()
    })
  }

  function fetchNonCustomerUserData (id, phoneCountryCode, phoneNumber) {
    return $http({
      method: 'GET',
      url: `${API.base}/admin/users/${id}-${phoneCountryCode}-${phoneNumber}/data`,
      headers: Authentication.generateHeaders()
    })
  }

  function fetchUsersStats (params) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/users/stats',
      headers: Authentication.generateHeaders(),
      params: params
    })
  }

  function findMyOrganizationUsers () {
    return $http({
      method: 'GET',
      url: API.base + '/me/organization/users',
      headers: Authentication.generateHeaders()
    })
  }

  function inviteUserToMyOrganization (userData) {
    return $http({
      method: 'POST',
      url: API.base + '/me/organization/invitations',
      headers: Authentication.generateHeaders(),
      data: { invitation: userData }
    })
  }

  function joinOrganizationInvitation (invitationCode, data) {
    return $http({
      method: 'PUT',
      url:
        API.base + '/me/organization/invitations/' + invitationCode + '/join',
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  function fetchOrganizationUserInvitation (invitationCode) {
    return $http({
      method: 'GET',
      url: API.base + '/me/organization/invitations/' + invitationCode,
      headers: Authentication.generateHeaders()
    })
  }

  function updateMyOrganizationUserInvitation (invitationCode, userData) {
    return $http({
      method: 'PUT',
      url: API.base + '/me/organization/invitations/' + invitationCode,
      headers: Authentication.generateHeaders(),
      data: { invitation: userData }
    })
  }

  function deleteMyOrganizationUserInvitation (invitationCode) {
    return $http({
      method: 'DELETE',
      url: API.base + '/me/organization/invitations/' + invitationCode,
      headers: Authentication.generateHeaders()
    })
  }

  function updateMyOrganizationUser (userId, userData) {
    return $http({
      method: 'PUT',
      url: API.base + '/me/organization/users/' + userId,
      headers: Authentication.generateHeaders(),
      data: { user: userData }
    })
  }

  function deleteMyOrganizationUser (userId) {
    return $http({
      method: 'DELETE',
      url: API.base + '/me/organization/users/' + userId,
      headers: Authentication.generateHeaders()
    })
  }

  function fetchNotifications (userId) {
    return $http({
      method: 'GET',
      url: API.base + '/users/' + userId + '/notifications',
      headers: Authentication.generateHeaders()
    })
  }

  function fetchUserTicketsByEvent (userId) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/users/' + userId + '/tickets-by-event',
      headers: Authentication.generateHeaders()
    })
  }

  function fetchPasswordRules (options) {
    const endpoint = Authentication.isLoggedIn()
      ? 'me/password-rules'
      : 'password-rules'

    const promise = $http({
      method: 'GET',
      url: `${API.base}/${endpoint}`,
      headers: Authentication.generateHeaders(),
      params: options
    })

    return promise.then(result => result.data.passwordRules)
  }

  function fetchMigrationTickets (email, phoneNumber, userId) {
    return $http({
      method: 'GET',
      url: API.base + '/admin/migration/tickets',
      headers: Authentication.generateHeaders(),
      params: { email: email, phoneNumber: phoneNumber, userId: userId }
    })
  }

  function migrateTickets (userId) {
    return $http({
      method: 'POST',
      url: API.base + '/admin/users/' + userId + '/migration',
      headers: Authentication.generateHeaders()
    })
  }

  function checkExistentAccounts (data) {
    return $http({
      method: 'POST',
      url: API.base + '/admin/users/existence-check',
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  function createParkerSignupInvitation (data) {
    return $http({
      method: 'POST',
      url: API.base + '/admin/parker-signup-invitations',
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  return {
    findAll: findAllUsers,
    createUser: createUser,
    updateUser: updateUser,
    verifyUser: verifyUser,
    resendVerificationCode: resendVerificationCode,
    findMyOrganizationUsers: findMyOrganizationUsers,
    fetchData: fetchUserData,
    fetchTickets: fetchUserTickets,
    fetchReservations: fetchUserReservations,
    fetchSubscriptions: fetchUserSubscriptions,
    fetchNonCustomerData: fetchNonCustomerUserData,
    fetchStats: fetchUsersStats,
    inviteUserToMyOrganization: inviteUserToMyOrganization,
    joinOrganizationInvitation: joinOrganizationInvitation,
    fetchOrganizationUserInvitation: fetchOrganizationUserInvitation,
    updateMyOrganizationUserInvitation: updateMyOrganizationUserInvitation,
    deleteMyOrganizationUserInvitation: deleteMyOrganizationUserInvitation,
    updateMyOrganizationUser: updateMyOrganizationUser,
    deleteMyOrganizationUser: deleteMyOrganizationUser,
    fetchNotifications: fetchNotifications,
    requestPasswordReset: requestPasswordReset,
    fetchUserTicketsByEvent: fetchUserTicketsByEvent,
    fetchPasswordRules: fetchPasswordRules,
    fetchMigrationTickets: fetchMigrationTickets,
    migrateTickets: migrateTickets,
    checkExistentAccounts: checkExistentAccounts,
    createParkerSignupInvitation: createParkerSignupInvitation
  }
})
