'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Cards', (Helper, API, Authentication, $http) => {
  function createCard (userId, data) {
    return $http({
      method: 'POST',
      url: API.base + '/users/' + userId + '/cards',
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  function findAllCards (userId) {
    return $http({
      method: 'GET',
      url: API.base + '/users/' + userId + '/cards',
      headers: Authentication.generateHeaders()
    })
  }

  return {
    create: createCard,
    findAll: findAllCards
  }
})
