import { min } from 'lodash'
import moment from 'moment-timezone'
import {
  getAngularService,
  getTranslator
} from '../../../../shared/react/utils'
import { getLastName } from '../../../../shared/utils/utils'

export const getPriceFromRate = rate => {
  return rate.value + rate.fee
}

export const calculateTicketPrice = ticket => {
  if (ticket.purchaseTransaction) {
    return ticket.purchaseTransaction.totalAmount
  }

  if (ticket.ticketExchanges.length) {
    const oldestExchange = min(ticket.ticketExchanges, function (exchange) {
      return moment(exchange.createdAt).unix()
    })

    const rate = oldestExchange.previousRate
    return getPriceFromRate(rate)
  }

  if (ticket.rate) {
    return getPriceFromRate(ticket.rate)
  }

  return null
}

export const getCardDetails = (isComplimentary, card) => {
  const t = getTranslator()

  if (isComplimentary || !card) {
    return {
      lastNameOnCard: '',
      cardExpirationDate: '',
      paymentStatus: isComplimentary
        ? t('transaction.ticketMainTransactionBox.statuses.complimentary')
        : ''
    }
  }

  return {
    lastNameOnCard: getLastName(card.name),
    cardExpirationDate: card.expMonth ? card.expMonth + '/' + card.expYear : '',
    paymentStatus: t('transaction.ticketMainTransactionBox.statuses.charged')
  }
}

export const shouldShowAvailableDates = ticket => {
  const Helper = getAngularService('Helper')

  const shouldShowAvailabilityStartDate = () => {
    return (
      ticket &&
      !Helper.isSameDate(
        ticket.availability.start,
        ticket.event.start,
        ticket.availability.timezoneName
      )
    )
  }

  const shouldShowAvailabilityEndDate = () => {
    if (
      !ticket ||
      Helper.isSameDate(ticket.availability.start, ticket.availability.end)
    ) {
      return false
    }

    const dayAfterStart = moment
      .tz(ticket.availability.start, ticket.availability.timezoneName)
      .add(1, 'day')

    return !Helper.isSameDate(dayAfterStart, ticket.availability.end)
  }

  return {
    shouldShowAvailabilityStartDate: shouldShowAvailabilityStartDate(),
    shouldShowAvailabilityEndDate: shouldShowAvailabilityEndDate()
  }
}
