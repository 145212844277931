'use strict'

import React from 'react'
import classNames from 'classnames'

import './style.scss'

export default function AlertBox ({ children, className, noMarginBottom }) {
  const adjustedClassName = classNames(
    'alert-box',
    { 'alert-box--no-margin-bottom': noMarginBottom },
    className
  )

  return <div className={adjustedClassName}>{children}</div>
}
