'use strict'

import angular from 'angular'
import _ from 'lodash'
import jQuery from 'jquery'

import templateUrl from './phone-number.html'
import './phone-number.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctPhoneNumber', Helper => {
  return {
    templateUrl: templateUrl,

    scope: {
      autofocus: '=',
      phoneNumber: '=',
      countryCode: '=',
      sameContinentOnly: '=',
      readOnly: '=',
      hideForContinent: '@',
      placeholder: '@',
      countries: '='
    },

    controller: $scope => {
      $scope.showOptions = false
      $scope.selectedCountry = null
      $scope.plainCountries = []

      $scope.$watch('countryCode', () => {
        if ($scope.countryCode) {
          $scope.selectedCountry = getSelectedCountry()
        }

        recalculatePlainCountries()
      })

      $scope.$watch('countries', () => {
        recalculatePlainCountries()
      })

      $scope.selectCountry = country => {
        $scope.countryCode = country.isoCode
        $scope.showOptions = false
      }

      $scope.toggleCountrySelection = () => {
        if ($scope.readOnly) {
          $scope.showOptions = false
          return
        }

        $scope.showOptions = !$scope.showOptions
      }

      function getSelectedCountry () {
        return findCountry($scope.countries, $scope.countryCode)
      }

      function recalculatePlainCountries () {
        if ($scope.countries) {
          $scope.plainCountries = Helper.planifyCountries($scope.countries)

          if ($scope.sameContinentOnly) {
            let selectedCountry = getSelectedCountry()
            if (selectedCountry) {
              $scope.plainCountries = _.filter($scope.plainCountries, {
                continent: selectedCountry.continent
              })
            }
          }

          $scope.selectedCountry = getSelectedCountry()
        } else {
          $scope.plainCountries = []
        }
      }

      function findCountry (countries, isoCode) {
        countries = Helper.planifyCountries(countries)
        return _.findWhere(countries, { isoCode })
      }
    },

    link: (scope, element) => {
      if (scope.autofocus) {
        setTimeout(() => {
          let input = jQuery(element).find('input')[0]
          if (input) {
            input.focus()
          }
        }, 600)
      }

      // Closes dropdown when clicking off
      function onClickOutside () {
        scope.showOptions = false
        scope.$apply()
      }

      jQuery(element)
        .find('.selected-country, .country-options')
        .on('click', e => e.stopPropagation())
      jQuery('body').on('click', onClickOutside)
      scope.$on('$destroy', () => jQuery('body').off('click', onClickOutside))
    }
  }
})
