import gql from 'graphql-tag'

export default gql`
  fragment CaAddress on CaAddress {
    line1
    line2
    city
    state
    postalCode
  }
`
