'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('API', ENV => {
  return {
    base: ENV.apiUrl
  }
})
