'use strict'

import React, { useState, useEffect, useMemo } from 'react'
import { NewGrid, Text, Icon } from '@citifyd/style'
import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment-timezone'

import Address from '../../shared/react/components/Address'
import { formatPhoneNumber } from '../../shared/utils/phone-number-formatting'
import LoadingManager from '../../shared/react/components/LoadingManager'
import BoxContent from '../../shared/react/components/BoxContent'
import ApolloClient from '../../shared/services/apolloClient'
import Dictionary, {
  DictionaryItem
} from '../../shared/react/components/Dictionary'
import PageHeader from '../../shared/react/components/PageHeader'
import RouterLink from '../../shared/react/components/RouterLink'
import PermitBadge from './components/PermitBadge'
import { permitStatus } from './utils'
import { useAngularService, useTranslator } from '../../shared/react/hooks'
import styles from './Permit.module.scss'
import ADMIN_GET_PERMIT_PASS from '../../graphql/permitPass/queries/AdminGetPermitPass'
import ADMIN_DELETE_PERMIT_PASS from '../../graphql/permitPass/mutations/AdminDeletePermitPass'

const apolloClient = ApolloClient()

const Permit = () => {
  const t = useTranslator()
  const $stateParams = useAngularService('$stateParams')

  const [hasError, setHasError] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(
    t('permits.information.loading')
  )
  const { permitId, status, dateRange, search } = $stateParams

  const [
    fetchPermit,
    {
      data: fetchPermitData,
      loading: fetchPermitLoading,
      error: fetchPermitError
    }
  ] = useLazyQuery(ADMIN_GET_PERMIT_PASS, {
    variables: {
      id: parseInt(permitId)
    },
    client: apolloClient,
    fetchPolicy: 'network-only'
  })

  const [
    deletePermit,
    {
      data: deletePermitData,
      loading: deletePermitLoading,
      error: deletePermitError
    }
  ] = useMutation(ADMIN_DELETE_PERMIT_PASS, {
    client: apolloClient
  })

  useEffect(() => {
    fetchPermit()
  }, [])

  useEffect(() => {
    if (deletePermitData) {
      fetchPermit()
    }
  }, [deletePermitData])

  useEffect(() => {
    if (fetchPermitError || deletePermitError) {
      setHasError(true)
    }
  }, [fetchPermitError, deletePermitError])

  const isLoading = useMemo(() => fetchPermitLoading || deletePermitLoading, [
    fetchPermitLoading,
    deletePermitLoading
  ])

  const errorMessage = useMemo(
    () => (fetchPermitError ?? deletePermitError)?.message,
    [fetchPermitError, deletePermitError]
  )

  const handleDelete = async () => {
    if (window.confirm(t('permits.information.confirmCancel'))) {
      setLoadingMessage(t('permits.information.canceling'))

      deletePermit({
        variables: {
          id: parseInt(permitId)
        }
      })
    }
  }

  const onClickTryAgain = () => {
    setHasError(false)

    if (fetchPermitError) {
      fetchPermit()
    }
  }

  if (isLoading || hasError || !fetchPermitData) {
    return (
      <LoadingManager
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        hasError={hasError}
        errorMessage={errorMessage}
        onClickTryAgain={onClickTryAgain}
      />
    )
  }

  const { admin_getPermitPass: permit } = fetchPermitData

  const getDate = (date, timezoneName) => moment.tz(date, timezoneName)

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          defaultState='permits'
          defaultParams={{ status, search, dateRange }}
          weight='300'
          title={t('permits.backToPermits')}
        />
      </PageHeader>

      <BoxContent>
        <BoxContent.Header title={t('permits.permitInformation')}>
          {permit.status !== 'PAST' && permit.status !== 'REMOVED' && (
            <BoxContent.HeaderActions>
              <RouterLink state='edit-permit' params={{ permitId: permit.id }}>
                <Icon
                  icon='pencil-alt'
                  appearance='white'
                  size='h2'
                  className={styles.icon}
                  title={t('permits.information.edit')}
                />
              </RouterLink>

              <Icon
                icon='trash-alt'
                appearance='white'
                size='h2'
                className={styles.icon}
                onClick={() => handleDelete()}
                title={t('permits.information.cancel')}
              />
            </BoxContent.HeaderActions>
          )}
        </BoxContent.Header>
        <BoxContent.Content>
          <NewGrid.Row>
            <NewGrid.Col xs={12} md={6}>
              <Text component='h3' variant='h3' bold>
                {permit.lot?.name}
              </Text>
            </NewGrid.Col>
            <NewGrid.Col xs={12} md={6} align='end'>
              <PermitBadge status={permit.status}>
                {t(`permits.status.${permitStatus[permit.status]}`)}
              </PermitBadge>
            </NewGrid.Col>
          </NewGrid.Row>
          <NewGrid.Row>
            <NewGrid.Col xs={12} md={6}>
              <NewGrid.Row>
                <NewGrid.Col>
                  <Dictionary>
                    <DictionaryItem
                      label={t('permits.information.phoneNumber')}
                    >
                      {permit.phoneNumber?.length > 2
                        ? formatPhoneNumber(
                            permit.phoneNumber,
                            permit.phoneCountryCode
                          )
                        : '—'}
                    </DictionaryItem>

                    <DictionaryItem label={t('permits.information.license')}>
                      {permit.vehicle?.license || '—'}
                    </DictionaryItem>

                    <DictionaryItem label={t('permits.information.start')}>
                      {t('defaultFormats.datetime', {
                        date: getDate(
                          permit.startTime,
                          permit.lot?.timezoneName
                        )
                      })}
                    </DictionaryItem>

                    <DictionaryItem label={t('permits.information.end')}>
                      {t('defaultFormats.datetime', {
                        date: getDate(permit.endTime, permit.lot?.timezoneName)
                      })}
                    </DictionaryItem>

                    <DictionaryItem
                      multiline
                      label={t('permits.information.lotAddress')}
                    >
                      <Address entity={permit.lot} format='full' />
                    </DictionaryItem>
                  </Dictionary>
                </NewGrid.Col>
              </NewGrid.Row>
            </NewGrid.Col>
            <NewGrid.Col xs={12} md={6}>
              <NewGrid.Row>
                <NewGrid.Col>
                  <Dictionary>
                    {/* <DictionaryItem label={t('permits.information.frequency')}>
                      One-time use
                    </DictionaryItem> */}

                    <DictionaryItem label={t('permits.information.activated')}>
                      {permit.activatedAt
                        ? t('defaultFormats.datetime', {
                            date: getDate(
                              permit.activatedAt,
                              permit.lot?.timezoneName
                            )
                          })
                        : '—'}
                    </DictionaryItem>
                    <DictionaryItem label={t('permits.information.requester')}>
                      {permit.requester?.name || '—'}
                    </DictionaryItem>
                  </Dictionary>
                </NewGrid.Col>
              </NewGrid.Row>
            </NewGrid.Col>
          </NewGrid.Row>
        </BoxContent.Content>
      </BoxContent>
    </NewGrid>
  )
}

export default Permit
