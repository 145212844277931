'use strict'

import React from 'react'
import { Input } from '@citifyd/style'

const SearchInput = props => {
  return (
    <Input
      type='search'
      iconLeft='search'
      iconAppearance='secondary'
      {...props}
    />
  )
}

export default SearchInput
