/* global google:false */

'use strict'

import angular from 'angular'
import AddressFormatter from '@citifyd/address-formatter'

import moment from 'moment-timezone'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.factory('Geo', (ENV, Helper, $q, $http, $log) => {
  var DEFAULT_TIMEZONE = 'America/Los_Angeles'

  // Get timezoneName based on a coordinate
  // You can pass two arguments containing the latitude and longitude,
  // or one argument that is an object with `latitude` and `longitude` keys
  function getTimezoneNameFromCoordinates (a, b) {
    var latitude, longitude

    if (hasCoordinates(a)) {
      latitude = a.latitude
      longitude = a.longitude
    } else {
      latitude = a
      longitude = b
    }

    function logError () {
      $log.error(
        'Error trying to retrieve timezone from coordinates (' +
          latitude +
          ', ' +
          longitude +
          '). Defaulting to "' +
          DEFAULT_TIMEZONE +
          '"'
      )
    }

    var url =
      'https://maps.googleapis.com/maps/api/timezone/json?location=' +
      latitude +
      ',' +
      longitude +
      '&timestamp=' +
      moment().unix() +
      '&key=' +
      ENV.googleMapsApiKey

    return $http({
      method: 'GET',
      url: url,
      // Clear default headers to prevent errors with Access-Control-Allow-Headers
      headers: Helper.generateDefaultHeadersClearObject()
    }).then(
      function (response) {
        var timezoneName = _.get(response, 'data.timeZoneId')
        if (!timezoneName) {
          logError()
        }

        return _.get(response, 'data.timeZoneId', DEFAULT_TIMEZONE)
      },

      function () {
        logError()
        return DEFAULT_TIMEZONE
      }
    )
  }

  // Gets the coordinates from an address
  // It returns a promise that resolves with an object containing `latitude` and `longitude` keys
  // The `address` argument can be a string or an object.
  // If it's an object, you can specify the following keys: `line1`, `line2`, `postalCode`, `city`
  // and `state`
  function getCoordinates (address, countryCode = 'us') {
    var geocoder = new google.maps.Geocoder()

    if (typeof address === 'object') {
      address = AddressFormatter.format(
        address,
        countryCode,
        'full_inline'
      ).join('\n')
      address += ', ' + countryCode.toUpperCase()
    }

    return $q(function (resolve, reject) {
      geocoder.geocode({ address: address }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          var loc = results[0].geometry.location
          resolve({ latitude: loc.lat(), longitude: loc.lng() })
        } else {
          reject(status)
        }
      })
    })
  }

  // Calculates the distance between two lat/lng coordinates
  // Arguments can be numbers or objects.
  //
  // Numbers form (lat1, lon1, lat2, lon2):
  // getDistanceBetweenCoordinates(45.531860, 122.666819, 45.530627, -122.680121)
  //
  // Objects form (each one should contain `latitude` and `longitude` properties):
  // getDistanceBetweenCoordinates(
  //   { latitude: 45.531860, longitude: 122.666819 },
  //   { latitude: 45.530627, longitude: -122.680121 }
  // )
  //
  // Based on:
  // http://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
  function getDistanceBetweenCoordinates (arg1, arg2, arg3, arg4) {
    var lat1, lon1, lat2, lon2

    if (hasCoordinates(arg1)) {
      lat1 = arg1.latitude
      lon1 = arg1.longitude
      lat2 = arg2.latitude
      lon2 = arg2.longitude
    } else {
      lat1 = arg1
      lon2 = arg2
      lat1 = arg3
      lon2 = arg4
    }

    var R = 6378.137
    var dLat = ((lat2 - lat1) * Math.PI) / 180
    var dLon = ((lon2 - lon1) * Math.PI) / 180
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c
    return d * 1000
  }

  function hasCoordinates (obj) {
    return _.isNumber(obj.latitude) && _.isNumber(obj.longitude)
  }

  return {
    getCoordinates: getCoordinates,
    hasCoordinates: hasCoordinates,
    getTimezoneNameFromCoordinates: getTimezoneNameFromCoordinates,
    getDistanceBetweenCoordinates: getDistanceBetweenCoordinates
  }
})
