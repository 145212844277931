'use strict'

import angular from 'angular'

import templateUrl from './address-form.html'
import './address-form.scss'

import layouts from './layouts'

const app = angular.module('citifydSellerApp')

app.directive('ctAddressForm', Settings => {
  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {
      editable: '=',
      address: '=',
      onChangeField: '=',
      countryCode: '=',
      onStartLoading: '=',
      onFinishLoading: '=',
      inputNamesPrefix: '@'
    },

    controller ($scope) {
      $scope.layout = null
      $scope.addressSchema = null

      $scope.$watch('countryCode', () => {
        if ($scope.countryCode) {
          loadSchema()
        }
      })

      function loadSchema () {
        if ($scope.onStartLoading) {
          $scope.onStartLoading()
        }

        Settings.getDetailedCountryData($scope.countryCode).then(
          countryInfo => {
            if ($scope.onFinishLoading) {
              $scope.onFinishLoading()
            }

            $scope.addressSchema = countryInfo.schemas.address
            $scope.layout = layouts[$scope.countryCode]
          }
        )
      }
    }
  }
})
