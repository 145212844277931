'use strict'

import React from 'react'
import { Text } from '@citifyd/style'

import Table from '../../../../shared/react/components/Table'
import { useTranslator } from '../../../../shared/react/hooks'
import { getData } from './utils'

const TicketExchangeConfirmationRefund = React.memo(
  ({ canSeeRefundCardExpiration, refundDestinations, ticket }) => {
    const t = useTranslator()

    const { columns, data } = getData(
      canSeeRefundCardExpiration,
      refundDestinations,
      ticket
    )

    return (
      <>
        <Text variant='h5' gutterBottom>
          {t('ticketExchange.refundDestination')}
        </Text>

        <Table columns={columns} data={data} striped responsive />
      </>
    )
  }
)

export default TicketExchangeConfirmationRefund
