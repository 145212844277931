'use strict'

import angular from 'angular'
import uuid from 'uuid/v4'

const app = angular.module('citifydSellerApp')
const ACCESS_TOKEN_COOKIE_NAME = 'citifyd_access_token'

app.factory(
  'Authentication',
  (API, Helper, $http, $cookies, $rootScope, $state, $window, Languages) => {
    let impersonatedUser = null
    let impersonatedUserId = null
    let loggedUser = null
    let permissions = []
    let sessionRenewalInterval = null

    return {
      getAccessToken () {
        return $cookies.get(ACCESS_TOKEN_COOKIE_NAME) || null
      },

      getClientToken () {
        let token = $window.localStorage.getItem('citifydClientToken')

        if (!token) {
          token = uuid()
          $window.localStorage.setItem('citifydClientToken', token)
        }

        return token
      },

      isLoggedIn () {
        return Boolean(loggedUser)
      },

      getPermissions () {
        return permissions
      },

      getLoggedUser () {
        return impersonatedUser || loggedUser
      },

      getOriginalLoggedUser () {
        return loggedUser
      },

      updateRootScope () {
        $rootScope.originalUser = loggedUser
        $rootScope.loggedUser = this.getLoggedUser()

        $rootScope.user = this.getLoggedUser() // deprecated

        $rootScope.returnToOriginalUser = () => {
          impersonatedUser = null
          impersonatedUserId = null
          this.updateRootScope()
          this.reloadUser()

          $state.go('dashboard', {}, { location: 'replace', reload: true })
        }
      },

      reloadUser () {
        const request = $http({
          method: 'GET',
          url: `${API.base}/me?loadPermissions=true`,
          headers: this.generateHeaders()
        })

        return request.then(response => {
          if (impersonatedUserId) {
            impersonatedUser = response.data.user
          } else {
            loggedUser = response.data.user
            Languages.change(loggedUser.language)
          }

          permissions = response.data.permissions

          this.updateRootScope()
          return this.getLoggedUser()
        })
      },

      logout () {
        $cookies.remove(ACCESS_TOKEN_COOKIE_NAME)

        impersonatedUser = null
        impersonatedUserId = null
        loggedUser = null
        permissions = []

        this.updateRootScope()
        this.endSessionRenewalInterval()
      },

      tryAuthenticate (data) {
        const language = Languages.getCurrent()

        const request = $http({
          method: 'POST',
          url: `${API.base}/login?loadPermissions=true`,
          data: {
            ...data,
            sessionType: 'management'
          },
          headers: {
            'Citifyd-client-token': this.getClientToken()
          }
        })

        return request.then(response => {
          if (response.data.user) {
            loggedUser = response.data.user
            permissions = response.data.permissions

            this.persistToken(response.data.user.accessToken)
            this.updateRootScope()
            this.startSessionRenewalInterval()

            /**
             * Customers can change their preferred language before login, and this change should be persisted.
             * In case this happens, we send a broadcast down to our ctLanguagueSelector directive to update
             * the user's profile with the appropriate language.
             */
            if (
              language !== loggedUser.language &&
              !Languages.hasUserUpdated()
            ) {
              Languages.change(loggedUser.language)
            } else if (language !== loggedUser.language) {
              $rootScope.$broadcast('persist-user-language', language)
            }
          }

          return response.data
        })
      },

      verifyAuthentication (loggedInCallback, notLoggedInCallback) {
        if (!this.getAccessToken()) {
          return notLoggedInCallback()
        }

        this.reloadUser().then(
          user => {
            if (user.currentSession.type !== 'management') {
              this.logout()
              notLoggedInCallback()
              return
            }

            this.renewSession()
            this.startSessionRenewalInterval()
            loggedInCallback()
          },

          () => notLoggedInCallback()
        )
      },

      startSessionRenewalInterval () {
        this.endSessionRenewalInterval()

        sessionRenewalInterval = setInterval(
          () => this.renewSession(),
          10 * 60 * 1000 // 10 minutes
        )
      },

      endSessionRenewalInterval () {
        if (sessionRenewalInterval !== null) {
          clearInterval(sessionRenewalInterval)
          sessionRenewalInterval = null
        }
      },

      renewSession () {
        if (!this.getAccessToken()) {
          return Helper.promise(false)
        }

        const request = $http({
          method: 'PUT',
          url: API.base + '/me/sessions/current',
          headers: this.generateHeaders()
        })

        return request.then(response => {
          if (response.data.renewed) {
            this.persistToken(response.data.accessToken)
            return true
          }

          return false
        })
      },

      persistToken (token) {
        $cookies.put(ACCESS_TOKEN_COOKIE_NAME, token)
      },

      setToken (token) {
        impersonatedUserId = null
        impersonatedUser = null

        this.persistToken(token)

        return this.reloadUser().then(result => {
          this.renewSession()
          this.startSessionRenewalInterval()

          return result
        })
      },

      impersonateUser (userId) {
        impersonatedUserId = userId
        return this.reloadUser()
      },

      generateHeaders () {
        const headers = {}
        const accessToken = this.getAccessToken()

        if (accessToken) {
          headers['Authorization'] = `Bearer ${accessToken}`
        }

        if (impersonatedUserId) {
          headers['Impersonate-user-id'] = impersonatedUserId
        }

        const clientToken = this.getClientToken()
        headers['Citifyd-client-token'] = clientToken

        return headers
      },

      mountAuthenticatedUrl (url) {
        const accessToken = this.getAccessToken()
        if (!accessToken) {
          return url
        }

        const glue = url.indexOf('?') !== -1 ? '&' : '?'
        let authenticatedUrl = `${url}${glue}access_token=${accessToken}`

        if (impersonatedUserId) {
          authenticatedUrl += `&impersonate_user_id=${impersonatedUserId}`
        }

        return authenticatedUrl
      }
    }
  }
)
