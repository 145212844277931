import gql from 'graphql-tag'

export default gql`
  fragment PermitCommonFields on PermitPass {
    id
    lot {
      name
      id
      timezoneName
    }
    phoneNumber
    phoneCountryCode
    startTime
    endTime
  }
`
