import AddressFormatter from '@citifyd/address-formatter'

import { getAngularService } from '../../../../shared/react/utils'

export const openMigrationTicketsInformation = (migrationTickets, user) => {
  const CitifydModal = getAngularService('CitifydModal')
  const $state = getAngularService('$state')

  CitifydModal.open(
    'user-migration-tickets',
    { migrationTickets, user },
    result => {
      if (result && result.action === 'refresh') {
        $state.go($state.current, {}, { reload: true })
      }
    }
  )
}

export const getAddress = user =>
  AddressFormatter.format(user.market.address, user.market.countryCode, 'city')

export const openUserUpdate = (type, user, loadUser) => {
  const CitifydModal = getAngularService('CitifydModal')

  CitifydModal.open(type, { user }, result => {
    if (result?.action === 'confirmed') {
      loadUser()
    }
  })
}
