import { useFormik } from 'formik'
import * as yup from 'yup'
import { getAngularService } from '../../../react/utils'
import Mode from '../../../utils/mode'
import View from '../Utils/view'

const useManageOrganizationUser = ({
  mode,
  onClose,
  isEditingMyself,
  userOrInvitation,
  setIsSubmitting,
  setView
}) => {
  return useFormik({
    initialValues: {
      name: '',
      email: '',
      organizationRole: 'user'
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      email: yup
        .string()
        .email()
        .required(),
      organizationRole: yup.string()
    }),
    onSubmit: async values => {
      const Helper = getAngularService('Helper')
      const Users = getAngularService('Users')

      const data = {
        name: values.name,
        email: values.email,
        organizationRole: values.organizationRole
      }

      try {
        setIsSubmitting(true)
        if (mode === Mode.ADD) {
          await Users.inviteUserToMyOrganization(data)

          setView(View.ADDED)
        } else if (mode === Mode.EDIT) {
          if (userOrInvitation.type === 'invitation') {
            await Users.updateMyOrganizationUserInvitation(
              userOrInvitation.code,
              data
            )
          } else if (isEditingMyself) {
            await Users.updateUser(
              userOrInvitation.id,
              _.pick(data, ['name', 'email'])
            )
          } else {
            await Users.updateMyOrganizationUser(
              userOrInvitation.id,
              _.pick(data, ['organizationRole'])
            )
          }

          onClose({ refreshUsers: true })
        }
      } catch (response) {
        Helper.showErrorAlert(response.data)
      } finally {
        setIsSubmitting(false)
      }
    }
  })
}

export default useManageOrganizationUser
