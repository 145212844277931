'use strict'

import React, { useMemo } from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import _ from 'lodash'
import { Text } from '@citifyd/style'

import BoxContent from '../BoxContent'
import Table from '../Table'
import TransactionExtraInformation from '../TransactionExtraInformation'

import { useTranslator } from '../../hooks'
import { getCurrentLanguage } from '../../utils'

function CancellationBox ({ reservation }) {
  const t = useTranslator()
  const language = getCurrentLanguage()

  const refundAmount = useMemo(() => {
    if (reservation.cancellation.refunded) {
      const amount = _(reservation.cancellation.transactions)
        .filter({ type: 'refund' })
        .sum('totalAmount')
      return -amount
    } else {
      return null
    }
  }, [reservation])

  const refundValue =
    refundAmount !== null
      ? CurrencyFormatter.format(refundAmount, {
          currency: reservation.currency,
          language
        })
      : t('transaction.inProcess')

  return (
    <BoxContent>
      <BoxContent.Header
        appearance='tertiary'
        title={t('transaction.cancellationInfo')}
      />
      <BoxContent.Content>
        <Table
          cleanMode
          showHeader={false}
          columns={[{ key: 'title' }, { key: 'amount' }]}
          data={[
            {
              borderBottom: true,
              title: <Text weight='600'>{t('transaction.cancelledAt')}</Text>,
              amount: {
                value: t('defaultFormats.datetime', {
                  date: reservation.cancellation.createdAt
                }),
                textAlign: 'right'
              }
            },
            {
              title: (
                <Text weight='600'>{t('transaction.amountRefunded')}</Text>
              ),
              amount: {
                value: refundValue,
                textAlign: 'right'
              }
            }
          ]}
        />
      </BoxContent.Content>

      {reservation.cancellation.user.id !== reservation.user.id && (
        <BoxContent.Footer>
          <Text bold gutterBottom>
            {t('transaction.fulfilledBy', {
              name: reservation.cancellation.user.name
            })}
          </Text>
          {reservation.cancellation.reason && (
            <div>
              <Text>{t('transaction.ifPasses')}</Text>
              <Text>{reservation.cancellation.reason}</Text>
              <Text italic>
                <TransactionExtraInformation data={reservation.cancellation} />
              </Text>
            </div>
          )}
        </BoxContent.Footer>
      )}
    </BoxContent>
  )
}

export default CancellationBox
