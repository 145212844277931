'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('ReferralCodes', (API, Authentication, $http) => {
  function findAllReferralCodes () {
    let promise = $http({
      method: 'GET',
      url: API.base + '/admin/referral-codes',
      headers: Authentication.generateHeaders()
    })

    return promise.then(response => response.data.referralCodes)
  }

  function findReferralCode (code) {
    let promise = $http({
      method: 'GET',
      url: API.base + '/admin/referral-codes/' + code,
      headers: Authentication.generateHeaders()
    })

    return promise.then(response => response.data.referralCode)
  }

  function createReferralCode (data) {
    return $http({
      method: 'POST',
      url: API.base + '/admin/referral-codes',
      headers: Authentication.generateHeaders(),
      data: { referralCode: data }
    })
  }

  function updateReferralCode (code, data) {
    return $http({
      method: 'PUT',
      url: API.base + '/admin/referral-codes/' + code,
      headers: Authentication.generateHeaders(),
      data: { referralCode: data }
    })
  }

  function toggleReferralCode (code, active) {
    return $http({
      method: 'PUT',
      url: API.base + '/admin/referral-codes/' + code + '/toggle',
      headers: Authentication.generateHeaders(),
      data: { toggle: { active: active } }
    })
  }

  return {
    findAll: findAllReferralCodes,
    find: findReferralCode,
    create: createReferralCode,
    update: updateReferralCode,
    toggle: toggleReferralCode
  }
})
