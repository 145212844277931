'use strict'

import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Text } from '@citifyd/style'

import { getTranslator } from '../../../../shared/react/utils'
import { useTranslator } from '../../../../shared/react/hooks'
import {
  generateOrderForClickEvent,
  sort
} from '../../../../shared/react/sorting'
import Table from '../../../../shared/react/components/Table'
import LoadingManager from '../../../../shared/react/components/LoadingManager'
import { getFormattedTotal, getData } from './utils'

function OnDemandReservations ({ reservations, isLoading, hasError }) {
  const t = useTranslator()

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        loadingMessage={t('user.reservations.loading')}
        hasError={hasError}
        errorMessage={t('user.errorLoadingPasses')}
      />
    )
  }

  if (reservations.length === 0) {
    return <Text>{t('user.reservations.empty')}</Text>
  }

  return <ReservationsTable reservations={reservations} />
}

const initialOrders = [['start', 'desc']]
const getSort = (items, order) => ({ items, order, sortFunctions })

function ReservationsTable ({ reservations }) {
  const [sortedReservations, setSortedReservations] = useState(
    sort(getSort(reservations, initialOrders))
  )
  const [orders, setOrders] = useState(initialOrders)
  const firstSort = useRef(true)

  useEffect(() => {
    if (!firstSort.current) {
      setSortedReservations(sort(getSort(reservations, orders)))
    } else {
      firstSort.current = false
    }
  }, [reservations, orders])

  const formattedTotal = useMemo(() => getFormattedTotal(reservations), [
    reservations
  ])

  const handleSort = (event, property) => {
    setOrders(
      generateOrderForClickEvent({
        event,
        currentOrder: orders,
        propertyName: property
      })
    )
  }

  const { columns, data } = getData({
    reservations: sortedReservations,
    handleSort,
    orders,
    formattedTotal
  })

  return (
    <>
      <Table
        columns={columns}
        data={data}
        sortable
        clickable
        responsive
        striped
        borderBottom
      />
    </>
  )
}

const sortFunctions = {
  status: status => {
    const t = getTranslator()
    return t(`reservations.statuses.${status}`).toLowerCase()
  },
  amountCharged: amountCharged => {
    let priceAsNumber = Number(amountCharged)
    if (isNaN(priceAsNumber)) priceAsNumber = 0
    return priceAsNumber
  }
}

export default OnDemandReservations
