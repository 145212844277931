'use strict'

import angular from 'angular'

import './user-update-email.html'

const app = angular.module('citifydSellerApp')

app.controller('UserUpdateEmailModalCtrl', function (
  $i18next,
  $scope,
  $element,
  params,
  $window,
  Helper,
  Users,
  close
) {
  $scope.save = function () {
    if (
      $scope.email === $scope.originalEmail &&
      !$scope.forceEmailVerification
    ) {
      return $window.alert($i18next.t('modalUpdateEmail.emailChangeRequired'))
    }

    $scope.isLoading = true

    const body = {
      email: $scope.email,
      forceSkipMigrationTrigger: $scope.skipTriggerMigration
    }

    if ($scope.forceEmailVerification) body.verifiedEmail = true

    Users.updateUser(params.user.id, body)
      .then(
        function () {
          $scope.isLoading = false
        },

        function (response) {
          $scope.isLoading = false
          Helper.showErrorAlert(response.data)
        }
      )
      .then(function () {
        $scope.successClose()
      })
  }

  // Cancel the modal
  $scope.cancel = function () {
    $element.remodal().close({
      action: 'cancel'
    })
  }

  // Close modal after successful action
  $scope.successClose = function () {
    $element.remodal().close({
      action: 'confirmed'
    })
  }

  // When remodal is closed, we send a close message to the controller that opened this modal.
  $element.on('closed', function (e) {
    close(e.reason)
  })

  function load () {
    $scope.isLoading = false
    $scope.email = params.user.email
    $scope.skipTriggerMigration = true
    $scope.forceEmailVerification = false
    $scope.originalEmail = params.user.email
    $scope.verifiedOriginalEmail = params.user.verifiedEmail
  }

  load()
})
