'use strict'

import React, { useEffect, useState, useRef } from 'react'
import { Text, NewGrid } from '@citifyd/style'
import { useLazyQuery } from '@apollo/client'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { useAngularService } from '../../shared/react/hooks'
import { getAngularService } from '../../shared/react/utils'
import LoadingManager from '../../shared/react/components/LoadingManager'
import ApolloClient from '../../shared/services/apolloClient'
import PageHeader from '../../shared/react/components/PageHeader'
import { useTranslator } from '../../shared/react/hooks'
import RouterLinkButton from '../../shared/react/components/RouterLinkButton'
import styles from './Permits.module.scss'
import PermitsFilters from './components/PermitsFilters'
import PermitsTable from './components/PermitsTable'
import { selectDefaultFilters, getFilterSelectionFromState } from './utils'

import ADMIN_LIST_PERMIT_PASSES from '../../graphql/permitPass/queries/AdminListPermitPasses'

const apolloClient = ApolloClient()
const PER_PAGE = 20

const Permits = () => {
  const t = useTranslator()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [sortBy, setSortBy] = useState('START_TIME')
  const [sortDirection, setSortDirection] = useState('DESC')
  const $state = useAngularService('$state')
  const [params, setParams] = useState($state.params)
  const currentFilter = useRef(null)

  const [fetchData, { data, loading, error, fetchMore }] = useLazyQuery(
    ADMIN_LIST_PERMIT_PASSES,
    {
      variables: {
        startDate: currentFilter.current?.startDate,
        endDate: currentFilter.current?.endDate,
        first: PER_PAGE,
        status: currentFilter.current?.status,
        searchTerm: currentFilter.current?.search,
        sortBy: sortBy,
        sortDirection: sortDirection
      },
      client: apolloClient,
      fetchPolicy: 'network-only'
    }
  )

  const load = () => {
    const { status, search, dateRange } = getFilterSelectionFromState(params)

    currentFilter.current = {
      status,
      search,
      startDate: dateRange.start,
      endDate: dateRange.end
    }

    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [sortBy, sortDirection])

  useEffect(() => {
    const $rootScope = getAngularService('$rootScope')
    const unsubscribe = $rootScope.$watch(
      () => {
        return getAngularService('$state').params
      },
      params => {
        setParams(params)
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (params.dateRange) {
      load()
    } else {
      selectDefaultFilters($state)
    }
  }, [params])

  useEffect(() => {
    if (isLoadingMore) {
      setIsLoadingMore(false)
    }
  }, [data])

  const { endCursor, hasNextPage } = data
    ? data.admin_listPermitPasses.pageInfo
    : {}

  const loadMore = () => {
    if (!hasNextPage || isLoadingMore) {
      return
    }

    setIsLoadingMore(true)

    fetchMore({
      variables: {
        after: endCursor
      },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevData

        fetchMoreResult.admin_listPermitPasses.edges = [
          ...prevData.admin_listPermitPasses.edges,
          ...fetchMoreResult.admin_listPermitPasses.edges
        ]

        return fetchMoreResult
      }
    })
  }

  const [tableRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: () => loadMore(),
    disabled: !hasNextPage,
    rootMargin: '0px 0px 400px 0px'
  })

  const renderContent = () => {
    if (loading || error || !data) {
      return (
        <LoadingManager
          isLoading={loading}
          hasError={error}
          loadingMessage={t('permits.loading')}
        />
      )
    }

    const { edges } = data.admin_listPermitPasses

    return (
      <>
        {!!edges.length && (
          <PermitsTable
            data={edges}
            sortBy={sortBy}
            sortDirection={sortDirection}
            setSortBy={setSortBy}
            setSortDirection={setSortDirection}
            currentFilter={currentFilter.current}
          />
        )}
        {!edges.length && (
          <Text textAlign='center'>{t('permits.noFound')}</Text>
        )}

        {hasNextPage && (
          <div className={styles.tableRef} ref={tableRef}>
            {isLoadingMore && !!data.admin_listPermitPasses?.edges?.length && (
              <LoadingManager isLoading={isLoadingMore} />
            )}
          </div>
        )}
      </>
    )
  }

  return (
    <NewGrid>
      <NewGrid.Row>
        <NewGrid.Col>
          <PageHeader>
            <PageHeader.Title weight='300' title={t('permits.permits')} />
            <PageHeader.Actions>
              <PageHeader.Action>
                <RouterLinkButton size='small' state='add-permit'>
                  <Text>{t('permits.addPermit')}</Text>
                </RouterLinkButton>
              </PageHeader.Action>
            </PageHeader.Actions>
          </PageHeader>

          <div className={styles.content}>
            <PermitsFilters currentFilter={currentFilter.current || {}} />
            {renderContent()}
          </div>
        </NewGrid.Col>
      </NewGrid.Row>
    </NewGrid>
  )
}

export default Permits
