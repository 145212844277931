'use strict'

import React, { useEffect, useState } from 'react'
import { Button, Modal, Text } from '@citifyd/style'
import { useAngularService, useTranslator } from '../../react/hooks'

import {
  downloadTicket,
  downloadBundlePurchase
} from './functions/downloadTicketAndBundlePurchase'
import getLanguages from './functions/getLanguages'
import languageSelectionRequired from './functions/languageSelectionRequired'

import LanguageSelection from './Components/LanguageSelection.jsx'
import LoadingManager from '../../../shared/react/components/LoadingManager'

const GenerateReceiptModal = ({
  bundlePurchaseId,
  forceLanguageSelection,
  onClose,
  open,
  ticketId
}) => {
  const t = useTranslator()
  const BundlePurchases = useAngularService('BundlePurchases')
  const Tickets = useAngularService('Tickets')

  const [errorMessage, setErrorMessage] = useState()
  const [languages, setLanguages] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState()
  const [step, setStep] = useState(null)

  useEffect(() => {
    init()
  }, [])

  const download = async ({ language }) => {
    setStep('generating')

    let result

    if (ticketId) {
      result = await downloadTicket({ ticketId, language })
    } else if (bundlePurchaseId) {
      result = await downloadBundlePurchase({ bundlePurchaseId, language })
    }

    if (result.status === 'success') {
      setStep('generated')
    } else {
      setStep('error')
      setErrorMessage(result.reason)
    }
  }

  const loadTicket = async () => {
    const response = await Tickets.load(ticketId)
    const { ticket } = response.data
    const { lot, purchaser } = ticket

    return {
      ticket,
      lot,
      purchaser
    }
  }

  const loadBundlePurchase = async () => {
    const response = await BundlePurchases.load(bundlePurchaseId)
    const { bundlePurchase } = response.data
    const { bundle, user: purchaser } = bundlePurchase
    const { lot } = bundle

    return {
      bundlePurchase,
      lot,
      purchaser
    }
  }

  const init = async () => {
    setStep('loading')

    let result

    if (ticketId) {
      result = await loadTicket()
    } else if (bundlePurchaseId) {
      result = await loadBundlePurchase()
    }
    const { lot, purchaser } = result

    const language = purchaser?.language || lot?.country?.language
    setSelectedLanguage(language)

    if (languageSelectionRequired({ purchaser, lot, forceLanguageSelection })) {
      const languages = getLanguages({ purchaser, lot })
      setLanguages(languages)
      setStep('language-selection')
    } else {
      download({ language })
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      textAlign='center'
      closeIcon={step === 'generated' || step === 'language-selection'}
    >
      {step === 'loading' && (
        <LoadingManager
          isLoading={true}
          loadingMessage={t('modalGenerateReceipt.loadingTransaction')}
        />
      )}

      {step === 'generating' && (
        <LoadingManager
          isLoading={true}
          loadingMessage={t('modalGenerateReceipt.generatingReceipt')}
        />
      )}

      {step === 'generated' && (
        <>
          <Modal.Title>{t('modalGenerateReceipt.generated.title')}</Modal.Title>
          <Modal.Content>
            <Text textAlign='center' variant='subtitle'>
              {t('modalGenerateReceipt.generated.description')}
            </Text>
          </Modal.Content>
          <Modal.Actions display='block'>
            <Button
              onClick={onClose}
              extraPadding
              justifyContent='center'
              uppercase
            >
              {t('commonButtons.ok')}
            </Button>
          </Modal.Actions>
        </>
      )}
      {step === 'language-selection' && (
        <LanguageSelection
          languages={languages}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          onClose={onClose}
          download={download}
        />
      )}
    </Modal>
  )
}

export default GenerateReceiptModal
