import CurrencyFormatter from '@citifyd/currency-formatter'
import { mapValues, map } from 'lodash'

import { getAngularService, getCurrentLanguage } from '../../shared/react/utils'

const generateFormattedAmountList = amountPerCurrency => {
  const language = getCurrentLanguage()

  const amounts = map(amountPerCurrency, (value, currency) =>
    CurrencyFormatter.format(value, {
      currency,
      language
    })
  )

  return amounts.join(' + ')
}

export const getUser = data => {
  const Helper = getAngularService('Helper')
  let user = data.user
  user.address = user.mailingAddress || user.billingAddress

  const totalBalance = mapValues(
    user.merchantValidationAmounts,
    u => u.totalBalance
  )

  user.formattedTotalBalance = generateFormattedAmountList(totalBalance)

  const totalValidations = mapValues(
    user.merchantValidationAmounts,
    u => u.totalValidations
  )

  user.formattedTotalValidations = generateFormattedAmountList(totalValidations)

  const totalAvailableForRefund = mapValues(
    user.merchantValidationAmounts,
    u => u.totalBalance - u.totalValidations
  )

  user.formattedTotalAvailableForRefund = generateFormattedAmountList(
    totalAvailableForRefund
  )

  user.stripeCustomerUrl = Helper.generateStripeCustomerUrl(
    user.stripeCustomerId
  )

  return user
}
