'use strict'

import angular from 'angular'
import _ from 'lodash'

import templateUrl from './invite-parkers.html'
import './invite-parkers.scss'

import accountInfoTemplateUrl from './views/account-info.html'
import eventSelectionTemplateUrl from './views/event-selection.html'
import lotSelectionTemplateUrl from './views/lot-selection.html'
import confirmationTemplateUrl from './views/confirmation.html'
import resultTemplateUrl from './views/result.html'

const app = angular.module('citifydSellerApp')

app.controller('InviteParkersCtrl', function (
  Users,
  $state,
  $stateParams,
  $scope
) {
  $scope.step = 1

  if ($scope.user.organization) {
    $scope.passGiver = $scope.user.organization.name
  } else if ($scope.user.isAdmin) {
    $scope.passGiver = 'Citifyd'
  } else {
    $state.go('dashboard', {}, { replace: true })
  }

  $scope.changeStep = function (stepNumber, info) {
    $scope.step = stepNumber
    $scope.$broadcast('init-step-' + stepNumber, _.cloneDeep(info || {}))
  }

  $scope.returnStep = function () {
    $scope.step--
    $scope.$broadcast('return-to-step-' + $scope.step)
  }

  $scope.accountInfoTemplateUrl = accountInfoTemplateUrl
  $scope.eventSelectionTemplateUrl = eventSelectionTemplateUrl
  $scope.lotSelectionTemplateUrl = lotSelectionTemplateUrl
  $scope.confirmationTemplateUrl = confirmationTemplateUrl
  $scope.resultTemplateUrl = resultTemplateUrl
})

export const controller = 'InviteParkersCtrl'
export const auth = true
export const title = 'i18n:inviteParkers.pageTitle'
export { templateUrl }
