'use strict'

import React from 'react'
import { Tab } from '@citifyd/style'

import RevenueDaily from '../RevenueDaily'
import RevenueEvents from '../RevenueEvents'
import RevenuePayouts from '../RevenuePayouts'
import styles from './RevenueTab.module.scss'

const RevenueTab = ({ active, name, lots, currencies, tab }) => {
  const renderContent = () => {
    switch (tab) {
      case 'daily':
        return (
          <RevenueDaily
            className={styles.content}
            lots={lots}
            currencies={currencies}
          />
        )
      case 'events':
        return (
          <RevenueEvents
            className={styles.content}
            lots={lots}
            currencies={currencies}
          />
        )
      case 'payouts':
        return (
          <RevenuePayouts
            className={styles.content}
            lots={lots}
            currencies={currencies}
          />
        )
    }
  }

  return (
    <Tab.Item active={active} name={name}>
      {renderContent()}
    </Tab.Item>
  )
}

export default RevenueTab
