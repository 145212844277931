'use strict'

import React from 'react'
import classNames from 'classnames'
import { Text, NewGrid } from '@citifyd/style'
import moment from 'moment-timezone'

import TransactionExtraInformation from '../../../../shared/react/components/TransactionExtraInformation'
import BoxContent from '../../../../shared/react/components/BoxContent'
import { useTranslator } from '../../../../shared/react/hooks'
import { formatPhoneNumber } from '../../../../shared/utils/phone-number-formatting'
import RouterLink from '../../../../shared/react/components/RouterLink'
import Dictionary, {
  DictionaryItem
} from '../../../../shared/react/components/Dictionary'
import styles from './TicketVoucherBox.module.scss'

const TicketVoucherBox = ({ ticket, voucher }) => {
  const t = useTranslator()

  const getTitle = () => {
    if (voucher.deletedAt) {
      return t('transaction.cancelledTransferInformation')
    }

    return voucher.redeemedAt
      ? t('transaction.redemptionInformation')
      : t('transaction.transferInformation')
  }

  const redeemedBoxHeader = !voucher.deletedAt && voucher.redeemedAt
  const transferredBoxHeader = !voucher.deletedAt && !voucher.redeemedAt
  const cancelledTransferBox = voucher.deletedAt

  return (
    <BoxContent
      className={classNames({
        [styles.cancelledTransferBox]: voucher.deletedAt
      })}
    >
      <BoxContent.Header
        title={getTitle()}
        className={classNames({
          [styles.redeemedBoxHeader]: redeemedBoxHeader,
          [styles.transferredBoxHeader]: transferredBoxHeader,
          [styles.cancelledTransferBoxHeader]: cancelledTransferBox
        })}
        appearanceTitle={
          cancelledTransferBox || transferredBoxHeader ? 'gray_darker' : 'white'
        }
      />

      <BoxContent.Content>
        <NewGrid.Row>
          <NewGrid.Col sm={12} md={6}>
            <Dictionary withDivisor={false}>
              <DictionaryItem
                label={
                  !voucher.deletedAt
                    ? t('transaction.transferredAt')
                    : t('transaction.transferStartedAt')
                }
              >
                {t('defaultFormats.datetime', {
                  date: moment.tz(
                    voucher.createdAt,
                    ticket.availability.timezoneName
                  )
                })}
              </DictionaryItem>

              {!voucher.deletedAt && (
                <DictionaryItem label={t('transaction.redeemedAt')}>
                  {voucher.redeemedAt
                    ? t('defaultFormats.datetime', {
                        date: moment.tz(
                          voucher.redeemedAt,
                          ticket.availability.timezoneName
                        )
                      })
                    : t('transaction.notYetRedeemed')}
                </DictionaryItem>
              )}

              {voucher.deletedAt && (
                <DictionaryItem label={t('transaction.cancelledAt')}>
                  {t('defaultFormats.datetime', {
                    date: moment.tz(
                      voucher.deletedAt,
                      ticket.availability.timezoneName
                    )
                  })}
                </DictionaryItem>
              )}
            </Dictionary>
          </NewGrid.Col>
          <NewGrid.Col sm={12} md={6}>
            <Dictionary withDivisor={false}>
              <DictionaryItem label={t('transaction.recipient')}>
                {voucher.redeemer && (
                  <RouterLink
                    appearance='tertiary'
                    state='user'
                    params={{ id: voucher.redeemer?.id }}
                  >
                    {voucher.redeemer?.name}
                  </RouterLink>
                )}

                {!voucher.redeemer && (
                  <>
                    {voucher?.extra?.email && voucher.extra.email}
                    {!voucher?.extra?.email &&
                      voucher?.extra?.phoneNumber &&
                      formatPhoneNumber(
                        voucher.extra.phoneNumber,
                        ticket.user?.phoneCountryCode || 'us'
                      )}

                    {!voucher?.extra?.email && !voucher.extra?.phoneNumber && (
                      <>
                        {!voucher.deletedAt ? (
                          t('transaction.notYetRedeemed')
                        ) : (
                          <span>&mdash;</span>
                        )}
                      </>
                    )}
                  </>
                )}
              </DictionaryItem>

              <DictionaryItem label={t('transaction.code')}>
                {voucher.code}
              </DictionaryItem>
            </Dictionary>
          </NewGrid.Col>
        </NewGrid.Row>
      </BoxContent.Content>

      {voucher.isAssisted && (
        <BoxContent.Footer appearance='gray'>
          <Text weight='700'>
            {t('transaction.fulfilledBy', { name: voucher.user.name })}
          </Text>
          <Text>{voucher?.extra?.reason}</Text>
          <Text italic>
            <TransactionExtraInformation data={voucher.extra} />
          </Text>
        </BoxContent.Footer>
      )}
    </BoxContent>
  )
}

export default TicketVoucherBox
