'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('AssistedPurchases', (API, Authentication, $http) => {
  function createAssistedPurchase (userId, data) {
    return $http({
      method: 'POST',
      url: API.base + '/admin/users/' + userId + '/assisted-purchases',
      headers: Authentication.generateHeaders(),
      data: { assistedPurchase: data }
    })
  }

  function getAvailableEventsForAssistedPurchase (userId) {
    let path
    if (userId) {
      path = `/admin/users/${userId}/assisted-purchases/available-events`
    } else {
      path = `/admin/assisted-purchases/available-events`
    }

    return $http({
      method: 'GET',
      url: API.base + path,
      headers: Authentication.generateHeaders()
    })
  }

  return {
    create: createAssistedPurchase,
    getAvailableEvents: getAvailableEventsForAssistedPurchase
  }
})
