import gql from 'graphql-tag'

export default gql`
  fragment JpAddress on JpAddress {
    line1
    line2
    city
    town
    state
    postalCode
  }
`
