/* global FileReader */

'use strict'

import angular from 'angular'
import templateUrl from './image-upload.html'
import './image-upload.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctImageUpload', function () {
  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {
      fileChange: '=',
      disabled: '=',
      preloadWith: '@',
      size: '@',
      inputName: '='
    },

    link: function (scope, element) {
      var pendingImage =
        scope.size === 'large'
          ? require('../../../images/file-button.png')
          : require('../../../images/file-button-square.jpg')

      scope.imageUploaded = false

      element.find('input:file').change(function () {
        if (this.files && this.files[0]) {
          var reader = new FileReader()

          reader.onload = function (e) {
            element.find('.upload-preview').attr('src', e.target.result)
          }

          reader.readAsDataURL(this.files[0])
          scope.imageUploaded = true
        } else {
          scope.imageUploaded = false
          setPendingUploadImage()
        }

        scope.$apply()
      })

      function setPendingUploadImage () {
        element.find('.upload-preview').attr('src', pendingImage)
      }

      scope.$watch('preloadWith', function () {
        if (!scope.imageUploaded) {
          if (scope.preloadWith) {
            element.find('.upload-preview').attr('src', scope.preloadWith)
          } else {
            setPendingUploadImage()
          }
        }
      })
    }
  }
})
