'use strict'

import angular from 'angular'
import _ from 'lodash'
import { react2angular } from 'react2angular'

import PasswordChangeNotificationBar from '../../react/components/PasswordChangeNotificationBar'

const app = angular.module('citifydSellerApp')

app.component(
  'ctPasswordChangeNotificationBar',
  react2angular(PasswordChangeNotificationBar, [
    'passwordChangeSuggestionDisplayed',
    'openChangePasswordModal',
    'dismissPasswordChangeSuggestion'
  ])
)
