'use strict'

import React from 'react'
import classNames from 'classnames'

import './style.scss'

export default function ToggleButton ({
  selected,
  disabled = false,
  offColor,
  onChange,
  customClassName
}) {
  const className = classNames('component-toggle-button', customClassName, {
    'component-toggle-button--enabled': !disabled,
    'component-toggle-button--disabled': disabled,
    [`component-toggle-button--off-color-${offColor}`]: offColor
  })

  const changed = () => onChange && onChange(!selected)

  return (
    <label className={className}>
      <input
        type='checkbox'
        checked={selected}
        disabled={disabled}
        onChange={changed}
      />
      <i />
    </label>
  )
}
