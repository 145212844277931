import React from 'react'
import { uniq, sum } from 'lodash'
import CurrencyFormatter from '@citifyd/currency-formatter'
import moment from 'moment-timezone'
import { Text } from '@citifyd/style'

import { calculateReservationStatus } from '../../../../shared/utils/status-calculation'
import ReservationStatusBadge from '../../../../shared/react/components/ReservationStatusBadge'
import {
  getAngularService,
  getCurrentLanguage,
  getTranslator
} from '../../../../shared/react/utils'

export const transformReservations = reservations => {
  const language = getCurrentLanguage()

  return reservations.map(reservation => ({
    id: reservation.id,
    start: reservation.startTime,
    end: reservation.endTime || reservation.expirationTime,
    lotName: reservation.lot.name,
    timezoneName: reservation.lot.timezoneName,
    currency: reservation.currency,
    amountCharged: reservation.amountCharged,
    formattedAmount: CurrencyFormatter.format(reservation.amountCharged, {
      currency: reservation.currency,
      language
    }),
    status: calculateReservationStatus(reservation)
  }))
}

export const getFormattedTotal = reservations => {
  const currencies = uniq(reservations, reservation => reservation.currency)
  const language = getCurrentLanguage()

  if (currencies.length === 1) {
    const total = sum(reservations, reservation => reservation.amountCharged)
    return CurrencyFormatter.format(total, {
      currency: reservations[0].currency,
      language
    })
  } else {
    return ''
  }
}

export const getData = ({
  reservations,
  handleSort,
  orders,
  formattedTotal
}) => {
  const t = getTranslator()
  const $state = getAngularService('$state')

  const columns = [
    {
      key: 'start',
      value: t('user.reservations.table.start'),
      onClick: e => handleSort(e, 'start'),
      sortableKey: 'start'
    },
    {
      key: 'end',
      value: t('user.reservations.table.end'),
      onClick: e => handleSort(e, 'end'),
      sortableKey: 'end'
    },
    {
      key: 'lotName',
      value: t('user.reservations.table.lot'),
      onClick: e => handleSort(e, 'lotName'),
      sortableKey: 'lotName'
    },
    {
      key: 'amountCharged',
      value: t('user.reservations.table.price'),
      onClick: e => handleSort(e, 'amountCharged'),
      sortableKey: 'amountCharged'
    },
    {
      key: 'status',
      value: t('user.reservations.table.status'),
      onClick: e => handleSort(e, 'status'),
      sortableKey: 'status'
    }
  ]

  const columnsFormatted = columns.map(column => ({
    ...column,
    sortable: true,
    orders
  }))

  const handleClick = id => {
    $state.go('reservation', { id }, { enableBackLink: true })
  }

  const data = reservations.map(reservation => ({
    onClick: () => handleClick(reservation.id),
    start: {
      value: t('defaultFormats.datetime', {
        date: moment.tz(reservation.start, reservation.timezoneName)
      }),
      verticalAlign: 'middle',
      nowrap: 'nowrap'
    },
    end: {
      value: t('defaultFormats.datetime', {
        date: moment.tz(reservation.end, reservation.timezoneName)
      }),
      verticalAlign: 'middle',
      nowrap: 'nowrap'
    },
    lotName: {
      value: reservation.lotName,
      verticalAlign: 'middle'
    },
    amountCharged: {
      value: reservation.formattedAmount,
      verticalAlign: 'middle'
    },
    status: {
      value: <ReservationStatusBadge status={reservation.status} />
    }
  }))

  const dataWithTotal = [
    ...data,
    {
      appearance: 'white',
      start: {
        value: <Text weight='700'>{t('revenue.total')}</Text>,
        colSpan: 3
      },
      end: {
        hide: true
      },
      lotName: {
        hide: true
      },
      amountCharged: {
        value: <Text weight='700'>{formattedTotal}</Text>
      },
      status: {
        hide: true
      }
    }
  ]

  return {
    columns: columnsFormatted,
    data: dataWithTotal
  }
}
