'use strict'

import angular from 'angular'

import gatesSummaryTemplateUrl from '../views/gates-summary.html'
import gateTemplateUrl from '../views/gate.html'

const app = angular.module('citifydSellerApp')

app.controller('EventGatesCtrl', function (
  Authentication,
  Helper,
  $i18next,
  $scope
) {
  $scope.selectedEventGate = null

  function hasCashierReport () {
    const user = Authentication.getLoggedUser()
    return user.isAdmin ? true : user.organization.hasCashierReport
  }

  $scope.viewTicketsWithNoEntries = function (lot, lots) {
    $scope.selectedEventGate = {
      id: -1,
      gate: { isSystemGate: true },
      name: $i18next.t('event.unredeemedPasses'),
      lot: lot,
      allLots: lots
    }
  }

  $scope.openGateView = function (eventGate, lots) {
    $scope.selectedEventGate = eventGate
    eventGate.allLots = lots
  }

  $scope.closeGateView = function () {
    Helper.confirmPageUnload(function () {
      $scope.selectedEventGate = null
    })
  }

  $scope.$on('activate-tab:gates', function () {
    $scope.closeGateView()
  })

  function init () {
    $scope.gatesSummaryTemplateUrl = gatesSummaryTemplateUrl
    $scope.gateTemplateUrl = gateTemplateUrl
    $scope.hasCashierReport = hasCashierReport()
  }

  init()
})
