'use strict'

import angular from 'angular'
import templateUrl from './signup-from-invitation.html'

import PasswordValidator from '../../shared/services/password-validator'

const app = angular.module('citifydSellerApp')

app.controller('SignupCtrl', function (
  Helper,
  Users,
  CitifydModal,
  Authentication,
  Intercom,
  Languages,
  $i18next,
  $stateParams,
  $scope,
  $window
) {
  var invitationCode = $stateParams.code

  function showModal () {
    $scope.showPage = true

    $scope.isLoading = true
    $scope.invalidPassword = false
    $scope.showingMessage = null
    $scope.passwordRules = null
    $scope.userExists = false
    $scope.invitation = null
    $scope.password = $scope.confirmPassword = ''
    $scope.phoneNumber = ''

    function validate () {
      if (!$scope.signupForm.$valid) {
        $scope.$broadcast('show-errors-check-validity')
        return false
      }

      if (!$scope.userExists) {
        if ($scope.password !== $scope.confirmPassword) {
          Helper.showAlert(
            $i18next.t('signupFromInvitation.invalidPasswordConfirmation')
          )
          return false
        }

        const passwordValidation = PasswordValidator.validate(
          $scope.password,
          $scope.passwordRules
        )
        if (!passwordValidation.isValid) {
          $scope.invalidPassword = true
          return false
        }
      }

      return true
    }

    $scope.submit = function () {
      if (!validate()) {
        return
      }

      $scope.isLoading = true

      Users.joinOrganizationInvitation(invitationCode, {
        password: $scope.password,
        phoneNumber: $scope.phoneNumber,
        language: Languages.getCurrent()
      }).then(
        function (response) {
          Authentication.setToken(response.data.accessToken)
          $window.location = '/'
        },

        function (response) {
          $scope.isLoading = false
          Helper.showErrorAlert(response.data)
        }
      )
    }

    $scope.passwordChanged = function () {
      $scope.invalidPassword = false
    }

    $scope.cancel = function () {
      $window.location = '/'
    }

    Users.fetchOrganizationUserInvitation(invitationCode).then(
      function (response) {
        $scope.isLoading = false
        $scope.invitation = response.data.invitation
        $scope.userExists = response.data.userExists

        if (!$scope.userExists) {
          loadPasswordRules()
        }
      },

      function (response) {
        if (response.status === 404) {
          $scope.showingMessage = 'notFound'
        } else {
          Helper.showErrorAlert(response.data)
        }
      }
    )
  }

  function loadPasswordRules () {
    $scope.isLoading = true

    Users.fetchPasswordRules({ potentialOrganizationUser: true }).then(
      rules => {
        $scope.isLoading = false
        $scope.passwordRules = rules
      }
    )
  }

  function initialize () {
    if (Authentication.isLoggedIn()) {
      Authentication.logout()
      Intercom.logout()
      $window.location.reload()
      return
    }

    showModal()
  }

  initialize()
})

export const controller = 'SignupCtrl'
export const auth = false
export const title = 'i18n:signupFromInvitation.pageTitle'
export { templateUrl }
