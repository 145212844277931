'use strict'

import React from 'react'
import classNames from 'classnames'
import { Text, List } from '@citifyd/style'

import './style.scss'
import styles from './Dictionary.module.scss'

export default function Dictionary ({
  children,
  withTopLine,
  withDivisor = true
}) {
  const className = classNames(styles.dictionary, {
    [styles.withTopLine]: withTopLine
  })

  return (
    <List border fullWidth className={className}>
      {withDivisor && <List.Divisor />}
      {children}
    </List>
  )
}

export function DictionaryItem ({
  label,
  multiline,
  children,
  textComponent,
  labelWeight = '600'
}) {
  return (
    <List.Item
      justifyContent='space-between'
      className={styles.item}
      textComponent={textComponent}
    >
      <Text component='span' weight={labelWeight} className={styles.label}>
        {label}
      </Text>
      <Text
        component='span'
        className={classNames(styles.value, { multiline })}
      >
        {children}
      </Text>
    </List.Item>
  )
}
