'use strict'

import angular from 'angular'

import CardIcon from '../../react/components/CardIcon'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component('ctCardIcon', react2angular(CardIcon, ['brand']))
