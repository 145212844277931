'use strict'

import React, { useEffect, useState, useMemo } from 'react'
import { NewGrid } from '@citifyd/style'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import AllPropertiesHeader from './AllPropertiesHeader/AllPropertiesHeader.jsx'
import Table from '../../../../shared/react/components/Table'
import Address from '../../../../shared/react/components/Address'
import LotDetails from './LotDetails/LotDetails.jsx'

import { useToggleApproval } from '../../hooks'
import LoadingManager from '../../../../shared/react/components/LoadingManager'

const AllProperties = () => {
  const Properties = useAngularService('Properties')
  const Helper = useAngularService('Helper')
  const t = useTranslator()

  const [properties, setProperties] = useState([])
  const [filterApproved, setFilterApproved] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  const toggleApproval = useToggleApproval({
    properties,
    setProperties
  })

  useEffect(() => {
    loadProperties()
  }, [])

  const loadProperties = async () => {
    setIsLoading(true)

    try {
      const result = await Properties.findAll({
        all: true,
        includeOwner: true,
        order: 'id',
        reverse: true
      })
      setProperties(result.data.lots)
    } catch (error) {
      setErrorMessage(Helper.getServerErrorMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  const columns = [
    { key: 'id', value: t('allProperties.id') },
    { key: 'address', value: t('allProperties.address') },
    { key: 'owner', value: t('allProperties.owner') },
    { key: 'city', value: t('allProperties.city') },
    { key: 'isApproved', value: t('allProperties.approved') },
    { key: 'isVisible', value: t('allProperties.visible') }
  ]

  const data = useMemo(() => {
    let filteredProperties = properties

    if (filterApproved) {
      filteredProperties = filteredProperties.filter(lot => lot.isApproved)
    }
    if (filterVisible) {
      filteredProperties = filteredProperties.filter(lot => lot.isVisible)
    }

    if (search) {
      const normalizedSearch = Helper.normalize(search)
      const searchItem = item =>
        Helper.normalize(String(item)).includes(normalizedSearch)
      const addressToString = address => Object.values(address).join(' ')
      filteredProperties = filteredProperties.filter(
        lot =>
          searchItem(lot.id) ||
          searchItem(lot.organization.name) ||
          searchItem(addressToString(lot.address))
      )
    }

    return filteredProperties.map(property => ({
      id: property.id,
      address: <Address entity={property} format='street' />,
      owner: property.organization.name,
      city: <Address entity={property} format='city' />,
      isApproved: property.isApproved ? t('yes') : t('no'),
      isVisible: property.isVisible ? t('yes') : t('no'),
      clickable: true,
      expanded: (
        <LotDetails property={property} toggleApproval={toggleApproval} />
      )
    }))
  }, [properties, filterApproved, filterVisible, search])

  const toggleFilterApproved = () => {
    setFilterApproved(!filterApproved)
  }
  const toggleFilterVisible = () => {
    setFilterVisible(!filterVisible)
  }

  if (isLoading || errorMessage) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={Boolean(errorMessage)}
        loadingMessage={t('permits.form.loading')}
        errorMessage={errorMessage}
        onClickTryAgain={loadProperties}
      />
    )
  }

  return (
    <NewGrid>
      <AllPropertiesHeader
        filterApproved={filterApproved}
        filterVisible={filterVisible}
        setSearch={setSearch}
        onClickFilterApproved={toggleFilterApproved}
        onClickFilterVisible={toggleFilterVisible}
      />
      <Table responsive data={data} columns={columns} striped></Table>
    </NewGrid>
  )
}

export default AllProperties
