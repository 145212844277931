'use strict'

import angular from 'angular'
import _ from 'lodash'

import '../views/step-1.html'

const AVAILABLE_COLORS = [
  '#ffa00b',
  '#498d86',
  '#0685aa',
  '#f47921',
  '#62c3a7',
  '#7576ce',
  '#b1678c'
]

const app = angular.module('citifydSellerApp')

app.controller('AddPropertyStep1Ctrl', function (
  Authentication,
  Helper,
  Geo,
  Properties,
  Settings,
  $i18next,
  $log,
  $scope,
  $state
) {
  $scope.countryChanged = () => {
    $scope.lot.address = null
    $scope.lot.latitude = undefined
    $scope.lot.longitude = undefined
    loadAddressSchema()
  }

  $scope.onAddressFormStartLoading = () => {
    $scope.isLoadingAddressForm = true
  }

  $scope.onAddressFormFinishLoading = () => {
    $scope.isLoadingAddressForm = false
  }

  // Checkbox shouldn't change if user is local manager
  $scope.checkboxClick = function ($event) {
    if (Authentication.getLoggedUser().role === 'localManager') {
      $event.preventDefault()
    }
  }

  // Get coordinates from map and update the lot object
  $scope.updateCoordinatesFromMap = function (event) {
    $scope.lot.latitude = event.latLng.lat()
    $scope.lot.longitude = event.latLng.lng()
  }

  // Update the map coordinates based on the lot address
  $scope.updateCoordinatesFromAddress = function () {
    // If the lot setup is complete, changing the lot coordinates is not allowed.
    if ($scope.lot.setupIsComplete) {
      return
    }

    // Wait until the address schema is loaded
    if (!$scope.addressSchema) {
      return
    }

    var required = _($scope.addressSchema.properties)
      .pick((config, key) => config.required)
      .keys()
      .value()

    var allFilled = _.every(required, prop => $scope.lot.address[prop])

    if (!allFilled) {
      return
    }

    Geo.getCoordinates($scope.lot.address, $scope.lot.countryCode).then(
      function (coordinates) {
        var distance
        var hasCoordinates = $scope.lot.latitude !== undefined

        if (hasCoordinates) {
          distance = Geo.getDistanceBetweenCoordinates($scope.lot, coordinates)
        }

        // Doesn't update if new coordinates are up to 200-meters close to the previous one
        if (!hasCoordinates || distance >= 200) {
          $scope.lot.latitude = coordinates.latitude
          $scope.lot.longitude = coordinates.longitude
        }
      },

      function (error) {
        $log.info('Error returned from Geo API')
        $log.error(error)

        Helper.showAlert($i18next.t('commonErrors.addressNotFound'))
      }
    )
  }

  // Checks if the map should be displayed on the first step
  $scope.shouldShowMap = function () {
    return (
      $scope.lot &&
      $scope.lot.latitude !== undefined &&
      $scope.lot.longitude !== undefined
    )
  }

  // This method should be called when the user changes the selected file in the form view.
  // When this happens, we save the file object into the scope to send later to the server.
  $scope.saveImageFileData = function (event, files) {
    $scope.invalidImage = false
    $scope.imageFile = files.length > 0 ? files[0] : null
  }

  // Checks if image is present on the first step
  $scope.validateImage = function () {
    $scope.invalidImage = false

    if (!$scope.imageFile && !$scope.lot.photoUrl) {
      $scope.invalidImage = true
      return false
    }

    return true
  }

  // Submit first step of the form
  function submitForm () {
    $scope.$broadcast('show-errors-check-validity')

    var isImageValid = $scope.validateImage()

    if ($scope.forms.firstStep.$invalid || !isImageValid) {
      Helper.scrollToError(true)
      return false
    }

    if ($scope.mode === 'add') {
      createLot()
    } else {
      updateLot()
    }
  }

  // Update lot with data from the first step of the form
  function updateLot () {
    var promise

    if (didDataChange()) {
      var data = getFormData($scope.lot)

      data = {
        ...data,
        reservedParkingValidationMethod: 'none'
      }

      $scope.isLoading = true
      $scope.loadingMessage = $i18next.t(
        'addProperty.loadingMessages.updatingProperty'
      )

      promise = Properties.update(data).then(function (response) {
        $log.info('Lot successfully updated')
        return response.data.lot
      })
    } else {
      promise = Helper.promise($scope.lot)
    }

    promise.then(
      function (lot) {
        $scope.originalLot = angular.copy($scope.lot)

        uploadPhoto(lot, function () {
          $scope.goToStep(2)
        })
      },

      function (response) {
        $scope.isLoading = false
        $log.info('Error updating lot')
        Helper.showErrorAlert(response.data)
      }
    )
  }

  // Get field data related to the first step of the form
  function getFormData (lot) {
    return _.pick(lot, [
      'id',
      'name',
      'address',
      'notes',
      'maxSpots',
      'notifyAboutEvents',
      'reservedParkingEnabled',
      'color',
      'extraInfo',
      'latitude',
      'longitude',
      'countryCode'
    ])
  }

  // Checks if the data of the first step changed
  function didDataChange () {
    var lot = getFormData($scope.lot)
    var originalLot = getFormData($scope.originalLot)

    return !angular.equals(lot, originalLot)
  }

  // Create the lot
  function createLot () {
    var data = getFormData($scope.lot)

    data = {
      ...data,
      reservedParkingValidationMethod: 'none'
    }

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'addProperty.loadingMessages.creatingProperty'
    )

    Properties.create(data).then(
      function (response) {
        $log.info('Lot successfully created')

        var lot = response.data.lot

        refreshUser()

        uploadPhoto(lot, function () {
          $state.go(
            'edit-property-step',
            { lotId: lot.id, step: 2 },
            { location: 'replace' }
          )
        })
      },

      function (response) {
        $scope.isLoading = false
        $log.info('Error creating lot')
        Helper.showErrorAlert(response.data)
      }
    )
  }

  // After creating a property, the user might have been associated with an organization.
  // In this case we reload their data and also reload the notifications.
  function refreshUser () {
    return Authentication.reloadUser()
  }

  // Upload photo to the server
  function uploadPhoto (property, callback) {
    if (!$scope.imageFile) {
      callback(property)
      return
    }

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'addProperty.loadingMessages.uploadingPhoto'
    )

    $log.info('Uploading photo...')

    Helper.uploadImage('lot-image-upload', 'lots')
      .then(result => {
        const { cloudName, publicId, format } = result

        return Properties.update({
          id: property.id,
          photo: {
            mode: 'cloudinary',
            cloudinary: {
              cloudName,
              publicId,
              format,
              defaultTransformation: 't_on_demand_map_lot_image_2'
            }
          }
        })
      })
      .then(
        function success (response) {
          $scope.lot.photoUrl = _.get(response, 'data.lot.photoUrl')
          $log.info('Photo successfully uploaded')
          callback(response.data.lot)
        },
        function error (response) {
          $log.info('Error uploading photo')
          $log.error(response)
          callback(property)
        }
      )
  }

  function enforceCoordinatesBoundaries () {
    var LIMIT = 200

    function isBeyondLimit () {
      var diff = Geo.getDistanceBetweenCoordinates(
        $scope.lot,
        $scope.originalLot
      )
      return diff > LIMIT
    }

    $scope.$watchGroup(['lot.latitude', 'lot.longitude'], function (
      values,
      oldValues
    ) {
      if (
        !$scope.lot ||
        !$scope.originalLot ||
        $scope.lot.addressCanBeChanged
      ) {
        return
      }

      var oldCoordinates = {
        latitude: oldValues[0],
        longitude: oldValues[1]
      }

      if (
        !Geo.hasCoordinates($scope.lot) ||
        !Geo.hasCoordinates(oldCoordinates)
      ) {
        return
      }

      if (isBeyondLimit()) {
        $scope.lot.latitude = oldCoordinates.latitude
        $scope.lot.longitude = oldCoordinates.longitude

        // Sanity-check. If even the old coordinates are beyond limit, we apply
        // the coordinates of the original lot data.
        if (isBeyondLimit()) {
          $scope.lot.latitude = $scope.originalLot.latitude
          $scope.lot.longitude = $scope.originalLot.longitude
        }
      }
    })
  }

  function loadAddressSchema () {
    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'addProperty.loadingMessages.loadingSettings',
      {
        step: 2,
        totalSteps: 2
      }
    )

    return Settings.getDetailedCountryData($scope.lot.countryCode).then(
      countryInfo => {
        $scope.isLoading = false
        $scope.addressSchema = countryInfo.schemas.address

        // create empty lot.address from schema
        if (!$scope.lot.address) {
          $scope.lot.address = _.mapValues(
            $scope.addressSchema.properties,
            (config, key) => {
              if (config.enum) {
                // it's important to return null instead of empty string if not found,
                // otherwise angular will create an empty blank option in the dropdown
                if (_.get($scope.user, `organization.address.${key}`)) {
                  return $scope.user.organization.address[key]
                } else {
                  return null
                }
              } else {
                return ''
              }
            }
          )
        }
      }
    )
  }

  function loadCountryList () {
    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'addProperty.loadingMessages.loadingSettings',
      {
        step: 1,
        totalSteps: 2
      }
    )

    return Settings.getCountries().then(countries => {
      $scope.isLoading = false
      $scope.availableCountries = Helper.planifyCountries(countries).filter(
        c => c.lotAllowed
      )
    })
  }

  function init () {
    $scope.imageFile = null
    $scope.isLoading = false
    $scope.isLoadingAddressForm = false

    $scope.addressSchema = null
    $scope.availableCountries = []
    $scope.availableColors = [...AVAILABLE_COLORS]

    // If current lot color is not on the list, just add it.
    if (
      $scope.lot.color &&
      !_.includes($scope.availableColors, $scope.lot.color)
    ) {
      $scope.availableColors.push($scope.lot.color)
    }

    $scope.mapMarkerIcon = {
      scaledSize: [30, 38],
      url: require('../../../images/map_marker.png')
    }

    enforceCoordinatesBoundaries()

    loadCountryList().then(() => {
      loadAddressSchema()
    })

    $scope.$on('add-property-step-1-submitted', function () {
      submitForm()
    })
  }

  init()
})
