import { getTranslator } from '../../../shared/react/utils'

const getSuccessContent = stripeConnectedAccount => {
  const t = getTranslator()
  const title = t('stripeAccountSetupResult.verified.title')
  const body = [
    {
      type: 'paragraph',
      content: t('stripeAccountSetupResult.verified.chargesAndPayoutsEnabled')
    }
  ]

  if (stripeConnectedAccount.hasDueRequirements) {
    body.push(
      {
        type: 'paragraph',
        content: t(
          'stripeAccountSetupResult.verified.pendingVerificationInfoRequired',
          {
            deadline: stripeConnectedAccount.requirementsDeadline
          }
        )
      },
      {
        type: 'button',
        content: t(
          'stripeAccountSetupResult.buttons.updateVerificationInformation'
        ),
        state: 'stripe-account-setup'
      }
    )
  } else {
    body.push({
      type: 'button',
      content: t('stripeAccountSetupResult.buttons.goToDashboard'),
      state: 'dashboard'
    })
  }

  return { title, body }
}

export default getSuccessContent
