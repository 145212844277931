'use strict'

import angular from 'angular'
import _ from 'lodash'

import templateUrl from './payout.html'
import './payout.scss'

import bundlesTemplateUrl from './views/bundles.html'
import reservationsTemplateUrl from './views/reservations.html'
import reservedParkingTemplateUrl from './views/reserved-parking.html'
import subscriptionsTemplateUrl from './views/subscriptions.html'
import ticketsTemplateUrl from './views/tickets.html'
import summaryTemplateUrl from './views/summary.html'
import payoutReconciliationsTemplateUrl from './views/payout-reconciliations.html'
import ticketCancellationsTemplateUrl from './views/ticket-cancellations.html'

const app = angular.module('citifydSellerApp')

app.controller('PayoutCtrl', function (
  Authentication,
  CitifydModal,
  Helper,
  Permissions,
  Revenue,
  Settings,
  $log,
  $i18next,
  $scope,
  $state,
  $stateParams
) {
  const loggedUser = Authentication.getLoggedUser()
  let currentPayoutItemsRequest

  $scope.lotChanged = function () {
    const params = {
      payout: $stateParams.payout
    }

    if ($scope.lotId) {
      params.lot = $scope.lotId
    }

    $scope.lot = _.find($scope.payout.lots, lot => lot.id === $scope.lotId)

    $state.go($state.current.name, params, {
      inherit: false,
      location: 'replace',
      notify: false
    })
    loadPayoutItems()
  }

  $scope.generateCsvClicked = function () {
    const options = {
      lotId: $scope.lotId
    }

    CitifydModal.open('generate-report', {
      fileFormat: 'csv',
      filterDescription: generateCsvReportDescription(),
      disableFilterDescriptionFirstCharacterLowercase: true,
      reportUrl: Revenue.fetchPayoutItemsCsvUrl($stateParams.payout, options)
    })
  }

  $scope.pageTitle = function () {
    if ($scope.isLoadingPayout) {
      return $i18next.t('payout.pageTitleLoading')
    } else if ($scope.payout.amount < 0) {
      return $i18next.t('payout.pageTitleDebit', {
        payoutDescription: $scope.payout.description
      })
    } else {
      return $i18next.t('payout.pageTitlePayment', {
        payoutDescription: $scope.payout.description
      })
    }
  }

  $scope.scrollToSection = function (id) {
    Helper.scrollTo('#' + id)
  }

  function generateCsvReportDescription () {
    if ($scope.lotId) {
      return $i18next.t('payout.csvReportName.lot', {
        payoutDescription: $scope.payout.description,
        lotName: $scope.lot.name
      })
    } else {
      return $i18next.t('payout.csvReportName.general', {
        payoutDescription: $scope.payout.description
      })
    }
  }

  function filterItems (items, property) {
    return _(items)
      .filter(function (item) {
        return item[property]
      })
      .map(function (item) {
        item[property].amount = item.amount
        return item[property]
      })
      .value()
  }

  function sumAmount (items) {
    return _.sum(items, 'amount')
  }

  function adjustForSingleDaySession (list) {
    list.forEach(function (item) {
      item.singleDay = Helper.isSameDate(
        item.startTime,
        item.endTime,
        item.lot.timezoneName
      )
    })

    return list
  }

  function loadFailedPayoutMessage () {
    let countryCode = 'us'
    if (loggedUser.organization) {
      countryCode = loggedUser.organization.countryCode
    }

    Settings.getDetailedCountryData(countryCode).then(
      function (country) {
        const { supportPhoneNumber, supportEmail } = country.citifydLocalInfo
        $scope.payoutFailedContactMessage = $i18next.t(
          'payout.failed.contact',
          {
            phoneNumber: supportPhoneNumber.value,
            email: `<a href="mailto:${supportEmail.value}">${supportEmail.value}</a>`,
            interpolation: { escapeValue: true }
          }
        )
      },

      function () {
        $log.info(`${countryCode}: country information could not be lodaded`)
      }
    )
  }

  function loadPayoutItems () {
    $scope.isLoadingPayoutItems = true
    $scope.payoutItems = {}

    if (currentPayoutItemsRequest) {
      currentPayoutItemsRequest.abort()
      currentPayoutItemsRequest = null
    }

    currentPayoutItemsRequest = Revenue.fetchPayoutItems($stateParams.payout, {
      lotId: $scope.lotId
    })

    currentPayoutItemsRequest.then(response => {
      currentPayoutItemsRequest = null
      $scope.isLoadingPayoutItems = false

      const items = response.data.payoutItems

      $scope.payoutItems.bundlePurchases = filterItems(items, 'bundlePurchase')
      $scope.payoutItems.bundlePurchasesTotal = sumAmount(
        $scope.payoutItems.bundlePurchases
      )

      $scope.payoutItems.reservations = adjustForSingleDaySession(
        filterItems(items, 'reservation')
      )

      $scope.payoutItems.reservationsTotal = sumAmount(
        $scope.payoutItems.reservations
      )

      $scope.payoutItems.reservedParkingPasses = adjustForSingleDaySession(
        filterItems(items, 'reservedParkingPass')
      )

      $scope.payoutItems.reservedParkingPassesTotal = sumAmount(
        $scope.payoutItems.reservedParkingPasses
      )

      $scope.payoutItems.subscriptions = filterItems(items, 'subscription')
      $scope.payoutItems.subscriptionsTotal = sumAmount(
        $scope.payoutItems.subscriptions
      )

      $scope.payoutItems.tickets = filterItems(items, 'ticket')
      $scope.payoutItems.ticketsTotal = sumAmount($scope.payoutItems.tickets)

      $scope.payoutItems.ticketCancellations = filterItems(
        items,
        'ticketCancellation'
      )
      $scope.payoutItems.ticketCancellationsTotal = sumAmount(
        $scope.payoutItems.ticketCancellations
      )

      $scope.payoutItems.payoutReconciliations = filterItems(
        items,
        'payoutReconciliation'
      )
      $scope.payoutItems.payoutReconciliationsTotal = sumAmount(
        $scope.payoutItems.payoutReconciliations
      )

      $scope.payoutItems.summaryTotal = sumAmount(items)

      $scope.payoutItems.summary = [
        {
          sectionId: 'reservations-section',
          name: $i18next.t('payout.onDemandPasses'),
          total: $scope.payoutItems.reservationsTotal,
          hasItems: $scope.payoutItems.reservations.length > 1
        },
        {
          sectionId: 'reserved-parking-section',
          name: $i18next.t('payout.reservedParkingPasses'),
          total: $scope.payoutItems.reservedParkingPassesTotal,
          hasItems: $scope.payoutItems.reservedParkingPasses.length > 0
        },
        {
          sectionId: 'tickets-section',
          name: $i18next.t('payout.eventPasses'),
          total: $scope.payoutItems.ticketsTotal,
          hasItems: $scope.payoutItems.tickets.length > 0
        },
        {
          sectionId: 'bundle-purchases-section',
          name: $i18next.t('payout.bundles'),
          total: $scope.payoutItems.bundlePurchasesTotal,
          hasItems: $scope.payoutItems.bundlePurchases.length > 0
        },
        {
          sectionId: 'subscriptions-section',
          name: $i18next.t('payout.monthlyParking'),
          total: $scope.payoutItems.subscriptionsTotal,
          hasItems: $scope.payoutItems.subscriptions.length > 0
        },
        {
          sectionId: 'ticket-cancellations-section',
          name: $i18next.t('payout.reconciliation'),
          total: $scope.payoutItems.ticketCancellationsTotal,
          hasItems: $scope.payoutItems.ticketCancellations.length > 0,
          hidden: $scope.payoutItems.ticketCancellationsTotal === 0
        },
        {
          sectionId: 'payout-reconciliations-section',
          name: $i18next.t('payout.reconciliation'),
          total: $scope.payoutItems.payoutReconciliationsTotal,
          hasItems: $scope.payoutItems.payoutReconciliations.length > 0,
          hidden: $scope.payoutItems.payoutReconciliationsTotal === 0
        }
      ]
    })
  }

  function setPermissionFlags () {
    $scope.canSeeUserInformation = Permissions.userMaySeeUserInformation()
    $scope.canOpenBundlePurchase = Permissions.userMayOpenBundlePurchasePage()
    $scope.canOpenTicket = Permissions.userMayOpenTicketPage()
    $scope.canOpenReservation = Permissions.userMayOpenReservationPage()
    $scope.canOpenReservedParking = Permissions.userMayOpenReservedParkingPassPage()
    $scope.canOpenSubscription = Permissions.userMayOpenSubscriptionPage()
    $scope.canOpenParker = Permissions.userMayOpenParkerPage()
  }

  function setTemplateUrls () {
    $scope.bundlesTemplateUrl = bundlesTemplateUrl
    $scope.reservationsTemplateUrl = reservationsTemplateUrl
    $scope.reservedParkingTemplateUrl = reservedParkingTemplateUrl
    $scope.subscriptionsTemplateUrl = subscriptionsTemplateUrl
    $scope.ticketsTemplateUrl = ticketsTemplateUrl
    $scope.summaryTemplateUrl = summaryTemplateUrl
    $scope.payoutReconciliationsTemplateUrl = payoutReconciliationsTemplateUrl
    $scope.ticketCancellationsTemplateUrl = ticketCancellationsTemplateUrl
  }

  function init () {
    setTemplateUrls()
    setPermissionFlags()

    $scope.lotId = $stateParams.lot
    $scope.isLoadingPayout = true
    $scope.isLoadingPayoutItems = false

    Revenue.fetchPayout($stateParams.payout).then(response => {
      $scope.isLoadingPayout = false

      $scope.payout = response.data.payout
      $scope.payout.absoluteAmount = Math.abs($scope.payout.amount)

      $scope.lot = _.find($scope.payout.lots, lot => lot.id === $scope.lotId)

      if ($scope.payout.status === 'failed') {
        loadFailedPayoutMessage()
      }

      Helper.setPageTitle($scope.pageTitle())

      loadPayoutItems()
    })
  }

  init()
})

export const controller = 'PayoutCtrl'
export const auth = true
export const title = 'i18n:payout.pageTitleLoading'
export { templateUrl }
