'use strict'

import React, { useState, useEffect } from 'react'
import { Text, NewGrid, Icon } from '@citifyd/style'

import { formatPhoneNumber } from '../../../../shared/utils/phone-number-formatting'
import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'

import {
  withModalManager,
  useModalOpener
} from '../../../../shared/react/modalManager'

import RouterLink from '../../../../shared/react/components/RouterLink'
import LoadingManager from '../../../../shared/react/components/LoadingManager'

import EditOrganizationModal from '../../../../shared/modals/EditOrganizationModal'
import NotificationSettingsModal from '../../../../shared/modals/NotificationSettingsModal'

import styles from './AccountInformation.module.scss'

const AccountInformation = ({ openEditPaymentModal }) => {
  const t = useTranslator()
  const openModal = useModalOpener()
  const Authentication = useAngularService('Authentication')
  const loggedUser = Authentication.getLoggedUser()
  const Permissions = useAngularService('Permissions')
  const Organizations = useAngularService('Organizations')
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [organizationInfo, setOrganizationInfo] = useState(null)

  const userMayChangePaymentInformation = () =>
    Permissions.userMayChangePaymentInformation()

  const mayEditOrganizationInformation =
    loggedUser && loggedUser.organizationRole === 'admin'

  const openEditOrganizationModal = () => {
    openModal(EditOrganizationModal, {
      onClose: ({ saved } = {}) => {
        if (saved) {
          loadOrganizationInformation()
        }
      }
    })
  }

  const loadOrganizationInformation = async () => {
    setIsLoading(true)
    if (!loggedUser.organization) {
      setIsLoading(false)
      return
    }

    try {
      const response = await Organizations.findMyOrganization()

      setOrganizationInfo(response.data.organization)
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadOrganizationInformation()
  }, [])

  const renderContent = () => (
    <>
      {organizationInfo && (
        <div className={styles.infoBucket}>
          <Text gutterBottom variant='subtitle' bold>
            {t('accountInfo.account')}
          </Text>
          <Text variant='subtitle'>{organizationInfo.name}</Text>
          <Text gutterBottom variant='subtitle'>
            {formatPhoneNumber(
              organizationInfo.phoneNumber,
              organizationInfo.phoneCountryCode
            )}
          </Text>
          <Text variant='subtitle'>{organizationInfo.email}</Text>

          {mayEditOrganizationInformation && (
            <span className={styles.edit} onClick={openEditOrganizationModal}>
              <Icon icon='edit' />
            </span>
          )}
        </div>
      )}

      <div className={styles.infoBucket}>
        <Text bold variant='subtitle'>
          {t('accountInfo.accountSettings')}
        </Text>

        <RouterLink className={styles.edit} state='my-account'>
          <Icon icon='edit' />
        </RouterLink>
      </div>

      {organizationInfo &&
        organizationInfo.hasStripeConnectedAccount &&
        userMayChangePaymentInformation() && (
          <div className={styles.infoBucket}>
            <Text bold variant='subtitle'>
              {t('accountInfo.verificationInformation')}
            </Text>

            <RouterLink className={styles.edit} state='stripe-account-setup'>
              <Icon icon='edit' />
            </RouterLink>
          </div>
        )}

      <div className={styles.infoBucket}>
        <Text bold variant='subtitle'>
          {t('accountInfo.notificationPreferences')}
        </Text>

        <span
          className={styles.edit}
          onClick={() => openModal(NotificationSettingsModal)}
        >
          <Icon icon='edit' />
        </span>
      </div>

      {organizationInfo && userMayChangePaymentInformation() && (
        <div className={styles.infoBucket}>
          <Text bold variant='subtitle'>
            {t('accountInfo.paymentInfo')}
          </Text>

          <span className={styles.edit} onClick={openEditPaymentModal}>
            <Icon icon='edit' />
          </span>
        </div>
      )}
    </>
  )

  return (
    <NewGrid.Col md={6} className={styles.account}>
      <Text className={styles.title} component='h2' variant='h1' bold>
        {t('accountInfo.accountInfo')}
      </Text>

      {isLoading ? (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      ) : (
        renderContent()
      )}
    </NewGrid.Col>
  )
}

export default withModalManager(AccountInformation)
