'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.directive('ctHighlightText', function ($interpolate, $parse) {
  return {
    terminal: true,

    controller: function ($scope, $element, $attrs) {
      function init (termExpr, unsafeTextExpr) {
        var text = null
        var regex = null
        var flags = $attrs.ctHighlightFlags || ''
        var watcher = $scope.$watch(
          function ($scope) {
            return {
              term: termExpr($scope),
              unsafeText: unsafeTextExpr($scope)
            }
          },
          function (state, prevState) {
            if (text === null || state.unsafeText !== prevState.unsafeText) {
              text = angular
                .element('<div>')
                .text(state.unsafeText)
                .html()
            }

            if (state.term.trim() === '') {
              $element.html(text)
              return
            }

            if (regex === null || state.term !== prevState.term) {
              regex = getRegExp(state.term, flags)
            }

            $element.html(
              text.replace(regex, '<span class="highlight-text">$&</span>')
            )
          },
          true
        )

        $element.on('$destroy', watcher)
      }

      function sanitize (term) {
        return term && term.replace(/[\\^$*+?.()|{}[\]]/g, '\\$&')
      }

      function addOptionalDashes (str) {
        return str
          .split('')
          .map(x => (x === '\\' ? x : x + '-?'))
          .join('')
      }

      function getRegExp (text, flags) {
        var str = ''

        if (flags.indexOf('^') >= 1) {
          str += '^'
        }

        str += sanitize(text)

        if (flags.indexOf('$') >= 1) {
          str += '$'
        }

        // If "a" is specified, it will match any word inside the highlight term
        if (flags.indexOf('a') !== -1) {
          str = '(' + str.replace(/ +/g, '|') + ')'
        }

        // If "-" is specified, it will ignore dashes
        if (flags.indexOf('-') !== -1) {
          str = addOptionalDashes(str)
        }

        return new RegExp(str, flags.replace(/[$^a-]/g, ''))
      }

      this.init = init
    },

    compile: function (tElement, tAttr) {
      var termExpr = $parse(tAttr.ctHighlightText)
      var unsafeTextExpr = $interpolate(tElement.html())

      return function (scope, element, attr, ctrl) {
        ctrl.init(termExpr, unsafeTextExpr)
      }
    }
  }
})
