import { useState } from 'react'

import ApolloClient from '../../../../shared/services/apolloClient'
import { useAngularService } from '../../../../shared/react/hooks'
import ADMIN_LIST_USER_RESERVED_PARKING_PASSES from '../../../../graphql/reservedParkingPasses/queries/AdminListUserReservedParkingPasses'
import { transformReservedParkingPass } from '../ReservedParkingPasses/utils'

const useReservedParkingPasses = () => {
  const { id } = useAngularService('$stateParams')
  const [reservedParkingPasses, setReservedParkingPasses] = useState(null)
  const [
    reservedParkingPassesLoaded,
    setReservedParkingPassesLoaded
  ] = useState(false)
  const [reservedParkingPassesError, setReservedParkingPassesError] = useState(
    false
  )

  const fetchReservedParkingPasses = async () => {
    ApolloClient()
      .query({
        query: ADMIN_LIST_USER_RESERVED_PARKING_PASSES,
        variables: {
          userId: parseInt(id)
        }
      })
      .then(response => {
        if (response?.data?.admin_listUserReservedParkingPasses) {
          const { admin_listUserReservedParkingPasses } = response.data
          setReservedParkingPasses({
            edges: transformReservedParkingPass(
              admin_listUserReservedParkingPasses.edges
            ),
            total: admin_listUserReservedParkingPasses.total
          })
        } else {
          setReservedParkingPassesError(true)
        }
      })
      .catch(() => {
        setReservedParkingPassesError(true)
      })
      .finally(() => {
        setReservedParkingPassesLoaded(true)
      })
  }

  return {
    fetchReservedParkingPasses,
    reservedParkingPasses,
    reservedParkingPassesLoaded,
    reservedParkingPassesError
  }
}

export default useReservedParkingPasses
