'use strict'

import React, { useState, useEffect } from 'react'
import { NewGrid } from '@citifyd/style'

import Table from '../../../shared/react/components/Table'
import PageHeader from '../../../shared/react/components/PageHeader'
import BoxContent from '../../../shared/react/components/BoxContent'
import LoadingIndicator from '../../../shared/react/components/LoadingIndicator'
import AdvancedRateDefinitionStatusBadge from '../../../shared/react/components/AdvancedRateDefinitionStatusBadge'

import '../styles/page.scss'
import { useAngularService, useTranslator } from '../../../shared/react/hooks'
import { generateOrderForClickEvent, sort } from '../../../shared/react/sorting'
import styles from './ListPropertyAdvancedRatesPage.module.scss'

function Page () {
  const AdvancedRateDefinitions = useAngularService('AdvancedRateDefinitions')
  const { lotId } = useAngularService('$stateParams')

  const [rateDefinitions, setRateDefinitions] = useState(null)

  useEffect(() => {
    async function fetchData () {
      const response = await AdvancedRateDefinitions.fetchAll({ lotId })
      setRateDefinitions(response.data.advancedRateDefinitions)
    }

    fetchData()
  }, [])

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          defaultState='edit-property-advanced-rates'
          defaultParams={{ lotId: lotId, advancedRateDefinitionId: 'latest' }}
        />
      </PageHeader>
      <HistoryBox rateDefinitions={rateDefinitions} />
    </NewGrid>
  )
}

function HistoryBox ({ rateDefinitions }) {
  return (
    <BoxContent>
      <BoxContent.Header title='Rate Editor History' />
      <BoxContent.Content noHorizontalPadding>
        {!rateDefinitions && <LoadingIndicator />}
        {rateDefinitions && <HistoryTable rateDefinitions={rateDefinitions} />}
      </BoxContent.Content>
    </BoxContent>
  )
}

function HistoryTable ({ rateDefinitions }) {
  const t = useTranslator()
  const [sortedRateDefinitions, setSortedRateDefinitions] = useState([])
  const [orders, setOrders] = useState([['versionNumber', 'desc']])
  const { lotId } = useAngularService('$stateParams')
  const $state = useAngularService('$state')

  useEffect(
    () =>
      setSortedRateDefinitions(sort({ items: rateDefinitions, order: orders })),
    [rateDefinitions, orders]
  )

  const handleSort = (event, property) => {
    setOrders(
      generateOrderForClickEvent({
        event,
        currentOrder: orders,
        propertyName: property
      })
    )
  }

  const columns = [
    {
      value: t('advancedRateDefinitionsList.fields.versionNumber.headerLabel'),
      onClick: e => handleSort(e, 'versionNumber'),
      width: '20%',
      key: 'versionNumber',
      nowwrap: 'nowrap',
      sortable: true,
      sortableKey: 'versionNumber'
    },
    {
      value: t('advancedRateDefinitionsList.fields.versionNumber.statusLabel'),
      key: 'status',
      width: '50%'
    },
    {
      value: t('advancedRateDefinitionsList.fields.lastUpdate.headerLabel'),
      onClick: e => handleSort(e, 'updatedAt'),
      width: '25%',
      key: 'updatedAt',
      nowwrap: 'nowrap',
      sortable: true,
      sortableKey: 'updatedAt'
    }
  ]

  const handleClick = advancedRateDefinitionId => {
    $state.go('edit-property-advanced-rates', {
      lotId,
      advancedRateDefinitionId
    })
  }

  const columnsFormatted = columns.map(column => ({
    ...column,
    textColor: 'gray_dark',
    uppercase: false,
    orders
  }))

  const data = sortedRateDefinitions.map(item => {
    return {
      onClick: () => handleClick(item.id),
      versionNumber: {
        value: t('advancedRateDefinitionsList.fields.versionNumber.value', {
          number: item.versionNumber
        }),
        verticalAlign: 'middle'
      },
      status: {
        value: (
          <div className={styles.boxBadge}>
            <AdvancedRateDefinitionStatusBadge status={item.status} />
          </div>
        ),
        verticalAlign: 'middle',
        noVerticalPadding: true
      },
      updatedAt: {
        value: t('defaultFormats.datetime', { date: item.updatedAt }),
        verticalAlign: 'middle'
      }
    }
  })

  return (
    <Table
      columns={columnsFormatted}
      sortable
      clickable
      responsive
      spacing
      showIconRight
      verticalAlignIconRight='middle'
      headerAppearance='white'
      data={data}
      className={styles.table}
    />
  )
}

export default Page
