export const generateMaxDistanceOptions = (Authentication, Settings, t) => {
  const user = Authentication.getLoggedUser()
  const measurementSystem = Settings.getMeasurementSystemFromCountryCode(
    user.phoneCountryCode
  )

  switch (measurementSystem) {
    case 'metric':
      return [
        {
          miles: 1,
          label: t('appendableEvents.kilometers', { count: 2 })
        },
        {
          miles: 6,
          label: t('appendableEvents.kilometers', { count: 10 })
        }
      ]

    case 'imperial':
      return [
        {
          miles: 1,
          label: t('appendableEvents.miles', { count: 1 })
        },
        {
          miles: 5,
          label: t('appendableEvents.miles', { count: 5 })
        }
      ]
  }
}
