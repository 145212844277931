'use strict'

export const ADD_RULE = 'ADD_RULE'
export const addRule = () => ({ type: ADD_RULE })

export const DUPLICATE_RULE = 'DUPLICATE_RULE'
export const duplicateRule = id => ({
  type: DUPLICATE_RULE,
  payload: { id }
})

export const DELETE_RULE = 'DELETE_RULE'
export const deleteRule = id => ({ type: DELETE_RULE, payload: { id } })

export const UPDATE_RULE_FIELD = 'UPDATE_RULE_FIELD'
export const updateRuleField = (id, fieldPath, fieldValue) => ({
  type: UPDATE_RULE_FIELD,
  payload: { id, fieldPath, fieldValue }
})

export const SORT_RULES = 'SORT_RULES'
export const sortRules = ({ event, propertyName }) => ({
  type: SORT_RULES,
  payload: { event, propertyName }
})

export const VALIDATE_RULES = 'VALIDATE_RULES'
export const validateRules = () => ({ type: VALIDATE_RULES })

export const START_LOADING = 'START_LOADING'
export const startLoading = ({ message = null } = {}) => ({
  type: START_LOADING,
  payload: { message }
})

export const STOP_LOADING = 'STOP_LOADING'
export const stopLoading = () => ({ type: STOP_LOADING })

export const MARK_EDITABLE_RULES_AS_PERSISTED =
  'MARK_EDITABLE_RULES_AS_PERSISTED'
export const markEditableRulesAsPersisted = () => ({
  type: MARK_EDITABLE_RULES_AS_PERSISTED
})

export const PATCH_PREVIEW_STATE = 'PATCH_PREVIEW_STATE'
export const patchPreviewState = patch => ({
  type: PATCH_PREVIEW_STATE,
  payload: { patch }
})

export const PATCH_STATE = 'PATCH_STATE'
export const patchState = patch => ({
  type: PATCH_STATE,
  payload: { patch }
})
