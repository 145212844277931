'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory(
  'StripeConnectedAccounts',
  (API, Authentication, $http, $log, $rootScope) => {
    function getStripeConnectedAccounts () {
      return $http({
        method: 'GET',
        url: API.base + '/admin/stripe-connected-accounts',
        headers: Authentication.generateHeaders()
      })
    }

    function checkStripeConnectedAccountsStatus () {
      return $http({
        method: 'GET',
        url: API.base + '/admin/stripe-connected-accounts/status-check',
        headers: Authentication.generateHeaders()
      })
    }

    function getStripeConnectedAccount (stripeAccountId) {
      return $http({
        method: 'GET',
        url: API.base + '/admin/stripe-connected-accounts/' + stripeAccountId,
        headers: Authentication.generateHeaders()
      })
    }

    function updateStripeConnectedAccount (
      stripeAccountId,
      { bankAccountToken }
    ) {
      return $http({
        method: 'PUT',
        url: API.base + '/admin/stripe-connected-accounts/' + stripeAccountId,
        headers: Authentication.generateHeaders(),
        data: { stripeConnectedAccount: { bankAccountToken } }
      })
    }

    function updateStripeConnectedAccountRequirements (stripeAccountId) {
      return $http({
        method: 'POST',
        url:
          API.base +
          '/admin/stripe-connected-accounts/' +
          stripeAccountId +
          '/requirement-updates',
        headers: Authentication.generateHeaders()
      })
    }

    function createStripeConnectedAccount ({ countryCode, bankAccountToken }) {
      return $http({
        method: 'POST',
        url: API.base + '/admin/stripe-connected-accounts',
        headers: Authentication.generateHeaders(),
        data: { stripeConnectedAccount: { countryCode, bankAccountToken } }
      })
    }

    function generateStripeConnectedAccountVerificationLink (stripeAccountId) {
      return $http({
        method: 'POST',
        url:
          API.base +
          '/admin/stripe-connected-accounts/' +
          stripeAccountId +
          '/account-links',
        headers: Authentication.generateHeaders()
      })
    }

    function loadStripeConnectedAccountsStatusToLoggedUser () {
      if (!$rootScope.loggedUser) {
        return
      }

      checkStripeConnectedAccountsStatus()
        .then(response => {
          if (!$rootScope.loggedUser) {
            throw new Error('No loggedUser')
          }
          $rootScope.loggedUser = {
            ...$rootScope.loggedUser,
            stripeConnectedAccountsStatus: response.data.result
          }
        })
        .catch(err => {
          $log.info('Error loading Stripe connected accounts status', err)
        })
    }

    function clearStripeConnectedAccountsStatusFromLoggedUser () {
      if ($rootScope.loggedUser) {
        $rootScope.loggedUser.stripeConnectedAccountsStatus = null
      }
    }

    return {
      getStripeConnectedAccounts,
      getStripeConnectedAccount,
      checkStripeConnectedAccountsStatus,
      createStripeConnectedAccount,
      updateStripeConnectedAccount,
      updateStripeConnectedAccountRequirements,
      generateStripeConnectedAccountVerificationLink,
      loadStripeConnectedAccountsStatusToLoggedUser,
      clearStripeConnectedAccountsStatusFromLoggedUser
    }
  }
)
