'use strict'

import angular from 'angular'

import templateUrl from './ticket-status-tag.html'
import './ticket-status-tag.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctTicketStatusTag', function ($i18next) {
  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {
      statusTag: '='
    },

    controller: function ($scope) {
      $scope.label = $i18next.t(`ticketStatusTags.${$scope.statusTag}`)

      $scope.cssClass = {}
      $scope.cssClass[$scope.statusTag] = true
    }
  }
})
