'use strict'

import angular from 'angular'
import revalidator from 'revalidator'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.factory('SchemaValidator', () => {
  function parsePatterns (obj) {
    for (let key in obj) {
      if (
        key === 'pattern' &&
        _.isString(obj[key]) &&
        obj[key].startsWith('/')
      ) {
        let parts = obj[key].match(/^\/(.*)\/([^/]*)$/)
        obj[key] = new RegExp(parts[1], parts[2])
      } else if (_.isPlainObject(obj[key])) {
        parsePatterns(obj[key])
      }
    }

    return obj
  }

  function getErrors (schema, value) {
    schema = parsePatterns(_.cloneDeep(schema))

    // Workaround on Revalidator bug described on
    // https://github.com/flatiron/revalidator/issues/122
    if (schema.type !== 'array' && !_.isPlainObject(value)) {
      return [
        {
          attribute: 'type',
          property: 'it',
          message: 'must be an object'
        }
      ]
    }

    let result = revalidator.validate(value, schema, {
      validateFormatsStrict: true,
      cast: true,
      additionalProperties: false
    })

    return result.valid ? [] : result.errors
  }

  function getErrorMessages (schema, value) {
    let errors = getErrors(schema, value)
    return errors.map(error => error.message)
  }

  return {
    getErrors,
    getErrorMessages
  }
})
