/* global FormData */

'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory(
  'Events',
  (API, Authentication, Helper, $http, $httpParamSerializer) => {
    function fetchStats (options) {
      return $http({
        method: 'GET',
        params: options,
        url: API.base + '/admin/events/stats',
        headers: Authentication.generateHeaders()
      })
    }

    function findAllEvents (options) {
      return Helper.abortableRequest({
        method: 'GET',
        params: options,
        url: API.base + '/admin/events',
        headers: Authentication.generateHeaders()
      })
    }

    function findMonitoringData (eventId, options) {
      return $http({
        method: 'GET',
        params: options,
        url: API.base + '/admin/events/' + eventId + '/monitoring',
        headers: Authentication.generateHeaders()
      })
    }

    function findEvent (eventId, options) {
      return $http({
        method: 'GET',
        params: options,
        url: API.base + '/admin/events/' + eventId,
        headers: Authentication.generateHeaders()
      })
    }

    function createEvent (data) {
      return $http({
        method: 'POST',
        url: API.base + '/events',
        headers: Authentication.generateHeaders(),
        data: data
      })
    }

    function updateEvent (eventId, data) {
      return $http({
        method: 'PUT',
        url: API.base + '/events/' + eventId,
        headers: Authentication.generateHeaders(),
        data: data
      })
    }

    function updateEventPhoto (eventId, file) {
      var formData = new FormData()
      formData.append('image', file)

      var url = API.base + '/events/' + eventId + '/image'
      var headers = Authentication.generateHeaders()

      return $http.post(url, formData, {
        transformRequest: angular.identity,
        headers: { ...headers, 'Content-Type': undefined }
      })
    }

    function findAppendableEvents (options) {
      return $http({
        method: 'GET',
        params: options,
        url: API.base + '/admin/events/append',
        headers: Authentication.generateHeaders()
      })
    }

    function appendToEvent (eventId, data) {
      return $http({
        method: 'PUT',
        data: data,
        url: API.base + '/admin/events/' + eventId + '/append',
        headers: Authentication.generateHeaders()
      })
    }

    function updateEventGates (eventId, eventGates) {
      return $http({
        method: 'PUT',
        data: { eventGates: eventGates },
        url: API.base + '/admin/events/' + eventId + '/gates',
        headers: Authentication.generateHeaders()
      })
    }

    function findEventsAvailableForExchange (
      ticketId,
      showDifferentRate,
      showSameEvent
    ) {
      return $http({
        method: 'GET',
        url: API.base + '/tickets/' + ticketId + '/exchange',
        headers: Authentication.generateHeaders(),
        params: {
          showDifferentRate: showDifferentRate,
          showSameEvent: showSameEvent
        }
      })
    }

    function getEventReportCsvUrl (id, options) {
      return Authentication.mountAuthenticatedUrl(
        API.base +
          '/admin/events/' +
          id +
          '/csv?' +
          $httpParamSerializer(options)
      )
    }

    return {
      find: findEvent,
      findMonitoringData: findMonitoringData,
      findAll: findAllEvents,
      findAllAppendable: findAppendableEvents,
      findAvailableForExchange: findEventsAvailableForExchange,
      appendToEvent: appendToEvent,
      fetchStats: fetchStats,
      create: createEvent,
      update: updateEvent,
      updateEventGates: updateEventGates,
      updatePhoto: updateEventPhoto,
      getEventReportCsvUrl
    }
  }
)
