import React from 'react'
import { NewGrid, Text, Icon } from '@citifyd/style'
import classNames from 'classnames'
import BackLink from '../BackLink'
import styles from './PageHeader.module.scss'

const PageHeader = ({ children, border = true, className, ...props }) => {
  return (
    <NewGrid.Row
      className={classNames(styles.pageHeader, className, { bordered: border })}
      {...props}
    >
      {children}
    </NewGrid.Row>
  )
}

PageHeader.Title = ({
  defaultState,
  defaultParams,
  children,
  className,
  title,
  backState,
  onClick,
  ...props
}) => {
  return (
    <NewGrid.Col
      className={classNames(styles.pageHeaderTitle, className)}
      xs={12}
      sm={6}
    >
      {children}
      {defaultState || backState ? (
        <BackLink
          defaultState={defaultState}
          defaultParams={defaultParams}
          text={title}
        />
      ) : onClick ? (
        <div onClick={onClick} className={styles.button}>
          <Icon size='h1' className={styles.icon} icon='chevron-left' />
          <Text variant='h1' component='h1' weight='300'>
            {title}
          </Text>
        </div>
      ) : (
        <Text variant='h1' component='h1' {...props}>
          {title}
        </Text>
      )}
    </NewGrid.Col>
  )
}

PageHeader.Actions = ({ children, className, ...props }) => {
  return (
    <NewGrid.Col
      className={classNames(styles.pageHeaderActions, className)}
      xs={12}
      sm={6}
      {...props}
    >
      {children}
    </NewGrid.Col>
  )
}

PageHeader.Action = ({ children, className }) => {
  return (
    <div className={classNames(styles.pageHeaderAction, className)}>
      {children}
    </div>
  )
}

export default PageHeader
