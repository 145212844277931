import React from 'react'
import classNames from 'classnames'
import { Text } from '@citifyd/style'

import NavBar from '../NavBar'
import { useTranslator } from '../../hooks'
import styles from '../NavBar/NavBar.module.scss'

const OriginalUserNotificationBar = ({
  loggedUser,
  originalUser,
  returnToOriginalUser
}) => {
  const t = useTranslator()

  if (!loggedUser || (loggedUser && originalUser.id === loggedUser.id)) {
    return null
  }

  return (
    <NavBar>
      <div className={classNames(styles.text, styles.centered)}>
        <Text>
          {t('index.currentlyAutheticated', { name: loggedUser.name })}{' '}
          <a href='' onClick={returnToOriginalUser}>
            {t('index.switchBack', { name: originalUser.name })}
          </a>
        </Text>
      </div>
    </NavBar>
  )
}

export default OriginalUserNotificationBar
