'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.controller('InviteParkersResultCtrl', function (
  Helper,
  Users,
  $window,
  $scope,
  $state
) {
  $scope.finish = function () {
    $state.go('users', {}, { location: 'replace' })
  }

  function init () {
    $scope.$on('init-step-5', function (e, data) {
      $scope.accounts = data.accounts
      $scope.bundles = data.bundles
      $scope.events = data.events
      $scope.result = data.result
    })
  }

  init()
})
