'use strict'

import React, { useState, useEffect } from 'react'
import { Text, NewGrid, Icon } from '@citifyd/style'
import { map, sortBy } from 'lodash'
import {
  useModalOpener,
  withModalManager
} from '../../../../shared/react/modalManager'

import LoadingManager from '../../../../shared/react/components/LoadingManager'
import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'
import accountLotsStyles from '../AccountLots/AccountLots.module.scss'
import styles from '../AccountInformation/AccountInformation.module.scss'
import ManageOrganizationUserModal from '../../../../shared/modals/ManageOrganizationUserModal'
import Mode from '../../../../shared/utils/mode'

const AccountInformation = () => {
  const t = useTranslator()
  const Authentication = useAngularService('Authentication')
  const Users = useAngularService('Users')
  const openModal = useModalOpener()

  const [users, setUsers] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const loggedUser = Authentication.getLoggedUser()
  const mayAddUsers = loggedUser?.organizationRole === 'admin'

  const openUserModal = ({ mode, userOrInvitation }) => {
    openModal(ManageOrganizationUserModal, {
      mode,
      userOrInvitation,
      onClose: ({ refreshUsers } = {}) => {
        if (refreshUsers) {
          loadUsers()
        }
      }
    })
  }

  const openAddUserModal = () => openUserModal({ mode: Mode.ADD })

  const openEditUserModal = userOrInvitation =>
    openUserModal({ mode: Mode.EDIT, userOrInvitation })

  const mayEditUser = user => {
    const isOrganizationAdmin = loggedUser.organizationRole === 'admin'
    const isMe = user.id === loggedUser.id

    return isOrganizationAdmin || isMe
  }

  const loadUsers = async () => {
    setIsLoading(true)

    if (!loggedUser.organization) {
      return null
    }

    try {
      const response = await Users.findMyOrganizationUsers()

      const invitations = map(response.data.invitations, function (invitation) {
        invitation.type = 'invitation'
        return invitation
      })

      const users = map(response.data.users, function (user) {
        user.type = 'user'
        return user
      })

      const allUsers = users.concat(invitations)

      setUsers(sortBy(allUsers, 'name'))
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadUsers()
  }, [])

  return (
    <NewGrid.Col md={6} className={styles.account}>
      <Text className={styles.title} component='h2' variant='h1' bold>
        {t('accountInfo.access')}

        {mayAddUsers && (
          <span className={accountLotsStyles.addNew} onClick={openAddUserModal}>
            <Text weight='300' variant='subtitle'>
              {t('accountInfo.add')}
            </Text>{' '}
            <Icon icon='plus-circle' pull='right' />
          </span>
        )}
      </Text>

      {isLoading || hasError ? (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      ) : (
        users.map((userOrInvitation, index) => {
          return (
            <div key={index} className={styles.infoBucket}>
              <Text bold variant='subtitle'>
                {userOrInvitation.name}{' '}
                {userOrInvitation.type === 'user' &&
                  userOrInvitation.organizationRole === 'admin' && (
                    <b>({t('accountInfo.admin')}) </b>
                  )}
                {userOrInvitation.type === 'invitation' && (
                  <b>({t('accountInfo.pending')})</b>
                )}
              </Text>

              {mayEditUser(userOrInvitation) && (
                <span
                  className={styles.edit}
                  onClick={() => openEditUserModal(userOrInvitation)}
                >
                  <Icon icon='edit' />
                </span>
              )}
            </div>
          )
        })
      )}
    </NewGrid.Col>
  )
}

export default withModalManager(AccountInformation)
