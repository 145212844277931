'use strict'

import React, { useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Text } from '@citifyd/style'
import CurrencyFormatter from '@citifyd/currency-formatter'

import TransactionExtraInformation from '../../../../shared/react/components/TransactionExtraInformation'
import { getCurrentLanguage } from '../../../../shared/react/utils'
import { useTranslator } from '../../../../shared/react/hooks'
import BoxContent from '../../../../shared/react/components/BoxContent'
import Dictionary, {
  DictionaryItem
} from '../../../../shared/react/components/Dictionary'
import styles from '../TicketTransactionData/TicketTransactionData.module.scss'

const TicketCancellationBox = ({ ticket }) => {
  const t = useTranslator()
  const ticketCancellation = useMemo(
    () => _.cloneDeep(ticket.ticketCancellation),
    []
  )
  const language = getCurrentLanguage()

  if (ticketCancellation) {
    const amount = _(ticketCancellation.transactions)
      .filter({ type: 'refund' })
      .sum('totalAmount')
    ticketCancellation.cancellationRefundAmount = Math.abs(amount)
  }

  if (!ticketCancellation) {
    return null
  }

  return (
    <BoxContent>
      <BoxContent.Header
        appearance='tertiary'
        title={t('transaction.cancellationInfo')}
        className={styles.informationHeader}
      />
      <BoxContent.Content>
        <Dictionary withDivisor={false}>
          <DictionaryItem
            textComponent='span'
            label={t('transaction.cancelledAt')}
          >
            {t('defaultFormats.datetime', {
              date: moment.tz(
                ticketCancellation.createdAt,
                ticket.availability.timezoneName
              )
            })}
          </DictionaryItem>
          {!ticket?.purchaseTransaction?.isCashPayment && (
            <DictionaryItem
              textComponent='span'
              label={t('transaction.amountRefunded')}
            >
              {ticketCancellation.refunded
                ? CurrencyFormatter.format(
                    ticketCancellation.cancellationRefundAmount,
                    {
                      currency: ticket.currency,
                      language
                    }
                  )
                : t('transaction.inProcess')}
            </DictionaryItem>
          )}

          {ticket?.purchaseTransaction?.isCashPayment && (
            <li>
              <Text className={styles.text}>
                {t('transaction.ticketCancellationBox.cashRefundDisclaimer')}
              </Text>
            </li>
          )}
        </Dictionary>
      </BoxContent.Content>
      {ticketCancellation.user?.id !== ticket.user?.id && (
        <BoxContent.Footer>
          <Text weight='700'>
            {t('transaction.fulfilledBy', {
              name: ticketCancellation?.user?.name || ''
            })}
          </Text>
          {ticketCancellation.reason && (
            <Text>
              {t('transaction.ifPasses')}
              <br />
              {ticketCancellation.reason}
              <br />
              <Text italic>
                <TransactionExtraInformation data={ticketCancellation} />
              </Text>
            </Text>
          )}
        </BoxContent.Footer>
      )}
    </BoxContent>
  )
}

export default TicketCancellationBox
