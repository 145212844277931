'use strict'

import React, { useEffect, useState } from 'react'
import { NewGrid, Label } from '@citifyd/style'
import styles from './Form.module.scss'

import {
  useTranslator,
  useAngularService
} from '../../../../../shared/react/hooks'

import FormikField, {
  FIELD_TYPE
} from '../../../../../shared/react/components/FormikField'

import Mode from '../../../mode'
import { syncLinkedDate } from '../../../../../shared/react/dateUtils'

const Form = ({ formikbag, venues, mode }) => {
  const t = useTranslator()
  const Helper = useAngularService('Helper')

  const [lastStartDateUpdate, setLastStartDateUpdate] = useState(null)
  const adjustAvailabilityDates = () => setLastStartDateUpdate(new Date())

  const times = Helper.generateTimesArray()

  const timeOptions = times.map(time => ({
    value: time.time,
    label: time.label
  }))
  const venueOptions = venues.map(venue => ({
    value: venue.id,
    label: venue.name
  }))

  const waitlistOptions = [
    { label: t('addEvent.waitlistOptions.enabled'), value: 'true' },
    { label: t('addEvent.waitlistOptions.disabled'), value: 'false' }
  ]

  useEffect(() => {
    if (mode === Mode.EDIT || !lastStartDateUpdate) {
      return
    }

    const { timezoneName } = venues.find(
      venue => venue.id === parseInt(formikbag.values.venueId)
    )

    const [
      updatedAvailabilityStartDate,
      updatedAvailabilityStartTime
    ] = syncLinkedDate({
      previousDate: formikbag.values.previousStartDate,
      previousTime: formikbag.values.previousStartTime,
      newDate: formikbag.values.startDate,
      newTime: formikbag.values.startTime,
      linkedDate: formikbag.values.defaultAvailabilityStartDate,
      linkedTime: formikbag.values.defaultAvailabilityStartTime,
      timezoneName
    })

    const [
      updatedAvailabilityEndDate,
      updatedAvailabilityEndTime
    ] = syncLinkedDate({
      previousDate: formikbag.values.previousStartDate,
      previousTime: formikbag.values.previousStartTime,
      newDate: formikbag.values.startDate,
      newTime: formikbag.values.startTime,
      linkedDate: formikbag.values.defaultAvailabilityEndDate,
      linkedTime: formikbag.values.defaultAvailabilityEndTime,
      timezoneName
    })

    formikbag.setFieldValue('previousStartDate', formikbag.values.startDate)
    formikbag.setFieldValue('previousStartTime', formikbag.values.startTime)
    formikbag.setFieldValue(
      'defaultAvailabilityStartDate',
      updatedAvailabilityStartDate
    )
    formikbag.setFieldValue(
      'defaultAvailabilityStartTime',
      updatedAvailabilityStartTime
    )
    formikbag.setFieldValue(
      'defaultAvailabilityEndDate',
      updatedAvailabilityEndDate
    )
    formikbag.setFieldValue(
      'defaultAvailabilityEndTime',
      updatedAvailabilityEndTime
    )
  }, [lastStartDateUpdate])

  return (
    <>
      <NewGrid.Row marginBottom>
        <NewGrid.Col>
          <FormikField
            fullWidth
            disabled={!formikbag.values.isEditable}
            as={FIELD_TYPE.INPUT}
            name='name'
            label={t('addEvent.labels.eventName')}
            placeholder={t('addEvent.placeholders.eventName')}
          />
        </NewGrid.Col>
      </NewGrid.Row>

      <NewGrid.Row marginBottom>
        <NewGrid.Col>
          <FormikField
            disabled={!formikbag.values.isEditable}
            fullWidth
            as={FIELD_TYPE.SELECT}
            name='venueId'
            label={t('addEvent.labels.venue')}
            options={venueOptions}
          />
        </NewGrid.Col>
      </NewGrid.Row>

      <Label>{t('addEvent.labels.start')}</Label>
      <NewGrid.Row align='end' marginBottom gutterWidth={3}>
        <NewGrid.Col xs={7} sm={3}>
          <FormikField
            disabled={!formikbag.values.isEditable}
            fullWidth
            as={FIELD_TYPE.DATEPICKER}
            name='startDate'
            shouldResetTime
            minDate={mode === Mode.ADD ? new Date() : ''}
            handleChange={adjustAvailabilityDates}
          />
        </NewGrid.Col>
        <NewGrid.Col xs={5} sm={3}>
          <FormikField
            disabled={!formikbag.values.isEditable}
            fullWidth
            as={FIELD_TYPE.SELECT}
            options={timeOptions}
            label=''
            name='startTime'
            handleChange={adjustAvailabilityDates}
          />
        </NewGrid.Col>
      </NewGrid.Row>

      {mode === Mode.ADD && (
        <>
          <Label>{t('addEvent.labels.duration')}</Label>
          <NewGrid.Row marginBottom gutterWidth={3}>
            <NewGrid.Col xs={7} sm={3}>
              <FormikField
                fullWidth
                as={FIELD_TYPE.DATEPICKER}
                name='defaultAvailabilityStartDate'
                requireTouchForError={false}
                shouldResetTime
                minDate={new Date()}
              />
            </NewGrid.Col>
            <NewGrid.Col xs={5} sm={3}>
              <FormikField
                fullWidth
                as={FIELD_TYPE.SELECT}
                options={timeOptions}
                name='defaultAvailabilityStartTime'
                hasError={Boolean(
                  formikbag.errors.defaultAvailabilityStartDate
                )}
              />
            </NewGrid.Col>
            <NewGrid.Col sm={12} className={styles.mobileSeparator}>
              <span>&mdash;</span>
            </NewGrid.Col>
            <NewGrid.Col xs={7} sm={3}>
              <div className={styles.fieldBox}>
                <span className={styles.desktopSeparator}>&mdash;</span>
                <FormikField
                  fullWidth
                  as={FIELD_TYPE.DATEPICKER}
                  name='defaultAvailabilityEndDate'
                  shouldResetTime
                  requireTouchForError={false}
                  minDate={new Date()}
                />
              </div>
            </NewGrid.Col>
            <NewGrid.Col xs={5} sm={3}>
              <FormikField
                fullWidth
                as={FIELD_TYPE.SELECT}
                options={timeOptions}
                name='defaultAvailabilityEndTime'
                hasError={Boolean(formikbag.errors.defaultAvailabilityEndDate)}
              />
            </NewGrid.Col>
          </NewGrid.Row>
        </>
      )}
      <NewGrid.Row marginBottom>
        <NewGrid.Col>
          <FormikField
            disabled={!formikbag.values.isEditable}
            as={FIELD_TYPE.SELECT}
            options={waitlistOptions}
            label={t('addEvent.labels.waitlist')}
            name='waitlist'
          />
        </NewGrid.Col>
      </NewGrid.Row>
    </>
  )
}

export default Form
