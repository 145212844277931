'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

import templateUrl from './dashboard.html'

const app = angular.module('citifydSellerApp')

app.controller('DashboardCtrl', function (
  $scope,
  $log,
  $timeout,
  $state,
  Authentication,
  Properties,
  Events,
  Users
) {
  var loggedUser = Authentication.getLoggedUser()
  var timezoneName = _.get(loggedUser, 'timezoneName')
  var selectedDate = timezoneName ? moment().tz(timezoneName) : moment()

  $scope.scheduleSelectedDate = selectedDate.locale('en').format('YYYY-MM-DD')
  $scope.updatedAt = new Date()
  $scope.properties = []

  $scope.openAddAvailabilityModal = null
  $scope.openEditAvailabilityModal = null

  $scope.shouldLoadSubComponents = false
  $scope.hasLoadedProperties = false

  $scope.loadedPropertySnapshot = false
  $scope.$on('property-snapshot:initial-load-completed', function () {
    $scope.loadedPropertySnapshot = true

    // wait a few miliseconds before loading the subcomponents (parkers and schedule snapshot)
    // to avoid a possible race condition where the requests from the subcomponents are added
    // to the browser's request queue before the property snapshot requests
    $timeout(function () {
      $scope.shouldLoadSubComponents = true
    }, 100)
  })

  function init () {
    if (loggedUser?.organizationRole === 'permitter') {
      $state.go('permits', { location: 'replace' })

      return
    }

    Properties.findAll().then(function (response) {
      $scope.hasLoadedProperties = true
      $scope.hasProperties = response.data.lots.length !== 0
      $scope.properties = response.data.lots
    })
  }

  init()
})

export const controller = 'DashboardCtrl'
export const auth = true
export const title = 'i18n:dashboard.pageTitle'
export { templateUrl }
