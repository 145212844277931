'use strict'

import React from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import { Badge, Text } from '@citifyd/style'

import Table from '../../../../shared/react/components/Table'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import { getCurrentLanguage } from '../../../../shared/react/utils'
import styles from '../RevenueTab/RevenueTab.module.scss'

const RevenueDailyTable = ({ dates = [], currentFilter = {}, totals = {} }) => {
  const t = useTranslator()
  const language = getCurrentLanguage()
  const $state = useAngularService('$state')

  const columns = [
    {
      value: t('revenue.date'),
      nowrap: 'nowrap',
      width: '35%',
      key: 'date'
    },
    {
      value: t('revenue.onDemand'),
      nowrap: 'nowrap',
      key: 'onDemand',
      textAlign: 'right'
    },
    {
      value: t('revenue.reservedPasses'),
      nowrap: 'nowrap',
      key: 'reservedPasses',
      textAlign: 'right'
    },
    {
      value: t('revenue.eventPasses'),
      nowrap: 'nowrap',
      key: 'eventPasses',
      textAlign: 'right'
    },
    {
      value: t('revenue.monthly'),
      nowrap: 'nowrap',
      key: 'monthly',
      textAlign: 'right'
    },
    {
      value: t('revenue.revenue'),
      nowrap: 'nowrap',
      key: 'revenue',
      textAlign: 'right',
      width: '12%'
    }
  ]

  const handleClick = date => {
    $state.go('revenue-on-date', {
      currency: currentFilter.currency,
      lot: currentFilter.lotId || undefined,
      date: date.date
    })
  }

  const data = dates.map(date => {
    return {
      onClick: () => handleClick(date),
      date: {
        value: (
          <div className={styles.boxDate}>
            {t('defaultFormats.date', { date: date.date })}{' '}
            {date.hasReservationWithPendingPaymentProcessing && (
              <Badge appearance='gray'>{t('dailyRevenue.estimated')}</Badge>
            )}
          </div>
        ),
        verticalAlign: 'middle',
        noVerticalPadding: true
      },
      onDemand: {
        value: date.reservationCount || '0',
        textAlign: 'right',
        verticalAlign: 'middle'
      },
      reservedPasses: {
        value: date.reservedParkingPassCount || '0',
        textAlign: 'right',
        verticalAlign: 'middle'
      },
      eventPasses: {
        value: date.ticketCount || '0',
        textAlign: 'right',
        verticalAlign: 'middle'
      },
      monthly: {
        value: date.subscriptionCount || '0',
        textAlign: 'right',
        verticalAlign: 'middle'
      },
      revenue: {
        value: CurrencyFormatter.format(date.netRevenueAmount, {
          currency: currentFilter.currency,
          language
        }),
        textAlign: 'right',
        verticalAlign: 'middle',
        textColor: date.netRevenueAmount < 0 ? 'tertiary' : 'default'
      }
    }
  })

  const renderCell = value => (
    <Text textAlign='right' weight='700' className={styles.total}>
      {value}
    </Text>
  )

  const dataWithTotal = [
    ...data,
    {
      showIcon: false,
      appearance: 'white',
      date: (
        <Text weight='700' className={styles.total}>
          {t('dailyRevenue.total')}
        </Text>
      ),
      onDemand: renderCell(totals.reservationCount || '0'),
      reservedPasses: renderCell(totals.reservedParkingPassCount || '0'),
      eventPasses: renderCell(totals.ticketCount || '0'),
      monthly: renderCell(totals.subscriptionCount || '0'),
      revenue: {
        value: renderCell(
          CurrencyFormatter.format(totals.netRevenueAmount, {
            currency: currentFilter.currency,
            language
          })
        ),
        textColor: totals.netRevenueAmount < 0 ? 'tertiary' : 'default'
      }
    }
  ]

  return (
    <Table
      striped
      columns={columns}
      data={dataWithTotal}
      responsive
      clickable
      showIconRight
      borderBottom
      headerGap
      verticalAlignIconRight='middle'
    />
  )
}

export default RevenueDailyTable
