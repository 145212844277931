'use strict'

import React from 'react'
import { useTranslator } from '../../hooks'
import { Badge } from '@citifyd/style'

export default function GuestBadge ({ label, ...props }) {
  const t = useTranslator()

  return (
    <Badge appearance='gray' textAppearance='gray_darker' {...props}>
      {label || t('user.guestBadge')}
    </Badge>
  )
}
