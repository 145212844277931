import { uniq } from 'lodash'
import { getAngularService } from '../../../react/utils'

const languageSelectionRequired = ({
  forceLanguageSelection,
  lot,
  purchaser
}) => {
  const Authentication = getAngularService('Authentication')

  if (forceLanguageSelection) {
    return true
  }

  const languages = [
    purchaser?.language,
    lot.country?.language,
    Authentication.getLoggedUser().language
  ]

  const allLanguagesAreEqual = uniq(languages).length === 1
  return !allLanguagesAreEqual
}

export default languageSelectionRequired
