'use strict'

import angular from 'angular'

import '../views/plans.html'

const app = angular.module('citifydSellerApp')

app.controller('AddPropertyPlansCtrl', function (
  Currencies,
  Plans,
  $log,
  $window,
  $scope,
  $location,
  $i18next
) {
  $scope.isLoading = false

  var planData = $scope.planData

  $scope.hasMonthlyPlan = function () {
    return planData.plan && !planData.plan.remove
  }

  $scope.addMonthlyPlan = function () {
    if (planData.plan) {
      planData.planEdit = planData.plan
      planData.plan.remove = false
      return
    }

    planData.planEdit = {
      period: 'month',
      billingCycle: 1,
      cancellationNoticePeriod: 30,
      spaces: $scope.lot.maxSpots,
      price: null
    }
  }

  $scope.editMonthlyPlan = function () {
    planData.planEdit = planData.plan
  }

  $scope.saveMonthlyPlan = function () {
    $scope.$broadcast('show-errors-check-validity')

    if ($scope.forms.monthlyPlan.$invalid) {
      return
    }

    planData.plan = planData.planEdit
    planData.planEdit = null
  }

  $scope.cancelEdit = function () {
    planData.planEdit = null
  }

  $scope.removeMonthlyPlan = function () {
    if (!$window.confirm($i18next.t('commonConfirmations.areYouSure'))) {
      return
    }

    planData.plan.remove = true
    planData.planEdit = null
  }

  $scope.amountToInteger = amount => {
    return Currencies.transformToInteger(amount, $scope.lot.country.currency)
  }

  function loadPlan () {
    if ($scope.planData.plan) {
      return
    }

    const currency = $scope.lot.country.currency
    $scope.currencySettings = Currencies.getCurrencySettings(currency)
    $scope.monthlyRatePlaceholder = 10000

    $scope.isLoading = true

    Plans.findAll({ lotId: $scope.lot.id }).then(
      function (plans) {
        $scope.isLoading = false
        planData.plan = plans[0]

        if (planData.plan) {
          planData.plan.price = Currencies.transformToDecimal(
            planData.plan.price,
            currency
          )
        } else if ($location.search().editPlan) {
          $scope.addMonthlyPlan()
        }
      },

      function () {
        $scope.isLoading = false
        $log.info('Error loading monthly plan')
      }
    )
  }

  function init () {
    loadPlan()

    $scope.$on('add-property-step-2-submitted', function () {
      if (planData.planEdit) {
        $scope.saveMonthlyPlan()
      }
    })
  }

  init()
})
