'use strict'

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Table from '../../../shared/react/components/Table/Table.jsx'

import {
  formatPrice,
  formatRuleDaysOfWeek,
  formatRuleEndType,
  formatRuleEnd,
  formatTimeRange
} from '../utils/formatters'

const NonEditableTable = ({ columns }) => {
  const rules = useSelector(state => state.editableRules)
  const lot = useSelector(state => state.lot)

  const data = rules.map(rule => {
    const { id, name } = rule
    const amount = formatPrice(rule.price, lot?.country?.currency)
    const daysOfWeek = formatRuleDaysOfWeek(rule.days)
    const endType = formatRuleEndType(rule.end.type)
    const end = useMemo(() => formatRuleEnd(rule.end), [rule.end])
    const formattedTime = useMemo(() => formatTimeRange(rule.time), [rule.time])

    return {
      id: { value: id, textAlign: 'center' },
      name,
      price: amount,
      type: endType,
      end,
      time: formattedTime,
      days: daysOfWeek
    }
  })

  return (
    <Table
      spacing
      sortable
      headerAppearance='white'
      columns={columns}
      data={data}
    />
  )
}

export default NonEditableTable
