'use strict'

import React from 'react'

import { useTranslator } from '../../hooks'
import exportPDFImage from '../../../../images/PDF icon@3x.png'
import styles from './PDFDownload.module.scss'

const PDFDownload = ({ onClick }) => {
  const t = useTranslator()

  return (
    <a onClick={onClick} className={styles.icon}>
      <img
        src={exportPDFImage}
        alt={t('commonButtons.exportAsPdf')}
        title={t('commonButtons.exportAsPdf')}
      />
    </a>
  )
}

export default PDFDownload
