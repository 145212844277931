'use strict'

import angular from 'angular'

import BackLink from '../../react/components/BackLink/BackLink.jsx'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctBackLink',
  react2angular(BackLink, [
    'defaultState',
    'defaultParams',
    'defaultOptions',
    'noText'
  ])
)
