'use strict'

import React, { useEffect } from 'react'
import { NewGrid } from '@citifyd/style'

import { useAngularService } from '../../shared/react/hooks'
import AccountInformation from './components/AccountInformation'
import AccountLots from './components/AccountLots'
import AccountAccess from './components/AccountAccess'

const Properties = () => {
  const Authentication = useAngularService('Authentication')
  const CitifydModal = useAngularService('CitifydModal')
  const Permissions = useAngularService('Permissions')
  const $state = useAngularService('$state')
  const $location = useAngularService('$location')
  const loggedUser = Authentication.getLoggedUser()

  const userMaySeeLots = Permissions.userMaySeeLots()
  const userMaySeeAccountList = Permissions.userMaySeeAccountList()

  const openEditPaymentModal = () => {
    CitifydModal.open('edit-payment')
  }

  const openModalFromQueryString = () => {
    const open = $location.search().open

    switch (open) {
      case 'payment':
        openEditPaymentModal()
        break
    }
  }

  useEffect(() => {
    if (loggedUser?.role !== 'localManager') {
      openModalFromQueryString()
    }
  }, [])

  if (loggedUser && loggedUser.role === 'localManager') {
    $state.go('all-properties')
    return null
  }

  return (
    <NewGrid>
      <NewGrid.Row>
        <AccountInformation openEditPaymentModal={openEditPaymentModal} />
        {userMaySeeAccountList && <AccountAccess />}
      </NewGrid.Row>

      {userMaySeeLots && <AccountLots />}
    </NewGrid>
  )
}

export default Properties
