'use strict'

import angular from 'angular'
import templateUrl from './no-properties.html'

import './no-properties.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctNoProperties', () => ({
  restrict: 'E',
  templateUrl: templateUrl,
  scope: {},

  controller ($scope, Permissions, Languages) {
    $scope.userMayCreateLot = Permissions.userMayCreateLot()
    $scope.currentLanguage = Languages.getCurrent()
  }
}))
