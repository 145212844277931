'use strict'

import React from 'react'
import { get } from 'lodash'
import { Button, Text } from '@citifyd/style'

import { useTranslator, useAngularService } from '../../hooks'
import RouterLinkButton from '../RouterLinkButton'
import NavBar from '../NavBar'
import styles from '../NavBar/NavBar.module.scss'

const PaymentInformationNotificationBar = ({ loggedUser }) => {
  const t = useTranslator()
  const $window = useAngularService('$window')
  const inStripeAccountSetupPage =
    $window.location.pathname.indexOf('/stripe-account-setup') === 0
  const status = get(loggedUser, 'stripeConnectedAccountsStatus.status')
  const CitifydModal = useAngularService('CitifydModal')

  if (
    !loggedUser?.stripeConnectedAccountsStatus ||
    inStripeAccountSetupPage ||
    (status && status === 'ok')
  ) {
    return null
  }

  const openEditPaymentModal = () => {
    CitifydModal.open('edit-payment')
  }

  const renderMissingVerificationInformationLabel = () => {
    if (loggedUser.stripeConnectedAccountsStatus?.chargesEnabled) {
      return loggedUser.stripeConnectedAccountsStatus?.payoutsEnabled
        ? t(
            'paymentInformationNotificationBar.messages.missingVerificationInformation.chargesAndPayoutsEnabled',
            {
              deadline: loggedUser.stripeConnectedAccountsStatus?.deadline
            }
          )
        : t(
            'paymentInformationNotificationBar.messages.missingVerificationInformation.chargesEnabledAndPayoutsDisabled',
            {
              deadline: loggedUser.stripeConnectedAccountsStatus?.deadline
            }
          )
    }

    return loggedUser.stripeConnectedAccountsStatus?.payoutsEnabled
      ? t(
          'paymentInformationNotificationBar.messages.missingVerificationInformation.chargesDisabledAndPayoutsEnabled',
          {
            deadline: loggedUser.stripeConnectedAccountsStatus?.deadline
          }
        )
      : t(
          'paymentInformationNotificationBar.messages.missingVerificationInformation.chargesAndPayoutsDisabled',
          {
            deadline: loggedUser.stripeConnectedAccountsStatus?.deadline
          }
        )
  }

  const NavBarItem = ({ title, onClick, label, link, params }) => {
    return (
      <div className={styles.textWithButtons}>
        <div className={styles.text}>
          <span>
            <Text>{title}</Text>
          </span>
        </div>
        <div className={styles.buttons}>
          {link ? (
            <RouterLinkButton
              state={link}
              appearance='white'
              size='small'
              extraPadding
              params={params}
            >
              {label}
            </RouterLinkButton>
          ) : (
            <Button
              onClick={onClick}
              appearance='white'
              size='small'
              extraPadding
            >
              {label}
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <NavBar>
      {loggedUser.stripeConnectedAccountsStatus?.status ===
        'pending_bank_account' && (
        <NavBarItem
          title={t(
            'paymentInformationNotificationBar.messages.enterBankAccount'
          )}
          onClick={openEditPaymentModal}
          label={t(
            'paymentInformationNotificationBar.buttons.setUpPaymentInformation'
          )}
        />
      )}

      {loggedUser.stripeConnectedAccountsStatus?.status ===
        'missing_verification_information' && (
        <NavBarItem
          title={renderMissingVerificationInformationLabel()}
          link='stripe-account-setup'
          label={t(
            'paymentInformationNotificationBar.buttons.updateVerificationInformation'
          )}
        />
      )}

      {loggedUser.stripeConnectedAccountsStatus?.status ===
        'information_being_verified' && (
        <NavBarItem
          title={t(
            'paymentInformationNotificationBar.messages.informationBeingVerified'
          )}
          link='stripe-account-setup-result'
          label={t('paymentInformationNotificationBar.buttons.viewDetails')}
          params={{ status: 'view-details' }}
        />
      )}
    </NavBar>
  )
}

export default PaymentInformationNotificationBar
