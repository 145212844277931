'use strict'

import React, { useState, useEffect } from 'react'
import { Text, NewGrid } from '@citifyd/style'
import moment from 'moment-timezone'
import CurrencyFormatter from '@citifyd/currency-formatter'
import { filter, includes, sum } from 'lodash'

import TransactionExtraInformation from '../../../../shared/react/components/TransactionExtraInformation'
import PaymentMethod from '../../../../shared/react/components/PaymentMethod'
import Dictionary, {
  DictionaryItem
} from '../../../../shared/react/components/Dictionary'
import BoxContent from '../../../../shared/react/components/BoxContent'
import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'
import { getCurrentLanguage } from '../../../../shared/react/utils'
import { getLastName } from '../../../../shared/utils/utils'
import { getLabels } from './utils'

const TicketExchangeBox = ({ ticket, exchange }) => {
  const t = useTranslator()
  const Helper = useAngularService('Helper')
  const [exchangeTotalAmount, setExchangeTotalAmount] = useState(0)
  const [exchangeTransactions, setExchangeTransactions] = useState()
  const [transactionTime, setTransactionTime] = useState(exchange?.createdAt)
  const [exchangeCardActionLabel, setExchangeCardActionLabel] = useState()
  const [exchangeStatusLabel, setExchangeStatusLabel] = useState()
  const [exchangeStatus, setExchangeStatus] = useState()
  const [dateLabel, setDateLabel] = useState()
  const [timeLabel, setTimeLabel] = useState()
  const language = getCurrentLanguage()

  useEffect(() => {
    if (exchange) {
      exchange.isComplimentary
        ? calculateComplimentaryValues()
        : calculateDefaultValues()
    }
  }, [])

  const {
    dateLabels,
    timeLabels,
    exchangeCardActionLabels,
    exchangeStatusLabels,
    exchangeStatuses
  } = getLabels()

  const calculateComplimentaryValues = () => {
    const totalAmount = Helper.calculateRateDifference(
      exchange.newRate,
      exchange.previousRate
    )

    setExchangeTotalAmount(totalAmount)
    setDateLabel(dateLabels.payment)
    setTimeLabel(timeLabels.payment)
    setExchangeCardActionLabel(exchangeCardActionLabels.payment)
    setExchangeStatusLabel(exchangeStatusLabels.payment)
    setExchangeStatus(t('transaction.ticketExchangeBox.statuses.complimentary'))
  }

  const calculateDefaultValues = () => {
    const exchangeTransactions = filter(exchange.transactions, transaction => {
      return includes(['payment', 'refund'], transaction.type)
    })

    setExchangeTransactions(exchangeTransactions)
    setExchangeTotalAmount(sum(exchangeTransactions, 'totalAmount'))

    const transactions = exchangeTransactions

    if (transactions.length > 0) {
      const transaction = transactions[0]

      setTransactionTime(transaction.createdAt)
      setExchangeCardActionLabel(exchangeCardActionLabels[transaction.type])
      setExchangeStatusLabel(exchangeStatusLabels[transaction.type])
      setExchangeStatus(exchangeStatuses[transaction.type])
      setDateLabel(dateLabels[transaction.type])
      setTimeLabel(timeLabels[transaction.type])
    }
  }

  return (
    <BoxContent>
      <BoxContent.Header
        appearance='gray_dark'
        title={t('transaction.exchangeInfo')}
      />
      <BoxContent.Content>
        {exchangeTotalAmount !== 0 && (
          <NewGrid.Row>
            <NewGrid.Col sm={12} md={6}>
              <Dictionary withDivisor={false}>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.exchangedAt')}
                >
                  {t('defaultFormats.datetime', {
                    date: moment.tz(
                      exchange.createdAt,
                      ticket.availability.timezoneName
                    )
                  })}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.previousDate')}
                >
                  {t('defaultFormats.datetime', {
                    date: moment.tz(
                      exchange.previousEvent.start,
                      exchange.previousEvent.timezoneName
                    )
                  })}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.previousEvent')}
                >
                  {exchange.previousEvent.name}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.previousLot')}
                >
                  {exchange.previousLot.name}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.updatedDate')}
                >
                  {t('defaultFormats.datetime', {
                    date: moment.tz(
                      exchange.newEvent.start,
                      exchange.newEvent.timezoneName
                    )
                  })}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.updatedEvent')}
                >
                  {exchange.newEvent.name}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.updatedLot')}
                >
                  {exchange.newLot.name}
                </DictionaryItem>
              </Dictionary>
            </NewGrid.Col>
            <NewGrid.Col sm={12} md={6}>
              <Dictionary withDivisor={false}>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.priceDifference')}
                >
                  <Text
                    component='span'
                    appearance={
                      exchangeTotalAmount < 0 ? 'tertiary' : 'default'
                    }
                  >
                    {CurrencyFormatter.format(exchangeTotalAmount, {
                      currency: ticket.currency,
                      language
                    })}
                  </Text>
                </DictionaryItem>
                <DictionaryItem textComponent='span' label={dateLabel}>
                  {t('defaultFormats.date', {
                    date: moment(transactionTime).tz(
                      ticket.availability.timezoneName
                    )
                  })}
                </DictionaryItem>
                <DictionaryItem textComponent='span' label={timeLabel}>
                  {t('defaultFormats.time', {
                    date: moment(transactionTime).tz(
                      ticket.availability.timezoneName
                    )
                  })}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={exchangeCardActionLabel}
                >
                  {exchangeTransactions?.map((exchangeTransaction, index) => {
                    exchangeTransaction.card ? (
                      <span>
                        <PaymentMethod card={exchangeTransaction.card} />
                        {index + 1 < exchangeTransactions.length && (
                          <span>—</span>
                        )}
                      </span>
                    ) : null
                  })}
                  {exchange.isComplimentary && (
                    <span>
                      {exchange.organization
                        ? exchange.organization.name
                        : 'Citifyd'}
                    </span>
                  )}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.lastNameOnCard', {
                    count: exchangeTransactions?.length
                  })}
                >
                  {exchangeTransactions?.map((exchangeTransaction, index) => {
                    exchangeTransaction.card ? (
                      <span>
                        {getLastName(exchangeTransaction.card.name)}
                        {index + 1 < exchangeTransactions.length && (
                          <span>—</span>
                        )}
                      </span>
                    ) : null
                  })}
                  {exchange.isComplimentary && <span> &mdash; </span>}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.expirationDate', {
                    count: exchangeTransactions?.length
                  })}
                >
                  {exchangeTransactions?.map((exchangeTransaction, index) => {
                    exchangeTransaction.card &&
                    exchangeTransaction.card.expMonth ? (
                      <span>
                        {exchangeTransaction.card.expMonth}/
                        {exchangeTransaction.card.expYear}
                        {index + 1 < exchangeTransactions.length && (
                          <span>—</span>
                        )}
                      </span>
                    ) : null
                  })}
                  {exchange.isComplimentary && <span> &mdash; </span>}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={exchangeStatusLabel}
                >
                  {exchangeStatus}
                </DictionaryItem>
              </Dictionary>
            </NewGrid.Col>
          </NewGrid.Row>
        )}

        {exchangeTotalAmount === 0 && (
          <NewGrid.Row>
            <NewGrid.Col sm={12} md={6}>
              <Dictionary withDivisor={false}>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.exchangedAt')}
                >
                  {t('defaultFormats.datetime', {
                    date: moment.tz(
                      exchange.createdAt,
                      ticket.availability.timezoneName
                    )
                  })}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.previousDate')}
                >
                  {t('defaultFormats.datetime', {
                    date: moment.tz(
                      exchange.previousEvent.start,
                      exchange.previousEvent.timezoneName
                    )
                  })}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.previousEvent')}
                >
                  {exchange.previousEvent.name}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.previousLot')}
                >
                  {exchange.previousLot.name}
                </DictionaryItem>
              </Dictionary>
            </NewGrid.Col>
            <NewGrid.Col sm={12} md={6}>
              <Dictionary withDivisor={false}>
                <DictionaryItem textComponent='span' label='&nbsp;'>
                  &nbsp;
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.updatedDate')}
                >
                  {t('defaultFormats.datetime', {
                    date: moment.tz(
                      exchange.newEvent.start,
                      exchange.newEvent.timezoneName
                    )
                  })}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.updatedEvent')}
                >
                  {exchange.newEvent.name}
                </DictionaryItem>
                <DictionaryItem
                  textComponent='span'
                  label={t('transaction.updatedLot')}
                >
                  {exchange.newLot.name}
                </DictionaryItem>
              </Dictionary>
            </NewGrid.Col>
          </NewGrid.Row>
        )}
      </BoxContent.Content>
      {exchange.extra && (
        <BoxContent.Footer appearance='gray'>
          <Text weight='700'>
            {t('transaction.fulfilledBy', { name: exchange.user.name })}
          </Text>
          <Text>{t('transaction.ifPasses')}</Text>
          <Text>{exchange.extra.reason}</Text>
          <Text italic>
            <TransactionExtraInformation data={exchange.extra} />
          </Text>
        </BoxContent.Footer>
      )}
    </BoxContent>
  )
}

export default TicketExchangeBox
