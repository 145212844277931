'use strict'

import { useMemo } from 'react'
const { getTranslator } = require('../../utils')

function TransactionExtraInformation ({ data }) {
  if (!data) {
    return ''
  }

  return useMemo(() => {
    const t = getTranslator()

    return [
      data.requestedByPhone && t('transaction.requestedByPhone'),
      data.requestedByEmail && t('transaction.requestedByEmail')
    ]
      .filter(item => item)
      .join(', ')
  }, [data])
}

export default TransactionExtraInformation
