import React from 'react'
import { Text } from '@citifyd/style'

import { useTranslator } from '../../hooks'
import styles from './AppendableEventsEmpty.module.scss'

export default function AppendableEventsEmpty () {
  const t = useTranslator()

  return (
    <div className={styles.emptyAppend}>
      <div className={styles.wereSorry}>
        <div className={styles.inner}>
          <Text variant='h2'>{t('appendableEvents.sorry')}</Text>

          <Text>{t('appendableEvents.noEvents')}</Text>

          <a className={styles.btn}>{t('appendableEvents.addOnDemand')}</a>
        </div>
      </div>

      <div className={styles.inMind}>
        <div className={styles.inner}>
          <Text variant='h2' appearance='white'>
            {t('appendableEvents.addEvent')}
          </Text>

          <Text appearance='white'>{t('appendableEvents.alwaysLooking')}</Text>

          <a
            className={styles.btn}
            href={`mailto:support@citifyd.com?subject=${t(
              'appendableEvents.requestEmailSubject'
            )}}`}
          >
            {t('appendableEvents.contactCitifyd')}
          </a>
        </div>
      </div>
    </div>
  )
}
