'use strict'
import React from 'react'
import { Modal } from '@citifyd/style'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { useTranslator } from '../../react/hooks'
import { getCurrentLanguage } from '../../react/utils'

import Form from './components/Form/Form.jsx'

const stripePromise = loadStripe(process.env.STRIPE_KEY)

const AddCardModal = ({ open, onClose, userId }) => {
  const t = useTranslator()
  const language = getCurrentLanguage()
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>{t('modalAddCard.addCard')}</Modal.Title>
      <Elements stripe={stripePromise} options={{ locale: language }}>
        <Form onClose={onClose} userId={userId} />
      </Elements>
    </Modal>
  )
}

export default AddCardModal
