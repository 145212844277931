'use strict'

import React, { useMemo } from 'react'
import { sum } from 'lodash'
import moment from 'moment-timezone'
import { Text } from '@citifyd/style'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import Table from '../../../../shared/react/components/Table'
import { formatCurrency } from '../../utils'
import { useTableColumns } from './utils'
import styles from './Subscriptions.module.scss'

const Subscriptions = ({ subscriptions }) => {
  const t = useTranslator()

  const columns = useTableColumns()
  const Permissions = useAngularService('Permissions')
  const $state = useAngularService('$state')

  const userMayOpenSubscriptionPage = Permissions.userMayOpenSubscriptionPage()

  const formattedData = useMemo(() => {
    let subscriptionsList = subscriptions.map(subscription => {
      return {
        ...(userMayOpenSubscriptionPage && {
          onClick: () =>
            $state.go(
              'subscription',
              { id: subscription.id },
              { enableBackLink: true }
            )
        }),
        name: subscription.user?.name,
        lotName: subscription.plan?.lot?.name,
        time: t('defaultFormats.time', {
          date: moment.tz(
            subscription.createdAt,
            subscription.plan?.lot?.timezoneName
          )
        }),
        type: subscription.initialSubscription
          ? t('dailyRevenue.renewal')
          : t('dailyRevenue.newParker'),
        revenueAmount: {
          value: formatCurrency(subscription.netRevenueAmount),
          textAlign: 'right',
          textColor: subscription.netRevenueAmount < 0 ? 'tertiary' : 'default'
        }
      }
    })

    subscriptionsList = [
      ...subscriptionsList,
      {
        name: <b>{t('dailyRevenue.total')}</b>,
        lotName: null,
        time: null,
        type: null,
        revenueAmount: {
          value: (
            <b>{formatCurrency(sum(subscriptions, 'netRevenueAmount'))}</b>
          ),
          textAlign: 'right'
        }
      }
    ]

    return subscriptionsList
  }, [subscriptions])

  return (
    <>
      <div className={styles.revenueContent}>
        <Text gutterBottom variant='h3'>
          {t('dailyRevenue.monthlyParkers')}
        </Text>

        <Table
          className={styles.table}
          data={formattedData}
          columns={columns}
          borderBottom
          striped
          clickable
        />
      </div>
    </>
  )
}

export default Subscriptions
