'use strict'

import angular from 'angular'

import './flag.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctFlag', function () {
  return {
    restrict: 'E',
    template: `<span ng-class="css"></span>`,
    scope: { countryCode: '=' },
    controller: $scope => {
      $scope.$watch('countryCode', () => {
        $scope.css = { flag: true, [`flag-${$scope.countryCode}`]: true }
      })
    }
  }
})
