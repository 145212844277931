'use strict'

import angular from 'angular'
import _ from 'lodash'
import uuid from 'uuid/v4'
import revalidator from 'revalidator'

const app = angular.module('citifydSellerApp')

app.controller('InviteParkersAccountInfoCtrl', function (
  Helper,
  Users,
  $i18next,
  $scope
) {
  $scope.isEmpty = function (account) {
    const fields = ['name', 'email', 'phoneNumber']
    return _.every(fields, field => account[field].trim() === '')
  }

  $scope.canAddAccount = function () {
    const last = _.last($scope.accounts)
    return !last || !$scope.isEmpty(last)
  }

  $scope.removeError = function (account) {
    account.error = null
  }

  $scope.addAccount = function () {
    if ($scope.canAddAccount()) {
      $scope.accounts.push({
        cid: uuid(),
        name: '',
        email: '',
        phoneNumber: ''
      })

      _.defer(() => Helper.scrollTo('.account-info-table tbody tr:last'))
    }
  }

  $scope.canRemoveAccount = function () {
    return $scope.accounts.length > 1
  }

  $scope.removeAccount = function (account) {
    if ($scope.canRemoveAccount()) {
      _.remove($scope.accounts, a => a.cid === account.cid)
    }
  }

  $scope.submitForm = function () {
    const isValid = validate()

    $scope.accounts.forEach(account => {
      const fields = ['name', 'email', 'phoneNumber']
      fields.forEach(field => {
        account[field] = account[field].trim()
      })
    })

    if (isValid) {
      let accounts = $scope.accounts
        .filter(account => !$scope.isEmpty(account))
        .map(account =>
          _.pick(account, ['cid', 'name', 'email', 'phoneNumber'])
        )

      // console.log('accounts', accounts)
      $scope.changeStep(2, { accounts })
    }
  }

  function removeEmptyFields (account) {
    return _.pick(account, value => !isEmpty(value))
  }

  function isEmpty (value) {
    return !_.isString(value) || value.trim() === ''
  }

  function validate () {
    const schema = {
      email: { type: 'string', format: 'email', required: false },
      phoneNumber: { type: 'string', pattern: /^[0-9]{10}$/, required: false }
    }

    let isValid = true
    const usedEmails = []
    const usedPhoneNumbers = []

    $scope.accounts.forEach((account, index) => {
      // if the last one is empty, ignore it
      const hasMultipleAccounts = $scope.accounts.length > 1
      const isLastAccount = index + 1 === $scope.accounts.length
      if (hasMultipleAccounts && isLastAccount && $scope.isEmpty(account)) {
        return
      }

      if (account.name === '') {
        account.error = $i18next.t(
          'inviteParkers.accountInfo.errors.missingName'
        )
        isValid = false
        return
      }

      if (account.email === '' && account.phoneNumber === '') {
        account.error = $i18next.t(
          'inviteParkers.accountInfo.errors.missingEmailOrPhoneNumber'
        )
        isValid = false
        return
      }

      const validation = revalidator.validate(removeEmptyFields(account), {
        properties: schema
      })
      const invalidEmail = _.find(validation.errors, { property: 'email' })
      const invalidPhoneNumber = _.find(validation.errors, {
        property: 'phoneNumber'
      })

      if (invalidEmail) {
        account.error = $i18next.t(
          'inviteParkers.accountInfo.errors.invalidEmail'
        )
        isValid = false
        return
      }

      if (invalidPhoneNumber) {
        account.error = $i18next.t(
          'inviteParkers.accountInfo.errors.invalidPhoneNumber'
        )
        isValid = false
        return
      }

      if (!isEmpty(account.email)) {
        if (_.includes(usedEmails, account.email)) {
          account.error = $i18next.t(
            'inviteParkers.accountInfo.errors.duplicateEmail'
          )
          isValid = false
          return
        }

        usedEmails.push(account.email)
      }

      if (!isEmpty(account.phoneNumber)) {
        if (_.includes(usedPhoneNumbers, account.phoneNumber)) {
          account.error = $i18next.t(
            'inviteParkers.accountInfo.errors.duplicatePhoneNumber'
          )
          isValid = false
          return
        }

        usedPhoneNumbers.push(account.phoneNumber)
      }
    })

    return isValid
  }

  function init () {
    $scope.accounts = []
    $scope.addAccount()
  }

  init()
})
