'use strict'

import angular from 'angular'

import templateUrl from './color-selector.html'
import './color-selector.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctColorSelector', function () {
  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {
      disabled: '=',
      selectedColor: '=',
      availableColors: '=',
      disableEmptySelection: '=',
      onChange: '='
    },

    controller: function ($scope) {
      $scope.setColor = function (color) {
        if ($scope.disabled) {
          return false
        }

        $scope.selectedColor = color

        if (typeof $scope.onChange === 'function') {
          $scope.onChange($scope.selectedColor)
        }

        return true
      }
    }
  }
})
