import gql from 'graphql-tag'

export default gql`
  mutation AdminCreatePermitPass(
    $phoneNumber: String!
    $phoneCountryCode: String!
    $lotId: Int!
    $startTime: DateTime!
    $endTime: DateTime
  ) {
    admin_createPermitPass(
      phoneNumber: $phoneNumber
      phoneCountryCode: $phoneCountryCode
      lotId: $lotId
      startTime: $startTime
      endTime: $endTime
    ) {
      id
      user {
        id
        name
      }
      lot {
        id
        name
      }
      vehicle {
        id
        label
        model
        license
      }
      isActive
      status
      startTime
      endTime
      activatedAt
      createdAt
    }
  }
`
