'use strict'

import angular from 'angular'
import _ from 'lodash'
import { react2angular } from 'react2angular'

import PaymentInformationNotificationBar from '../../react/components/PaymentInformationNotificationBar'

const app = angular.module('citifydSellerApp')

app.component(
  'ctPaymentInformationNotificationBar',
  react2angular(PaymentInformationNotificationBar, ['loggedUser'])
)
