'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.directive('ctChangesLinkedTimeOnObject', function (Helper) {
  return {
    require: 'ngModel',

    scope: {
      object: '=ctChangesLinkedTimeOnObject',
      ctChangesLinkedTimeOnObjectProperty: '@'
    },

    link: function (scope, element, attributes, ngModel) {
      var watchFunction = function () {
        return ngModel.$modelValue
      }

      scope.$watch(watchFunction, function (newValue, oldValue) {
        if (oldValue && newValue) {
          var times = Helper.generateTimesArray()
          var oldValueIndex = _.findIndex(times, { time: oldValue })
          var newValueIndex = _.findIndex(times, { time: newValue })

          if (oldValueIndex !== -1 && newValueIndex !== -1) {
            const properties = scope.ctChangesLinkedTimeOnObjectProperty
              .split(',')
              .map(prop => prop.trim())

            properties.forEach(property => {
              if (scope.object[property]) {
                var diff = newValueIndex - oldValueIndex
                var currentLinkedTimeIndex = _.findIndex(times, {
                  time: scope.object[property]
                })
                var newLinkedTime = times[currentLinkedTimeIndex + diff]

                if (newLinkedTime) {
                  scope.object[property] = newLinkedTime.time
                }
              }
            })
          }
        }
      })
    }
  }
})
