'use strict'

import React from 'react'
import { Button, Text } from '@citifyd/style'
import { useAngularService, useTranslator } from '../../../../react/hooks'
import FormikField, {
  FIELD_TYPE
} from '../../../../../shared/react/components/FormikField'
import styles from './CodeVerification.module.scss'
import getNormalizedValues from '../functions/getNormalizedValues'
import onNextRender from '../functions/onNextRender'

const CodeVerification = ({
  trackLoading,
  untrackLoading,
  handleError,
  setErrorMessase,
  values,
  setReadyForCode,
  codeFieldRef,
  phoneNumberFieldRef
}) => {
  const t = useTranslator()
  const PhoneNumbers = useAngularService('PhoneNumbers')

  const resendPhoneVerification = async method => {
    trackLoading('resend-phone-verification')
    setErrorMessase(null)
    try {
      const { phoneCountryCode, phoneNumber } = getNormalizedValues(values)

      await PhoneNumbers.sendVerification({
        phoneCountryCode,
        phoneNumber,
        method: method
      })
    } catch (err) {
      handleError(err)
    }
    untrackLoading('resend-phone-verification')
  }

  const changePhoneNumber = () => {
    setReadyForCode(false)
    setErrorMessase(null)
    onNextRender(() => phoneNumberFieldRef.current?.focus())
  }

  return (
    <div className={styles.container}>
      <div>
        <Text
          variant='h5'
          weight='300'
          className={styles.verificationCodeTitle}
        >
          {t('phoneVerification.enterVerificationCode')}
        </Text>
        <FormikField
          fullWidth
          as={FIELD_TYPE.INPUT}
          name='code'
          size='large'
          textAlign='center'
          ref={codeFieldRef}
        />
      </div>
      <div className={styles.tryAgain}>
        <Text variant='h5' weight='300'>
          {t('phoneVerification.needCodeAgain')}
        </Text>
        <Button
          appearance='secondary'
          textColor='white'
          fullWidth
          size='small'
          onMouseDown={() => resendPhoneVerification('textMessage')}
        >
          {t('phoneVerification.resendSMS')}
        </Button>
        <Button
          appearance='secondary'
          textColor='white'
          fullWidth
          size='small'
          onMouseDown={() => resendPhoneVerification('phoneCall')}
        >
          {t('phoneVerification.callWithCode')}
        </Button>
        <Button
          appearance='secondary'
          textColor='white'
          fullWidth
          size='small'
          onMouseDown={changePhoneNumber}
        >
          {t('phoneVerification.changePhoneNumber')}
        </Button>
      </div>
    </div>
  )
}

export default CodeVerification
