'use strict'

import angular from 'angular'
import _ from 'lodash'

import './change-password.html'
import './change-password.scss'

import PasswordValidator from '../../services/password-validator'

const app = angular.module('citifydSellerApp')

app.controller('ChangePasswordModalCtrl', function (
  $scope,
  $i18next,
  $element,
  $window,
  Authentication,
  Helper,
  Users,
  params
) {
  var user = Authentication.getLoggedUser()

  $scope.changeRequired = Boolean(params.changeRequired)

  // Cancel the modal
  $scope.close = function () {
    $element.remodal().close()
  }

  $scope.save = function (canUseRecentPassword) {
    const passwordValidation = PasswordValidator.validate(
      $scope.newPassword,
      $scope.passwordRules
    )
    if (!passwordValidation.isValid) {
      $scope.invalidPassword = true
      return
    }

    if (!$scope.changePassword.$valid) {
      return
    }

    $scope.isLoading = true

    Users.updateUser(user.id, {
      currentPassword: $scope.currentPassword,
      password: $scope.newPassword,
      checkRecentPasswords: true,
      canUseRecentPassword: Boolean(canUseRecentPassword)
    }).then(
      function () {
        $scope.isLoading = false
        return $scope.close()
      },

      function (response) {
        $scope.isLoading = false

        // 2519 = recentPasswordAdvice
        if (_.get(response, 'data.error.code') === 2519) {
          if (
            $window.confirm(
              $i18next.t(
                'modalChangePassword.usageOfPreviousPasswordConfirmation'
              )
            )
          ) {
            $scope.save(true)
          }
        } else {
          Helper.showErrorAlert(response.data)
        }
      }
    )
  }

  $scope.changedPassword = () => {
    $scope.invalidPassword = false
  }

  function init () {
    $scope.isLoading = true
    $scope.passwordRules = null
    $scope.invalidPassword = false

    Users.fetchPasswordRules({ potentialOrganizationUser: true }).then(
      rules => {
        $scope.isLoading = false
        $scope.passwordRules = rules
      }
    )
  }

  init()
})
