'use strict'

import React from 'react'
import { List } from '@citifyd/style'

import { useTranslator } from '../../../../shared/react/hooks'
import { formatPhoneNumber } from '../../../../shared/utils/phone-number-formatting'
import styles from '../../Organization.module.scss'
import Address from '../../../../shared/react/components/Address'

const OrganizationInfo = ({ organization }) => {
  const t = useTranslator()

  if (!organization) {
    return null
  }

  const yesNo = value => (value ? t('yes') : t('no'))

  return (
    <div className={styles.section}>
      <List className={styles.list}>
        <List.Item>
          {t('organization.adminName')} {organization.administratorName}
        </List.Item>
        <List.Item>
          {t('organization.email')} {organization.email}
        </List.Item>
        <List.Item>
          {t('organization.phone')}{' '}
          {formatPhoneNumber(
            organization.phoneNumber,
            organization.phoneCountryCode
          )}
        </List.Item>
        <List.Item>
          {t('organization.address')}{' '}
          {organization.address && (
            <Address entity={organization} format='full_inline' />
          )}
        </List.Item>
        <List.Item>
          {t('organization.autoPayments')}{' '}
          {yesNo(organization.isAutomaticPayoutEnabled)}
        </List.Item>
        <List.Item>
          {t('organization.cashierReport')}{' '}
          {yesNo(organization.hasCashierReport)}
        </List.Item>
        <List.Item>
          {t('organization.cmrAccess')} {yesNo(organization.hasCrmAccess)}
        </List.Item>
        <List.Item>
          {t('organization.createEvents')}{' '}
          {yesNo(organization.hasEventCreationAccess)}
        </List.Item>
        {organization.paymentInformationDeadline &&
          !organization.stripeAccountId && (
            <List.Item>
              {t('organization.paymentInfo')}{' '}
              {organization.paymentInformationDeadline}
            </List.Item>
          )}
      </List>
    </div>
  )
}

export default OrganizationInfo
