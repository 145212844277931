'use strict'

import angular from 'angular'
import _ from 'lodash'
import AddCardModal from '../../../shared/modals/AddCardModal'

const app = angular.module('citifydSellerApp')

app.controller('UserPurchaseTicketPaymentMethodCtrl', function (
  CitifydModal,
  Cards,
  AssistedPurchases,
  $stateParams,
  $scope,
  $i18next,
  $window
) {
  var userId = $stateParams.id

  $scope.openCardModal = function () {
    CitifydModal.openReactModal(AddCardModal, {
      userId,
      onClose: (result = {}) => {
        if (result?.action === 'added') {
          $scope.cards.push(result.card)
          $scope.selectedCardId = result.card.id
          $scope.$apply()
        }
      }
    })
  }

  $scope.selectCard = function (cardId) {
    $scope.selectedCardId = cardId
  }

  $scope.toggleSelect = function (item) {
    item.selected = !item.selected

    if (
      !$scope.complimentaryOptionShouldAppear() &&
      $scope.selectedCardId === '_complimentary'
    ) {
      $scope.selectedCardId = null
    }
  }

  $scope.calculateEventPrice = function (event) {
    return event.selectedLot.rate.value + event.selectedLot.rate.fee
  }

  $scope.complimentaryOptionShouldAppear = function () {
    return (
      $scope.complimentaryOption && !_.find($scope.bundles, { selected: true })
    )
  }

  $scope.calculateBundlePrice = function (bundle) {
    return bundle.price.value + bundle.price.fee
  }

  $scope.hasSelectedItems = function () {
    return (
      _.some($scope.bundles, 'selected') || _.some($scope.events, 'selected')
    )
  }

  $scope.hasMultiplePasses = function () {
    if (_.some($scope.bundles, 'selected')) {
      return true
    }

    return _.filter($scope.events, 'selected').length > 1
  }

  $scope.total = function () {
    var bundlesTotal = _($scope.bundles)
      .filter('selected')
      .sum($scope.calculateBundlePrice)

    var eventsTotal = _($scope.events)
      .filter('selected')
      .sum($scope.calculateEventPrice)

    return bundlesTotal + eventsTotal
  }

  $scope.submitForm = function () {
    if (!validate()) {
      return
    }

    var bundles = _($scope.bundles)
      .filter('selected')
      .map(function (bundle) {
        return _.pick(bundle, 'id')
      })
      .value()

    var events = _($scope.events)
      .filter('selected')
      .map(function (event) {
        return {
          eventId: event.id,
          lotId: event.selectedLot.id,
          availabilityId: event.selectedLot.availability.id
        }
      })
      .value()

    var data = {
      bundles: bundles,
      events: events,
      cardId: $scope.selectedCardId,
      reason: $scope.reason,
      extra: $scope.extraInfo
    }

    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t('userPurchaseTicket.purchasingPass', {
      count: data.events.length + data.bundles.length > 1
    })

    AssistedPurchases.create(userId, data).then(function (response) {
      $scope.changeStep(4, {
        data: response.data,
        events: $scope.events,
        bundles: $scope.bundles
      })
    })
  }

  function validate () {
    if (!$scope.hasSelectedItems()) {
      $window.alert($i18next.t('userPurchaseTicket.eventsRequired'))
      return false
    }

    if (!$scope.selectedCardId) {
      $window.alert($i18next.t('userPurchaseTicket.paymentMethodRequired'))
      return false
    }

    if ($scope.reason.trim() === '') {
      $window.alert($i18next.t('userPurchaseTicket.reasonRequired'))
      return false
    }

    return true
  }

  function markAllAsSelected (items) {
    return items.map(function (item) {
      item.selected = true
      return item
    })
  }

  function loadCards () {
    $scope.isLoading = true
    $scope.loadingMessage = $i18next.t(
      'userPurchaseTicket.loadingPaymentMethods'
    )

    Cards.findAll(userId).then(function (response) {
      $scope.isLoading = false
      $scope.loadingMessage = ''
      $scope.cards = response.data.cards
      $scope.canSeeCardExpiration = _.some($scope.cards, card => card.expMonth)
      $scope.complimentaryOption = response.data.complimentaryOption
    })
  }

  function getPurchaseCurrency (purchaseData) {
    let bundleCurrencies = purchaseData.selectedBundles.map(
      bundle => bundle.lot.country.currency
    )
    let eventCurrencies = purchaseData.selectedEvents.map(
      event => event.selectedLot.country.currency
    )
    let currencies = _.uniq([...bundleCurrencies, ...eventCurrencies])
    return currencies[0]
  }

  function initialize () {
    $scope.isLoading = false

    $scope.$on('init-step-3', function (e, data) {
      $scope.bundles = markAllAsSelected(data.selectedBundles)
      $scope.events = markAllAsSelected(data.selectedEvents)
      $scope.purchaseCurrency = getPurchaseCurrency(data)

      $scope.selectedCardId = null
      $scope.reason = ''
      $scope.extraInfo = { requestedByPhone: false, requestedByEmail: false }

      loadCards()
    })
  }

  initialize()
})
