'use strict'
import React, { useEffect, useState } from 'react'

import { NewGrid, Icon, Text, Button } from '@citifyd/style'
import { useAngularService, useTranslator } from '../../shared/react/hooks'
import PageHeader from '../../shared/react/components/PageHeader'
import LoadingManager from '../../shared/react/components/LoadingManager'
import styles from './StripeAccountSetup.module.scss'

const StripeAccountSetup = () => {
  const t = useTranslator()
  const StripeConnectedAccounts = useAngularService('StripeConnectedAccounts')
  const Authentication = useAngularService('Authentication')
  const Settings = useAngularService('Settings')
  const [hasError, setHasError] = useState(true)
  const [email, setEmail] = useState()

  useEffect(() => {
    // redirectToStripe()
  }, [])

  const getExistingStripeAccount = async () => {
    const response = await StripeConnectedAccounts.getStripeConnectedAccounts()
    const stripeAccount = response.data.stripeConnectedAccounts[0]

    if (!stripeAccount) {
      throw new Error('Stripe account not found')
    }
    return stripeAccount
  }

  const generateSetupLink = async stripeAccountId => {
    const response = await StripeConnectedAccounts.generateStripeConnectedAccountVerificationLink(
      stripeAccountId
    )
    return response.data.url
  }

  const showErrorMessage = async () => {
    setHasError(true)

    const loggedUser = Authentication.getLoggedUser()
    const { countryCode } = loggedUser.organization

    try {
      const country = await Settings.getDetailedCountryData(countryCode)
      const { supportEmail } = country.citifydLocalInfo
      setEmail(supportEmail.value)
    } catch (err) {
      console.log(`${countryCode}: country information could not be lodaded`)
    }
  }

  const redirectToStripe = async () => {
    try {
      const stripeAccount = await getExistingStripeAccount()
      const url = await generateSetupLink(stripeAccount.stripeAccountId)
      window.location.replace(url)
    } catch (err) {
      console.log(err)
      showErrorMessage()
    }
  }
  const tryAgain = () => {
    setHasError(false)
    redirectToStripe()
  }
  if (!hasError) {
    return <LoadingManager isLoading={!hasError} />
  }
  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          backState
          weight='300'
          title={t('stripeAccountSetup.accountVerificationInformation')}
        />
      </PageHeader>
      <Text variant='subtitle'>{t('stripeAccountSetup.errorOccurred')}</Text>
      <div className={styles.buttons}>
        <Button onClick={tryAgain} justifyContent='center' shadow uppercase>
          {t('stripeAccountSetup.tryAgain')}
        </Button>
        {email && (
          <Button
            href={`mailto:${email}`}
            justifyContent='center'
            shadow
            uppercase
          >
            {t('stripeAccountSetup.contactSupport')}
          </Button>
        )}
      </div>
    </NewGrid>
  )
}

export default StripeAccountSetup
