'use strict'

import { react2angular } from 'react2angular'
import angular from 'angular'
import RoundButton from '../../react/components/RoundButton'

const app = angular.module('citifydSellerApp')

app.component(
  'reactRoundButton',
  react2angular(RoundButton, [
    'disabled',
    'className',
    'onClick',
    'title',
    'type'
  ])
)

app.directive('ctRoundButton', function () {
  return {
    restrict: 'E',

    template: `
      <react-round-button
        on-click="ngClick"
        class-name="ngClass"
        title="ngTitle"
        disabled="ngDisabled"
        type="type"
      >
      </react-round-button>
    `.trim(),

    scope: {
      ngClick: '&',
      ngClass: '@',
      ngDisabled: '=',
      ngTitle: '@',
      type: '@'
    }
  }
})
