'use strict'

import React, { useEffect } from 'react'
import { Button, Text, NewGrid } from '@citifyd/style'
import { useFormikContext } from 'formik'
import moment from 'moment-timezone'

import Table from '../../../../shared/react/components/Table'
import PageHeader from '../../../../shared/react/components/PageHeader'
import StickyFooter from '../../../../shared/react/components/StickyFooter'
import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'
import { getData } from './utils'

const TicketExchangeLot = ({ ticket, setStep }) => {
  const t = useTranslator()
  const { sameEvent } = useAngularService('$stateParams')
  const Helper = useAngularService('Helper')
  const { values, setFieldValue } = useFormikContext()
  const { selectedEvent, selectedLot } = values

  useEffect(() => {
    if (!selectedLot || lots?.length === 1) {
      const currentLot = lots?.filter(lot => lot.isCurrentLot)

      if (currentLot) {
        setFieldValue('selectedLot', currentLot[0])
      }
    }
  }, [])

  const handleContinue = () => {
    if (
      selectedEvent.id === ticket.event.id &&
      selectedLot?.id === ticket.lot.id
    ) {
      Helper.showAlert(t('ticketExchange.selectedCurrentLot'))
      return
    }

    setStep(3)
  }

  const handleSelectEvent = lot => {
    if (!lot.soldOut) {
      setFieldValue('selectedLot', lot)
    }
  }

  const { columns, data, lots } = getData(
    selectedEvent,
    handleSelectEvent,
    selectedLot?.id,
    ticket
  )

  return (
    <div>
      <PageHeader>
        {sameEvent ? (
          <PageHeader.Title
            title={t('ticketExchange.changeParkingLot')}
            defaultState='ticket'
            defaultParams={{ id: ticket.id }}
          />
        ) : (
          <PageHeader.Title
            title={t('ticketExchange.assignParkingLot')}
            onClick={() => setStep(1)}
          />
        )}
      </PageHeader>

      <NewGrid.Row>
        <NewGrid.Col>
          <Text gutterBottom variant='h5'>
            {selectedEvent.name}
          </Text>
        </NewGrid.Col>
        <NewGrid.Col>
          <Text variant='h5' textAlign='right'>
            {t('defaultFormats.datetime', {
              date: moment.tz(selectedEvent.start, selectedEvent.timezoneName)
            })}
          </Text>
        </NewGrid.Col>
      </NewGrid.Row>

      <Table columns={columns} data={data} striped responsive />

      {selectedLot && (
        <StickyFooter justifyContent='flex-end'>
          <Button uppercase onMouseDown={handleContinue} extraPadding>
            {t('commonButtons.continue')}
          </Button>
        </StickyFooter>
      )}
    </div>
  )
}

export default TicketExchangeLot
