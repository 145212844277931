'use strict'

import React, { useState, useEffect } from 'react'
import { NewGrid } from '@citifyd/style'

import ApolloClient from '../../../shared/services/apolloClient'
import ReservedParkingFullInformation from '../../../shared/react/components/ReservedParkingFullInformation'
import PageHeader from '../../../shared/react/components/PageHeader'
import RouterLink from '../../../shared/react/components/RouterLink'
import LoadingManager from '../../../shared/react/components/LoadingManager'
import { getAngularService } from '../../../shared/react/utils'
import { useAngularService, useTranslator } from '../../../shared/react/hooks'

import ADMIN_GET_RESERVED_PARKING_PASS from '../../../graphql/reservedParkingPasses/queries/AdminGetReservedParkingPass'

function Page () {
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [reservedParkingPass, setReservedParkingPass] = useState(null)

  useEffect(() => {
    async function fetchData () {
      const { id } = getAngularService('$stateParams')
      setIsLoading(true)

      ApolloClient()
        .query({
          query: ADMIN_GET_RESERVED_PARKING_PASS,
          variables: {
            passId: parseInt(id)
          }
        })
        .then(response => {
          if (response?.data?.admin_getReservedParkingPass) {
            setReservedParkingPass(response?.data?.admin_getReservedParkingPass)
          } else {
            setHasError(true)
          }
        })
        .catch(() => {
          setHasError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    fetchData()
  }, [])

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />
  }

  return (
    <NewGrid className='page-reserved-parking'>
      <div className='heading clearfix'>
        <ReservedParkingPageTitle reservedParkingPass={reservedParkingPass} />
      </div>
      {reservedParkingPass && (
        <>
          <ReservedParkingFullInformation
            reservedParkingPass={reservedParkingPass}
          />
          <ReservedParkingActions reservedParkingPass={reservedParkingPass} />
        </>
      )}
    </NewGrid>
  )
}

const ReservedParkingPageTitle = ({ reservedParkingPass }) => {
  const t = useTranslator()

  if (!reservedParkingPass?.user || reservedParkingPass?.user?.isGuest) {
    return (
      <PageHeader>
        <PageHeader.Title title={t('reservedParkingPage.pageTitle')} />
      </PageHeader>
    )
  }

  return (
    <PageHeader>
      <PageHeader.Title
        defaultState='user'
        defaultParams={{ id: reservedParkingPass?.user?.id }}
      />
    </PageHeader>
  )
}

const ReservedParkingActions = ({ reservedParkingPass }) => {
  const Permissions = useAngularService('Permissions')
  const t = useTranslator()

  const buttons = []

  if (!reservedParkingPass.cancelledAt && Permissions.userMayCreateRefunds()) {
    buttons.push(
      <RouterLink
        key='refund'
        state='reserved-parking-pass-refund'
        params={{ id: reservedParkingPass.id }}
        options={{ enableBackLink: true }}
        className='regular-button'
      >
        {t('reservedParkingPage.actionButtons.refund')}
      </RouterLink>
    )
  }

  return buttons
}

export default Page
