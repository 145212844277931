import { useEffect, useState } from 'react'
import { isObject } from 'lodash'
import { useFormikContext } from 'formik'

const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0]

  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey])
  }
  return [...keys, firstErrorKey].join('.')
}

const FormikOnError = () => {
  const formik = useFormikContext()
  const [submitCount, setSubmitCount] = useState(formik.submitCount)

  useEffect(() => {
    if (!formik.isValid && formik.submitCount > submitCount) {
      const firstErrorKey = getFirstErrorKey(formik.errors)
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        const element = global.window.document.getElementsByName(
          firstErrorKey
        )[0]

        const disableFocus = element?.classList?.contains('disable-focus')

        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
        !disableFocus && element.focus({ preventScroll: true })
      }
      setSubmitCount(formik.submitCount)
    }
  }, [formik.submitCount, formik.isValid, formik.errors])
  return null
}

export default FormikOnError
