import React from 'react'
import moment from 'moment-timezone'
import { Radio } from '@citifyd/style'

import { getTranslator } from '../../../../shared/react/utils'

export const getColumns = () => {
  const t = getTranslator()

  return [
    {
      key: 'radio',
      value: '',
      width: '1%'
    },
    {
      key: 'eventDate',
      value: t('ticketExchange.eventDate'),
      width: '10%'
    },
    {
      key: 'event',
      value: t('ticketExchange.event'),
      width: '60%'
    },
    {
      key: 'time',
      value: t('ticketExchange.time'),
      width: '10%'
    }
  ]
}

export const getData = (events, selectedEvent, handleSelectEvent) => {
  const t = getTranslator()

  return events.map(event => ({
    ...(!event.soldOut && { onClick: () => handleSelectEvent(event) }),
    hover: false,
    radio: {
      value: !event.soldOut ? (
        <Radio disabled={event.soldOut} checked={event.id === selectedEvent} />
      ) : (
        <div></div>
      ),
      noVerticalPadding: true,
      verticalAlign: 'middle'
    },
    eventDate: {
      value: t('defaultFormats.date', {
        date: moment.tz(event.start, event.timezoneName)
      }),
      nowrap: 'nowrap',
      verticalAlign: 'middle'
    },
    event: {
      value: (
        <>
          {event.name}
          {event.soldOut && <i>{t('ticketExchange.soldOut')}</i>}
        </>
      ),
      verticalAlign: 'middle'
    },
    time: {
      value: t('defaultFormats.time', {
        date: moment.tz(event.start, event.timezoneName)
      }),
      nowrap: 'nowrap',
      verticalAlign: 'middle'
    }
  }))
}
