'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('MonthlyScheduleCtrl', function (
  Plans,
  Permissions,
  Properties,
  $q,
  $scope,
  $state
) {
  function loadData () {
    const propertyId = $scope.selectedProperty?.id || $state.params.property

    if (!propertyId) {
      $scope.isLoading = false
      return
    }

    $scope.isLoading = true

    $q.all([loadMonthlyPlan(propertyId), loadSubscriptions(propertyId)])
      .then(function (results) {
        $scope.monthlyPlan = results[0]
        $scope.subscriptions = results[1]
      })
      .finally(function () {
        $scope.isLoading = false
      })
  }

  function loadSubscriptions (propertyId) {
    return Properties.getSubscriptions(propertyId).then(function (response) {
      return response.data.subscriptions
    })
  }

  function loadMonthlyPlan (propertyId) {
    return Plans.findAll({ lotId: propertyId }).then(function (plans) {
      return _.findWhere(plans, { period: 'month' })
    })
  }

  function init () {
    $scope.isLoading = true
    $scope.subscriptions = []
    $scope.canSeeUserInformation = Permissions.userMaySeeUserInformation()

    $scope.$watch(
      () => $state.params.property,
      () => loadData()
    )
  }

  init()
})
