const generateVerificationText = (ticket, t) => {
  const { verification } = ticket

  if (!verification) {
    return '—'
  }

  switch (verification.method) {
    case 'beacon':
      return t('revenue.ticketAuthentication.beacon', {
        beaconUids: verification.data.beaconUids.join(', ')
      })

    case 'gps':
      return t('revenue.ticketAuthentication.gps')

    case 'manual':
      return t('revenue.ticketAuthentication.manual')

    case 'scan':
      return t('revenue.ticketAuthentication.scan')
  }
}

export const parseTickets = (tickets, t) => {
  for (const ticket of tickets) {
    ticket.verificationAsText = generateVerificationText(ticket, t)
  }

  return tickets
}
