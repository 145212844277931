'use strict'

import React from 'react'
import { List, Text } from '@citifyd/style'
import classNames from 'classnames'

import RouterLink from '../../../../shared/react/components/RouterLink'
import { useTranslator } from '../../../../shared/react/hooks'
import styles from '../../Organization.module.scss'
import Address from '../../../../shared/react/components/Address'

const OrganizationLots = ({ organization }) => {
  const t = useTranslator()

  if (organization.lots.length <= 0) {
    return null
  }

  return (
    <div className={styles.section}>
      <Text variant='h2' component='h2' className={styles.subtitle}>
        {t('organization.lots')}
      </Text>
      <List className={classNames(styles.list, styles.gutterBottomItem)}>
        {organization.lots.map(lot => (
          <List.Item key={lot.id}>
            <span>
              <RouterLink
                underline
                state='edit-property'
                params={{ lotId: lot.id }}
                variant='subtitle'
                weight='700'
              >
                {lot.name}
              </RouterLink>{' '}
              <br />
              {lot.address && <Address entity={lot} format='full_inline' />}
            </span>
          </List.Item>
        ))}
      </List>
    </div>
  )
}

export default OrganizationLots
