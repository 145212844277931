'use strict'

import angular from 'angular'

import LoadingManager from '../../react/components/LoadingManager/LoadingManager.jsx'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctLoadingManager',
  react2angular(LoadingManager, [
    'isLoading',
    'hasError',
    'errorMessage',
    'loadingMessage',
    'onClickTryAgain'
  ])
)
