import React from 'react'
import Table from '../../react/components/Table'
import { useTranslator } from '../../../shared/react/hooks'
import { Button, Modal, Text } from '@citifyd/style'
import { useMemo } from 'react'
import VehicleColorIndicator from '../../react/components/VehicleColorIndicator'

const UserVehicleInformationModal = ({ open, onClose, user }) => {
  const t = useTranslator()

  const columns = [
    {
      value: t('modalVehicleInfo.make'),
      key: 'makeAndColor',
      uppercase: false
    },
    {
      value: t('modalVehicleInfo.plate'),
      key: 'plate',
      uppercase: false
    }
  ]

  const vehicles = useMemo(() => {
    return user.vehicles.map(vehicle => ({
      makeAndColor: (
        <>
          <VehicleColorIndicator vehicle={vehicle} /> {vehicle.make}
        </>
      ),
      plate: vehicle.licenseInfo.plate
    }))
  }, [user])

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Title textAlign='center' variant='h1' weight='300' gutterBottom>
        {t('modalVehicleInfo.vehicle')}
      </Modal.Title>
      <Modal.Content>
        {user.vehicles.length > 0 && (
          <Table
            spacing
            headerAppearance='white'
            columns={columns}
            data={vehicles}
          />
        )}
        {!user.vehicles.length && (
          <Text variant='subtitle'>{t('modalVehicleInfo.noVehicles')}</Text>
        )}
      </Modal.Content>
      <Modal.Actions display='block'>
        <Button
          uppercase
          extraPadding
          justifyContent='center'
          onMouseDown={onClose}
        >
          {t('modalVehicleInfo.gotIt')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UserVehicleInformationModal
