'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('AdvancedRateDefinitions', (Helper, API, Authentication, $http) => {
  function fetchAll ({ lotId }) {
    return $http({
      method: 'GET',
      url: `${API.base}/admin/lots/${lotId}/advanced-rate-definitions`,
      headers: Authentication.generateHeaders()
    })
  }

  function fetch ({ lotId, id = 'latest' }) {
    return $http({
      method: 'GET',
      url: `${API.base}/admin/lots/${lotId}/advanced-rate-definitions/${id}`,
      headers: Authentication.generateHeaders()
    })
  }

  function save ({ lotId, advancedRateDefinition }) {
    return $http({
      method: 'PUT',
      url: `${API.base}/admin/lots/${lotId}/advanced-rate-definitions/latest`,
      headers: Authentication.generateHeaders(),
      data: { advancedRateDefinition }
    })
  }

  function publishLatest ({ lotId }) {
    return $http({
      method: 'POST',
      url: `${API.base}/admin/lots/${lotId}/advanced-rate-definitions/latest/publish`,
      headers: Authentication.generateHeaders()
    })
  }

  function preview ({ lotId, rules }) {
    return $http({
      method: 'POST',
      url: `${API.base}/admin/lots/${lotId}/advanced-rate-definitions/preview`,
      headers: Authentication.generateHeaders(),
      data: { advancedRateDefinition: { rules } }
    })
  }

  return {
    fetchAll,
    fetch,
    save,
    publishLatest,
    preview
  }
})
