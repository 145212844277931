'use strict'

import React, { createContext, useContext, useState } from 'react'

// Implements utilities to open React modals imperatively.
//
// Usage:
//
// import { useModalOpener, withModalManager } from 'shared/react/modalHelper'
// import AddThingModal from 'shared/modals/AddThingModal'
//
// const MyPage = () => {
//   const openModal = useModalOpener()
//
//   const openAddThingModal = () => {
//     openModal(AddThingModal, {
//       foo: "bar", // <- will be passed as prop to the modal component
//       onClose: () => console.log("Modal closed") // <- called when modal is closed
//     })
//   }
//
//   return (
//     <button onClick={openAddThingModal}>Add thing</button>
//   )
// }
//
// export default withModalManager(MyPage) // <- must enclose component with this HoC

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null)

  const openModal = (component, props = {}) => {
    setModal({ component, props })
  }

  const onClose = (...args) => {
    setModal(null)
    modal.props.onClose && modal.props.onClose(...args)
  }

  const Component = modal?.component

  return (
    <ModalContext.Provider value={{ openModal }}>
      {children}
      {Component && (
        <Component {...modal.props} onClose={onClose} open={true} />
      )}
    </ModalContext.Provider>
  )
}

export const withModalManager = WrappedComponent => {
  return props => (
    <ModalProvider>
      <WrappedComponent {...props} />
    </ModalProvider>
  )
}

export const useModalOpener = () => {
  const { openModal } = useContext(ModalContext)
  return openModal
}
