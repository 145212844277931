'use strict'

import React, { useEffect, useState } from 'react'
import { NewGrid } from '@citifyd/style'

import PageHeader from '../../shared/react/components/PageHeader'
import { useAngularService, useTranslator } from '../../shared/react/hooks'
import LoadingManager from '../../shared/react/components/LoadingManager'
import UserPasses from './components/UserPasses'
import UserSummary from './components/UserSummary'
import { getUser } from './utils'

const User = () => {
  const t = useTranslator()
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState({})
  const [nonCustomer, setNonCustomer] = useState()
  const Authentication = useAngularService('Authentication')
  const Users = useAngularService('Users')
  const $stateParams = useAngularService('$stateParams')
  const Helper = useAngularService('Helper')
  const Permissions = useAngularService('Permissions')
  const $state = useAngularService('$state')
  const [migrationTickets, setMigrationTickets] = useState()
  const [migrationTicketsLoadError, setMigrationTicketsLoadError] = useState()
  const canSeeUserInformation = Permissions.userMaySeeUserInformation()

  const { id: userId, phoneCountryCode, phoneNumber } = $stateParams

  const fetchMigrationTickets = async user => {
    try {
      const response = await Users.fetchMigrationTickets(
        user.email,
        user.phoneNumber,
        userId
      )

      setMigrationTickets(response.data.tickets)
    } catch (err) {
      setMigrationTicketsLoadError(true)
    }
  }

  const loadUser = async () => {
    setIsLoading(true)

    let userPromise, nonCustomer

    if (phoneCountryCode && phoneNumber) {
      nonCustomer = true
      userPromise = Users.fetchNonCustomerData(
        userId,
        phoneCountryCode,
        phoneNumber
      )
    } else {
      nonCustomer = false
      userPromise = Users.fetchData(userId)
    }

    setNonCustomer(nonCustomer)

    try {
      const response = await userPromise
      const { data } = response
      // makes sure user is accessing the full-featured page if the user is customer
      if (nonCustomer && data.user.isCustomer) {
        $state.go('user', { id: data.user.id }, { location: 'replace' })
        return
      }

      const user = getUser(data)

      Helper.setPageTitle(
        t('user.pageTitleWithName', {
          name: user.isGuest ? t('user.guestBadge') : user.name
        })
      )

      // load migration passes information (admin only)
      if (Authentication.getLoggedUser().isAdmin && !user.isGuest) {
        await fetchMigrationTickets(user)
      }

      setUser(user)
    } catch (err) {
      setHasError(true)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadUser()
  }, [])

  if (isLoading) {
    return <LoadingManager isLoading={isLoading} />
  }

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          defaultState={canSeeUserInformation ? 'users' : 'dashboard'}
          weight='300'
        />
      </PageHeader>
      <UserSummary
        user={user}
        nonCustomer={nonCustomer}
        loadUser={loadUser}
        migrationTickets={migrationTickets}
        migrationTicketsLoadError={migrationTicketsLoadError}
      />
      <UserPasses partialUser={user} nonCustomer={nonCustomer} />
    </NewGrid>
  )
}

export default User
