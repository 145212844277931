'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

import './bulk-availability-editor.html'
import './bulk-availability-editor.scss'

const app = angular.module('citifydSellerApp')

app.controller('BulkAvailabilityEditorModalCtrl', function (
  Currencies,
  $scope,
  $i18next,
  $window,
  $timeout,
  $element,
  Properties,
  Helper,
  close,
  params
) {
  $scope.toggleDay = function (day) {
    day.selected = !day.selected
  }

  $scope.save = function () {
    $scope.$broadcast('show-errors-check-validity')

    if ($scope.bulkAvailabilityEditor.$invalid) {
      return
    }

    $scope.isSaving = true

    var data = generateSubmitObject()

    Properties.updateSchedule($scope.property.id, data).then(
      // success
      function () {
        $element.remodal().close({ action: 'success' })
      },

      // error
      function (response) {
        $scope.isSaving = false

        var message = ''

        if (response.status === 400 && response.data.error) {
          message = response.data.error.message
        } else {
          message = $i18next.t('commonErrors.connectionProblem')
        }

        Helper.showAlert(message)
      }
    )
  }

  $scope.cancel = function () {
    $element.remodal().close({ action: 'cancel' })
  }

  $scope.shouldShowEarlyBirdDiscount = function () {
    return params.period === 'day'
  }

  function refreshDiscountTimes () {
    var rate = $scope.rate
    $scope.discountTimes = Helper.generateTimesArray(rate.startTime)

    if ($scope.discountTimes.length === 0) {
      rate.discount.enabled = false
    } else {
      var hasValue = Boolean(
        _.findWhere($scope.discountTimes, { time: rate.discount.arriveBefore })
      )

      if (!hasValue) {
        rate.discount.arriveBefore = $scope.discountTimes[0].time
      }
    }
  }

  function mountDaysArray () {
    switch (params.type) {
      case 'weekday':
        $scope.days = [
          {
            label: Helper.getTranslatedDayName('Mon'),
            key: 'mon',
            selected: false
          },
          {
            label: Helper.getTranslatedDayName('Tue'),
            key: 'tue',
            selected: false
          },
          {
            label: Helper.getTranslatedDayName('Wed'),
            key: 'wed',
            selected: false
          },
          {
            label: Helper.getTranslatedDayName('Thu'),
            key: 'thu',
            selected: false
          },
          {
            label: Helper.getTranslatedDayName('Fri'),
            key: 'fri',
            selected: false
          }
        ]
        break

      case 'weekend':
        $scope.days = [
          {
            label: Helper.getTranslatedDayName('Sat'),
            key: 'sat',
            selected: false
          },
          {
            label: Helper.getTranslatedDayName('Sun'),
            key: 'sun',
            selected: false
          }
        ]
        break
    }
  }

  function generateSubmitObject () {
    var rate = {
      type: $scope.rate.rateType,
      value: Currencies.transformToInteger(
        $scope.rate.rateValue,
        $scope.currencySettings.code
      )
    }

    if ($scope.rate.rateType === 'hourly' && $scope.rate.maxRate) {
      rate.maxRate = Currencies.transformToInteger(
        $scope.rate.maxRate,
        $scope.currencySettings.code
      )
    }

    if ($scope.rate.discount.enabled) {
      rate.discount = {
        maxRate: Currencies.transformToInteger(
          $scope.rate.discount.maxRate,
          $scope.currencySettings.code
        ),
        arriveBefore: $scope.rate.discount.arriveBefore + ':00'
      }
    }

    var settings = {
      period: params.period,
      startTime: $scope.rate.startTime + ':00',
      endTime: $scope.rate.endTime + ':00',
      spaces: [{ name: 'regular', max: parseInt($scope.rate.spaces, 10) }],
      rate: rate
    }

    var data = _($scope.days)
      .map(function (day) {
        var daySettings = day.selected
          ? settings
          : { period: params.period, delete: true }
        return [day.key, [daySettings]]
      })
      .zipObject()
      .value()

    return data
  }

  function mountModalTitle () {
    // params.type can be 'weekday' or 'weekend'
    // params.period can be 'day' or 'night'
    const localeKey = `modalBulkAvailabilityEditor.title.${params.type}.${params.period}`
    $scope.modalTitle = $i18next.t(localeKey)
  }

  function findDay (key) {
    return _.find($scope.days, function (day) {
      return day.key === key
    })
  }

  function loadForm () {
    $scope.isLoading = true

    Properties.find(params.propertyId)
      .then(function (response) {
        $scope.property = response.data.lot
        $scope.currencySettings = Currencies.getCurrencySettings(
          $scope.property.country.currency
        )
        return Properties.fetchWeeklySchedule($scope.property.id)
      })
      .then(function (response) {
        $scope.isLoading = false

        // Calculates some information we'll need to set up the form based on the
        // availabilities
        var availabilities = response.data.availabilities
        availabilities.forEach(function (availability) {
          availability.calculatedInfo = {
            dayOfWeek: moment
              .tz(availability.start, availability.timezoneName)
              .locale('en')
              .format('ddd')
              .toLowerCase(),
            startTime: moment
              .tz(availability.start, availability.timezoneName)
              .locale('en')
              .format('HH:mm:ss'),
            endTime: moment
              .tz(availability.end, availability.timezoneName)
              .locale('en')
              .format('HH:mm:ss'),
            rate: _.pick(availability.lots[0].rate, [
              'type',
              'value',
              'fee',
              'maxRate',
              'discount'
            ]),
            spaces: _.findWhere(availability.lots[0].spaces, {
              name: 'regular'
            }).max
          }
        })

        // Filters only the availabilities that happen on the days/period passed as parameters
        // to this modal (e.g. only "weekdays"/"evening")
        var selectedAvailabilities = _.filter(availabilities, function (
          availability
        ) {
          var isCorrectDay = findDay(availability.calculatedInfo.dayOfWeek)
          return isCorrectDay && availability.period === params.period
        })

        // Selects the form days
        selectedAvailabilities.forEach(function (availability) {
          var day = findDay(availability.calculatedInfo.dayOfWeek)

          if (day) {
            day.selected = true
          }
        })

        // Generates the rate object for the form
        if (selectedAvailabilities.length) {
          $scope.rate = Currencies.transformRateToDecimal(
            {
              rateType: selectedAvailabilities[0].calculatedInfo.rate.type,
              rateValue: selectedAvailabilities[0].calculatedInfo.rate.value,
              maxRate: selectedAvailabilities[0].calculatedInfo.rate.maxRate,
              discount: selectedAvailabilities[0].calculatedInfo.rate.discount
            },
            $scope.currencySettings.code
          )

          if ($scope.rate.discount) {
            $scope.rate.discount.enabled = true
          } else {
            $scope.rate.discount = { enabled: false }
          }

          $scope.rate.startTime = selectedAvailabilities[0].calculatedInfo.startTime.replace(
            /:00$/,
            ''
          )
          $scope.rate.endTime = selectedAvailabilities[0].calculatedInfo.endTime.replace(
            /:00$/,
            ''
          )
          $scope.rate.spaces = selectedAvailabilities[0].calculatedInfo.spaces
        } else {
          $scope.rate = {
            spaces: $scope.property.maxSpots,
            rateType: 'hourly',
            ...(params.period === 'day'
              ? { startTime: '08:00', endTime: '18:00' }
              : {}),
            ...(params.period === 'evening'
              ? { startTime: '18:00', endTime: '23:00' }
              : {}),
            discount: {
              enabled: false
            }
          }
        }

        $scope.$watch('rate.startTime', function () {
          refreshDiscountTimes()
        })
      })
  }

  function init () {
    mountModalTitle()
    mountDaysArray()
    loadForm()

    $scope.times = Helper.generateTimesArray()

    // When remodal is closed, we send a close message to the controller that opened this modal.
    $element.on('closed', function (e) {
      close(e.reason)
    })
  }

  init()
})
