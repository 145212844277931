'use strict'

import React from 'react'

import RouterLink from '../RouterLink'
import GuestBadge from '../GuestBadge'

import styles from './UserLink.module.scss'

import { formatPhoneNumber } from '../../../utils/phone-number-formatting'

export default function UserLink ({
  user,
  routerOptions = {},
  disabled,
  appearance,
  underline = true,
  children
}) {
  const identifier = (
    <span>
      {getUserIdentifier(user)}
      {user.isGuest && <GuestBadge className={styles.guestBadge} />}
      {children}
    </span>
  )

  if (disabled) {
    return identifier
  } else {
    return (
      <RouterLink
        state='user'
        underline={underline}
        appearance={appearance}
        params={{ id: user.id }}
        options={routerOptions}
      >
        {identifier}
      </RouterLink>
    )
  }
}

function getUserIdentifier (user) {
  if (user.name) {
    return user.name
  } else if (user.phoneNumber) {
    return formatUserPhoneNumber(user)
  } else {
    return `User ${user.id}`
  }
}

function formatUserPhoneNumber (user) {
  return formatPhoneNumber(user.phoneNumber, user.phoneCountryCode)
}
