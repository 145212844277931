'use strict'

import React, { useCallback } from 'react'
import { useAngularService } from '../../hooks'
import { Button } from '@citifyd/style'

export default function RouterLinkButton ({
  state = '',
  params = {},
  options = {},
  children,
  ...props
}) {
  const $state = useAngularService('$state')
  const onClick = useCallback(() => $state.go(state, params, options), [
    state,
    params,
    options
  ])

  return (
    <Button {...props} onClick={onClick}>
      {children}
    </Button>
  )
}
