import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import { ApolloClient } from '@apollo/client'
import { getAngularService } from '../react/utils'
import possibleTypes from '../data/possibleTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: possibleTypes
})

export default () => {
  const httpLink = new HttpLink({
    uri: process.env.GRAPHQL_ENDPOINT
  })

  const authLink = setContext((_, { headers }) => {
    const Authentication = getAngularService('Authentication')

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${Authentication.getAccessToken()}`
      }
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Code: ${extensions?.code}`
        )
      )
    }

    if (networkError) console.log(`[Network error]: ${networkError}`)
    if (response) console.log(`[response error]: ${response}`)
  })

  return new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher
    }),
    link: from([authLink, errorLink, httpLink])
  })
}
