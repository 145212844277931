'use strict'

import React from 'react'

import { useAngularService } from '../../../../shared/react/hooks.js'
import TicketMainTransactionBox from '../TicketMainTransactionBox'
import TicketParkingInfoBox from '../TicketParkingInfoBox'
import TicketCancellationBox from '../TicketCancellationBox'
import TicketDeletedVoucherGroup from '../TicketDeletedVoucherGroup'
import TicketExchangeBox from '../TicketExchangeBox'
import TicketTransactionActions from '../TicketTransactionActions'
import TicketVoucherBox from '../TicketVoucherBox'
import { mountTicket } from './utils'

const TicketTransactionData = React.memo(({ ticket, actionsEnabled }) => {
  const Permissions = useAngularService('Permissions')
  const shouldShowActions =
    actionsEnabled && Permissions.userMayManageUserTickets()

  if (!ticket) {
    return null
  }

  const currentTicket = mountTicket(ticket)

  return (
    <div>
      <TicketMainTransactionBox ticket={currentTicket} />

      {currentTicket.history.map(item => (
        <React.Fragment key={item.type}>
          {item.type === 'deletedVoucherGroup' && (
            <TicketDeletedVoucherGroup
              ticket={currentTicket}
              vouchers={item.vouchers}
            />
          )}

          {item.type === 'voucher' && (
            <TicketVoucherBox ticket={currentTicket} voucher={item.voucher} />
          )}

          {item.type === 'exchange' && (
            <TicketExchangeBox
              ticket={currentTicket}
              exchange={item.exchange}
            />
          )}
        </React.Fragment>
      ))}

      <TicketParkingInfoBox ticket={currentTicket} />
      <TicketCancellationBox ticket={currentTicket} />

      {shouldShowActions && <TicketTransactionActions ticket={currentTicket} />}
    </div>
  )
})

export default TicketTransactionData
