'use strict'

import angular from 'angular'
import moment from 'moment-timezone'

const Pikaday = require('pikaday')
const app = angular.module('citifydSellerApp')

app.directive('ctDatePicker', function (Helper, $i18next, $timeout) {
  return {
    restrict: 'A',

    scope: {
      date: '=',
      maxDate: '@',
      minDate: '@',
      yearRange: '@',
      viewDate: '@',
      onChange: '=',
      changesLinkedDateOnObject: '=',
      changesLinkedDateOnObjectProperty: '@'
    },

    link: function (scope, element) {
      var pikadayDate = null

      var options = {
        field: element[0],
        format: getDateFormat(),
        showMonthAfterYear: shouldShowMonthAfterYear(),
        yearSuffix: $i18next.i18n.t('datePicker.yearSuffix'),
        onSelect: function (date) {
          if (!isSameDate(date, pikadayDate)) {
            scope.date = date
            changeLinkedDate(date, pikadayDate)
            scope.$apply()
            if (scope.onChange) {
              scope.onChange()
              scope.$apply()
            }
          }
        },
        i18n: {
          previousMonth: $i18next.t('datePicker.previousMonth'),
          nextMonth: $i18next.t('datePicker.nextMonth'),
          months: [
            Helper.getTranslatedMonthName('Janaury'),
            Helper.getTranslatedMonthName('February'),
            Helper.getTranslatedMonthName('March'),
            Helper.getTranslatedMonthName('April'),
            Helper.getTranslatedMonthName('May'),
            Helper.getTranslatedMonthName('June'),
            Helper.getTranslatedMonthName('July'),
            Helper.getTranslatedMonthName('August'),
            Helper.getTranslatedMonthName('September'),
            Helper.getTranslatedMonthName('October'),
            Helper.getTranslatedMonthName('November'),
            Helper.getTranslatedMonthName('December')
          ],
          weekdays: [
            Helper.getTranslatedDayName('Sunday'),
            Helper.getTranslatedDayName('Monday'),
            Helper.getTranslatedDayName('Tuesday'),
            Helper.getTranslatedDayName('Wednesday'),
            Helper.getTranslatedDayName('Thursday'),
            Helper.getTranslatedDayName('Friday'),
            Helper.getTranslatedDayName('Saturday')
          ],
          weekdaysShort: [
            Helper.getTranslatedDayName('Sun'),
            Helper.getTranslatedDayName('Mon'),
            Helper.getTranslatedDayName('Tue'),
            Helper.getTranslatedDayName('Wed'),
            Helper.getTranslatedDayName('Thu'),
            Helper.getTranslatedDayName('Fri'),
            Helper.getTranslatedDayName('Sat')
          ]
        }
      }

      if (scope.yearRange) {
        options.yearRange = JSON.parse(scope.yearRange)
      }

      var pikaday = new Pikaday(options)

      scope.$watch('minDate', function (minDate) {
        if (minDate) {
          pikaday.setMaxDate(moment(minDate).toDate())
        }
      })

      scope.$watch('maxDate', function (maxDate) {
        if (maxDate) {
          pikaday.setMaxDate(moment(maxDate).toDate())
        }
      })

      scope.$watch('viewDate', function (viewDate) {
        if (viewDate) {
          pikaday.gotoDate(moment(viewDate).toDate())
        }
      })

      scope.$watch(
        'date',
        function (changedDate) {
          pikadayDate = pikaday.getDate()

          if (!isSameDate(pikadayDate, changedDate)) {
            pikadayDate = changedDate
            pikaday.setDate(changedDate)
          }
        },
        true
      )

      function isSameDate (first, second) {
        return (
          first !== null &&
          second !== null &&
          moment(first)
            .startOf('day')
            .isSame(moment(second).startOf('day'))
        )
      }

      function changeLinkedDate (newDate, previousDate) {
        let obj = scope.changesLinkedDateOnObject
        let properties = scope.changesLinkedDateOnObjectProperty

        if (!previousDate || !(obj && properties)) {
          return
        }

        properties = properties.split(',').map(prop => prop.trim())

        const diff = moment(newDate).diff(previousDate, 'days')

        properties.forEach(property => {
          if (obj[property]) {
            obj[property] = moment(obj[property])
              .add(diff, 'days')
              .toDate()
          }
        })
      }

      function getDateFormat () {
        var resource = $i18next.i18n.getResource(
          $i18next.i18n.language,
          'translation',
          'datePicker.dateFormat'
        )
        var dateFormat = resource ? resource.match(/dateFormat:([^}]+)/) : null
        return dateFormat ? dateFormat[1] : 'L'
      }

      function shouldShowMonthAfterYear () {
        return $i18next.i18n.language === 'ja'
      }
    }
  }
})
