'use strict'

import moment from 'moment-timezone'
import { includes } from 'lodash'

const getValidDate = (date, month) =>
  moment(date).isValid()
    ? moment(date).toDate()
    : moment(new Date())
        [month]('month')
        .toDate()

function getFilterSelectionFromState ($state, currencies) {
  let dateRange = null

  if ($state.params.dateRange) {
    const parts = $state.params.dateRange.split(':')
    const start = getValidDate(parts[0], 'startOf')
    const end = getValidDate(parts[1], 'endOf')

    dateRange = { start, end }
  } else {
    const start = moment(new Date())
      .startOf('month')
      .toDate()
    const end = moment(new Date())
      .endOf('month')
      .toDate()

    dateRange = { start, end }
  }

  let lotId = $state.params.lot || null
  let currency = $state.params.currency || currencies[0]?.code

  return { lotId, currency, dateRange }
}

function selectDefaultFilters ($state, $scope, fields) {
  const params = { ...$state.params }
  let changed = false

  if (includes(fields, 'currency') && !params.currency) {
    params.currency = $scope.currencies[0].code
    changed = true
  }

  if (includes(fields, 'dateRange') && !params.dateRange) {
    const start = moment()
      .startOf('month')
      .format('YYYY-MM-DD')
    const end = moment()
      .endOf('month')
      .format('YYYY-MM-DD')
    params.dateRange = `${start}:${end}`
    changed = true
  }

  if (changed) {
    $state.transitionTo($state.current.name, params, {
      location: 'replace',
      notify: false,
      inherit: true
    })
  }
  return { changed }
}

export { selectDefaultFilters, getFilterSelectionFromState }
