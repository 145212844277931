'use strict'

import angular from 'angular'

import TransactionExtraInformation from '../../react/components/TransactionExtraInformation'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctTransactionExtraInformation',
  react2angular(TransactionExtraInformation, ['data'])
)
