'use strict'

import moment from 'moment-timezone'
import { getTranslator, getAngularService } from '../../../shared/react/utils'

import _ from 'lodash'

export function buildRule (data) {
  return {
    name: '',
    price: '',
    end: {
      type: 'duration',
      duration: { hours: '', minutes: '' },
      time: { time: '', days: 0 }
    },
    time: { start: '', end: '' },
    days: {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false
    },
    errors: {},
    ...data
  }
}

// Converts a rule from back-end format to front-end format.
// The front-end format has some differences because of how the data is represented on the UI.
//
// Back-end format for rate:
// {
//   "name": "Foo",
//   "price": 500, // stored in cents, this represents USD 5.00 (in case lot using this rate is in US)
//   "end": { ... } // end object goes here, see more details below
//   "time": { "start": "17:00", "end": "00:00" }, // it means that this rate is valid if parking session starts from 7pm to 12am
//   "days": { "sun": true, "mon": true, "tue": false, "wed": false, "thu": false, "fri": false, "sat": false }
// }
//
// The end object can be of two types: 'duration' or 'time'.
//
// Example of 'end' object of type 'duration':
// {
//   "type": "duration",
//   "minute": 80 // parking session is valid for 1 hour 20 minutes
// }
//
// Example of 'end' object of type 'time':
// {
//   "type": "time",
//   "time": "05:00", // parking session expires at 5am
//   "days": 1 // expires next day
// }
//
// The differences between the back-end and the front-end object format are:
//
// 1. Each front-end rule object will have an 'id' assigned to it.
//
// 2. the 'price' is represented as a decimal string
// e.g. if back-end price is 500 and lot uses US Dollars (decimal currency), it will be "5.00" on the front-end
//      if back-end price is 500 and lot uses JP Yens (zero-decimal currency), it will be "500" on the front-end
//
// 3. the 'duration' end is repersented in 'hours' and 'minutes' instead of just 'minute'
//
// 4. inside 'end', we keep the 'type' property, but we move the type-specific fields to a subkey in the object.
//
// Here's an example of a 'duration' end object:
// { "type": "duration", "duration": { "hours": 1, "minutes": 20 }, "time": { "time": "", "days": 0 } }
// and an example of a 'time' end object:
// { "type": "time", "time": { "time": "05:00", "days": 1 }, "duration": { "hours": 0, "minutes": 0 } }
//
// As you can see, 'end' will always have two keys 'time' and 'duration', but the configuration used will be defined
// based on the 'type' key.
//
// Those are all the differences.
export function convertBackEndRuleToFrontEndFormat (backEndRule, currency) {
  const Currencies = getAngularService('Currencies')

  const frontEndRule = buildRule({
    id: backEndRule.id,
    name: backEndRule.name,
    time: backEndRule.time,
    days: backEndRule.days,
    end: {
      type: backEndRule.end.type,
      duration: { hours: '', minutes: '' },
      time: { time: '', days: 0 }
    }
  })

  // Back-end uses cents for storing price (e.g. 500 for $5.00, or 500 for ¥500 which is zero-decimal)
  // Here we convert it to a decimal string (e.g. "5.00" for $5.00, or "500" for ¥500)
  // A decimal string is required for 'react-number-format' (mask library) to work
  frontEndRule.price = String(
    Currencies.transformToDecimal(backEndRule.price, currency)
  )

  if (backEndRule.end.type === 'duration') {
    frontEndRule.end.duration = {
      hours: Math.floor(backEndRule.end.minute / 60),
      minutes: backEndRule.end.minute % 60
    }
  } else if (backEndRule.end.type === 'time') {
    frontEndRule.end.time = {
      time: backEndRule.end.time,
      days: backEndRule.end.days
    }
  }

  return frontEndRule
}

export function convertFrontEndRuleToBackEndFormat (frontEndRule, currency) {
  const Currencies = getAngularService('Currencies')
  const priceAsInteger = Currencies.transformToInteger(
    frontEndRule.price,
    currency
  )

  const end = { type: frontEndRule.end.type }
  if (end.type === 'duration') {
    end.minute =
      Number(frontEndRule.end.duration.hours) * 60 +
      Number(frontEndRule.end.duration.minutes)
  } else if (end.type === 'time') {
    end.time = frontEndRule.end.time.time
    end.days = frontEndRule.end.time.days
  }

  return {
    id: frontEndRule.id,
    name: frontEndRule.name,
    price: priceAsInteger,
    end: end,
    time: frontEndRule.time,
    days: frontEndRule.days
  }
}

export function validateRule (rule) {
  const errors = {}
  const t = getTranslator()

  if (rule.name.trim() === '') {
    errors.name = t('advancedRateEditor.validationErrors.required')
  }

  if (rule.price === '') {
    errors.price = t('advancedRateEditor.validationErrors.required')
  }

  if (rule.end.type === 'duration') {
    const hours = Number(rule.end.duration.hours)
    const minutes = Number(rule.end.duration.minutes)
    const total = hours + minutes
    if (total < 1) {
      errors.end = t('advancedRateEditor.validationErrors.required')
    } else if (minutes > 59) {
      errors.end = t(
        'advancedRateEditor.validations.fields.end.options.duration.validationErrors.invalidMinutes'
      )
    }
  } else if (rule.end.type === 'time') {
    if (rule.end.time.time === '') {
      errors.end = t('advancedRateEditor.validationErrors.required')
    } else if (!isMinuteMultipleOf5(rule.end.time.time)) {
      errors.end = t(
        'advancedRateEditor.validationErrors.minuteMustBeMultipleOf5'
      )
    } else {
      const expirationTime = moment(rule.end.time.time, 'HH:mm').add(
        rule.end.time.days,
        'days'
      )

      if (rule.time.start) {
        const rateStartTime = moment(rule.time.start, 'HH:mm')

        if (expirationTime.isSameOrBefore(rateStartTime)) {
          errors.end = t(
            'advancedRateEditor.fields.end.options.time.validationErrors.endsBeforeStartTime'
          )
        } else if (rule.time.end) {
          const rateEndTime = moment(rule.time.end, 'HH:mm')
          if (rateEndTime.isSameOrBefore(rateStartTime)) {
            rateEndTime.add(1, 'day')
          }
          if (expirationTime.isSameOrBefore(rateEndTime)) {
            errors.end = t(
              'advancedRateEditor.fields.end.options.time.validationErrors.endsBeforeEndTime'
            )
          }
        }
      }
    }
  }

  if (rule.time.start === '' || rule.time.end === '') {
    errors.time = t('advancedRateEditor.validationErrors.required')
  } else if (!isMinuteMultipleOf5(rule.time.start)) {
    errors.time = t(
      'advancedRateEditor.validationErrors.minuteMustBeMultipleOf5'
    )
  } else if (!isMinuteMultipleOf5(rule.time.end)) {
    errors.time = t(
      'advancedRateEditor.validationErrors.minuteMustBeMultipleOf5'
    )
  }

  if (_.every(rule.days, active => !active)) {
    errors.days = t('advancedRateEditor.validationErrors.required')
  }

  return { errors }
}

// We validate if the minute is multiple of 5 to prevent parking providers from setting
// end times as xx:59 and causing a rule-gap at the last minute of an hour.
// If a parking provider sets a rule to end at 1:59pm, and the next rule starts at 2:00pm,
// there'll be a one-minute gap from 1:59pm to 2:00pm where no rule will be visible to users,
// as 1:59pm means that the rule ends at exactly 1:59:00.
// The correct way to do it is to set end of previous rule = 2:00pm, and start of next rule as 2:00pm too.
export function isMinuteMultipleOf5 (time) {
  const [, minutes] = time.split(':')
  return parseInt(minutes, 10) % 5 === 0
}

export function hasInvalidRule (rules) {
  return _.some(rules, rule => !_.isEmpty(rule.errors))
}

export function revalidateRuleField (rule, fieldPath) {
  const fieldRootPath = fieldPath.split('.')[0]
  if (rule.errors[fieldRootPath]) {
    const newValidation = validateRule(rule)
    const error = newValidation.errors[fieldRootPath]
    if (error) {
      rule.errors[fieldRootPath] = error
    } else {
      delete rule.errors[fieldRootPath]
    }
  }
}

export function generateRuleRowId (ruleId) {
  return `row-edit-rule-${ruleId}`
}
