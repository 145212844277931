'use strict'

import React, { useRef, useEffect } from 'react'
import { Text } from '@citifyd/style'
// For some reason, Angular.js is interfering with our ability to add `onClick` functions
// to <a> elements. The `onClick` functions are not called when `href` is a valid URL.
//
// Example:
// <a href="foo" onClick={e => { e.preventDefault(); alert('test') }}>example</a>  <-- that onClick is never called
// <a href="#" onClick={e => { e.preventDefault(); alert('test') }}>example</a>    <-- that onClick is called properly
//
// A possible workaround is to add a `click` event listener that calls `event.preventDefault()` using a ref.
// In order to not pollute components that need that behavior with refs, we've created this component.
export default function FakeLink ({ children, ...props }) {
  const elementRef = useRef()

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.addEventListener('click', e => e.preventDefault())
    }
  }, [elementRef.current])

  return (
    <Text component='a' ref={elementRef} {...props}>
      {children}
    </Text>
  )
}
