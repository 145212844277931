'use strict'

import angular from 'angular'
import _ from 'lodash'

import templateUrl from './dropdown.html'
import './dropdown.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctDropdown', function ($document) {
  var globalLastId = 0

  return {
    restrict: 'E',
    require: 'ngModel',
    templateUrl: templateUrl,

    scope: {
      emptyOptionName: '@',
      emptyOptionNameNotSelected: '@',
      options: '=',
      presenter: '=',
      optionKey: '@',
      fullWidth: '='
    },

    link: function (scope, element, attrs, ngModelCtrl) {
      scope.uniqueId = ++globalLastId

      scope.getValue = function (option) {
        return scope.optionKey ? option[scope.optionKey] : option
      }

      scope.isSelected = function (option) {
        return scope.getValue(option) === scope.selectedOption
      }

      scope.select = function (option) {
        const value = scope.getValue(option)
        ngModelCtrl.$setViewValue(value)
        scope.showOptions = false
      }

      scope.$watch(
        function () {
          return ngModelCtrl.$viewValue
        },
        function () {
          scope.selectedOption = ngModelCtrl.$viewValue
        }
      )

      var body = angular.element($document[0].body)
      var pageFooter = body.find('#footer')

      body.on('click', function () {
        if (scope.showOptions) {
          scope.showOptions = false
          scope.$digest()
        }
      })

      scope.$on('$destroy', function () {
        body.off('click')
      })

      scope.$watch('showOptions', function () {
        if (scope.showOptions) {
          scope.$emit('citifyd-dropdown:open', scope.uniqueId)

          var containerEl = element.find('.dropdown')
          var selectedOptionEl = element.find('.selected-option')
          var contentEl = element.find('.dropdown-content')

          var dropdownContentMaxHeight =
            parseInt(contentEl.css('max-height').replace('px', ''), 10) ||
            Infinity
          var selectedOptionHeight = selectedOptionEl.height()

          // we calculate it instead of using .height() because the dropdown is not yet rendered in the screen
          var approximateDropdownContentHeight = Math.min(
            contentEl.find('li').length * 22,
            dropdownContentMaxHeight
          )

          var dropdownContainerTop = containerEl.offset().top
          var footerTop = pageFooter.offset().top
          var hasOverflow =
            dropdownContainerTop +
              selectedOptionHeight +
              approximateDropdownContentHeight >
            footerTop

          scope.bottomMode = hasOverflow
        }
      })
    },

    controller: function ($scope, $rootScope) {
      $scope.showOptions = false
      $scope.bottomMode = false

      var defaultPresenter = function (obj) {
        return obj
      }

      $scope.presenterFunction = defaultPresenter

      $scope.$watch('presenter', function (presenter) {
        var func

        switch (typeof presenter) {
          case 'string':
            func = function (obj) {
              return obj[presenter]
            }
            break

          case 'function':
            func = presenter
            break

          default:
            func = defaultPresenter
        }

        $scope.presenterFunction = function (obj) {
          if (obj === null || obj === undefined) {
            return ''
          }

          let value

          if ($scope.optionKey) {
            value = _.find($scope.options, o => o[$scope.optionKey] === obj)
          } else {
            value = obj
          }

          return func(value)
        }
      })

      // close this dropdown if any other dropdown on page is open
      $rootScope.$on('citifyd-dropdown:open', function (e, dropdownId) {
        if (dropdownId !== $scope.uniqueId) {
          $scope.showOptions = false
        }
      })
    }
  }
})
