import React from 'react'

import styles from './HighlightText.module.scss'

export default function HighlightText ({
  text = '',
  highlight = '',
  flags = ''
}) {
  if (!highlight.trim()) {
    return <span>{text}</span>
  }

  const sanitize = term => {
    return term && term.replace(/[\\^$*+?.()|{}[\]]/g, '\\$&')
  }

  const addOptionalDashes = str => {
    return str
      .split('')
      .map(x => (x === '\\' ? x : x + '-?'))
      .join('')
  }

  const getRegExp = text => {
    let str = ''

    if (flags.indexOf('^') >= 1) {
      str += '^'
    }

    str += sanitize(text)

    if (flags.indexOf('$') >= 1) {
      str += '$'
    }

    // If "a" is specified, it will match any word inside the highlight term
    if (flags.indexOf('a') !== -1) {
      str = '(' + str.replace(/ +/g, '|') + ')'
    }

    // If "-" is specified, it will ignore dashes
    if (flags.indexOf('-') !== -1) {
      str = addOptionalDashes(str)
    }

    return new RegExp(str, flags.replace(/[$^a-]/g, ''))
  }

  const regex = getRegExp(highlight)
  const parts = text
    .replace(regex, '<highlight>$&<highlight>')
    .split('<highlight>')

  return (
    <span>
      {parts.map((part, index) => {
        if (index === 1) {
          return (
            <span className={styles.highlightText} key={index}>
              {part}
            </span>
          )
        }
        return <span key={index}>{part}</span>
      })}
    </span>
  )
}
