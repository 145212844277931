'use strict'

import React, { Fragment } from 'react'
import { Button, Text, NewGrid } from '@citifyd/style'

import ReservedParkingPassRefundContextProvider, {
  useReservedParkingPassRefundContext
} from './ReservedParkingPassRefundContext.jsx'
import RefundDestination from './ReservedParkingPassRefundDestination.jsx'
import RefundSource from './ReservedParkingPassRefundSource.jsx'
import RefundReason from './ReservedParkingPassRefundReason.jsx'

import PageHeader from '../../../shared/react/components/PageHeader'
import LoadingManager from '../../../shared/react/components/LoadingManager'
import ReservedParkingFullInformation from '../../../shared/react/components/ReservedParkingFullInformation'
import StickyFooter from '../../../shared/react/components/StickyFooter'

import { getAngularService } from '../../../shared/react/utils'
import { useTranslator } from '../../../shared/react/hooks'

import styles from './ReservedParkingPassRefund.module.scss'

function ReservedParkingPassRefundPage () {
  const { id } = getAngularService('$stateParams')

  return (
    <ReservedParkingPassRefundContextProvider reservedParkingPassId={id}>
      <ReservedParkingPassRefundPageContent />
    </ReservedParkingPassRefundContextProvider>
  )
}

function ReservedParkingPassRefundPageContent () {
  const {
    reservedParkingPass,
    refundOptions,
    isLoading,
    loadingMessage,
    hasError
  } = useReservedParkingPassRefundContext()

  if (isLoading || hasError) {
    return (
      <LoadingManager
        loadingMessage={loadingMessage}
        isLoading={isLoading}
        hasError={hasError}
      />
    )
  }

  return (
    <NewGrid>
      <ReservedParkingPassRefundPageTitle />

      {reservedParkingPass && refundOptions && (
        <Fragment>
          <RefundDestination />
          <RefundSource />
          <RefundReason />
          <TransactionHistory />
          <Footer />
        </Fragment>
      )}
    </NewGrid>
  )
}

function ReservedParkingPassRefundPageTitle () {
  const t = useTranslator()
  const { reservedParkingPassId } = useReservedParkingPassRefundContext()

  return (
    <PageHeader>
      <PageHeader.Title
        defaultState='reserved-parking-pass'
        defaultParams={{ id: reservedParkingPassId }}
        title={t('reservedParkingPassRefundPage.pageTitle')}
      />
    </PageHeader>
  )
}

function TransactionHistory () {
  const t = useTranslator()
  const { reservedParkingPass } = useReservedParkingPassRefundContext()

  return (
    <div>
      <Text
        variant='h5'
        gutterBottom
        className={styles.transactionHistoryTitle}
      >
        {t('refundPages.transactionHistory')}
      </Text>

      <ReservedParkingFullInformation
        reservedParkingPass={reservedParkingPass}
      />
    </div>
  )
}

function Footer () {
  const t = useTranslator()
  const { isLoading, requestRefund } = useReservedParkingPassRefundContext()

  return (
    <StickyFooter isBlocked={isLoading} justifyContent='flex-end'>
      <Button uppercase onClick={requestRefund} extraPadding>
        {t('ticketRefund.refundPass')}
      </Button>
    </StickyFooter>
  )
}

export default ReservedParkingPassRefundPage
