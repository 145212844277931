'use strict'

import React, { useEffect, useState, useRef } from 'react'
import { NewGrid, Text, Icon } from '@citifyd/style'

import logo from '../../../../images/logo.jpg'
import styles from './Header.module.scss'
import RouterLink from '../RouterLink'
import DropdownHeader from '../DropdownHeader'
import NavMenu from '../NavMenu'
import { useAngularService } from '../../hooks'

export default function Header ({ showMenu, loggedUser, originalUser }) {
  const node = useRef()
  const [openMenu, setOpenMenu] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const Permissions = useAngularService('Permissions')

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showDropdown])

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return
    }

    setShowDropdown(false)
  }

  return (
    <div>
      <div className={styles.headerWrap}>
        <NewGrid className={styles.header}>
          <NewGrid.Row nogutter justify='between'>
            <NewGrid.Col>
              <div className={styles.navLeft}>
                <RouterLink
                  state={Permissions.userMayAccessSnapshots() && 'dashboard'}
                >
                  <img src={logo} alt='Citifyd' className={styles.logo} />
                </RouterLink>

                {loggedUser && (
                  <div
                    className={styles.userDropdown}
                    onClick={e => {
                      setShowDropdown(!showDropdown)
                      e.stopPropagation()
                    }}
                    ref={node}
                  >
                    <div className={styles.userDropdownToggle}>
                      <Text weight='600' variant='subtitle' appearance='white'>
                        {loggedUser?.name}
                        {showDropdown ? (
                          <Icon
                            icon='chevron-up'
                            pull='none'
                            appearance='white'
                          />
                        ) : (
                          <Icon
                            icon='chevron-down'
                            pull='none'
                            appearance='white'
                          />
                        )}
                      </Text>
                      <Text appearance='white'>{loggedUser?.email}</Text>
                    </div>

                    {showDropdown && (
                      <DropdownHeader
                        loggedUser={loggedUser}
                        originalUser={originalUser}
                        setShowDropdown={setShowDropdown}
                      />
                    )}
                  </div>
                )}
              </div>

              {loggedUser && (
                <button
                  onClick={() => setOpenMenu(!openMenu)}
                  className={styles.menuToggle}
                  aria-controls='primary-menu'
                  aria-expanded={!openMenu}
                >
                  <Icon icon='bars' appearance='white' />
                </button>
              )}
            </NewGrid.Col>
          </NewGrid.Row>
        </NewGrid>
      </div>

      {showMenu && <NavMenu openMenu={openMenu} loggedUser={loggedUser} />}
    </div>
  )
}
