'use strict'

import React from 'react'
import { get, find } from 'lodash'
import { NewGrid, Button } from '@citifyd/style'

import RouterLinkButton from '../../../../shared/react/components/RouterLinkButton'
import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'

import styles from './TicketTransactionsActions.module.scss'

const TicketTransactionActions = ({ ticket }) => {
  const t = useTranslator()
  const Permissions = useAngularService('Permissions')
  const Tickets = useAngularService('Tickets')
  const Helper = useAngularService('Helper')
  const $state = useAngularService('$state')
  const $stateParams = useAngularService('$stateParams')

  const getPendingTransfer = () => {
    var vouchers = get(ticket, 'vouchers')

    return find(vouchers, function (voucher) {
      return !voucher.redeemedAt && !voucher.deletedAt
    })
  }

  const hasPendingTransfer = () => {
    return Boolean(getPendingTransfer())
  }

  const updateTicketTransfer = async data => {
    try {
      await Tickets.updateTransfer(parseInt($stateParams.id), data)
    } catch (err) {
      console.log('err', err)
      Helper.showErrorAlert(err.data)
    } finally {
      $state.go($state.current, $stateParams, {
        reload: true,
        location: 'replace'
      })
    }
  }

  const cancelTransfer = () => {
    if (
      !window.confirm(
        t('transaction.ticketTransactionActions.transferCancelConfirmation')
      )
    ) {
      return
    }

    updateTicketTransfer({ confirm: false })
  }

  const resendTransferCode = () => {
    if (
      !window.confirm(
        t('transaction.ticketTransactionActions.resendTransferCodeConfirmation')
      )
    ) {
      return
    }

    updateTicketTransfer({ regenerate: true })
  }

  const shouldShowResendTransferCodeButton = () => {
    const transfer = getPendingTransfer()

    return (
      transfer &&
      (get(transfer, 'extra.email') || get(transfer, 'extra.phoneNumber'))
    )
  }

  const shouldShowRefundButton = () => {
    const canRefund = Permissions.userMayCreateRefunds()

    return (
      canRefund &&
      ticket &&
      ticket.cancellationAllowed &&
      !ticket.ticketCancellation &&
      !ticket.bundlePurchase
    )
  }

  const shouldShowTransferButton = () => {
    return ticket && ticket.transferAllowed
  }

  const shouldShowExchangeButton = () => {
    return ticket && ticket.exchangeAllowed
  }

  return (
    <div>
      {hasPendingTransfer() && (
        <NewGrid.Row>
          <NewGrid.Col xs={12} md={3}>
            <Button
              className={styles.button}
              onClick={cancelTransfer}
              uppercase
              fullWidth
              justifyContent='center'
              letterSpacing
              shadow
            >
              {t('transaction.cancelTransfer')}
            </Button>
          </NewGrid.Col>
          {shouldShowResendTransferCodeButton() && (
            <NewGrid.Col xs={12} md={3}>
              <Button
                className={styles.button}
                onClick={resendTransferCode}
                uppercase
                fullWidth
                justifyContent='center'
                letterSpacing
                shadow
              >
                {t('transaction.sendCodeAgain')}
              </Button>
            </NewGrid.Col>
          )}
        </NewGrid.Row>
      )}

      {!hasPendingTransfer() && (
        <NewGrid.Row>
          {shouldShowRefundButton() && (
            <NewGrid.Col xs={12} md={3}>
              <RouterLinkButton
                className={styles.button}
                state='ticket-refund'
                params={{ id: ticket.id }}
                uppercase
                fullWidth
                justifyContent='center'
                letterSpacing
                shadow
              >
                {t('transaction.refundPass')}
              </RouterLinkButton>
            </NewGrid.Col>
          )}

          {shouldShowTransferButton() && (
            <NewGrid.Col xs={12} md={3}>
              <RouterLinkButton
                className={styles.button}
                state='ticket-transfer'
                params={{ id: ticket.id }}
                uppercase
                fullWidth
                justifyContent='center'
                letterSpacing
                shadow
              >
                {t('transaction.transferPass')}
              </RouterLinkButton>
            </NewGrid.Col>
          )}

          {shouldShowExchangeButton() && (
            <NewGrid.Col xs={12} md={3}>
              <RouterLinkButton
                className={styles.button}
                state='ticket-exchange'
                params={{ id: ticket.id }}
                uppercase
                fullWidth
                justifyContent='center'
                letterSpacing
                shadow
              >
                {t('transaction.exchangePass')}
              </RouterLinkButton>
            </NewGrid.Col>
          )}

          {shouldShowExchangeButton() && (
            <NewGrid.Col xs={12} md={3}>
              <RouterLinkButton
                className={styles.button}
                state='ticket-exchange'
                params={{ id: ticket.id, sameEvent: true }}
                uppercase
                fullWidth
                justifyContent='center'
                letterSpacing
                shadow
              >
                {t('transaction.changeLot')}
              </RouterLinkButton>
            </NewGrid.Col>
          )}
        </NewGrid.Row>
      )}
    </div>
  )
}

export default TicketTransactionActions
