'use strict'

import angular from 'angular'

import Address from '../../react/components/Address'
import { react2angular } from 'react2angular'

const app = angular.module('citifydSellerApp')

app.component(
  'ctAddress',
  react2angular(Address, [
    'entity',
    'format',
    'addressFieldName',
    'countryFieldName'
  ])
)
