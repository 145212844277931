import { getAngularService } from '../../react/utils'

export const updateIntercom = organization => {
  const rootScope = getAngularService('$rootScope')
  const Intercom = getAngularService('Intercom')

  const user = {
    ...rootScope.user,
    organization: {
      ...rootScope.user.organization,
      name: organization.name
    }
  }

  Intercom.saveUserData(user)
}
