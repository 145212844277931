'use strict'

import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { NewGrid } from '@citifyd/style'
import { useFormik } from 'formik'

import FormikProvider from '../../shared/react/components/FormikProvider'
import LoadingManager from '../../shared/react/components/LoadingManager'
import PageHeader from '../../shared/react/components/PageHeader'
import EnterRecipient from './components/EnterRecipient'
import Confirmation from './components/Confirmation'
import { useAngularService, useTranslator } from '../../shared/react/hooks'
import { makeValidationSchema, getTransfer } from './utils'

const TicketTransfer = () => {
  const t = useTranslator()
  const [step, setStep] = useState(1)
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState(
    t('ticketTransfer.loading')
  )
  const [countries, setCountries] = useState([])
  const [ticket, setTicket] = useState([])
  const [phoneCountryCode, setPhoneCountryCode] = useState(null)
  const Settings = useAngularService('Settings')
  const Helper = useAngularService('Helper')
  const Tickets = useAngularService('Tickets')
  const $state = useAngularService('$state')
  const $stateParams = useAngularService('$stateParams')
  const ticketId = $stateParams.id

  const loadTicket = async () => {
    try {
      const response = await Tickets.load(ticketId)
      const { ticket } = response.data

      setTicket(ticket)
      setPhoneCountryCode(
        ticket?.user?.phoneCountryCode || ticket?.lot?.countryCode
      )
    } catch (err) {
      console.log('err', err)
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const loadCountries = async () => {
    try {
      const response = await Settings.getCountries()

      setCountries(response)
    } catch (err) {
      console.log('err', err)
      setHasError(true)
    } finally {
      loadTicket()
    }
  }

  useEffect(() => {
    loadCountries()
  }, [])

  const Step1 = (
    <EnterRecipient countries={countries} setStep={setStep} ticket={ticket} />
  )

  const renderStep = useCallback(() => {
    switch (step) {
      case 1:
        return Step1
      case 2:
        return <Confirmation ticket={ticket} setStep={setStep} />
      default:
        return Step1
    }
  }, [step, ticket])

  const updateTransfer = async () => {
    try {
      await Tickets.updateTransfer(ticketId, { confirm: true })

      $state.go('ticket', { id: ticket.id }, { location: 'replace' })
    } catch (err) {
      console.log('err', err)
      Helper.showErrorAlert(err.data)
      setIsLoading(false)
    }
  }

  const onSubmit = async values => {
    setIsLoading(true)
    setLoadingMessage(t('ticketTransfer.transferringPass'))

    const transfer = getTransfer(values)

    try {
      await Tickets.transfer(ticketId, transfer)

      updateTransfer()
    } catch (err) {
      console.log('err', err)
      Helper.showErrorAlert(err.data)
      setIsLoading(false)
    }
  }

  const formikbag = useFormik({
    initialValues: {
      countryCode: phoneCountryCode || 'us',
      phoneNumber: '',
      email: '',
      reason: '',
      requestedByEmail: false,
      requestedByPhone: false
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: useMemo(() => makeValidationSchema(), [])
  })

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    )
  }

  return (
    <FormikProvider value={formikbag}>
      <NewGrid>
        <PageHeader>
          {step === 1 ? (
            <PageHeader.Title
              defaultState='ticket'
              defaultParams={{ id: ticket.id }}
              weight='300'
              title={t('ticketTransfer.enterRecipient')}
            />
          ) : (
            <PageHeader.Title
              onClick={() => setStep(1)}
              weight='300'
              title={t('ticketTransfer.confirmTransfer')}
            />
          )}
        </PageHeader>

        {renderStep()}
      </NewGrid>
    </FormikProvider>
  )
}

export default TicketTransfer
