'use strict'

import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Text } from '@citifyd/style'

import { useTranslator } from '../../../../shared/react/hooks'
import {
  generateOrderForClickEvent,
  sort
} from '../../../../shared/react/sorting'
import Table from '../../../../shared/react/components/Table'
import { getTranslator } from '../../../../shared/react/utils'
import LoadingManager from '../../../../shared/react/components/LoadingManager'
import { getData, getFormattedTotal } from './utils'

function ReservedParkingPasses ({
  isLoading,
  hasError,
  reservedParkingPasses
}) {
  const t = useTranslator()

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        loadingMessage={t('user.reservedParking.loading')}
        hasError={hasError}
        errorMessage={t('user.errorLoadingPasses')}
      />
    )
  }

  if (reservedParkingPasses.length === 0) {
    return <Text>{t('user.reservedParking.empty')}</Text>
  }

  return (
    <ReservedParkingPassesTable reservedParkingPasses={reservedParkingPasses} />
  )
}

const initialOrders = [['start', 'desc']]
const getSort = (items, order) => ({ items, order, sortFunctions })

function ReservedParkingPassesTable ({ reservedParkingPasses }) {
  const [
    sortedReservedParkingPasses,
    setSortedReservedParkingPasses
  ] = useState(sort(getSort(reservedParkingPasses, initialOrders)))
  const [orders, setOrders] = useState(initialOrders)
  const firstSort = useRef(true)

  useEffect(() => {
    if (!firstSort.current) {
      setSortedReservedParkingPasses(
        sort(getSort(reservedParkingPasses, orders))
      )
    } else {
      firstSort.current = false
    }
  }, [reservedParkingPasses, orders])

  const formattedTotal = useMemo(
    () => getFormattedTotal(reservedParkingPasses),
    [reservedParkingPasses]
  )

  const handleSort = (event, property) => {
    setOrders(
      generateOrderForClickEvent({
        event,
        currentOrder: orders,
        propertyName: property
      })
    )
  }

  const { columns, data } = getData({
    reservedParkingPasses: sortedReservedParkingPasses,
    handleSort,
    orders,
    formattedTotal
  })

  return (
    <>
      <Table
        columns={columns}
        data={data}
        sortable
        clickable
        responsive
        striped
        borderBottom
      />
    </>
  )
}

const sortFunctions = {
  status: status => {
    const t = getTranslator()
    return t(`reservedParking.statuses.${status}`).toLowerCase()
  },
  price: price => {
    let priceAsNumber = Number(price)
    if (isNaN(priceAsNumber)) priceAsNumber = 0
    return priceAsNumber
  }
}

export default ReservedParkingPasses
