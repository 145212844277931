'use strict'

import uuid from 'uuid/v4'

const origins = {}

export default {
  addState (state) {
    const id = uuid()
    origins[id] = state
    return id
  },

  getState (id) {
    return origins[id]
  }
}
