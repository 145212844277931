import React from 'react'
import moment from 'moment-timezone'

import TicketStatusBadge from '../../../../shared/react/components/TicketStatusBadge'
import {
  getAngularService,
  getTranslator
} from '../../../../shared/react/utils'

export const getData = bundlePurchase => {
  const t = getTranslator()
  const $state = getAngularService('$state')

  const columns = [
    {
      value: t('bundlePurchase.purchase'),
      key: 'purchase'
    },
    {
      value: t('bundlePurchase.eventDate'),
      key: 'eventDate'
    },
    {
      value: t('bundlePurchase.event'),
      key: 'event'
    },
    {
      value: t('bundlePurchase.lot'),
      key: 'lot'
    },
    {
      value: t('bundlePurchase.price'),
      key: 'price'
    },
    {
      value: t('bundlePurchase.status'),
      key: 'status'
    }
  ]

  const data = bundlePurchase.tickets.map(ticket => ({
    onClick: () =>
      $state.go('ticket', { id: ticket.id }, { enableBackLink: true }),
    purchase: {
      value: t('defaultFormats.date', {
        date: moment.tz(ticket.createdAt, ticket.lot.timezoneName)
      }),
      nowrap: 'nowrap',
      verticalAlign: 'middle'
    },
    eventDate: {
      value: t('defaultFormats.date', {
        date: moment.tz(ticket.event.start, ticket.lot.timezoneName)
      }),
      nowrap: 'nowrap',
      verticalAlign: 'middle'
    },
    event: {
      value: ticket.event.name,
      verticalAlign: 'middle'
    },
    lot: {
      value: ticket.lot.name,
      verticalAlign: 'middle'
    },
    price: {
      value: t('bundlePurchase.package'),
      verticalAlign: 'middle'
    },
    status: {
      value: <TicketStatusBadge statusTag={ticket.statusTags[0]} />,
      verticalAlign: 'middle'
    }
  }))

  return {
    columns,
    data
  }
}
