'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('EventTicketsCtrl', function (
  Authentication,
  Permissions,
  Events,
  CitifydModal,
  Helper,
  $i18next,
  $scope,
  $timeout,
  $log,
  $state,
  $stateParams
) {
  const eventId = $stateParams.id

  let dataUpdateTimer
  let scopeDestroyed = false

  const categoryFilters = {
    sold: ticket => !_.includes(ticket.statusTags, 'cancelled'),
    parked: ticket => _.includes(ticket.statusTags, 'parked'),
    transferred: ticket => _.includes(ticket.statusTags, 'transferred'),
    cancelled: ticket => _.includes(ticket.statusTags, 'cancelled')
  }

  $scope.setCategoryFilter = function (categoryKey, fromMobile = false) {
    $scope.selectedFilter.secondLevel.category = categoryKey

    if (fromMobile) {
      $scope.mobileNavigationOpen = false
    }
  }

  $scope.searchQueryChanged = function () {
    applySecondLevelFilter()
  }

  $scope.generateReport = function (format) {
    const reportDescription = $i18next.t('event.reportName', {
      filteredPassesDescription: $scope.selectedFilter.description,
      eventName: $scope.event.name
    })

    const currentUser = Authentication.getLoggedUser()

    const reportUrl = Events.getEventReportCsvUrl(eventId, {
      timezoneName: currentUser.timezoneName,
      lotId: $scope.selectedFilter.firstLevel.lotId,
      category: $scope.selectedFilter.secondLevel.category
    })

    CitifydModal.open('generate-report', {
      fileFormat: format,
      filterDescription: reportDescription,
      reportUrl
    })
  }

  function applyFirstLevelFilter () {
    const { lotId } = $scope.selectedFilter.firstLevel
    const lot = _.find($scope.lots, lot => lot.id === lotId)

    let tickets = $scope.tickets.raw
    if ($scope.selectedFilter.firstLevel.lotId) {
      tickets = tickets.filter(t => t.lotId === lotId)
    }

    $scope.tickets.firstLevelFiltered = tickets

    $scope.countsByCategory = {}
    $scope.countsByCategory.available = lot
      ? lot.available
      : _.sum($scope.lots, 'available')
    $scope.countsByCategory.total = tickets.length

    for (const filterKey in categoryFilters) {
      const filterFunction = categoryFilters[filterKey]
      $scope.countsByCategory[
        filterKey
      ] = $scope.tickets.firstLevelFiltered.filter(filterFunction).length
    }
  }

  function applySecondLevelFilter () {
    let { category, searchQuery } = $scope.selectedFilter.secondLevel
    searchQuery = _.trim(searchQuery)

    const filterFunction = categoryFilters[category] || (() => false)
    let filteredTickets = $scope.tickets.firstLevelFiltered.filter(
      filterFunction
    )

    if (searchQuery !== '') {
      filteredTickets = filteredTickets.filter(t =>
        filterBySearchQuery(t, searchQuery)
      )
    }

    $scope.tickets.secondLevelFiltered = filteredTickets

    $scope.selectedFilter.description = $i18next.t(
      `event.filterDescriptions.${category}`
    )
  }

  function filterBySearchQuery (item, searchQuery) {
    return (
      item.userName.toLowerCase().indexOf(searchQuery) !== -1 ||
      item.email.toLowerCase().indexOf(searchQuery) !== -1 ||
      item.phoneNumber.toLowerCase().indexOf(searchQuery) !== -1
    )
  }

  function applyAllFilters () {
    applyFirstLevelFilter()
    applySecondLevelFilter()
  }

  function fetchEventData () {
    $timeout.cancel(dataUpdateTimer)
    $log.info('Retrieving data for event ' + eventId)

    if (scopeDestroyed) {
      return
    }

    return Events.findMonitoringData(eventId, {
      include_waitlist_entries: false,
      include_tickets: true
    })
      .then(response => {
        const data = response.data

        Helper.setPageTitle('Event: ' + data.name)

        if (data.hash === $scope.lastHash) {
          $log.info("Event data didn't change")
          return
        }

        $scope.loadedInitialData = true
        $scope.lots = data.lots
        $scope.tickets.raw = prepareTickets(data.tickets)

        applyAllFilters()

        $scope.lastHash = data.hash
        $log.info('Event data updated')
      })
      .catch(function (err) {
        $log.error('Error retrieving the event data', err)
      })
      .finally(function () {
        dataUpdateTimer = $timeout(fetchEventData, 5000)
      })
  }

  function prepareTickets (tickets) {
    return tickets.map(ticket => {
      const owner = ticket.User || ticket.Purchaser
      const lot = _.find($scope.lots, lot => lot.id === ticket.LotId)

      return {
        id: ticket.id,
        createdAt: ticket.createdAt,
        time: moment(ticket.createdAt)
          .locale('en')
          .format('HH:mm'),
        attendance: ticket.attendance,
        purchaser: ticket.Purchaser,
        cancelledAt: ticket.cancelledAt,
        rate: ticket.rate,
        lotId: ticket.LotId,
        lotName: _.get(lot, 'name'),
        userName: ticket.isPosPurchase
          ? $i18next.t('users.posUser')
          : _.get(owner, 'name'),
        isGuest: _.get(ticket.User, 'isGuest'),
        phoneNumber: _.get(owner, 'phoneNumber'),
        phoneCountryCode: _.get(owner, 'phoneCountryCode'),
        email: _.get(owner, 'email'),
        purchaserId: _.get(ticket.Purchaser, 'id', null),
        userId: _.get(ticket.User, 'id', null),
        ownerId: _.get(owner, 'id', null),
        statusTags: ticket.statusTags,
        statusTag: ticket.statusTags[0]
      }
    })
  }

  function watchFilterChanges () {
    let searchTimer
    $scope.$watch('selectedFilter.secondLevel.searchQuery', function (
      newValue,
      oldValue
    ) {
      if (!angular.equals(oldValue, newValue)) {
        if (searchTimer) {
          $timeout.cancel(searchTimer)
        }

        $state.transitionTo(
          'event',
          { search: newValue || undefined },
          { location: 'replace', notify: false, inherit: true }
        )

        searchTimer = $timeout(() => applySecondLevelFilter(), 300)
      }
    })

    $scope.$watch('selectedFilter.firstLevel.lotId', (newLotId, oldLotId) => {
      if (!angular.equals(oldLotId, newLotId)) {
        $state.transitionTo(
          'event',
          { lot: newLotId || undefined },
          { location: 'replace', notify: false, inherit: true }
        )

        applyAllFilters()
      }
    })

    $scope.$watch(
      'selectedFilter.secondLevel.category',
      (newCategory, previousCategory) => {
        if (!angular.equals(previousCategory, newCategory)) {
          $state.transitionTo(
            'event',
            { category: newCategory },
            { location: 'replace', notify: false, inherit: true }
          )

          applySecondLevelFilter()
        }
      }
    )
  }

  function init () {
    $scope.canOpenTicket = Permissions.userMayOpenTicketPage()

    $scope.loadedInitialData = false
    $scope.selectedFilter = {
      firstLevel: { lotId: $stateParams.lot },
      secondLevel: {
        category: $stateParams.category || 'sold',
        searchQuery: $stateParams.search || ''
      }
    }
    $scope.tickets = {
      raw: [],
      firstLevelFiltered: [],
      secondLevelFiltered: []
    }
    $scope.countsByCategory = {}
    $scope.lastHash = null
    $scope.ticketsSortBy = 'createdAt'
    $scope.ticketsSortReverse = true

    fetchEventData().then(() => {
      watchFilterChanges()
    })

    $scope.$on('$destroy', function () {
      scopeDestroyed = true
      $timeout.cancel(dataUpdateTimer)
      $log.info('EventTicketsCtrl scope destroyed')
    })
  }

  init()
})
