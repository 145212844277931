'use strict'

import React from 'react'
import { Text, Button } from '@citifyd/style'
import { useFormikContext } from 'formik'

import {
  useModalOpener,
  withModalManager
} from '../../../../shared/react/modalManager'
import Table from '../../../../shared/react/components/Table'
import { useTranslator } from '../../../../shared/react/hooks'
import { getData } from './utils'
import AddCardModal from '../../../../shared/modals/AddCardModal'

const TicketExchangeConfirmationPayment = ({
  canSeeCardExpiration,
  cards,
  complimentaryOption,
  ticket,
  setCards
}) => {
  const t = useTranslator()
  const { values, setFieldValue } = useFormikContext()
  const openModal = useModalOpener()
  const { selectedCardId } = values

  const handleSelectCard = card => {
    setFieldValue('selectedCardId', card)
  }

  const handleCardModal = () => {
    openModal(AddCardModal, {
      userId: ticket.purchaser.id,
      onClose: result => {
        if (result && result?.action === 'added') {
          const newCard = result.card
          const oldCards = cards.filter(card => card.id !== newCard.id)
          setCards([...oldCards, newCard])
          handleSelectCard(newCard.id)
        }
      }
    })
  }

  const { columns, data } = getData(
    canSeeCardExpiration,
    cards,
    selectedCardId,
    handleSelectCard,
    complimentaryOption
  )

  return (
    <>
      <Text variant='h5' gutterBottom>
        {t('ticketExchange.paymentMethod')}
      </Text>

      <Table columns={columns} data={data} striped responsive gutterBottom />

      <Button
        onClick={handleCardModal}
        uppercase
        extraPadding
        size='small'
        shadow
      >
        {t('ticketExchange.addCard')}
      </Button>
    </>
  )
}

export default withModalManager(TicketExchangeConfirmationPayment)
