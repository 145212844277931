'use strict'

import React, { useMemo } from 'react'
import { times } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Select, Text } from '@citifyd/style'
import { useAngularService, useTranslator } from '../../../shared/react/hooks'
import Table from '../../../shared/react/components/Table'

import { patchPreviewState } from '../redux/actions'

import styles from '../styles/Preview.module.scss'

import {
  formatPrice,
  formatRuleEnd,
  formatTimeRange
} from '../utils/formatters'

export default function Preview () {
  const timeRanges = useSelector(state => state.preview.timeRanges)
  const selectedDay = useSelector(state => state.preview.selectedDay)
  const error = useSelector(state => state.preview.error)

  if (error) {
    return (
      <div className={styles.noRules}>
        <Text
          textAlign='center'
          gutterBottom
          variant='subtitle'
          appearance='gray_dark'
        >
          {error}
        </Text>
      </div>
    )
  }

  if (!timeRanges) {
    return null
  }

  return (
    <>
      <DaySelector />
      {selectedDay && (
        <RateBoard day={selectedDay} timeRanges={timeRanges[selectedDay]} />
      )}
    </>
  )
}

function DaySelector () {
  const { getTranslatedDayName } = useAngularService('Helper')
  const t = useTranslator()

  const dispatch = useDispatch()
  const selectedDay = useSelector(state => state.preview.selectedDay)

  const updateSelectedDay = e =>
    dispatch(patchPreviewState({ selectedDay: e.target.value }))
  const days = useMemo(
    () => ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
    []
  )

  return (
    <Select
      className={styles.daySelector}
      value={selectedDay || ''}
      onChange={updateSelectedDay}
      placeholder={t('advancedRateEditor.preview.selectDayOfWeek')}
      options={days.map(day => ({
        label: getTranslatedDayName(day, 'dddd'),
        value: day
      }))}
    />
  )
}

function RateBoard ({ day, timeRanges }) {
  const t = useTranslator()
  const { getTranslatedDayName } = useAngularService('Helper')

  if (!timeRanges || !timeRanges.length) {
    return (
      <div className={styles.noRules}>
        <Text
          textAlign='center'
          gutterBottom
          variant='subtitle'
          appearance='gray_dark'
        >
          {t('advancedRateEditor.preview.noRates', {
            day: getTranslatedDayName(day, 'dddd')
          })}
        </Text>
      </div>
    )
  }

  return (
    <div className={styles.rateBoard}>
      <div className={styles.sideGradient} />
      <div className={styles.sideGradient} />
      <TableComp timeRanges={timeRanges} />
    </div>
  )
}

function TableComp ({ timeRanges }) {
  const t = useTranslator()
  const lot = useSelector(state => state.lot)

  const amountOfPositions = useMemo(() => {
    const rulesPerTimeRange = timeRanges.map(
      timeRange => timeRange.rules.length
    )
    return rulesPerTimeRange.length ? Math.max(...rulesPerTimeRange) : 0
  }, [timeRanges])

  const ratePositionWidth = 68
  const timeRangeWidth = timeRanges.length <= 5 ? 178 : 200
  const tableWidth = ratePositionWidth + timeRangeWidth * timeRanges.length

  const ratesColumns = [
    {
      key: 'ratePosition',
      uppercase: false,
      border: 'gray_dark',
      verticalAlign: 'middle',
      width: `${ratePositionWidth}px`,
      value: (
        <Text variant='small' textAlign='center'>
          {t('advancedRateEditor.preview.ratePosition')}
        </Text>
      )
    },
    ...timeRanges.map((timeRange, i) =>
      getTimeRangeHeader({
        key: `column-${i}`,
        timeRange: timeRange,
        width: timeRangeWidth
      })
    )
  ]

  return (
    <div className={styles.scrollWrapper}>
      <div className={styles.tableWrapper}>
        <Table
          noPadding
          condensed
          style={{ width: `${tableWidth}px` }}
          columns={ratesColumns}
          headerAppearance='gray_lighter'
          data={times(amountOfPositions).map((position, i) =>
            getPosition({
              position: position,
              timeRanges: timeRanges,
              currency: lot?.country?.currency
            })
          )}
        />
      </div>
    </div>
  )
}
const getTimeRangeHeader = ({ timeRange, width, key }) => {
  const formattedTimeRange = formatTimeRange({
    start: timeRange.start.earliest.time,
    end: timeRange.start.latest.time
  })

  return {
    key: key,
    width: `${width}px`,
    border: 'gray_dark',
    verticalAlign: 'middle',
    value: (
      <Text variant='small' textAlign='center'>
        {formattedTimeRange}
      </Text>
    ),
    uppercase: false
  }
}

const getPosition = ({ position, timeRanges, currency }) => {
  const getRule = timeRange => timeRange.rules[position]

  return {
    appearance: 'white',
    ratePosition: {
      verticalAlign: 'middle',
      border: 'gray_dark',
      value: (
        <Text variant='small' textAlign='center'>
          {position + 1}
        </Text>
      )
    },
    ...Object.assign(
      {},
      ...timeRanges.map((timeRange, index) =>
        getRuleNameCell({
          key: index,
          rule: getRule(timeRange),
          currency: currency
        })
      )
    )
  }
}

const getRuleNameCell = ({ key, rule, currency }) => {
  const data = [
    {
      expiration: {
        value: (
          <Text variant='small' textAlign='center'>
            [#{rule?.id}] {rule?.name}
          </Text>
        ),
        colSpan: 2,
        border: 'gray_light'
      },
      price: { hide: true }
    },
    {
      appearance: 'white',
      expiration: {
        value: (
          <Text variant='small' textAlign='center'>
            Expiration
          </Text>
        ),
        border: 'gray_light'
      },
      price: {
        value: (
          <Text variant='small' textAlign='center'>
            Price
          </Text>
        ),
        border: 'gray_light'
      }
    },
    {
      appearance: 'white',
      expiration: {
        value: (
          <Text variant='small' textAlign='center'>
            {formatRuleEnd(rule?.end)}
          </Text>
        ),
        border: 'gray_light'
      },
      price: {
        value: (
          <Text variant='small' textAlign='center'>
            {formatPrice(rule?.price, currency)}
          </Text>
        ),
        border: 'gray_light'
      }
    }
  ]

  return {
    [`column-${key}`]: {
      border: 'gray_dark',
      value: (
        <div>
          <Table
            condensed
            fixed
            showHeader={false}
            columns={[{ key: 'expiration' }, { key: 'price' }]}
            data={rule ? data : []}
          />
        </div>
      )
    }
  }
}
