'use strict'

import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Text, NewGrid } from '@citifyd/style'

import PageHeader from '../../shared/react/components/PageHeader'
import { useAngularService, useTranslator } from '../../shared/react/hooks'
import LoadingManager from '../../shared/react/components/LoadingManager'
import { getAngularService } from '../../shared/react/utils'

import RevenueOfEventSubHeader from './components/RevenueOfEventSubHeader'
import RevenueOfEventHeader from './components/RevenueOfEventHeader'
import RevenueOfEventSummary from './components/RevenueOfEventSummary'
import RevenueOfEventTickets from './components/RevenueOfEventTickets'
import { parseTickets } from './utils'
import styles from './RevenueOfEvent.module.scss'

const RevenueOfEvent = () => {
  const t = useTranslator()
  const Authentication = useAngularService('Authentication')
  const Revenue = useAngularService('Revenue')
  const Events = useAngularService('Events')
  const $stateParams = useAngularService('$stateParams')
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [event, setEvent] = useState(null)
  const [lots, setLots] = useState([])
  const [selectedLots, setSelectedLots] = useState([])
  const [summary, setSummary] = useState({})
  const [hasCashPayments, setHasCashPayments] = useState(false)
  const currentLot = $stateParams.currentLot
  const lotId = $stateParams.lot || currentLot
  const [lotSelected, setLotSelected] = useState(lotId || currentLot || '')

  const user = Authentication.getLoggedUser()
  const canExportToPdf = user.isAdmin || user.organization.id === 1
  const dateRange = $stateParams.dateRange
  const eventId = $stateParams.event
  const currency = $stateParams.currency

  const calculateSummary = (tickets, selectedLots) => {
    const lots = _(selectedLots)
      .map(lot => {
        const lotTickets = tickets.filter(t => t.lot.id === lot.id)

        return [
          lot.id,
          {
            totalTickets: lotTickets.length,
            totalTicketsRedeemed: tickets.filter(
              t => t.redeemedLot && t.redeemedLot.id === lot.id
            ).length,
            netRevenue: _.sum(lotTickets, t => t.payment.netRevenue)
          }
        ]
      })
      .zipObject()
      .value()

    const totals = {}

    for (const field of [
      'totalTickets',
      'totalTicketsRedeemed',
      'netRevenue'
    ]) {
      totals[field] = _.sum(lots, lot => lot[field])
    }

    return { lots, totals }
  }

  const fetchRevenue = async (lots, selectedLots) => {
    const lotId = getAngularService('$stateParams').lot

    try {
      const response = await Revenue.fetchForEvent(eventId, {
        currency,
        lotId
      })

      const tickets = parseTickets(response.data.tickets, t)

      setSummary(calculateSummary(tickets, selectedLots))

      const hasCashPayments = _.some(tickets, t => t.payment.method === 'cash')

      setHasCashPayments(hasCashPayments)

      for (const lot of lots) {
        lot.tickets = tickets.filter(ticket => ticket.lot.id === lot.id)
      }

      setLots(lots)
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchEvent = async () => {
    const lotId = getAngularService('$stateParams').lot
    setHasError(false)
    const user = Authentication.getLoggedUser()

    try {
      const response = await Events.find(eventId)
      const { event } = response.data

      const lots = _(event.lots)
        .filter(
          lot => user.isAdmin || lot.organizationId === user.organizationId
        )
        .sortBy('name')
        .value()

      const selectedLots = lots.filter(lot => !lotId || lotId === lot.id)

      setEvent(event)
      setSelectedLots(selectedLots)
      fetchRevenue(lots, selectedLots)
    } catch (err) {
      console.log('err', err)
      setHasError(true)
    }
  }

  useEffect(() => {
    const $rootScope = getAngularService('$rootScope')
    const unsubscribe = $rootScope.$watch(
      () => {
        return getAngularService('$state').params
      },
      () => {
        setIsLoading(true)
        fetchEvent()
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          defaultState='revenue-tabs'
          defaultParams={{
            type: 'events',
            currency,
            lot: currentLot || undefined,
            dateRange
          }}
          title={t('revenue.revenue')}
        />
      </PageHeader>
      {event && (
        <>
          <RevenueOfEventHeader event={event} />
          <RevenueOfEventSubHeader
            event={event}
            lots={lots}
            setLotSelected={setLotSelected}
            lotSelected={lotSelected}
            canExportToPdf={canExportToPdf}
            currency={currency}
            lotId={lotId}
          />
        </>
      )}
      {isLoading || hasError ? (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      ) : (
        <>
          <RevenueOfEventSummary
            selectedLots={selectedLots}
            summary={summary}
            currency={currency}
          />
          <RevenueOfEventTickets
            selectedLots={selectedLots}
            summary={summary}
            currency={currency}
          />
          {hasCashPayments && (
            <Text variant='subtitle' className={styles.hasCashPayments}>
              * {t('revenue.eventReportCashPaymentDisclaimer')}
            </Text>
          )}
        </>
      )}
    </NewGrid>
  )
}

export default RevenueOfEvent
