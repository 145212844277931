'use strict'

import angular from 'angular'
import moment from 'moment-timezone'
import _ from 'lodash'

import templateUrl from './parkers-snapshot.html'
import './parkers-snapshot.scss'

const app = angular.module('citifydSellerApp')

app.directive('ctParkersSnapshot', function (
  $log,
  $timeout,
  $i18next,
  Authentication,
  Snapshot,
  Helper
) {
  var PARKERS_PER_PAGE = 10

  return {
    restrict: 'E',
    templateUrl: templateUrl,

    scope: {
      properties: '='
    },

    controller: function ($scope) {
      var refreshTimeout
      var destroyed = false
      var loggedUser = Authentication.getLoggedUser()
      var refreshRate = loggedUser.role === 'localManager' ? 60 : 3

      function log (message) {
        $log.log('parkers-snapshot: ' + message)
      }

      function load () {
        if (destroyed) {
          return
        }

        cancelRefreshTimeout()

        log('refreshing...')
        $scope.isLoading = true

        var options = {
          limit: PARKERS_PER_PAGE,
          offset: ($scope.currentPage - 1) * PARKERS_PER_PAGE
        }

        if ($scope.selectedProperty) {
          options.lotId = $scope.selectedProperty.id
        }

        if (_.trim($scope.searchQuery) !== '') {
          options.search = $scope.searchQuery
        }

        Snapshot.getParkers(options)
          .then(function (response) {
            $scope.isLoading = false
            $scope.showLoadingIndicator = false
            $scope.finishedFirstLoad = true
            $scope.reservations = response.data.reservations.map(
              adjustReservation
            )
            $scope.count = response.data.count

            recalculatePagination()

            log('done')
          })
          .finally(function () {
            scheduleRefresh()
          })
      }

      function adjustReservation (reservation) {
        return {
          ...reservation,
          timeLeft: calculateTimeLeft(reservation),
          type: adjustReservationType(reservation.type)
        }
      }

      function calculateTimeLeft (reservation) {
        let timeLeft = null

        if (reservation.expirationTime) {
          if (moment().isAfter(reservation.expirationTime)) {
            timeLeft = $i18next.t('dashboard.expired')
          } else {
            const duration = moment.duration(
              moment(reservation.expirationTime).diff(moment())
            )
            timeLeft = Helper.formatDuration(duration)
          }
        }

        return timeLeft
      }

      function adjustReservationType (originalType) {
        return _.camelCase(originalType) // 'on_demand' -> 'onDemand'
      }

      function recalculatePagination () {
        $scope.amountOfPages = Math.max(
          Math.ceil($scope.count / PARKERS_PER_PAGE),
          1
        )
        $scope.pagination = _.times($scope.amountOfPages, function (n) {
          return n + 1
        })

        if ($scope.currentPage > $scope.amountOfPages) {
          $scope.currentPage = $scope.amountOfPages
          $scope.showLoadingIndicator = true
          load()
        }
      }

      function cancelRefreshTimeout () {
        $timeout.cancel(refreshTimeout)
      }

      function scheduleRefresh () {
        cancelRefreshTimeout()
        refreshTimeout = $timeout(load, refreshRate * 1000)
      }

      function watchSearchQuery () {
        var timer

        $scope.$watch('searchQuery', function (oldValue, newValue) {
          if (!angular.equals(oldValue, newValue)) {
            if (timer) {
              $timeout.cancel(timer)
            }

            timer = $timeout(function () {
              $scope.count = 0
              $scope.reservations = []
              $scope.currentPage = 1
              $scope.showLoadingIndicator = true

              load()
            }, 500)
          }
        })
      }

      $scope.propertyChanged = function () {
        $scope.count = 0
        $scope.reservations = []
        $scope.currentPage = 1
        $scope.showLoadingIndicator = true

        load()
      }

      $scope.goToPage = function (page) {
        $scope.reservations = []
        $scope.currentPage = page
        $scope.showLoadingIndicator = true
        load()
      }

      function init () {
        $scope.count = 0
        $scope.amountOfPages = 1
        $scope.reservations = []
        $scope.currentPage = 1

        $scope.finishedFirstLoad = false
        $scope.isLoading = false
        $scope.showLoadingIndicator = true
        $scope.selectedProperty = null

        load()
        watchSearchQuery()

        $scope.$on('$destroy', function () {
          if (refreshTimeout) {
            $timeout.cancel(refreshTimeout)
          }

          destroyed = true
          log('destroyed')
        })
      }

      init()
    }
  }
})
