import { useMemo } from 'react'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'

export const useTableColumns = () => {
  const t = useTranslator()
  const Permissions = useAngularService('Permissions')
  const userMaySeeUserInformation = Permissions.userMaySeeUserInformation()

  return useMemo(() => {
    const columns = [
      userMaySeeUserInformation
        ? {
            value: t('dailyRevenue.username'),
            key: 'name'
          }
        : null,
      {
        value: t('dailyRevenue.property'),
        key: 'lotName'
      },
      {
        value: t('dailyRevenue.time'),
        key: 'time'
      },
      {
        value: t('dailyRevenue.type'),
        key: 'type'
      },
      {
        value: t('dailyRevenue.revenue'),
        textAlign: 'right',
        key: 'revenueAmount'
      }
    ]

    return columns.filter(column => column !== null)
  }, [userMaySeeUserInformation])
}
