'use strict'

import angular from 'angular'
import _ from 'lodash'

import './user-migration-tickets.html'
import './user-migration-tickets.scss'

const app = angular.module('citifydSellerApp')

app.controller('UserMigrationTicketsModalCtrl', function (
  Helper,
  Users,
  $window,
  $scope,
  $element,
  $i18next,
  params,
  close
) {
  $scope.close = function (data) {
    $element.remodal().close(data)
  }

  $scope.migrate = function () {
    if (!$window.confirm($i18next.t('modalUserMigration.confirmMigration'))) {
      return
    }

    $scope.isLoading = true

    Users.migrateTickets(params.user.id).then(
      function success (response) {
        $scope.step = 'migration-result'
        $scope.isLoading = false

        if (response.data.migrated) {
          const count = response.data.redeemedTickets.length
          $scope.migrationResult = $i18next.t(
            'modalUserMigration.passesRedeemed',
            { count }
          )
        } else if (response.data.verificationEmailSent) {
          $scope.migrationResult = $i18next.t(
            'modalUserMigration.verificationEmailSent'
          )
        } else {
          $scope.migrationResult = $i18next.t(
            'modalUserMigration.noPassesRedeemed'
          )
        }
      },

      function error (response) {
        $scope.isLoading = false
        Helper.showErrorAlert(response.data)
      }
    )
  }

  $scope.getStatusDescription = function (status) {
    switch (status) {
      case 'MISSING_EVENT_CONFIG':
        return $i18next.t('modalUserMigration.statuses.missingEventConfig')

      case 'UNVERIFIED_PHONE_NUMBER':
        return $i18next.t('modalUserMigration.statuses.unverifiedPhoneNumber')

      case 'UNVERIFIED_EMAIL':
        return $i18next.t('modalUserMigration.statuses.unverifiedEmail')

      case 'READY':
        return $i18next.t('modalUserMigration.statuses.ready')

      default:
        return '-'
    }
  }

  function isMigrationAvailable () {
    return _.some($scope.migrationTickets, { status: 'READY' })
  }

  function initialize () {
    $scope.step = 'show'
    $scope.isLoadin = false
    $scope.migrationTickets = params.migrationTickets
    $scope.migrationAvailable = isMigrationAvailable()

    // When remodal is closed, we send a close message to the controller
    // that opened this modal.
    $element.on('closed', function (e) {
      close(e.reason)
    })
  }

  $scope.matchedEmail = function (email) {
    return email === params.user.email
  }

  $scope.matchedPhoneNumber = function (phoneNumber) {
    return phoneNumber === params.user.phoneNumber
  }

  initialize()
})
