'use strict'

import React from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import { Text } from '@citifyd/style'
import moment from 'moment-timezone'
import classNames from 'classnames'

import Table from '../../../../shared/react/components/Table'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import { getCurrentLanguage } from '../../../../shared/react/utils'
import styles from '../RevenueTab/RevenueTab.module.scss'
import stylesTable from './RevenuePayoutsTable.module.scss'

const RevenuePayoutsTable = ({
  payouts = [],
  currentFilter = {},
  totals = {},
  loggedUser,
  lotId
}) => {
  const t = useTranslator()
  const $state = useAngularService('$state')
  const language = getCurrentLanguage()

  const columns = [
    {
      value: t('revenue.description'),
      nowrap: 'nowrap',
      key: 'description',
      width: '30%'
    },
    ...(loggedUser.isAdmin
      ? [
          {
            value: t('revenue.organization'),
            nowrap: 'nowrap',
            key: 'organization',
            width: '15%'
          }
        ]
      : []),
    {
      value: t('revenue.depositTime'),
      nowrap: 'nowrap',
      key: 'depositTime',
      width: '15%'
    },
    {
      value: t('revenue.depositDate'),
      nowrap: 'nowrap',
      key: 'depositDate',
      width: '15%'
    },
    {
      value: t('revenue.status'),
      nowrap: 'nowrap',
      key: 'status',
      width: '15%'
    },
    {
      value: lotId ? t('revenue.totalPayoutAmount') : t('revenue.amount'),
      nowrap: 'nowrap',
      key: 'amount',
      textAlign: 'right',
      width: '15%'
    },
    ...(lotId
      ? [
          {
            value: lotId ? t('revenue.propertyAmount') : t('revenue.amount'),
            nowrap: 'nowrap',
            key: 'propertyAmount',
            textAlign: 'right',
            width: '15%'
          }
        ]
      : [])
  ]

  const handleClick = payout => {
    $state.go('payout', { payout: payout.id, lot: lotId || undefined })
  }

  const getDate = (date, timezoneName) => moment.tz(date, timezoneName)

  const data = payouts.map(payout => {
    return {
      onClick: () => handleClick(payout),
      description: {
        value: (
          <>
            {payout.amount < 0 && (
              <span className={stylesTable.debitLabel}>
                {t('revenue.debit')}
              </span>
            )}
            {payout.description}
          </>
        ),
        verticalAlign: 'middle'
      },
      ...(loggedUser.isAdmin && { organization: payout.organization.name }),
      depositTime: {
        value: payout.executedAt ? (
          t('defaultFormats.time', {
            date: getDate(payout.executedAt, loggedUser.timezoneName)
          })
        ) : (
          <>&mdash;</>
        ),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      depositDate: {
        value: payout.executedAt ? (
          t('defaultFormats.date', {
            date: getDate(payout.executedAt, loggedUser.timezoneName)
          })
        ) : (
          <>&mdash;</>
        ),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      status: {
        value: (
          <span
            className={classNames({
              [stylesTable.statusFailed]: payout.status === 'failed'
            })}
          >
            {t(`payout.status.values.${payout.status}`)}
          </span>
        ),
        verticalAlign: 'middle'
      },
      amount: {
        value: CurrencyFormatter.format(payout.amount, {
          currency: currentFilter.currency,
          language
        }),
        textAlign: 'right',
        verticalAlign: 'middle',
        textColor: payout.amount < 0 ? 'tertiary' : 'default'
      },
      ...(lotId && {
        propertyAmount: {
          value: CurrencyFormatter.format(payout.amountForSelectedLot, {
            currency: currentFilter.currency,
            language
          }),
          textAlign: 'right',
          verticalAlign: 'middle',
          textColor: payout.amountForSelectedLot < 0 ? 'tertiary' : 'default'
        }
      })
    }
  })

  const renderCell = value => (
    <Text textAlign='right' weight='700' className={styles.total}>
      {value}
    </Text>
  )

  const dataWithTotal = [
    ...data,
    {
      showIcon: false,
      appearance: 'white',
      description: {
        value: (
          <Text weight='700' className={styles.total}>
            {t('dailyRevenue.total')}
          </Text>
        ),
        colSpan: loggedUser.isAdmin ? 5 : 4
      },
      organization: {
        hide: true
      },
      depositTime: {
        hide: true
      },
      depositDate: {
        hide: true
      },
      status: {
        hide: true
      },
      amount: {
        value: renderCell(
          CurrencyFormatter.format(totals.amount, {
            currency: currentFilter.currency,
            language
          })
        ),
        textAlign: 'right',
        textColor: totals.amount < 0 ? 'tertiary' : 'default'
      },
      ...(lotId && {
        propertyAmount: {
          value: renderCell(
            CurrencyFormatter.format(totals.amountForSelectedLot, {
              currency: currentFilter.currency,
              language
            })
          ),
          textAlign: 'right',
          textColor: totals.amountForSelectedLot < 0 ? 'tertiary' : 'default'
        }
      })
    }
  ]

  return (
    <Table
      striped
      columns={columns}
      data={dataWithTotal}
      responsive
      clickable
      showIconRight
      borderBottom
      headerGap
      verticalAlignIconRight='middle'
    />
  )
}

export default RevenuePayoutsTable
