'use strict'

import React from 'react'
import { NewGrid } from '@citifyd/style'
import classNames from 'classnames'

import styles from './StickyFooter.module.scss'

export default function StickyFooter ({
  children,
  isBlocked,
  justifyContent = 'space-between'
}) {
  return (
    <div className={styles.stickyFooter}>
      <NewGrid>
        <NewGrid.Row>
          <NewGrid.Col
            className={classNames(styles.column, styles[justifyContent])}
          >
            {isBlocked && <div className={styles.blockOverlay} />}
            {children}
          </NewGrid.Col>
        </NewGrid.Row>
      </NewGrid>
    </div>
  )
}
