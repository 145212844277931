'use strict'

import React, { useCallback, useEffect, useState } from 'react'
import { useAngularService } from '../../hooks'

import FakeLink from '../FakeLink/FakeLink.jsx'

export default function RouterLink ({
  state = '',
  params = {},
  options = {},
  children,
  ...props
}) {
  const $state = useAngularService('$state')
  const [url, setUrl] = useState()
  const onClick = useCallback(() => $state.go(state, params, options), [
    state,
    params,
    options
  ])

  useEffect(() => setUrl($state.href(state, params, options)), [
    state,
    params,
    options
  ])

  return (
    <FakeLink {...props} href={url} onClick={onClick}>
      {children}
    </FakeLink>
  )
}
