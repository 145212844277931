'use strict'

import angular from 'angular'
import _ from 'lodash'
import CurrencyFormatter from '@citifyd/currency-formatter'

const app = angular.module('citifydSellerApp')

app.factory('Currencies', ($i18next, $filter) => {
  // Gets settings for a given currency.
  // These settings can be useful for creating currency inputs, for example,
  // where we need to define the currency symbol and amount of decimal cases.
  // Examples: getCurrencySettings('usd') returns { code: 'usd', symbol: '$',
  //                                                decimalPlaces: 2,
  //                                                name: 'US Dollars' }
  //           getCurrencySettings('jpy') returns { code: 'jpy', symbol: '¥',
  //                                                decimalPlaces: 0,
  //                                                name: 'Japanese Yen' }
  function getCurrencySettings (currency) {
    const symbol =
      CurrencyFormatter.getPrefix({ currency }) ||
      CurrencyFormatter.getSuffix({ currency })
    const decimalPlaces = CurrencyFormatter.isZeroDecimal({ currency }) ? 0 : 2
    const name = getName(currency)
    return { name, code: currency, symbol, decimalPlaces }
  }

  // Gets the name of a given currency.
  // Examples: getName('usd') returns "US Dollars"
  //           getName('jpy') returns "Japanese Yen"
  function getName (currency) {
    currency = currency.toLowerCase()
    return $i18next.t(`currencies.${currency}`)
  }

  // Transforms a given value from integers to decimal.
  // This is mostly used to convert values from the server -- where the amounts
  // are always stored in the minimum currency's smallest unit -- to the client
  // before placing values into input fields.
  // For US Dollars, for example, the back-end always stores the amounts in cents
  // (e.g. 1000 for $10.00). This function will convert 1000 to 10.00 so that we
  // can populate an input field with this amount.
  // For Japanese Yens, a zero-decimal currency, the smallest unit is already a
  // Yen, so the same 1000 represents ¥1,000. In this case, this function will
  // return the value received itself.
  // Examples: transformToDecimal(1000, 'usd') returns 10.00
  //           transformToDecimal(1000, 'jpy') returns 1000
  function transformToDecimal (value, currency) {
    return CurrencyFormatter.isZeroDecimal({ currency })
      ? parseInt(value, 10)
      : value / 100
  }

  // Does the inverse operation of transformToDecimal. For more details, see its
  // description.
  // Examples: transformToInteger(10.00, 'usd') returns 1000
  //           transformToInteger(10, 'jpy') returns 10
  function transformToInteger (value, currency) {
    return CurrencyFormatter.isZeroDecimal({ currency })
      ? parseInt(value, 10)
      : parseInt(value * 100, 10)
  }

  // Transforms a rate object to decimal. For more information on what this means,
  // see the description for transformToDecimal.
  // Examples: transformRateToDecimal({ type: 'fixed', value: 1500, fee: 150 }, 'usd')
  //           returns { type: 'fixed', value: 15.00, fee: 1.50 }
  //
  //           transformRateToDecimal({ type: 'fixed', value: 1500, fee: 150 }, 'jpy')
  //           returns { type: 'fixed', value: 1500, fee: 150 }
  function transformRateToDecimal (rate, currency) {
    return _.mapValues(rate, value => {
      if (_.isObject(value)) {
        return transformRateToDecimal(value, currency)
      } else if (_.isNumber(value)) {
        return transformToDecimal(value, currency)
      } else {
        return value
      }
    })
  }

  // Transforms a rate object to integer. For more information on what this means,
  // see the description for transformToInteger.
  // Examples: transformRateToDecimal({ type: 'fixed', value: 15.00, fee: 1.50 }, 'usd')
  //           returns { type: 'fixed', value: 1500, fee: 150 }
  //
  //           transformRateToDecimal({ type: 'fixed', value: 1500, fee: 150 }, 'jpy')
  //           returns { type: 'fixed', value: 1500, fee: 150 }
  function transformRateToInteger (rate, currency) {
    return _.mapValues(rate, value => {
      if (_.isObject(value)) {
        return transformRateToInteger(value, currency)
      } else if (_.isNumber(value)) {
        return transformToInteger(value, currency)
      } else {
        return value
      }
    })
  }

  return {
    getName,
    getCurrencySettings,
    transformToInteger,
    transformToDecimal,
    transformRateToDecimal,
    transformRateToInteger
  }
})
