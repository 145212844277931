'use strict'

import React from 'react'
import moment from 'moment-timezone'
import { Text } from '@citifyd/style'

import { useTranslator } from '../../../../shared/react/hooks'

import styles from './RevenueOfEventHeader.module.scss'

const RevenueOfEventHeader = ({ event }) => {
  const t = useTranslator()

  return (
    <div className={styles.header}>
      <div className={styles.image}>
        <img src={event.images?.image2} />
        <img src={event.images?.image1} />
      </div>

      <div className={styles.eventInfo}>
        <Text appearance='white' variant='subtitle'>
          {event.name}
        </Text>
        <Text appearance='secondary' variant='subtitle'></Text>
        {event.venue?.name && (
          <Text appearance='secondary' variant='subtitle'>
            {event.venue?.name}
          </Text>
        )}
        <Text appearance='secondary' variant='subtitle'>
          {t('defaultFormats.datetime', {
            date: moment.tz(event.start, event.timezoneName)
          })}
        </Text>
      </div>
    </div>
  )
}

export default RevenueOfEventHeader
