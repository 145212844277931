'use strict'

import React, { useMemo } from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import PaymentMethod from '../../../shared/react/components/PaymentMethod'
import moment from 'moment-timezone'
import { get, sum, uniq } from 'lodash'
import { Text } from '@citifyd/style'

import { useTranslator } from '../../../shared/react/hooks'
import { getCurrentLanguage } from '../../../shared/react/utils'
import Table from '../../../shared/react/components/Table'
import BoxContent from '../../../shared/react/components/BoxContent'
import styles from '../Subscription.module.scss'

function PaymentHistoryBox ({ subscription: baseSubscription }) {
  const t = useTranslator()

  return (
    <BoxContent className='subscription-payment-history-box'>
      <BoxContent.Header
        title={t('subscriptionPage.paymentHistoryBox.title')}
      />
      <BoxContent.Content noHorizontalPadding>
        <PaymentHistoryTable baseSubscription={baseSubscription} />
      </BoxContent.Content>
    </BoxContent>
  )
}

function PaymentHistoryTable ({ baseSubscription }) {
  const t = useTranslator()

  const paymentHistoryItems = useMemo(() => {
    return baseSubscription.allSubscriptions.map(subscription => {
      const purchaseTransactions = subscription.transactions.filter(
        t => t.type === 'purchase'
      )
      const chargedAmount = sum(purchaseTransactions, t => t.totalAmount)
      const chargedAt =
        get(purchaseTransactions[0], 'createdAt') || subscription.subscribedAt
      const currency = get(purchaseTransactions[0], 'currency')
      const card = get(purchaseTransactions[0], 'card')
      return {
        subscription,
        chargedAt,
        purchaseTransactions,
        chargedAmount,
        card,
        currency
      }
    })
  }, [baseSubscription])

  const columns = [
    {
      value: '#',
      width: '1%',
      key: 'sequence'
    },
    {
      value: t('subscriptionPage.paymentHistoryBox.fields.paymentDate'),
      key: 'paymentDate'
    },
    {
      value: t('subscriptionPage.paymentHistoryBox.fields.parkingPeriod'),
      key: 'parkingPeriod',
      width: '10%',
      nowrap: 'nowrap'
    },
    {
      key: 'separator',
      width: '1%'
    },
    {
      key: 'expiresAt',
      width: '30%'
    },
    {
      value: t('subscriptionPage.paymentHistoryBox.fields.paymentMethod'),
      key: 'paymentMethod',
      nowrap: 'nowrap'
    },
    {
      value: t('subscriptionPage.paymentHistoryBox.fields.amountPaid'),
      textAlign: 'right',
      key: 'amountPaid',
      nowrap: 'nowrap'
    }
  ]

  const data = paymentHistoryItems.map(item => {
    const formatDate = date =>
      t('subscriptionPage.paymentHistoryBox.dateFormat', {
        date: moment(date).tz(baseSubscription.plan.lot.timezoneName)
      })

    return {
      sequence: {
        value: item.subscription.sequence,
        verticalAlign: 'middle'
      },
      paymentDate: {
        value: formatDate(item.chargedAt),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      parkingPeriod: {
        value: formatDate(item.subscription.subscribedAt),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      separator: {
        value: t(
          'subscriptionPage.paymentHistoryBox.fields.parkingPeriodSeparator'
        ),
        verticalAlign: 'middle'
      },
      expiresAt: {
        value: formatDate(item.subscription.expiresAt),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      paymentMethod: {
        value: <PaymentMethod card={item.card} />,
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      amountPaid: {
        value: (
          <TransactionAmount
            amount={item.chargedAmount}
            currency={item.currency}
          />
        ),
        textAlign: 'right',
        verticalAlign: 'middle'
      }
    }
  })

  const columnsFormatted = columns.map(column => ({
    ...column,
    textColor: 'gray_dark',
    uppercase: false
  }))

  const currency = useMemo(() => {
    const currencies = uniq(paymentHistoryItems.map(item => item.currency))
    return currencies.length === 1 ? currencies[0] : null
  }, [paymentHistoryItems])

  const totalAmount = useMemo(() => {
    if (currency) {
      return sum(paymentHistoryItems, item => item.chargedAmount)
    } else {
      return null
    }
  }, [paymentHistoryItems, currency])

  const dataFormatted = !totalAmount
    ? data
    : [
        ...data,
        {
          appearance: 'white',
          sequence: {
            value: (
              <Text bold>
                {t('subscriptionPage.paymentHistoryBox.fields.total')}
              </Text>
            ),
            colSpan: 6
          },
          paymentDate: {
            hide: true
          },
          parkingPeriod: {
            hide: true
          },
          separator: {
            hide: true
          },
          expiresAt: {
            hide: true
          },
          paymentMethod: {
            hide: true
          },
          amountPaid: (
            <Text textAlign='right' bold>
              <TransactionAmount amount={totalAmount} currency={currency} />
            </Text>
          )
        }
      ]

  return (
    <Table
      responsive
      spacing
      columns={columnsFormatted}
      data={dataFormatted}
      headerAppearance='white'
      className={styles.table}
    />
  )
}

function TransactionAmount ({ amount, currency }) {
  const language = getCurrentLanguage()
  return CurrencyFormatter.format(amount, { currency, language })
}

export default PaymentHistoryBox
