'use strict'

import angular from 'angular'
import { some } from 'lodash'

const app = angular.module('citifydSellerApp')

app.factory('Permissions', function PermissionsFactory (Authentication) {
  function isAdminOrBelongsToOrganization () {
    const user = Authentication.getLoggedUser()
    return user && (user.isAdmin || user.organization)
  }

  function isPermitUser () {
    const user = Authentication.getLoggedUser()
    return user?.organizationRole === 'permitter'
  }

  function userMayCreateEvent () {
    return hasPermission('manage-events')
  }

  function userMayEditEvent () {
    return hasPermission('manage-events')
  }

  function userMayAccessLimeDashboard () {
    const user = Authentication.getLoggedUser()
    return hasPermission('lime:access') && user?.role === 'admin'
  }

  function userMayAccessPosDashboard () {
    const user = Authentication.getLoggedUser()
    return hasPermission('pos:access') && user?.role === 'admin'
  }

  function userMayAccessCitifydLive () {
    const user = Authentication.getLoggedUser()
    return user?.role !== 'localManager' && !isPermitUser()
  }

  function userMayAppendLotToEvent () {
    if (userMayCreateEvent()) {
      return false
    }

    return hasPermission('manage-event-lots')
  }

  function userMayAccessSnapshots () {
    return !isPermitUser()
  }

  function userMayAccessSchedule () {
    return isAdminOrBelongsToOrganization() && !isPermitUser()
  }

  function userMaySetRequiresBluetoothFlag () {
    return isAdminOrBelongsToOrganization() && !isPermitUser()
  }

  function userMaySetHasAttendantFlag () {
    return isAdminOrBelongsToOrganization() && !isPermitUser()
  }

  function userMayCreateOnDemandAvailability () {
    return true
  }

  function userMaySeeLots () {
    return !isPermitUser()
  }

  function userMaySeeAccountList () {
    const user = Authentication.getLoggedUser()
    return user?.organization && !isPermitUser()
  }

  function userMayCreateRefunds () {
    return hasPermission('create-refunds')
  }

  function userMaySeeUserInformation () {
    return hasPermission('view-user-information')
  }

  function userMaySeeAllProperties () {
    const user = Authentication.getLoggedUser()
    return user && (user.isAdmin || user.role === 'localManager')
  }

  function userMaySeePermits () {
    const user = Authentication.getLoggedUser()
    return user?.isAdmin || Boolean(user?.organizationId)
  }

  function userMayConfigureGates () {
    const user = Authentication.getLoggedUser()
    return user && user.isAdmin
  }

  function userMayAuthenticateAsOthers () {
    const user = Authentication.getLoggedUser()
    return user && user.isAdmin
  }

  function userMayPurchaseTickets () {
    const user = Authentication.getLoggedUser()
    return user && user.isAdmin
  }

  function userMayOpenReservationPage () {
    return true
  }

  function userMayOpenReservedParkingPassPage () {
    return true
  }

  function userMayOpenSubscriptionPage () {
    return true
  }

  function userMayOpenTicketPage () {
    return true
  }

  function userMayOpenBundlePurchasePage () {
    return true
  }

  function userMayOpenParkerPage () {
    return hasPermission('view-user-information')
  }

  function userMayDestroySessions () {
    const user = Authentication.getLoggedUser()
    return user && user.isAdmin
  }

  function userMayChangePaymentInformation () {
    return hasPermission('manage-payment-information')
  }

  function userMayCreateLot () {
    return hasPermission('manage-lots')
  }

  function userMayEditLot () {
    return hasPermission('manage-lots')
  }

  function userMayDisableLotAvailabilityServiceFee () {
    const user = Authentication.getLoggedUser()
    return user && user.isAdmin
  }

  function userMayUpdateLotAvailabilityBarcodeActivation () {
    return hasPermission('manage-lot-availabilities-barcode-activation')
  }

  function userMayAccessRevenue () {
    return hasPermission('view-revenue')
  }

  function userMayManageUserTickets () {
    return hasPermission('manage-user-tickets')
  }

  function userMayManageLotAvailabilitiesPosRates () {
    return hasPermission('manage-lot-availabilities-pos-rates')
  }

  function hasPermission (permissionKey) {
    const permissions = Authentication.getPermissions()

    return some(permissions, p => p.key === permissionKey)
  }

  return {
    userMayCreateEvent,
    userMayEditEvent,
    userMayAccessSnapshots,
    userMayAccessSchedule,
    userMayAppendLotToEvent,
    userMayCreateOnDemandAvailability,
    userMayCreateRefunds,
    userMaySetRequiresBluetoothFlag,
    userMaySetHasAttendantFlag,
    userMaySeeAllProperties,
    userMayConfigureGates,
    userMayAuthenticateAsOthers,
    userMayPurchaseTickets,
    userMayOpenReservationPage,
    userMayOpenSubscriptionPage,
    userMayOpenReservedParkingPassPage,
    userMayOpenTicketPage,
    userMayOpenParkerPage,
    userMayOpenBundlePurchasePage,
    userMayDestroySessions,
    userMayChangePaymentInformation,
    userMayCreateLot,
    userMaySeeLots,
    userMaySeeAccountList,
    userMayEditLot,
    userMayDisableLotAvailabilityServiceFee,
    userMayUpdateLotAvailabilityBarcodeActivation,
    userMayAccessRevenue,
    userMaySeeUserInformation,
    userMayManageUserTickets,
    userMayAccessLimeDashboard,
    userMayAccessPosDashboard,
    userMayAccessCitifydLive,
    userMayManageLotAvailabilitiesPosRates,
    userMaySeePermits
  }
})
