'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('PhoneNumbers', (API, Authentication, $http) => {
  function check (options) {
    const { phoneCountryCode, phoneNumber, ...otherOptions } = options
    const url = `${API.base}/phone-numbers/${phoneCountryCode}/${phoneNumber}`

    return $http({
      method: 'GET',
      url: url,
      headers: Authentication.generateHeaders(),
      params: otherOptions
    }).then(response => response.data.phoneNumber)
  }

  function sendVerification (options) {
    return $http({
      method: 'POST',
      url: `${API.base}/verify/send`,
      data: options // phoneCountryCode, phoneNumber, method (textMessage, phoneCall)
    })
  }

  function verify (options) {
    return $http({
      method: 'POST',
      url: `${API.base}/phone-numbers/${options.phoneCountryCode}/${options.phoneNumber}/verify`,
      data: options // code
    }).then(function (response) {
      return response.data.success
    })
  }

  return {
    check,
    sendVerification,
    verify
  }
})
