'use strict'

import angular from 'angular'
import CurrencyFormatter from '@citifyd/currency-formatter'

const app = angular.module('citifydSellerApp')

app.filter('ctCurrency', (Currencies, $i18next) => {
  return (value, currency) => {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      currency === null ||
      currency === undefined ||
      currency === ''
    ) {
      return ''
    } else {
      return CurrencyFormatter.format(value, {
        currency,
        language: $i18next.i18n.language
      })
    }
  }
})
