'use strict'

import angular from 'angular'
import $ from 'jquery'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.directive('ctShowErrors', () => {
  return {
    restrict: 'A',
    require: '^form',

    link (scope, el, attrs, formCtrl) {
      const INPUT_SELECTOR = 'input[name], select[name], textarea[name]'

      function getFields () {
        const inputEl = el.find(INPUT_SELECTOR)
        const fields = _.map(inputEl, el => formCtrl[el.name])
        return _.filter(fields, el => Boolean(el))
      }

      function getInvalidFields () {
        const fields = getFields()
        return _.filter(fields, field => field.$invalid)
      }

      function checkHasInvalidFields (callback) {
        _.defer(() => {
          const hasInvalidFields = Boolean(getInvalidFields().length)
          callback(hasInvalidFields)
        })
      }

      function init () {
        el.on('blur', INPUT_SELECTOR, function () {
          const last = el.find(INPUT_SELECTOR).last()
          if ($(this).is(last)) {
            checkHasInvalidFields(hasInvalidFields => {
              el.toggleClass('has-error', hasInvalidFields)
            })
          }
        })

        el.on('keyup blur change', INPUT_SELECTOR, function () {
          checkHasInvalidFields(hasInvalidFields => {
            if (!hasInvalidFields) {
              el.removeClass('has-error')
            }
          })
        })

        scope.$on('show-errors-check-validity', function () {
          checkHasInvalidFields(hasInvalidFields => {
            el.toggleClass('has-error', hasInvalidFields)
          })
        })
      }

      init()
    }
  }
})
