import React, { useState, useEffect } from 'react'
import { NewGrid, Label, Select, Icon, Input, Text } from '@citifyd/style'

import LoadingManager from '../../../../shared/react/components/LoadingManager'
import AppendableEventsEmpty from '../../../../shared/react/components/AppendableEventsEmpty'
import AppendableEventsList from '../../../../shared/react/components/AppendableEventsList'
import {
  useTranslator,
  useAngularService
} from '../../../../shared/react/hooks'
import { generateMaxDistanceOptions } from './utils'
import PageHeader from '../../../../shared/react/components/PageHeader'
import SearchInput from '../../../../shared/react/components/SearchInput'

const AppendableEvents = () => {
  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const Authentication = useAngularService('Authentication')
  const Settings = useAngularService('Settings')
  const Events = useAngularService('Events')
  const t = useTranslator()
  const maxDistanceOptions = generateMaxDistanceOptions(
    Authentication,
    Settings,
    t
  )
  const $stateParams = useAngularService('$stateParams')
  const [maxDistance, setMaxDistance] = useState(
    $stateParams.maxDistance || maxDistanceOptions[0].miles
  )
  const [searchQuery, setSearchQuery] = useState($stateParams.searchQuery || '')

  const loadEvents = async () => {
    setHasError(false)
    setIsLoading(true)

    try {
      const response = await Events.findAllAppendable({ maxDistance })

      setEvents(response.data.events)
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadEvents()
  }, [maxDistance])

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />
  }

  const handleMaxDistance = e => setMaxDistance(e.target.value)
  const handleSearchQuery = e => setSearchQuery(e.target.value)

  const maxDistanceSelect = maxDistanceOptions.map(option => ({
    label: option.label,
    value: option.miles
  }))

  if (!events.length) {
    return <AppendableEventsEmpty />
  }

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title title={t('appendableEvents.nearbyEvents')} />
        <PageHeader.Actions>
          <PageHeader.Action>
            <SearchInput
              value={searchQuery}
              id='search'
              onChange={handleSearchQuery}
              fullWidth
            />
          </PageHeader.Action>

          <PageHeader.Action>
            <Label small htmlFor='maxDistance'>
              {t('appendableEvents.maxDistance')}
            </Label>
            <Select
              options={maxDistanceSelect}
              id='maxDistance'
              onChange={handleMaxDistance}
              defaultValue={maxDistance}
              fullWidth
            />
          </PageHeader.Action>
        </PageHeader.Actions>
      </PageHeader>
      <NewGrid.Row>
        <NewGrid.Col>
          <AppendableEventsList
            events={events}
            maxDistance={maxDistance}
            searchQuery={searchQuery}
          />
        </NewGrid.Col>
      </NewGrid.Row>
    </NewGrid>
  )
}

export default AppendableEvents
