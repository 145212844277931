'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.factory(
  'AddPropertyAvailabilityGenerator',
  function AddPropertyAvailabilityGeneratorFactory (Currencies) {
    function transformPricingObject (obj, currency) {
      if (!obj) {
        return null
      }

      var result = {
        startTime: obj.startTime + ':00',
        endTime: obj.endTime + ':00',
        rate: {
          type: obj.rateType,
          value: Currencies.transformToInteger(obj.rateValue, currency)
        },
        spaces: [
          {
            name: 'regular',
            max: obj.spaces
          }
        ]
      }

      if (obj.maxRate) {
        result.rate.maxRate = Currencies.transformToInteger(
          obj.maxRate,
          currency
        )
      }

      if (obj.discount.enabled) {
        result.rate.discount = {
          maxRate: Currencies.transformToInteger(
            obj.discount.maxRate,
            currency
          ),
          arriveBefore: obj.discount.arriveBefore + ':00'
        }
      }

      return result
    }

    function generateInitialAvailabilitiesObject (lot, settings) {
      const currency = lot.country.currency

      var weekdays = transformPricingObject(settings.weekdays.data, currency)
      var weeknights = transformPricingObject(
        settings.weeknights.data,
        currency
      )
      var weekends = transformPricingObject(settings.weekends.data, currency)
      var weekendEvenings = transformPricingObject(
        settings.weekendEvenings.data,
        currency
      )

      var pricing = _.mapValues(settings.days, function (isSelected, day) {
        var availabilities = []

        if (isSelected) {
          var isWeekend = _.includes(['sat', 'sun'], day)
          var daytimeData = isWeekend ? weekends : weekdays
          var eveningData = isWeekend ? weekendEvenings : weeknights
          var data

          if (daytimeData) {
            data = angular.copy(daytimeData)
            data.period = 'day'
            availabilities.push(data)
          }

          if (eveningData) {
            data = angular.copy(eveningData)
            data.period = 'evening'
            availabilities.push(data)
          }
        }

        return availabilities
      })

      return pricing
    }

    return {
      generate: generateInitialAvailabilitiesObject
    }
  }
)
