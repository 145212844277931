'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.directive('ctFileChange', function () {
  return {
    restrict: 'A',

    link: function (scope, element, attrs) {
      var onChangeHandler = scope.$eval(attrs.ctFileChange)
      element.bind('change', function (event) {
        onChangeHandler(event, event.target.files)
        scope.$apply()
      })
    }
  }
})
