import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('UserPurchaseReportCtrl', ($scope, $stateParams, $state) => {
  $scope.finish = function () {
    $state.go('user', { id: $stateParams.id }, { location: 'replace' })
  }

  const find = (events, bundles, purchaseInfo) => {
    if (purchaseInfo.type === 'event') {
      return _.find(events, { id: purchaseInfo.options.eventId })
    }

    if (purchaseInfo.type === 'bundle') {
      const bundle = _.find(bundles, { id: purchaseInfo.options.id })
      bundle.start = null
      bundle.selectedLot = {
        name: bundle.lot.name,
        country: bundle.lot.country,
        rate: bundle.price
      }
      return bundle
    }
  }

  function initialize () {
    $scope.isLoading = false

    $scope.$on('init-step-4', function (e, { data, events, bundles }) {
      const dataset = _.map(data, event => {
        return {
          ...event,
          event: find(events, bundles, event.purchaseInfo)
        }
      })
      $scope.summary = _.groupBy(dataset, 'state')
      $scope.rejected = _.groupBy($scope.summary.rejected, 'reason')
    })
  }

  initialize()
})
