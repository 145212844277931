'use strict'

import { getTranslator, getAngularService } from '../../../shared/react/utils'

import {
  patchState,
  startLoading,
  stopLoading,
  validateRules,
  markEditableRulesAsPersisted,
  patchPreviewState
} from './actions'

import {
  convertBackEndRuleToFrontEndFormat,
  convertFrontEndRuleToBackEndFormat,
  hasInvalidRule,
  generateRuleRowId
} from '../utils/rules'

import _ from 'lodash'

export const fetchData = ({
  lotId,
  advancedRateDefinitionId
}) => async dispatch => {
  const AdvancedRateDefinitions = getAngularService('AdvancedRateDefinitions')
  const Properties = getAngularService('Properties')
  const t = getTranslator()

  dispatch(
    startLoading({ message: t('advancedRateEditor.messages.loadingRates') })
  )

  const lotResponse = await Properties.find(lotId)
  const lot = lotResponse.data.lot

  const rateDefinitionResponse = await AdvancedRateDefinitions.fetch({
    lotId,
    id: advancedRateDefinitionId
  })
  const rateDefinition = rateDefinitionResponse.data.advancedRateDefinition
  const rules = rateDefinition.rules.map(rule =>
    convertBackEndRuleToFrontEndFormat(rule, lot.country.currency)
  )
  const editEnabled = rateDefinition.isLatestVersion

  dispatch(
    patchState({
      lot,
      rateDefinition,
      editableRules: rules,
      editEnabled,
      lastRuleId: rules.length ? Math.max(...rules.map(rule => rule.id)) : 0,
      mode: rules.length > 0 || !editEnabled ? 'view' : 'edit'
    })
  )

  dispatch(stopLoading())
}

export const generateTimeRangesPreview = () => async (dispatch, getState) => {
  const AdvancedRateDefinitions = getAngularService('AdvancedRateDefinitions')
  const Helper = getAngularService('Helper')
  const t = getTranslator()

  const { lot, editableRules } = getState()

  const backEndRules = editableRules.map(rule =>
    convertFrontEndRuleToBackEndFormat(rule, lot.country.currency)
  )

  dispatch(
    startLoading({
      message: t('advancedRateEditor.messages.generatingPreview')
    })
  )

  try {
    const response = await AdvancedRateDefinitions.preview({
      lotId: lot.id,
      rules: backEndRules
    })
    const { timeRanges } = response.data.preview

    for (const day in timeRanges) {
      const timeRangesOfDay = timeRanges[day]
      for (const timeRange of timeRangesOfDay) {
        timeRange.rules = timeRange.rules.map(rule =>
          convertBackEndRuleToFrontEndFormat(rule, lot.country.currency)
        )
      }
    }

    dispatch(patchPreviewState({ timeRanges, error: null }))
  } catch (err) {
    dispatch(
      patchPreviewState({
        timeRanges: {},
        error: Helper.getServerErrorMessage(err.data)
      })
    )
  } finally {
    dispatch(stopLoading())
  }
}

export const changeMode = mode => (dispatch, getState) => {
  const validate = validCallback => {
    dispatch(validateRules())

    const { editableRules } = getState()
    if (hasInvalidRule(editableRules)) {
      dispatch(scrollToFirstInvalidRule(editableRules))
    } else {
      validCallback()
    }
  }

  if (mode === 'edit') {
    dispatch(patchState({ mode: 'edit' }))
  } else if (mode === 'view') {
    validate(() => dispatch(patchState({ mode: 'view' })))
  } else if (mode === 'preview') {
    validate(() => {
      dispatch(generateTimeRangesPreview())
      dispatch(patchState({ mode: 'preview' }))
    })
  }
}

export const saveRateDefinition = ({ publish = false }) => async (
  dispatch,
  getState
) => {
  const t = getTranslator()
  const Helper = getAngularService('Helper')
  const AdvancedRateDefinitions = getAngularService('AdvancedRateDefinitions')

  dispatch(validateRules())
  const { editableRules, lot } = getState()

  if (hasInvalidRule(editableRules)) {
    dispatch(patchState({ mode: 'edit' }))
    dispatch(scrollToFirstInvalidRule(editableRules))
    return
  }

  dispatch(startLoading({ message: t('advancedRateEditor.messages.saving') }))

  const backEndRules = editableRules.map(rule =>
    convertFrontEndRuleToBackEndFormat(rule, lot.country.currency)
  )

  let saved = false

  try {
    const response = await AdvancedRateDefinitions.save({
      lotId: lot.id,
      advancedRateDefinition: { rules: backEndRules }
    })
    dispatch(
      patchState({ rateDefinition: response.data.advancedRateDefinition })
    )
    saved = true

    if (publish) {
      dispatch(
        startLoading({ message: t('advancedRateEditor.messages.publishing') })
      )
      const response = await AdvancedRateDefinitions.publishLatest({
        lotId: lot.id
      })
      dispatch(
        patchState({ rateDefinition: response.data.advancedRateDefinition })
      )
    }
  } catch (err) {
    await Helper.showErrorAlert(err.data)
  }

  dispatch(stopLoading())

  if (saved) {
    // Mark rules as persisted, so that we can remove the confirmation alert if user leaves the page without
    // saving.
    dispatch(markEditableRulesAsPersisted())

    // Change to view mode.
    dispatch(changeMode('view'))

    // Makes the URL points to latest version after saving, in case it's being saved as a copy from a
    // previous version.
    // This is to make sure that if the user refreshes the page after saving, they'll not go back to
    // the previous version.
    dispatch(changeUrlToLatest())
  }
}

export const changeUrlToLatest = () => (_, getState) => {
  const $state = getAngularService('$state')
  const { lot } = getState()

  $state.go(
    'edit-property-advanced-rates',
    { lotId: lot.id, advancedRateDefinitionId: 'latest' },
    { location: 'replace', notify: false }
  )
}

export const scrollToRule = ruleId => () => {
  const Helper = getAngularService('Helper')
  Helper.scrollTo('#' + generateRuleRowId(ruleId))
}

export const scrollToFirstInvalidRule = () => (dispatch, getState) => {
  const { editableRules } = getState()
  const firstInvalidRule = _.find(
    editableRules,
    rule => !_.isEmpty(rule.errors)
  )
  if (firstInvalidRule) {
    dispatch(scrollToRule(firstInvalidRule.id))
  }
}

export const focusOnRule = (ruleId, wait = 100) => () => {
  const rowId = generateRuleRowId(ruleId)

  setTimeout(() => {
    const firstInputOfRow = document.querySelector(`#${rowId} input`)
    if (firstInputOfRow) {
      firstInputOfRow.focus()
    }
  }, wait)
}

export const focusOnLastRule = (wait = 100) => (dispatch, getState) => {
  const { editableRules } = getState()
  if (editableRules.length) {
    const lastId = Math.max(...editableRules.map(rule => rule.id))
    dispatch(focusOnRule(lastId, wait))
  }
}

export const focusOnNewestRule = (ruleId, wait = 100) => () => {
  const rowId = generateRuleRowId(ruleId)

  setTimeout(() => {
    const firstInputOfRow = document.querySelector(`#${rowId} input`)
    if (firstInputOfRow) {
      firstInputOfRow.focus()
    }
  }, wait)
}
