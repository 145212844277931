import React from 'react'

import { SubscriptionStatusBadges } from '../../../../shared/react/components/SubscriptionStatusBadge'
import { calculateSubscriptionStatuses } from '../../../../shared/utils/status-calculation'
import {
  getAngularService,
  getTranslator
} from '../../../../shared/react/utils'

export const transformSubscriptions = subscriptions => {
  return subscriptions.map(subscription => ({
    id: subscription.id,
    start: subscription.initialSubscription
      ? subscription.initialSubscription.subscribedAt
      : subscription.createdAt,
    lotName: subscription.plan.lot.name,
    statuses: calculateSubscriptionStatuses(subscription)
  }))
}

export const getData = ({ subscriptions, handleSort, orders }) => {
  const t = getTranslator()
  const $state = getAngularService('$state')

  const columns = [
    {
      value: t('user.subscriptions.table.start'),
      onClick: e => handleSort(e, 'start'),
      width: '30%',
      sortableKey: 'start',
      key: 'start'
    },
    {
      value: t('user.subscriptions.table.lot'),
      onClick: e => handleSort(e, 'lotName'),
      sortableKey: 'lotName',
      key: 'lotName'
    },
    {
      value: t('user.subscriptions.table.status'),
      onClick: e => handleSort(e, 'statuses'),
      width: '15%',
      sortableKey: 'statuses',
      key: 'statuses'
    }
  ]

  const handleClick = id => {
    $state.go('subscription', { id }, { enableBackLink: true })
  }

  const data = subscriptions.map(subscription => {
    return {
      onClick: () => handleClick(subscription.id),
      start: {
        value: t('defaultFormats.datetimeWithTimezone', {
          date: subscription.start
        }),
        verticalAlign: 'middle'
      },
      lotName: {
        value: subscription.lotName,
        verticalAlign: 'middle'
      },
      statuses: {
        value: <SubscriptionStatusBadges statuses={subscription.statuses} />,
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      }
    }
  })

  const columnsFormatted = columns.map(column => ({
    ...column,
    nowrap: 'nowrap',
    sortable: true,
    orders
  }))

  return {
    columns: columnsFormatted,
    data
  }
}
