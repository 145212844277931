'use strict'

import React from 'react'
import CurrencyFormatter from '@citifyd/currency-formatter'
import { Text } from '@citifyd/style'
import moment from 'moment-timezone'

import Table from '../../../../shared/react/components/Table'
import {
  useAngularService,
  useTranslator
} from '../../../../shared/react/hooks'
import { getCurrentLanguage } from '../../../../shared/react/utils'
import styles from '../RevenueTab/RevenueTab.module.scss'

const RevenueEventsTable = ({
  events = [],
  currentFilter = {},
  totals = {},
  params = {}
}) => {
  const t = useTranslator()
  const language = getCurrentLanguage()
  const $state = useAngularService('$state')

  const columns = [
    {
      value: t('revenue.date'),
      nowrap: 'nowrap',
      key: 'date'
    },
    {
      value: t('revenue.event'),
      nowrap: 'nowrap',
      key: 'event'
    },
    {
      value: t('revenue.venue'),
      nowrap: 'nowrap',
      key: 'venue'
    },
    {
      value: t('revenue.time'),
      nowrap: 'nowrap',
      key: 'time'
    },
    {
      value: t('revenue.sold'),
      nowrap: 'nowrap',
      key: 'sold',
      textAlign: 'right'
    },
    {
      value: t('revenue.revenue'),
      nowrap: 'nowrap',
      key: 'revenue',
      textAlign: 'right'
    }
  ]

  const handleClick = event => {
    $state.go('revenue-of-event', {
      currency: currentFilter.currency,
      lot: currentFilter.lotId || undefined,
      currentLot: currentFilter.lotId || undefined,
      event: event.id,
      dateRange: params.dateRange
    })
  }

  const getDate = (date, timezoneName) => moment.tz(date, timezoneName)

  const data = events.map(event => {
    return {
      onClick: () => handleClick(event),
      date: {
        value: t('defaultFormats.date', {
          date: getDate(event.start, event.timezoneName)
        }),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      event: event.name,
      venue: {
        value: event.venueName,
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      time: {
        value: t('defaultFormats.time', {
          date: getDate(event.start, event.timezoneName)
        }),
        nowrap: 'nowrap',
        verticalAlign: 'middle'
      },
      sold: {
        value: `${event.totalTickets}/${event.totalSpaces}`,
        textAlign: 'right',
        verticalAlign: 'middle'
      },
      revenue: {
        value: CurrencyFormatter.format(event.netRevenueAmount, {
          currency: currentFilter.currency,
          language
        }),
        textAlign: 'right',
        verticalAlign: 'middle',
        textColor: event.netRevenueAmount < 0 ? 'tertiary' : 'default'
      }
    }
  })

  const renderCell = value => (
    <Text textAlign='right' weight='700' className={styles.total}>
      {value}
    </Text>
  )

  const dataWithTotal = [
    ...data,
    {
      appearance: 'white',
      showIcon: false,
      date: {
        value: (
          <Text weight='700' className={styles.total}>
            {t('dailyRevenue.total')}
          </Text>
        ),
        colSpan: 4
      },
      event: {
        hide: true
      },
      venue: {
        hide: true
      },
      time: {
        hide: true
      },
      sold: renderCell(`${totals.totalTickets}/${totals.totalSpaces}`),
      revenue: {
        value: renderCell(
          CurrencyFormatter.format(totals.netRevenueAmount, {
            currency: currentFilter.currency,
            language
          })
        ),
        textColor: totals.netRevenueAmount < 0 ? 'tertiary' : 'default'
      }
    }
  ]

  return (
    <Table
      striped
      columns={columns}
      data={dataWithTotal}
      responsive
      clickable
      showIconRight
      borderBottom
      headerGap
      verticalAlignIconRight='middle'
    />
  )
}

export default RevenueEventsTable
