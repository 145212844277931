'use strict'

import angular from 'angular'

import './admin-edit-user-phone-number.html'
import './admin-edit-user-phone-number.scss'

const app = angular.module('citifydSellerApp')

app.controller('AdminEditUserPhoneNumberModalCtrl', function (
  $i18next,
  $scope,
  $element,
  params,
  $window,
  Helper,
  Users,
  Settings,
  close
) {
  $scope.save = function () {
    if (
      !$scope.clearPhoneNumber &&
      (!$scope.phoneNumber || $scope.phoneNumber.trim() === '')
    ) {
      return $window.alert(
        $i18next.t('modalEditUserPhoneNumber.errors.phoneNumberRequired')
      )
    }

    if (
      !$scope.clearPhoneNumber &&
      !$scope.forcePhoneNumberVerification &&
      $scope.phoneNumber === $scope.originalPhoneNumber &&
      $scope.phoneCountryCode === $scope.originalPhoneCountryCode
    ) {
      return $window.alert(
        $i18next.t('modalEditUserPhoneNumber.errors.phoneNumberChangeRequired')
      )
    }

    $scope.isLoading = true

    const phoneNumber = $scope.clearPhoneNumber ? null : $scope.phoneNumber
    const phoneCountryCode = $scope.phoneCountryCode
    const forceSkipMigrationTrigger =
      $scope.clearPhoneNumber || $scope.skipTriggerMigration

    const body = {
      phoneNumber,
      phoneCountryCode,
      forceSkipMigrationTrigger
    }

    if (!$scope.clearPhoneNumber && $scope.forcePhoneNumberVerification) {
      body.verifiedPhoneNumber = true
    }

    Users.updateUser(params.user.id, body)
      .then(
        function () {
          $scope.isLoading = false
        },

        function (response) {
          $scope.isLoading = false
          Helper.showErrorAlert(response.data)
        }
      )
      .then(function () {
        $scope.successClose()
      })
  }

  // Cancel the modal
  $scope.cancel = function () {
    $element.remodal().close({
      action: 'cancel'
    })
  }

  // Close modal after successful action
  $scope.successClose = function () {
    $element.remodal().close({
      action: 'confirmed'
    })
  }

  // When remodal is closed, we send a close message to the controller that opened this modal.
  $element.on('closed', function (e) {
    close(e.reason)
  })

  function loadCountries () {
    $scope.isLoading = true

    Settings.getCountries().then(countries => {
      $scope.availableCountries = countries
      $scope.isLoading = false
    })
  }

  function load () {
    $scope.isLoading = false

    $scope.clearPhoneNumber = false
    $scope.phoneNumber = params.user.phoneNumber
    $scope.phoneCountryCode = params.user.phoneCountryCode

    $scope.originalPhoneNumber = $scope.phoneNumber
    $scope.originalPhoneCountryCode = $scope.phoneCountryCode

    $scope.skipTriggerMigration = true
    $scope.forcePhoneNumberVerification = false

    $scope.verifiedOriginalPhoneNumber = params.user.verifiedPhoneNumber

    loadCountries()
  }

  load()
})
