'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Reservations', (API, Authentication, $http) => {
  async function find (id) {
    const promise = $http({
      method: 'GET',
      url: `${API.base}/admin/reservations/${id}`,
      headers: Authentication.generateHeaders()
    })

    return promise.then(response => response.data.reservation)
  }

  async function cancel (id, options) {
    const promise = $http({
      method: 'POST',
      url: `${API.base}/admin/reservations/${id}/cancel`,
      headers: Authentication.generateHeaders(),
      data: { refund: options || {} }
    })

    return promise
  }

  function calculateRefundPreview (id, options) {
    const promise = $http({
      method: 'GET',
      url: `${API.base}/admin/reservations/${id}/refund-preview`,
      headers: Authentication.generateHeaders(),
      params: options
    })

    return promise.then(response => response.data)
  }

  async function generateFeeBreakdown (id) {
    const promise = $http({
      method: 'GET',
      url: `${API.base}/admin/reservations/${id}/fee-breakdown`,
      headers: Authentication.generateHeaders()
    })

    return promise.then(response => response.data.feeBreakdown)
  }

  async function getDefaultCancellationOptions (reservationId) {
    const promise = $http({
      method: 'GET',
      url: `${API.base}/admin/reservations/${reservationId}/default-cancellation-options`,
      headers: Authentication.generateHeaders()
    })

    return promise.then(response => response.data.defaultCancellationOptions)
  }

  return {
    find,
    generateFeeBreakdown,
    getDefaultCancellationOptions,
    calculateRefundPreview,
    cancel
  }
})
