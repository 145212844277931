'use strict'

import React, { useState } from 'react'
import { Button, Icon, List, Modal, Text } from '@citifyd/style'
import { useAngularService, useTranslator } from '../../react/hooks'
import moment from 'moment-timezone'
import styles from './UserSessionInformationModal.module.scss'
import LoadingIndicator from '../../react/components/LoadingIndicator'

const UserSessionInformationModal = ({ open, onClose, user }) => {
  const t = useTranslator()
  const Authentication = useAngularService('Authentication')
  const Helper = useAngularService('Helper')
  const Permissions = useAngularService('Permissions')
  const Sessions = useAngularService('Sessions')

  const [isLoading, setIsLoading] = useState(false)
  const [sessions, setSessions] = useState(user.sessions)
  const [changedSession, setChangedSession] = useState(false)

  const loggedUser = Authentication.getLoggedUser()
  const destroyAllowed = Permissions.userMayDestroySessions(loggedUser)

  const getIconClass = session => {
    const os = session.clientInformation.os.toLowerCase()
    const browser = session.clientInformation.browser.toLowerCase()

    let iconName
    if (os === 'ios') {
      iconName = 'apple'
    } else if (os === 'android') {
      iconName = 'android'
    } else if (
      _.includes(['chrome', 'firefox', 'safari', 'opera', 'edge'], browser)
    ) {
      iconName = `${browser}`
    } else if (browser === 'internet explorer') {
      iconName = 'internet-explorer'
    } else {
      iconName = 'desktop'
    }

    return iconName
  }

  const destroySession = async sessionId => {
    try {
      if (!window.confirm(t('modalUserSession.removalConfirmation'))) {
        return
      }

      setIsLoading(true)

      await Sessions.deleteSession(sessionId)

      const updatedSessions = sessions.filter(
        session => session.id !== sessionId
      )
      setSessions(updatedSessions)

      setChangedSession(true)
    } catch (err) {
      Helper.showErrorAlert(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    onClose({ changedSession })
  }

  return (
    <Modal open={open} onClose={handleClose} closeOnOutsideClick>
      <Modal.Title>{t('modalUserSession.device')}</Modal.Title>
      <Modal.Content>
        {sessions.length > 0 && (
          <List border>
            {sessions.map(session => (
              <List.Item
                key={session.id}
                customIconLeft={
                  <Icon.Brand icon={getIconClass(session)} size='h1' />
                }
                customIconRight={
                  destroyAllowed && (
                    <Button appearance='transparent'>
                      <Icon
                        appearance='error'
                        icon='times-circle'
                        size='h3'
                        onMouseDown={() => destroySession(session.id)}
                      />
                    </Button>
                  )
                }
              >
                <div className={styles.session}>
                  <Text>{Sessions.getClientDescription(session)}</Text>
                  <Text variant='small'>
                    {t('modalUserSession.lastAccess', {
                      lastAccess: moment.tz(
                        session.lastRenewalCheckAt,
                        loggedUser.timezoneName
                      )
                    })}
                  </Text>
                </div>
              </List.Item>
            ))}
          </List>
        )}
        {!sessions.length && (
          <>
            <Text variant='subtitle'> {t('modalUserSession.noDevice')}</Text>
          </>
        )}
      </Modal.Content>
      {isLoading && <LoadingIndicator isLoading={isLoading} withOverlay />}
    </Modal>
  )
}

export default UserSessionInformationModal
