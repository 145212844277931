'use strict'

import React, { useState, useEffect } from 'react'
import { find } from 'lodash'
import { NewGrid } from '@citifyd/style'

import LoadingManager from '../../shared/react/components/LoadingManager'
import PageHeader from '../../shared/react/components/PageHeader'
import CSVDownload from '../../shared/react/components/CSVDownload'

import { useAngularService, useTranslator } from '../../shared/react/hooks'
import { fetchLots } from './utils'
import AllPropertiesSelect from './components/AllPropertiesSelect.jsx'
import Summary from './components/Summary'
import Reservations from './components/Reservations'
import ReservedParkingPass from './components/ReservedParkingPass'
import Tickets from './components/Tickets'
import Subscriptions from './components/Subscriptions'
import Bundles from './components/Bundles'

const Page = () => {
  const t = useTranslator()
  const [isLoading, setIsLoading] = useState(true)
  const [lots, setLots] = useState([])
  const [hasError, setHasError] = useState(false)
  const [currencies, setCurrencies] = useState([])
  const [data, setData] = useState([])
  const [propertiesList, setPropertiesList] = useState([])
  const Revenue = useAngularService('Revenue')
  const CitifydModal = useAngularService('CitifydModal')
  const $state = useAngularService('$state')
  const { date, currency, lot } = useAngularService('$stateParams')

  const fetchForDate = () =>
    Revenue.fetchForDate(date, {
      currency: currency,
      lotId: lot
    }).then(response => response?.data)

  const fetchCurrencies = () =>
    Revenue.fetchCurrencies().then(response => response?.data?.currencies)

  const formatPropertiesList = lots => {
    const propertiesList = lots.map(item => ({
      label: item.name,
      value: item.id
    }))

    return [
      { label: t('revenue.revenueFilter.fields.allProperties'), value: '' },
      ...propertiesList
    ]
  }

  const generateCsvClicked = () => {
    const options = {
      lotId: lot,
      currency: currency
    }

    CitifydModal.open('generate-report', {
      fileFormat: 'csv',
      filterDescription: generateCsvReportDescription(),
      reportUrl: Revenue.fetchForDateCsvUrl(date, options)
    })
  }

  const generateCsvReportDescription = () => {
    if (lot) {
      const lot = find(lots, lot => lot.id === lot)
      return t('dailyRevenue.csvReportName.date.lot', {
        date: date,
        lotName: lot.name
      })
    } else {
      return t('dailyRevenue.csvReportName.date.currency', {
        date: date,
        currency: currency
      })
    }
  }

  useEffect(() => {
    async function fetchData () {
      const data = await fetchForDate()
      const currencies = await fetchCurrencies()
      const lots = await fetchLots()

      try {
        setData(data)
        setLots(lots)
        setCurrencies(currencies)
        setPropertiesList(formatPropertiesList(lots))
      } catch (err) {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  const onPropertyChange = id => {
    setIsLoading(true)
    const params = {
      date: date
    }

    if (id) {
      const lot = find(lots, lot => lot.id === id)
      params.lot = id
      params.currency = lot?.country?.currency
    } else {
      params.currency = currencies[0]
    }

    $state.go($state.current.name, params, {
      inherit: false,
      location: 'replace'
    })
  }

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title
          defaultState='revenue-tabs'
          defaultParams={{
            type: 'daily',
            currency: currency,
            lot: lot || undefined
          }}
          title={t('dailyRevenue.revenueOnDate', {
            date: date
          })}
        />
        <PageHeader.Actions>
          <PageHeader.Action>
            <CSVDownload onClick={() => generateCsvClicked()} />
          </PageHeader.Action>
        </PageHeader.Actions>
      </PageHeader>

      <AllPropertiesSelect
        disabled={isLoading}
        selected={lot}
        properties={propertiesList}
        onChange={e => onPropertyChange(parseInt(e.target.value))}
      />

      {(isLoading || hasError) && (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      )}

      {!isLoading && <Summary data={data} />}

      {!isLoading && data?.reservations?.length > 0 && (
        <Reservations reservations={data.reservations} />
      )}

      {!isLoading && data?.reservedParkingPasses?.length > 0 && (
        <ReservedParkingPass
          reservedParkingPasses={data.reservedParkingPasses}
        />
      )}

      {!isLoading && data?.tickets?.length > 0 && (
        <Tickets tickets={data?.tickets} />
      )}

      {!isLoading && data?.bundlePurchases?.length > 0 && (
        <Bundles bundlePurchases={data?.bundlePurchases} />
      )}

      {!isLoading && data?.subscriptions?.length > 0 && (
        <Subscriptions subscriptions={data?.subscriptions} />
      )}
    </NewGrid>
  )
}

export default Page
