'use strict'
import React from 'react'

import { Button, Text, Modal } from '@citifyd/style'

import { useAngularService, useTranslator } from '../../../../react/hooks'
import View from '../../Utils/view'

const DeleteConfirmation = ({ setView, userOrInvitation, setIsSubmitting }) => {
  const t = useTranslator()
  const Users = useAngularService('Users')

  const deleteUserInvitation = async () => {
    setIsSubmitting(true)
    if (userOrInvitation.type === 'invitation') {
      await Users.deleteMyOrganizationUserInvitation(userOrInvitation.code)
    } else {
      await Users.deleteMyOrganizationUser(userOrInvitation.id)
    }
    setIsSubmitting(false)
    setView(View.DELETED)
  }

  return (
    <>
      <Modal.Title>{t('modalManageOrganizationUser.areYouSure')}</Modal.Title>
      <Modal.Content>
        <Text appearance='default' textAlign='center' variant='h3' weight='300'>
          {t('modalManageOrganizationUser.undone')}
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onMouseDown={() => setView(View.FORM)}
          type='button'
          textColor='white'
          appearance='secondary'
          fullWidth
          justifyContent='center'
          uppercase
        >
          {t('commonButtons.cancel')}
        </Button>
        <Button
          fullWidth
          onMouseDown={deleteUserInvitation}
          type='button'
          justifyContent='center'
          extraPadding
          uppercase
        >
          {t('commonButtons.confirm')}
        </Button>
      </Modal.Actions>
    </>
  )
}

export default DeleteConfirmation
