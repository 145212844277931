'use strict'

import React, { useMemo } from 'react'
import AddressFormatter from '@citifyd/address-formatter'

export default function Address ({
  entity,
  format = 'full',
  addressFieldName = 'address',
  countryFieldName = 'countryCode',
  className
}) {
  const html = useMemo(() => {
    let formattedAddress = ''

    if (entity) {
      const address = entity[addressFieldName]
      const country = entity[countryFieldName]
      formattedAddress = AddressFormatter.formatHTML(address, country, format)
    }

    return { __html: formattedAddress }
  }, [entity, format, addressFieldName, countryFieldName])

  return <span dangerouslySetInnerHTML={html} className={className} />
}
