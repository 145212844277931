import React from 'react'

import styles from './UserChart.module.scss'

const UserChart = ({ items, passes, withClass = true }) => (
  <div className={styles.chartCeption}>
    <svg viewBox='0 0 32 32'>
      {items.map(item =>
        withClass ? (
          <circle
            key={item}
            className={styles[item]}
            r='16'
            cx='16'
            cy='16'
            style={passes[item]}
          />
        ) : (
          <circle key={item} r='16' cx='16' cy='16' style={passes[item]} />
        )
      )}
    </svg>

    <div className={styles.smallChart}></div>
  </div>
)

export default UserChart
