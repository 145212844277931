import React, { useEffect, useState } from 'react'
import { startsWith } from 'lodash'
import { Icon, Text } from '@citifyd/style'
import { useAngularService, useTranslator } from '../../hooks'
import FakeLink from '../FakeLink/FakeLink.jsx'
import HistoryTracker from '../../../services/history-tracker'
import styles from './BackLink.module.scss'

export default function BackLink ({
  defaultState,
  defaultParams,
  defaultOptions,
  text,
  noText
}) {
  const t = useTranslator()
  const $state = useAngularService('$state')
  const $stateParams = useAngularService('$stateParams')
  const originParam = $stateParams['_origin']

  const [backState, setBackState] = useState(null)
  const [backUrl, setBackUrl] = useState('')
  const [foundOrigin, setFoundOrigin] = useState(false)
  const [calculatedText, setCalculatedText] = useState('')

  useEffect(() => {
    let backState = null
    let foundOrigin = false
    let calculatedText = ''

    if (originParam) {
      backState = HistoryTracker.getState(originParam)
      if (backState) {
        foundOrigin = true
      }
    }

    if (!backState && defaultState) {
      const state = $state.get(defaultState)
      if (state) {
        backState = {
          name: defaultState,
          params: defaultParams,
          options: defaultOptions
        }
      } else {
        console.error(`BackLink component: State ${defaultState} not found`)
      }
    }

    if (!backState) {
      backState = { name: 'dashboard' }
    }

    if (!noText) {
      if (text) {
        calculatedText = text
      } else {
        const backStateDefinition = $state.get(backState.name)
        if (backStateDefinition.title) {
          let pageTitle = backStateDefinition.title
          if (startsWith(pageTitle, 'i18n:')) {
            pageTitle = t(pageTitle.replace('i18n:', ''))
          }
          calculatedText = t('back.backTo', { title: pageTitle })
        } else {
          calculatedText = t('back.back')
        }
      }
    }

    setBackState(backState)
    setBackUrl($state.href(backState.name, backState.params || {}))
    setFoundOrigin(foundOrigin)
    setCalculatedText(calculatedText)
  }, [defaultState, defaultParams, defaultOptions, noText, text, originParam])

  const goBack = () => {
    if (foundOrigin) {
      // We need to go back first to remove one item from the browser's history, and then
      // immediately replace the location with the defined state to go back.
      window.history.back()
    }

    $state.go(backState.name, backState.params, {
      ...backState.options,
      location: 'replace'
    })
  }

  return (
    <FakeLink href={backUrl} onClick={goBack} className={styles.link}>
      <Icon size='h1' className={styles.icon} icon='chevron-left' />
      <Text variant='h1' component='h1' weight='300'>
        {calculatedText}
      </Text>
    </FakeLink>
  )
}
