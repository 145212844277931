'use strict'

import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { Text, Button, Box, List } from '@citifyd/style'
import { useTranslator } from '../../hooks'
import LoadingIndicator from '../LoadingIndicator'
import { getAngularService } from '../../utils'
import styles from './LanguageSelector.module.scss'

const LanguageSelector = () => {
  const node = useRef()
  const t = useTranslator()
  const Languages = getAngularService('Languages')
  const Helper = getAngularService('Helper')
  const Users = getAngularService('Users')
  const Authentication = getAngularService('Authentication')

  const user = Authentication.getLoggedUser()

  const [currentLanguage, setCurrentLanguage] = useState(
    Languages?.getCurrent()
  )
  const [showLanguages, setShowLanguages] = useState(false)
  const [hideSelectorFadeOut, setHideSelectorFadeOut] = useState(true)
  const [showUpdateOverlay, setShowUpdateOverlay] = useState(false)

  useEffect(() => {
    if (showLanguages) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showLanguages])

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return
    }

    setShowLanguages(false)
  }

  const toogleSelector = () => {
    if (!showLanguages) {
      setHideSelectorFadeOut(false)
      setTimeout(() => {
        setShowLanguages(true)
      }, 200)
    } else {
      setShowLanguages(false)
      setTimeout(() => {
        setHideSelectorFadeOut(true)
      }, 200)
    }
  }

  const onChange = key => {
    if (key === Languages?.getCurrent()) {
      return
    }

    Languages?.change(key, true)

    setTimeout(() => {
      setShowLanguages(false)
    }, 200)

    if (!user) {
      setCurrentLanguage(Languages?.getCurrent())
      return
    }

    setShowUpdateOverlay(true)
    updateUserLanguage(key, true)
  }

  const updateUserLanguage = (language, updatePage) => {
    if (updatePage) {
      setShowUpdateOverlay(true)
    }

    return Users.updateUser(user.id, { language }).then(
      () => {
        if (updatePage) {
          window.location.reload()
        }
      },
      response => Helper.showErrorAlert(response.data)
    )
  }

  return (
    <>
      {showUpdateOverlay && (
        <div className={styles.languageOverlay}>
          <div className={styles.dialog}>
            <LoadingIndicator no-margin='true'></LoadingIndicator>
            <Text variant='subtitle'>
              {t('languageSelector.applyingLanguage')}
            </Text>
          </div>
        </div>
      )}

      <div className={styles.languageSelector} ref={node}>
        <Button
          appearance='transparent'
          uppercase
          textColor='white'
          iconRight={showLanguages ? 'chevron-down' : 'chevron-up'}
          onClick={e => {
            toogleSelector()
            e.stopPropagation()
          }}
        >
          {Languages?.getName(currentLanguage)}
        </Button>

        <Box
          smallRadius
          className={classNames(styles.languagesContent, {
            [styles.enter]: showLanguages,
            [styles.hide]: hideSelectorFadeOut
          })}
          onClick={e => e.stopPropagation()}
        >
          <Box.Content>
            <List border hover='primary'>
              {Languages?.getAvailable().map((language, index) => (
                <List.Item
                  key={index}
                  bold
                  uppercase
                  textComponent='a'
                  onClick={e => {
                    onChange(language.key)
                    e.stopPropagation()
                  }}
                  iconSize='lg'
                  iconRight={
                    language.key === currentLanguage
                      ? ['far', 'check-circle']
                      : ['far', 'circle']
                  }
                  iconAppearance={
                    language.key === currentLanguage ? 'primary' : 'default'
                  }
                >
                  {language.name}
                </List.Item>
              ))}
            </List>
          </Box.Content>
        </Box>
      </div>
    </>
  )
}

export default LanguageSelector
