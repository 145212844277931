'use strict'

import * as yup from 'yup'
import { localDateAndTimeToMoment } from '../../../../shared/react/dateUtils'
import { getTranslator } from '../../../../shared/react/utils'
import Mode from '../../mode'

const requiredWhenAdding = [
  'mode',
  {
    is: mode => mode === Mode.ADD,
    then: yup.string().required(),
    otherwise: yup.string()
  }
]

const validationSchema = () => {
  const t = getTranslator()
  return yup.object().shape({
    name: yup.string().required(),
    venueId: yup.string(),
    startDate: yup.date().required(),
    startTime: yup.string().required(),
    defaultAvailabilityStartDate: yup
      .string()
      .when(...requiredWhenAdding)
      .when(
        ['startDate', 'startTime', 'defaultAvailabilityStartTime', 'mode'],
        (startDate, startTime, defaultAvailabilityStartTime, mode) => {
          if (mode === Mode.EDIT) {
            return
          }

          return yup.string().test({
            test: defaultAvailabilityStartDate => {
              const startMoment = localDateAndTimeToMoment(
                startDate,
                startTime,
                'UTC'
              )

              const availabilityStartMoment = localDateAndTimeToMoment(
                defaultAvailabilityStartDate,
                defaultAvailabilityStartTime,
                'UTC'
              )

              return availabilityStartMoment.isBefore(startMoment)
            },
            message: t('addEvent.errors.mustBeBeforeEventStart')
          })
        }
      ),
    defaultAvailabilityStartTime: yup.string().when(...requiredWhenAdding),
    defaultAvailabilityEndDate: yup
      .string()
      .when(...requiredWhenAdding)
      .when(
        [
          'defaultAvailabilityStartDate',
          'defaultAvailabilityStartTime',
          'defaultAvailabilityEndTime',
          'mode'
        ],
        (
          defaultAvailabilityStartDate,
          defaultAvailabilityStartTime,
          defaultAvailabilityEndTime,
          mode
        ) => {
          if (mode === Mode.EDIT) {
            return
          }

          return yup.string().test({
            test: defaultAvailabilityEndDate => {
              const availabilityStartMoment = localDateAndTimeToMoment(
                defaultAvailabilityStartDate,
                defaultAvailabilityStartTime,
                'UTC'
              )

              const availabilityEndMoment = localDateAndTimeToMoment(
                defaultAvailabilityEndDate,
                defaultAvailabilityEndTime,
                'UTC'
              )

              return availabilityEndMoment.isAfter(availabilityStartMoment)
            },
            message: t('addEvent.errors.mustBeAfterPassDurationStart')
          })
        }
      ),
    defaultAvailabilityEndTime: yup.string().when(...requiredWhenAdding),
    hasImage: yup.bool().when('mode', {
      is: mode => mode === Mode.ADD,
      then: yup.bool().oneOf([true])
    })
  })
}

export default validationSchema
