'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.factory('Sessions', function SessionsFactory (
  API,
  Authentication,
  $i18next,
  $http
) {
  function getMySessions (sessionId) {
    const promise = $http({
      method: 'GET',
      url: API.base + '/me/sessions',
      headers: Authentication.generateHeaders()
    })

    return promise.then(response => response.data.sessions)
  }

  function updateMySession (sessionId, data) {
    const promise = $http({
      method: 'PUT',
      url: API.base + '/me/sessions/' + sessionId,
      headers: Authentication.generateHeaders(),
      data: { session: data }
    })

    return promise.then(response => response.data.session)
  }

  function deleteSession (sessionId) {
    return $http({
      method: 'DELETE',
      url: API.base + '/sessions/' + sessionId,
      headers: Authentication.generateHeaders()
    })
  }

  function getClientDescription (session, glue) {
    const parts = []
    const {
      app,
      appVersion,
      browser,
      os,
      osVersion,
      device
    } = session.clientInformation
    const isMobile = _.includes(['ios', 'android'], os.toLowerCase())

    if (app) {
      let str = app
      if (appVersion) {
        str += ` (${appVersion})`
      }
      parts.push(str)
    }

    if (browser || os) {
      let osWithDevice = ''
      if (os) {
        osWithDevice = os + (osVersion ? ` ${osVersion}` : '')

        if (isMobile) {
          osWithDevice += ` (${device})`
        }
      }

      if (browser && browser !== app) {
        if (os) {
          parts.push(`${browser} on ${osWithDevice}`)
        } else {
          parts.push(browser)
        }
      } else if (os) {
        parts.push(osWithDevice)
      }
    } else {
      parts.push($i18next.t('sessions.unknownDevice'))
    }

    return parts.join(glue || ' - ')
  }

  return {
    getMySessions,
    updateMySession,
    deleteSession,
    getClientDescription
  }
})
