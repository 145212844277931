'use strict'

import angular from 'angular'
import { react2angular } from 'react2angular'
import { uniqueId, kebabCase, get } from 'lodash'

const getAngularInjector = (() => {
  let injector
  return function () {
    if (!injector) injector = angular.element(document.body).injector()
    return injector
  }
})()

export const getAngularService = (() => {
  const cache = {}
  return function (serviceName) {
    if (!cache[serviceName]) {
      cache[serviceName] = getAngularInjector().get(serviceName)
    }
    return cache[serviceName]
  }
})()

export function getTranslator () {
  const $i18next = getAngularService('$i18next')
  return (...args) => $i18next.t(...args)
}

export function getCurrentLanguage () {
  const $i18next = getAngularService('$i18next')
  return $i18next.i18n.language
}

export function getReactComponentAsTemplate (component) {
  const app = angular.module('citifydSellerApp')
  const angularComponentName = uniqueId(`reactComponent`)
  const angularTagName = kebabCase(angularComponentName)
  app.component(angularComponentName, react2angular(component, [], ['$scope']))
  return `<${angularTagName}></${angularTagName}>`
}
