'use strict'

import React from 'react'
import { Icon } from '@citifyd/style'

import styles from './SmallLoadingIndicator.module.scss'

const SmallLoadingIndicator = ({ appearance = 'gray_darker' }) => {
  return (
    <Icon appearance={appearance} className={styles.spinner} icon='spinner' />
  )
}

export default SmallLoadingIndicator
