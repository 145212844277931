'use strict'

const row1 = [
  { field: 'postalCode', width: '25%' },
  { field: 'state', width: '25%' },
  { field: 'city', width: '25%' },
  { field: 'town', width: '25%' }
]

const row2 = [
  { field: 'line1', width: '50%' },
  { field: 'line2', width: '50%' }
]

export default {
  rows: [row1, row2]
}
