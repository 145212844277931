'use strict'
import React, { useState } from 'react'
import { Modal } from '@citifyd/style'
import View from './Utils/view'

import LoadingIndicator from '../../react/components/LoadingIndicator'
import Form from './Components/Form.jsx'
import AddedMessage from './Components/Messages/AddedMessage.jsx'
import DeleteConfirmation from './Components/Messages/DeleteConfirmation.jsx'
import Deleted from './Components/Messages/Deleted.jsx'

const ManageOrganizationUserModal = ({
  mode,
  userOrInvitation,
  open,
  onClose
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [view, setView] = useState(View.FORM)

  return (
    <Modal open={open} onClose={onClose} closeIcon={view === View.FORM}>
      {view === View.FORM && (
        <Form
          userOrInvitation={userOrInvitation}
          mode={mode}
          onClose={onClose}
          setIsSubmitting={setIsSubmitting}
          setView={setView}
        />
      )}
      {view === View.ADDED && <AddedMessage onClose={onClose} />}

      {view === View.DELETE_CONFIRMATION && (
        <DeleteConfirmation
          setView={setView}
          userOrInvitation={userOrInvitation}
          setIsSubmitting={setIsSubmitting}
        />
      )}
      {view === View.DELETED && (
        <Deleted onClose={onClose} userOrInvitation={userOrInvitation} />
      )}

      {isSubmitting && (
        <LoadingIndicator isLoading={isSubmitting} withOverlay />
      )}
    </Modal>
  )
}

export default ManageOrganizationUserModal
