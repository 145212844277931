'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('EventGateCtrl', function (
  Authentication,
  CitifydModal,
  Helper,
  GateEntries,
  $i18next,
  $log,
  $stateParams,
  $scope,
  $timeout
) {
  var eventId = $stateParams.id

  var cashierUpdateTimer
  var scopeDestroyed = false

  $scope.sortLotsForGatesDropdown = function (lots) {
    // first sort by name
    lots = _.sortBy(lots, 'name')

    // put lot of selected gate in first position
    var selectedLotIndex = _.findIndex(lots, {
      id: $scope.selectedEventGate.lot.id
    })
    if (selectedLotIndex !== -1) {
      var selectedLot = lots[selectedLotIndex]
      lots.splice(selectedLotIndex, 1)
      lots.unshift(selectedLot)
    }

    return lots
  }

  $scope.updateSelectedGates = function () {
    var changedEntries = getEntriesWithChangedGates()

    if (!changedEntries.length) {
      return
    }

    $scope.updatingSelectedGates = true
    $log.info(changedEntries.length + ' gates changed. Updating...')

    var promise = Helper.promise()

    _.forEach(changedEntries, function (entry) {
      promise = promise
        .then(function () {
          $log.info('Updating for user #' + entry.user.id)
          return GateEntries.updateParkerGateEntry(
            eventId,
            entry.user.id,
            entry.changeEventGate
          ).then(function () {
            $log.info('Updated.')
          })
        })
        .catch(function (err) {
          $log.error('Error updating for user #' + entry.user.id, err)
        })
    })

    promise.then(function () {
      $log.info('Finished.')
      $scope.updatingSelectedGates = false
      populateGateInfo(true) // reload entries
    })
  }

  $scope.removeSystemGates = function (gates) {
    return _.filter(gates, function (gate) {
      return !gate.isSystemGate
    })
  }

  $scope.cashierCloseoutFilterIs = function (filter) {
    return $scope.cashierCloseoutFilter === filter
  }

  $scope.checkNoFilter = function () {
    var filter = $scope.cashierCloseoutFilter || {}
    return !filter.inProgressOnly && !filter.cashierCloseout
  }

  $scope.isFilteringByInProgressOnly = function () {
    return Boolean(_.get($scope.cashierCloseoutFilter, 'inProgressOnly'))
  }

  $scope.isFilteringByCashierCloseout = function (closeout) {
    return (
      _.get($scope.cashierCloseoutFilter, 'cashierCloseout.id') === closeout.id
    )
  }

  $scope.setCashierCloseoutFilter = function (
    inProgressOnly,
    cashierCloseout,
    scroll
  ) {
    $scope.cashierCloseoutFilter = {
      inProgressOnly: inProgressOnly,
      cashierCloseout: cashierCloseout || null
    }

    if ($scope.cashierCloseoutFilter.inProgressOnly) {
      $scope.filterLabel = $i18next.t('event.cashierCloseoutFilters.inProgress')
    } else if ($scope.cashierCloseoutFilter.cashierCloseout) {
      $scope.filterLabel = $i18next.t('event.cashierCloseoutFilters.cashier', {
        name: cashierCloseout.name,
        counter: cashierCloseout.counter
      })
    } else {
      $scope.filterLabel = $i18next.t('event.cashierCloseoutFilters.all')
    }

    populateGateInfo(true)

    if (scroll) {
      Helper.scrollTo('.gate-entries')
    }
  }

  $scope.getEntriesCount = function (type) {
    if (!$scope.cashierCloseoutFilter) {
      return ''
    }

    if ($scope.cashierCloseoutFilter.inProgressOnly) {
      return _.get(
        $scope.serverData,
        'amountOfInProgress' + type + 'Entries',
        ''
      )
    }

    if ($scope.cashierCloseoutFilter.cashierCloseout) {
      return $scope.cashierCloseoutFilter.cashierCloseout[
        'amountOf' + type + 'Entries'
      ]
    }

    return _.get($scope.serverData, 'amountOf' + type + 'Entries', '')
  }

  $scope.closeoutCashier = function () {
    var params = {
      eventId: eventId,
      eventGateId: $scope.selectedEventGate.id,
      eventGateName: $scope.selectedEventGate.name
    }

    CitifydModal.open('closeout-cashier', params, function (result) {
      if (result && result.action === 'saved') {
        populateGateInfo(true)
      }
    })
  }

  function updateEntries (entries) {
    _.forEach(entries, function (entry) {
      var index = _.findIndex($scope.entries, { id: entry.id })

      if (index !== -1) {
        var changeEventGate = $scope.entries[index].changeEventGate
        $scope.entries[index] = entry
        entry.changeEventGate = changeEventGate // don't overwrite selected event gate on dropdown
      } else {
        $scope.entries.push(entry)
      }
    })
  }

  function updateCashierCloseouts (closeouts) {
    _.forEach(closeouts, function (closeout) {
      var index = _.findIndex($scope.cashierCloseouts, { id: closeout.id })

      if (index !== -1) {
        $scope.cashierCloseouts[index] = closeout
      } else {
        $scope.cashierCloseouts.push(closeout)
      }
    })
  }

  function fetchGateInfo () {
    var options = {}

    if ($scope.lastUpdate) {
      options.updatedAfter = $scope.lastUpdate
    }

    if ($scope.cashierCloseoutFilter) {
      if ($scope.cashierCloseoutFilter.inProgressOnly) {
        options.cashierCloseoutId = 'null'
      } else if ($scope.cashierCloseoutFilter.cashierCloseout) {
        options.cashierCloseoutId =
          $scope.cashierCloseoutFilter.cashierCloseout.id
      }
    }

    var requestPromise

    if ($scope.selectedEventGate.id === -1) {
      requestPromise = GateEntries.getTicketsWithNoEntries(
        eventId,
        $scope.selectedEventGate.lot.id
      ).then(function (response) {
        var tickets = response.data.tickets

        _.forEach(tickets, function (ticket) {
          if (ticket.reservations.length) {
            ticket.vehicle = ticket.reservations[0].vehicle
          }
        })

        return {
          entries: tickets,
          amountOfCitifydEntries: tickets.length
        }
      })
    } else {
      requestPromise = GateEntries.getEntriesForEventGate(
        eventId,
        $scope.selectedEventGate.id,
        options
      ).then(function (response) {
        return response.data
      })
    }

    return requestPromise
  }

  function populateGateInfo (reset) {
    $timeout.cancel(cashierUpdateTimer)
    $log.log(
      'Loading cashier info on gate #' +
        $scope.selectedEventGate.id +
        ' - ' +
        $scope.selectedEventGate.name
    )

    if (reset) {
      $scope.lastUpdate = null
      $scope.entries.length = 0
      $scope.loadingEntries = true
    }

    if (scopeDestroyed) {
      return
    }

    fetchGateInfo()
      .then(
        function (data) {
          $scope.serverData = data
          $scope.lastUpdate = data.lastUpdate

          updateEntries(data.entries)

          if (data.closeouts) {
            updateCashierCloseouts(data.closeouts)
          }

          $scope.loadingEntries = false

          if (!$scope.loadedInitialData) {
            $scope.loadedInitialData = true
          }
        },

        function () {
          $log.error('Error retrieving summary')
        }
      )
      .finally(function () {
        cashierUpdateTimer = $timeout(populateGateInfo, 20000)
      })
  }

  function getEntriesWithChangedGates () {
    return _.filter($scope.entries, function (entry) {
      return entry.changeEventGate
    })
  }

  function setPageUnloadConfirmation () {
    Helper.setPageUnloadConfirmation($scope, function () {
      var changed = getEntriesWithChangedGates()

      if (changed.length) {
        return $i18next.t('event.gatePage.leaveConfirmation')
      }
    })
  }

  function init () {
    $scope.updatingSelectedGates = false
    $scope.loadedInitialData = false
    $scope.cashierCloseouts = []
    $scope.searchQuery = ''
    $scope.entries = []

    $scope.setCashierCloseoutFilter(false)

    setPageUnloadConfirmation()

    $scope.$on('$destroy', function () {
      scopeDestroyed = true
      $timeout.cancel(cashierUpdateTimer)
      $log.info('EventGatesSummaryCtrl scope destroyed')
    })
  }

  init()
})
