'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.filter('abs', function () {
  return function (input) {
    return Math.abs(input)
  }
})
