'use strict'

import React, { useEffect, useState } from 'react'
import { NewGrid, Tab } from '@citifyd/style'

import PageHeader from '../../shared/react/components/PageHeader'
import LoadingManager from '../../shared/react/components/LoadingManager'
import { getAngularService } from '../../shared/react/utils'
import { useAngularService, useTranslator } from '../../shared/react/hooks'
import RevenueTab from './components/RevenueTab'
import { selectDefaultFilters } from './utils'

const Revenue = () => {
  const [selectedTab, setSelectedTab] = useState(null)
  const t = useTranslator()
  const [loadBaseFilterOptions, setLoadBaseFilterOptions] = useState(true)
  const [lots, setLots] = useState([])
  const [hasErrorLots, setHasErrorLots] = useState(false)
  const [currencies, setCurrencies] = useState([])
  const [hasErrorCurrencies, setHasErrorCurrencies] = useState(false)
  const Revenue = useAngularService('Revenue')
  const Authentication = useAngularService('Authentication')
  const Properties = useAngularService('Properties')
  const Currencies = useAngularService('Currencies')

  const handleTab = name => {
    const $state = getAngularService('$state')
    $state.transitionTo(
      'revenue-tabs',
      { type: name },
      { notify: false, location: 'replace', inherit: true }
    )
  }

  const loadCurrencies = async () => {
    const $state = getAngularService('$state')
    setHasErrorCurrencies(false)

    try {
      const response = await Revenue.fetchCurrencies()

      const currencies = response.data.currencies.map(code => ({
        code,
        name: Currencies.getName(code)
      }))

      selectDefaultFilters($state, { currencies }, ['currency', 'dateRange'])

      setCurrencies(currencies)
    } catch (err) {
      console.log('err', err)
      setHasErrorCurrencies(true)
    } finally {
      setLoadBaseFilterOptions(false)
    }
  }

  const loadLots = async () => {
    setHasErrorLots(false)
    const user = Authentication.getLoggedUser()
    const options = {}

    if (user.isAdmin) {
      options.all = true
    }

    try {
      const response = await Properties.findAll(options)

      setLots(response.data.lots)
    } catch (err) {
      console.log('err', err)
      setHasErrorLots(true)
    } finally {
      loadCurrencies()
    }
  }

  useEffect(() => {
    loadLots()

    const $rootScope = getAngularService('$rootScope')
    $rootScope.$watch(
      () => {
        return getAngularService('$state').params.type
      },
      type => {
        setSelectedTab(type)
      }
    )
  }, [])

  if (loadBaseFilterOptions || hasErrorLots || hasErrorCurrencies) {
    return (
      <LoadingManager
        isLoading={loadBaseFilterOptions}
        loadingMessage={t('revenue.loading')}
        hasError={hasErrorLots || hasErrorCurrencies}
      />
    )
  }

  return (
    <NewGrid>
      <PageHeader>
        <PageHeader.Title weight='300' title={t('revenue.revenue')} />
      </PageHeader>
      <Tab stateless>
        <RevenueTab
          active={selectedTab === 'daily'}
          name={t('revenue.revenuePerDay')}
          tab='daily'
          onClick={() => handleTab('daily')}
          lots={lots}
          currencies={currencies}
        />
        <RevenueTab
          active={selectedTab === 'events'}
          name={t('revenue.revenuePerEvent')}
          tab='events'
          onClick={() => handleTab('events')}
          lots={lots}
          currencies={currencies}
        />
        <RevenueTab
          active={selectedTab === 'payouts'}
          name={t('revenue.payoutHistory')}
          tab='payouts'
          onClick={() => handleTab('payouts')}
          lots={lots}
          currencies={currencies}
        />
      </Tab>
    </NewGrid>
  )
}

export default Revenue
