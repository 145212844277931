'use strict'

import React, { useEffect, useState, useMemo } from 'react'
import { useFormik } from 'formik'
import { NewGrid } from '@citifyd/style'

import FormikProvider from '../../shared/react/components/FormikProvider'
import LoadingManager from '../../shared/react/components/LoadingManager'
import TicketExchangeConfirmation from './components/TicketExchangeConfirmation'
import TicketExchangeLot from './components/TicketExchangeLot'
import TicketExchangeEvent from './components/TicketExchangeEvent'
import { useAngularService, useTranslator } from '../../shared/react/hooks'
import { parseEvents, makeValidationSchema, getData } from './utils'

const TicketExchange = () => {
  const t = useTranslator()
  const [step, setStep] = useState(1)
  const $state = useAngularService('$state')
  const $stateParams = useAngularService('$stateParams')
  const Tickets = useAngularService('Tickets')
  const Events = useAngularService('Events')
  const Helper = useAngularService('Helper')
  const [ticket, setTicket] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [loadingMessage, setLoadingMessage] = useState(
    t('ticketExchange.loading')
  )
  const [hasError, setHasError] = useState(false)
  const [events, setEvents] = useState([])

  const showSameEvent = Boolean($stateParams.sameEvent)
  const ticketId = $stateParams.id

  const findEvents = async () => {
    try {
      const response = await Events.findAvailableForExchange(
        ticketId,
        true,
        showSameEvent
      )

      setEvents(parseEvents(response.data.events))
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }

  const loadTicket = async () => {
    setIsLoading(true)
    setHasError(false)

    try {
      const response = await Tickets.load(ticketId)

      setTicket(response.data.ticket)
    } catch (err) {
      setHasError(true)
    } finally {
      findEvents()
    }
  }

  useEffect(() => {
    loadTicket()
  }, [])

  const onSubmit = async values => {
    setIsLoading(true)
    setLoadingMessage(t('ticketExchange.exchangingPass'))

    const data = getData(values)

    try {
      const response = await Tickets.exchange(ticket.id, data)

      if (response) {
        $state.go('ticket', { id: ticket.id }, { location: 'replace' })
      }
    } catch (err) {
      setIsLoading(false)
      Helper.showErrorAlert(err.data)
    }
  }

  const formikbag = useFormik({
    initialValues: {
      selectedEvent: '',
      selectedLot: '',
      selectedCardId: '',
      allowDifferentRate: true,
      reason: '',
      requestedByEmail: false,
      requestedByPhone: false
    },
    onSubmit,
    validationSchema: useMemo(() => makeValidationSchema(), [])
  })

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        onClickTryAgain={loadTicket}
        loadingMessage={loadingMessage}
      />
    )
  }

  const step1 = (
    <TicketExchangeEvent ticket={ticket} events={events} setStep={setStep} />
  )

  const renderStep = () => {
    switch (step) {
      case 1:
        return step1
      case 2:
        return <TicketExchangeLot ticket={ticket} setStep={setStep} />
      case 3:
        return <TicketExchangeConfirmation ticket={ticket} setStep={setStep} />
    }
  }

  return (
    <NewGrid>
      <FormikProvider value={formikbag}>{renderStep()}</FormikProvider>
    </NewGrid>
  )
}

export default TicketExchange
