'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('EventGatesSummaryCtrl', function (
  Authentication,
  CitifydModal,
  Events,
  GateEntries,
  Helper,
  Permissions,
  $log,
  $stateParams,
  $scope,
  $timeout
) {
  var eventId = $stateParams.id

  var dataUpdateTimer
  var scopeDestroyed = false

  $scope.closeoutCashier = function (eventGate) {
    var params = {
      eventId: eventId,
      eventGateId: eventGate.id,
      eventGateName: eventGate.name
    }

    CitifydModal.open('closeout-cashier', params, function (result) {
      if (result && result.action === 'saved') {
        $scope.loadedInitialData = false
        loadSummaryInfo()
      }
    })
  }

  $scope.getTotal = function () {
    var fields = arguments

    return _.sum($scope.summary, function (lot) {
      var values = _.map(fields, function (field) {
        return lot[field]
      })

      return _.sum(values)
    })
  }

  function populateLotAndGateReferencesTo (lots) {
    _.forEach(lots, function (lot) {
      _.forEach(lot.gates, function (gate) {
        gate.lot = lot
        gate.eventGate.lot = lot
        gate.eventGate.gate = gate
      })
    })
  }

  function localizeSystemGateNamesTo (lots) {
    _.forEach(lots, lot => {
      _.forEach(lot.gates, gate => {
        gate.name = GateEntries.localizeSystemGateName(gate)
        gate.eventGate.name = GateEntries.localizeSystemGateName({
          name: gate.eventGate.name,
          isSystemGate: gate.isSystemGate
        })
      })
    })
  }

  function populateLastCashierCloseoutsTo (lots) {
    _.forEach(lots, function (lot) {
      _.forEach(lot.gates, function (gate) {
        var closeouts = gate.eventGate.cashierCloseouts

        if (closeouts.length > 0) {
          gate.eventGate.lastCashierCloseout = _.max(closeouts, 'id')
        } else {
          gate.eventGate.lastCashierCloseout = null
        }
      })
    })
  }

  function populateSumsTo (lots) {
    _.forEach(lots, function (lot) {
      lot.amountOfCitifydEntries = _.sum(
        lot.gates,
        'eventGate.amountOfCitifydEntries'
      )
      lot.amountOfTicketmasterEntries = _.sum(
        lot.gates,
        'eventGate.amountOfTicketmasterEntries'
      )
      lot.amountOfEntries =
        lot.amountOfCitifydEntries + lot.amountOfTicketmasterEntries
    })
  }

  function loadSummaryInfo () {
    $timeout.cancel(dataUpdateTimer)
    $log.log('Refreshing summary for event ' + eventId)

    if (scopeDestroyed) {
      return
    }

    GateEntries.getSummaryForEvent(eventId)
      .then(
        function (lots) {
          localizeSystemGateNamesTo(lots)
          populateSumsTo(lots)
          populateLastCashierCloseoutsTo(lots)
          populateLotAndGateReferencesTo(lots)

          $scope.summary = lots
          $scope.loadedInitialData = true
        },

        function () {
          $log.error('Error retrieving summary')
        }
      )
      .finally(function () {
        dataUpdateTimer = $timeout(loadSummaryInfo, 5000)
      })
  }

  function init () {
    $scope.loadedInitialData = false

    loadSummaryInfo()

    $scope.$on('$destroy', function () {
      scopeDestroyed = true
      $timeout.cancel(dataUpdateTimer)
      $log.info('EventGatesSummaryCtrl scope destroyed')
    })
  }

  init()
})
