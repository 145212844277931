'use strict'

import React, { useEffect, useState } from 'react'
import { DatePicker } from '@citifyd/style'
import { useField } from 'formik'
import moment from 'moment-timezone'

import { getCurrentLanguage } from '../../utils'

const FormikDatePicker = props => {
  const language = getCurrentLanguage()
  const [field, , helpers] = useField(props.name)

  const setDate = date => {
    const dateFormatted = props.shouldResetTime
      ? moment(date)
          .startOf('day')
          .toDate()
      : date

    helpers.setValue(dateFormatted)
  }

  const handleChange = date => {
    setDate(date)
    props.onChange && props.onChange(date)
  }

  return (
    <DatePicker
      selectedDate={field.value}
      locale={language}
      {...props}
      onChange={handleChange}
    />
  )
}

export default FormikDatePicker
