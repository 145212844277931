'use strict'

import angular from 'angular'
import _ from 'lodash'

const app = angular.module('citifydSellerApp')

app.controller('ScheduleEventsCtrl', function (
  Helper,
  API,
  Authentication,
  Permissions,
  CitifydModal,
  Events,
  $i18next,
  $timeout,
  $state,
  $location,
  $log,
  $scope
) {
  var PER_PAGE = 15

  $scope.filter = 'future'
  $scope.events = []
  $scope.currentPage = 0
  $scope.currentRequest = null
  $scope.ended = false
  $scope.loadingEvents = false
  $scope.loadingStats = false
  $scope.searchQuery = ''

  $scope.generateEmptyListMessage = function () {
    const { filter } = $scope
    const localeOptions = {}

    let localeKey = `schedule.events.empty.${filter}`
    if ($scope.selectedProperty) {
      localeKey += 'Lot'
      localeOptions.lotName = $scope.selectedProperty.name
    }
    if ($scope.searchQuery) {
      localeKey += 'Search'
      localeOptions.searchQuery = $scope.searchQuery
    }

    return $i18next.t(localeKey, localeOptions)
  }

  $scope.showStat = function (value) {
    return _.isUndefined(value) ? '-' : value
  }

  $scope.addEvent = function () {
    if (Permissions.userMayCreateEvent()) {
      $state.transitionTo('add-event')
    } else if (Permissions.userMayAppendLotToEvent()) {
      $state.transitionTo('appendable-events')
    }
  }

  $scope.setFilter = function (filter) {
    $scope.filter = filter
    $scope.reloadEvents()

    $scope.mobileFilterMenuOpen = false
  }

  $scope.loadStats = function () {
    const propertyId = $scope.selectedProperty?.id || $state.params.property
    $scope.loadingStats = true
    $scope.stats = {}

    var options = {}

    if (propertyId) {
      options.lotId = propertyId
    }

    Events.fetchStats(options).then(function (response) {
      $scope.loadingStats = false
      $scope.stats = response.data
    })
  }

  $scope.loadMore = function () {
    if ($scope.loadingEvents || $scope.ended) {
      return
    }

    $scope.currentPage++
    $scope.loadingEvents = true

    $log.info('Loading more events (page ' + $scope.currentPage + ')')

    $scope
      .loadEvents($scope.currentPage * PER_PAGE, PER_PAGE)
      .then(function (events) {
        if (events.length < PER_PAGE) {
          $scope.ended = true
        }

        _.forEach(events, function (user) {
          $scope.events.push(user)
        })

        $timeout(function () {
          $scope.loadingEvents = false
        }, 1000)
      })
  }

  $scope.generateReport = function (format) {
    const propertyId = $scope.selectedProperty?.id || $state.params.property

    var url =
      API.base +
      '/admin/events/csv?timezoneName=' +
      Helper.getLocalTimezoneName() +
      (propertyId ? '&lotId=' + propertyId : '') +
      ($scope.filter ? '&filter=' + $scope.filter : '')

    CitifydModal.open('generate-report', {
      fileFormat: format,
      filterDescription: getFilterDescription($scope.filter),
      reportUrl: Authentication.mountAuthenticatedUrl(url)
    })
  }

  $scope.reloadEvents = function () {
    $scope.events = []
    $scope.ended = false
    $scope.loadingEvents = true
    $scope.currentPage = 0

    return $scope.loadEvents(0, PER_PAGE).then(function (events) {
      $scope.loadingEvents = false

      if (events.length < PER_PAGE) {
        $scope.ended = true
      }

      $scope.events = events
    })
  }

  $scope.loadEvents = function (offset, limit) {
    const propertyId = $scope.selectedProperty?.id || $state.params.property

    var options = {
      filter: $scope.filter,
      offset: offset,
      limit: limit
    }

    if (_.trim($scope.searchQuery) !== '') {
      options.search = _.trim($scope.searchQuery)
    }

    if (propertyId) {
      options.lotId = propertyId
    }

    if ($scope.currentRequest) {
      $log.info('Loading aborted')
      $scope.currentRequest.abort()
    }

    $scope.currentRequest = Events.findAll(options)

    return $scope.currentRequest.then(function (response) {
      $scope.currentRequest = null
      $log.info(_.get(response, 'data.events.length', 0) + ' events loaded.')
      return response.data.events
    })
  }

  $scope.totalSpaces = function (event) {
    return _.sum(
      _.flatten(
        _.map(event.lots, function (lot) {
          return lot.spaces
        })
      ),
      'max'
    )
  }

  function getFilterDescription (filter) {
    switch (filter) {
      case 'today':
        return $i18next.t('schedule.events.filterDescription.today')
      case 'week':
        return $i18next.t('schedule.events.filterDescription.week')
      case 'month':
        return $i18next.t('schedule.events.filterDescription.month')
      case 'past':
        return $i18next.t('schedule.events.filterDescription.past')
      default:
        return $i18next.t('schedule.events.filterDescription.none')
    }
  }

  function watchSearchQuery () {
    var timer

    $scope.$watch('searchQuery', function (oldValue, newValue) {
      if (!angular.equals(oldValue, newValue)) {
        if (timer) {
          $timeout.cancel(timer)
        }

        timer = $timeout($scope.reloadEvents, 500)
      }
    })
  }

  function initialize () {
    watchSearchQuery()

    $scope.$watch(
      () => $state.params.property,
      () => {
        $scope.loadStats()
        $scope.reloadEvents()
      }
    )
  }

  initialize()
})
