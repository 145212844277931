'use strict'

import React from 'react'
import { List, Box } from '@citifyd/style'

import { useTranslator, useAngularService } from '../../hooks'
import whiteLogo from '../../../../images/white-logo.png'
import styles from './DropdownHeader.module.scss'

const DropdownHeader = ({ loggedUser, originalUser, setShowDropdown }) => {
  const t = useTranslator()
  const Permissions = useAngularService('Permissions')
  const $window = useAngularService('$window')
  const $state = useAngularService('$state')
  const CitifydModal = useAngularService('CitifydModal')
  const Intercom = useAngularService('Intercom')
  const Authentication = useAngularService('Authentication')

  const userMayAccessLimeDashboard = Permissions.userMayAccessLimeDashboard()
  const userMayAccessPosDashboard = Permissions.userMayAccessPosDashboard()
  const userMayAccessCitifydLive = Permissions.userMayAccessCitifydLive()
  const userMayChangePaymentInformation = Permissions.userMayChangePaymentInformation()

  const openLimeDashboard = () => {
    const url = process.env.LIME_DASHBOARD_URL.replace(/\/$/, '')
    $window.open(`${url}/signin?token=${Authentication.getAccessToken()}`)
  }

  const openEditPaymentModal = () => {
    CitifydModal.open('edit-payment')
    setShowDropdown(false)
  }

  const openLiveApp = () => {
    const url = process.env.LIVE_APP_URL.replace(/\/$/, '')
    $window.open(
      `${url}/#!/user/login?token=${Authentication.getAccessToken()}`
    )
  }

  const openPosDashboard = () => {
    const url = process.env.POS_DASHBOARD_URL.replace(/\/$/, '')
    $window.open(
      `${url}/signin?token=${Authentication.getAccessToken()}&pos=true`
    )
  }

  const logout = () => {
    if (!$window.confirm(t('header.userNav.areYouSure'))) {
      return
    }

    Authentication.logout()
    Intercom.logout()
    $window.location = '/'
  }

  return (
    <Box
      smallRadius
      className={styles.dropdownContent}
      onClick={e => e.stopPropagation()}
    >
      <Box.Content>
        <List hover='primary'>
          {loggedUser?.role !== 'localManager' && (
            <List.Item
              onClick={() => {
                $state.go('properties')
              }}
            >
              {t('header.userNav.account')}
            </List.Item>
          )}

          {userMayChangePaymentInformation && (
            <List.Item textComponent='a' onClick={openEditPaymentModal}>
              {t('header.userNav.payment')}
            </List.Item>
          )}

          {(userMayAccessCitifydLive ||
            userMayAccessLimeDashboard ||
            userMayAccessPosDashboard) && <List.Divisor></List.Divisor>}

          {userMayAccessCitifydLive && (
            <List.Item
              textComponent='a'
              customIconLeft={
                <img src={whiteLogo} className={styles.citifydLogo} />
              }
              onClick={openLiveApp}
            >
              {t('header.userNav.citifydLive')}
            </List.Item>
          )}

          {userMayAccessLimeDashboard && (
            <List.Item
              textComponent='a'
              customIconLeft={
                <img src={whiteLogo} className={styles.citifydLogo} />
              }
              onClick={openLimeDashboard}
            >
              {t('header.userNav.openLime')}
            </List.Item>
          )}

          {userMayAccessPosDashboard && (
            <List.Item
              textComponent='a'
              customIconLeft={
                <img src={whiteLogo} className={styles.citifydLogo} />
              }
              onClick={openPosDashboard}
            >
              {t('header.userNav.openPOS')}
            </List.Item>
          )}

          {(userMayAccessCitifydLive ||
            userMayAccessLimeDashboard ||
            userMayAccessPosDashboard) && <List.Divisor></List.Divisor>}

          {originalUser?.id === loggedUser?.id && (
            <List.Item textComponent='a' onClick={logout}>
              {t('header.userNav.logout')}
            </List.Item>
          )}
        </List>
      </Box.Content>
    </Box>
  )
}

export default DropdownHeader
