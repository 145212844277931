'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.directive('ctSort', function () {
  return {
    restrict: 'A',
    transclude: true,
    template:
      '<a>' +
      '<span ng-transclude></span>' +
      ' <i class="fa arrow" ng-class="{\'fa-caret-up\' : order === key && !reverse, \'fa-caret-down\' : order === key && reverse}"></i>' +
      '</a>',

    scope: {
      order: '=',
      key: '@',
      reverse: '='
    },

    link: function (scope, element) {
      element.addClass('sortable')

      element.click(function () {
        if (scope.order === scope.key) {
          scope.reverse = !scope.reverse
        } else {
          scope.order = scope.key
          scope.reverse = false
        }

        scope.$apply()
      })
    }
  }
})
