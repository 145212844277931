'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.filter('lastWord', function () {
  return function (str) {
    return String(str || '')
      .trim()
      .split(' ')
      .pop()
  }
})
