'use strict'

import angular from 'angular'

const app = angular.module('citifydSellerApp')

app.factory('Venues', function VenuesFactory (API, Authentication, $http) {
  function findAllVenues () {
    return $http({
      method: 'GET',
      url: API.base + '/venues',
      headers: Authentication.generateHeaders()
    })
  }

  function findMyVenues () {
    return $http({
      method: 'GET',
      url: API.base + '/me/organization/venues',
      headers: Authentication.generateHeaders()
    })
  }

  function updateVenue (id, venueData) {
    var data = {}

    if (venueData) {
      data.venue = venueData
    }

    return $http({
      method: 'PUT',
      url: API.base + '/venues/' + id,
      headers: Authentication.generateHeaders(),
      data: data
    })
  }

  return {
    findMyVenues: findMyVenues,
    findAll: findAllVenues,
    update: updateVenue
  }
})
