'use strict'

import angular from 'angular'
import loadScript from 'load-script-global'

const app = angular.module('citifydSellerApp')

app.factory('Stripe', (ENV, $q, $i18next) => {
  let stripeInstance = null

  return {
    load: function () {
      return $q((resolve, reject) => {
        if (stripeInstance) {
          return resolve(stripeInstance)
        }

        loadScript(
          {
            url: 'https://js.stripe.com/v3/',
            global: 'Stripe'
          },
          (err, Stripe) => {
            if (Stripe) {
              stripeInstance = Stripe(ENV.stripeKey, {
                locale: $i18next.i18n.language
              })
              resolve(stripeInstance)
            } else {
              reject(err)
            }
          }
        )
      })
    }
  }
})
