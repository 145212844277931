import gql from 'graphql-tag'

export default gql`
  mutation AdminUpdatePermitPass(
    $id: Int!
    $lotId: Int!
    $startTime: DateTime!
    $endTime: DateTime
  ) {
    admin_updatePermitPass(
      id: $id
      lotId: $lotId
      startTime: $startTime
      endTime: $endTime
    ) {
      __typename
      id
      user {
        id
        name
      }
      lot {
        id
        name
        timezoneName
      }
      vehicle {
        id
        label
        model
        license
      }
      phoneNumber
      phoneCountryCode
      isActive
      status
      startTime
      endTime
      activatedAt
      createdAt
    }
  }
`
